import React from "react";

import Information from '../../User/Information';
import ProfilePicture from '../../User/ProfilePicture';

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function UserProfile(props) {
  const theme = useTheme();

  return (
    <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
      <div style={{display : 'flex', flexDirection : 'row'}}>
        <div style={{marginRight : '20px'}}>
        {
          props.user && props.employees &&
          <ProfilePicture user={props.user} employee={props.employees[props.user.id]} size="extra-large" />
        }
        </div>
        <div style={{flex : 1}}>
          <Typography>{props.user.firstname} {props.user.lastname}</Typography>
          <Typography>{props.user.email}</Typography>
        </div>
      </div>
      <Grid container>
        <Grid xs={12} sm={6} item>
          <Information user={props.user.id} projectId={props.project.details.id} />
        </Grid>
      </Grid>
    </Paper>
  );
}
