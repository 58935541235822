import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FilterListIcon from '@material-ui/icons/FilterList';

import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CloseIcon from '@material-ui/icons/Close';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import { SELECTED_FILTERS } from "../../../../config/localStorage";
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  container : {
    display:'flex',
    flexDirection:'row'
  },
  cell : {
    border:'1px solid ' + theme.palette.secondary.main,
    padding:'5px',
    margin:'5px',
    borderRadius:10,
    display:'flex',
    flexDirection:'column',
    flexGrow:1,
    flexBasis:0
  },
  redBadge : {
    backgroundColor:'red',
    color:'#FFFFFF'
  },
  blueBadge : {
    backgroundColor:'blue',
    color:'#FFFFFF'
  },
  purpleBadge : {
    backgroundColor:'purple',
    color:'#FFFFFF'
  },
  goldBadge : {
    backgroundColor:'gold',
    color:'#FFFFFF'
  },
  badgeBox : {
    width:'27px',
    height:'27px'
  }
}));

function localFilters() {
  const filters = localStorage.getItem(SELECTED_FILTERS) !== '' ? JSON.parse(localStorage.getItem(SELECTED_FILTERS)) : null;
  if (filters) {
    return filters;
  } else {
    return null;
  }
}

export default function Filters(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { setFilters } = props;

  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(localFilters());
  const [subordinates, setSubordinates] = useState({});
  const [search, setSearch] = useState(null);

  useEffect(() => {
    let mounted = true;
    API.get('budeeBackend', 'project/supervisor', {
      queryStringParameters : {
        projectId : props.project.details.id
      }
    }).then((res) => {
      if (mounted) {
        let subs = {};
        for (const id of res) {
          if (props.employees.hasOwnProperty(id)) {
            subs[id] = props.employees[id];
          }
        }
        setSubordinates(subs);
      }
    });
    return () => mounted = false;
  }, [props.project, props.employees])

  useEffect(() => {
    let emps = Object.values(props.employees).filter(e => !e.inactive);
    emps.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setEmployees(emps);
  }, [props.employees]);

  useEffect(() => {
    if (setFilters) {
      let filts = null;
      if (active && subordinates) {
        filts = {
          ...active,
          subordinates : Object.keys(subordinates)
        };
      } else if (!active && subordinates && Object.keys(subordinates).length > 0) {
        filts = {
          subordinates : Object.keys(subordinates)
        };
      }
      setFilters(filts);
    }
    localStorage.setItem(SELECTED_FILTERS, JSON.stringify(active));
  }, [subordinates, active, setFilters]);

  const handleClose = () => {
    setOpen(false);
  }

  const remove = (type, val) => {
    var copy = {...active};
    switch(type) {
      case 'roles':
        if (copy.roles.includes(val)) {
          copy.roles.splice(copy.roles.indexOf(val), 1);
        }
        break;
      case 'departments':
        if (copy.departments.includes(val)) {
          copy.departments.splice(copy.departments.indexOf(val), 1);
        }
        break;
      case 'contractors':
        if (copy.contractors.includes(val)) {
          copy.contractors.splice(copy.contractors.indexOf(val), 1);
        }
        break;
      default:
    }
    setActive(copy.roles.length > 0 || copy.departments.length > 0 || copy.contractors.length > 0 ? copy : null);
  }

  const add = (type, val) => {
    var copy;
    if (active) {
      copy = {...active};
    } else {
      copy = {
        roles : [],
        departments : [],
        contractors : []
      }
    }

    switch(type) {
      case 'roles':
        if (!copy.roles.includes(val)) {
          copy.roles.push(val);
        }
        break;
      case 'departments':
        if (!copy.departments.includes(val)) {
          copy.departments.push(val);
        }
        break;
      case 'contractors':
        if (!copy.contractors.includes(val)) {
          copy.contractors.push(val);
        }
        break;
      default:
    }
    setActive(copy);
  }

  const updateSubordinates = async (subs) => {
    return API.post('budeeBackend', 'project/supervisor', {
      body : {
        projectId : props.project.details.id,
        subordinates : subs
      }
    });
  }

  const handleClear = (e) => {
    e.stopPropagation();
    localStorage.removeItem(SELECTED_FILTERS);
    setActive(null);
    setSubordinates({})
    updateSubordinates([]);
  }

  return (
    <>
      <div style={{width:'75px'}}>
        <FilterListIcon style={{cursor:'pointer'}} onClick={() => setOpen(true)} />
      </div>
      {
        (active || (subordinates && Object.keys(subordinates).length > 0)) &&
        <div>
          {
            active && active.roles && active.roles.length > 0 &&
            <Badge badgeContent={active.roles.length} classes={{badge:classes.redBadge}}><div className={classes.badgeBox}>R</div></Badge>
          }
          {
            active && active.departments && active.departments.length > 0 &&
            <Badge badgeContent={active.departments.length} classes={{badge:classes.blueBadge}}><div className={classes.badgeBox}>D</div></Badge>
          }
          {
            active && active.contractors && active.contractors.length > 0 &&
            <Badge badgeContent={active.contractors.length} classes={{badge:classes.purpleBadge}}><div className={classes.badgeBox}>C</div></Badge>
          }
          {
            subordinates && Object.keys(subordinates).length > 0 &&
            <Badge badgeContent={Object.keys(subordinates).length} classes={{badge:classes.goldBadge}}><div className={classes.badgeBox}>E</div></Badge>
          }
          <Button variant="outlined" color="secondary" style={{marginLeft:'20px'}} onClick={(e) => handleClear(e)}>
            CLEAR
          </Button>
        </div>
      }
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div className={classes.cell}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <WorkOutlineIcon style={{color:'red',fontSize:20,marginRight:'5px'}} />
                <Typography variant="h6" style={{color:'red'}}>Roles</Typography>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {
                props.roles && props.roles.map((r, i) => {
                  return (
                    <FormControlLabel
                      key={'role-' + i}
                      control={
                        <Checkbox
                          checked={active && active.roles ? active.roles.includes(r.name) : false}
                          onChange={(e) => e.target.checked ? add('roles', e.target.name) : remove('roles', e.target.name)}
                          name={r.name}
                          color="primary"
                        />
                      }
                      label={r.name}
                    />
                  )
                })
              }
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <BusinessIcon style={{color:'purple',fontSize:20,marginRight:'5px'}} />
                <Typography variant="h6" style={{color:'purple'}}>Contractors</Typography>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {
                props.contractors && props.contractors.map((c, i) => {
                  return (
                    <FormControlLabel
                      key={'contractor-' + i}
                      control={
                        <Checkbox
                          checked={active && active.contractors ? active.contractors.includes(c.name) : false}
                          onChange={(e) => e.target.checked ? add('contractors', e.target.name) : remove('contractors', e.target.name)}
                          name={c.name}
                          color="primary"
                        />
                      }
                      label={c.name}
                    />
                  )
                })
              }
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <GroupWorkIcon style={{color:'blue',fontSize:20,marginRight:'5px'}} />
                <Typography variant="h6" style={{color:'blue'}}>Departments</Typography>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {
                props.departments && props.departments.map((d, i) => {
                  return (
                    <FormControlLabel
                      key={'department-' + i}
                      control={
                        <Checkbox
                          checked={active && active.departments ? active.departments.includes(d.name) : false}
                          onChange={(e) => e.target.checked ? add('departments', e.target.name) : remove('departments', e.target.name)}
                          name={d.name}
                          color="primary"
                        />
                      }
                      label={d.name}
                    />
                  )
                })
              }
              </div>
            </div>
            <div className={classes.cell}>
              <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <div style={{marginRight:'5px'}}>
                    <ProfilePicture size={'small'} user={props.user} employee={null} />
                  </div>
                  <Typography variant="h6" style={{color:'gold'}}>Employee(s)</Typography>
                </div>
                <div style={{flexGrow:1}}></div>
                {
                  subordinates && Object.keys(subordinates).length > 0 &&
                  <Button color="secondary" variant="outlined" onClick={() => {
                    setSubordinates({});
                    updateSubordinates([]);
                    setSearch(null);
                  }}>
                    Clear All
                  </Button>
                }
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Typography variant="h6">Search and Add:</Typography>
                <Autocomplete
                  id="employee-lookup"
                  options={employees}
                  value={search}
                  onChange={(e, v) => {
                    if (v) {
                      let copy = {...subordinates};
                      copy[v.id] = v
                      setSubordinates(copy);
                      updateSubordinates(Object.keys(copy));
                      setSearch(v);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => {
                    return (
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <ProfilePicture user={props.user} employee={option} />
                        <Typography style={{marginLeft:'10px'}} variant="body2">{option.name}</Typography>
                      </div>
                    )
                  }}
                  style={{ width: 300, marginLeft: '10px' }}
                  renderInput={(params) => {
                    return (
                      <>
                        {
                          params.value &&
                          <>
                            <TextField {...params} label="Employee Name" variant="outlined" />
                            <IconButton onClick={() => setSearch(null)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        }
                        {
                          !params.value && props.user &&
                          <TextField {...params} value={props.user.name} label="Employee Name" variant="outlined" />
                        }
                      </>
                    );
                  }}
                />
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {
                  subordinates && Object.values(subordinates).map((emp,i) => {
                    return (
                      <ListItem key={'missing-' + emp.id} style={{width:'50%',marginBottom:'2px',borderRadius:10,border: theme.palette.primary.main}}>
                        <ListItemAvatar>
                          <ProfilePicture user={props.user} employee={emp} />
                        </ListItemAvatar>
                        <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                        <IconButton onClick={() => {
                          let copy = {...subordinates};
                          delete copy[emp.id];
                          setSubordinates(copy);
                          updateSubordinates(Object.keys(copy));
                        }}>
                          <CloseIcon />
                        </IconButton>
                      </ListItem>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
