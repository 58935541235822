import React from "react";

import Dynamic from './Documents/Dynamic';
import Upload from './Documents/Upload';
import queryString from 'query-string';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { useTheme } from '@material-ui/core/styles';

const { PRIVILEGED_USER } = require('@budeesolutions/budee-constants');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function setTab(params, status) {
  if (params) {
    switch(params.type) {
      case 'dynamic':
        return 0;
      case 'upload':
        return PRIVILEGED_USER.includes(status) ? 1 : 0;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function setQuery(val, status) {
  if (val) {
    switch(val) {
      case 0:
        return 'dynamic';
      case 1:
        return 'upload';
      default:
        return 'dynamic';
    }
  } else {
    if (PRIVILEGED_USER.includes(status)) {
      return 'dynamic';
    } else {
      return 'upload';
    }
  }
}

export default function Documents(props) {
  const theme = useTheme();
  var parsed = queryString.parse(props.location.search);
  const [value, setValue] = React.useState(setTab(parsed, props.project.status));

  const handleChange = (event, newValue) => {
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify({type:setQuery(newValue, props.project.status)})
    });
    setValue(newValue);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
            <Typography variant="h6" gutterBottom>Documents</Typography>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="Manage Personnel" variant="scrollable" scrollButtons="auto">
                {
                  props.project && PRIVILEGED_USER.includes(props.project.status) &&
                  <Tab label="Documents" {...a11yProps(0)} />
                }
                <Tab label="Uploads" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            {
              props.project && PRIVILEGED_USER.includes(props.project.status) &&
              <>
                <TabPanel value={value} index={0}>
                  <Dynamic {...props} />
                </TabPanel>
              </>
            }
            <TabPanel value={value} index={props.project && PRIVILEGED_USER.includes(props.project.status) ? 1 : 0}>
              <Upload {...props} />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
