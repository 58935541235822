import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Storage } from "aws-amplify";

export default function Text(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const [imageUrl, setImageUrl] = useState(null);

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);

  useEffect(() => {
    let mounted = true;
    if (props.field && props.field.config && props.field.config.image) {
      Storage.get(props.field.config.image.key, {
        level: "protected",
        identityId : props.field.config.image.identityId
      }).then(value => {
        if (mounted) {
          setImageUrl(value);
        }
      });
    }
    return () => mounted = false;
  }, [props.field]);

  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions, imageUrl]);

  return (
    <Grid xs={12} sm={(props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
      <Typography variant={props.print ? "body2" : "subtitle2"} style={{width:'100%',fontSize: props.print ? '12px' : '16px'}}>{props.field.name}</Typography>
      {
        imageUrl &&
        <img style={{width:'100%'}} src={imageUrl} alt={props.field.name} />
      }
    </Grid>
  );
}
