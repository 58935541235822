import React, { useState, useEffect } from "react";

import { useTheme } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import Registration from '../../../Project/Registration';
import ProfilePicture from '../../../User/ProfilePicture';
import Role from '../../../User/Role';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { makeStyles } from '@material-ui/core/styles';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({

}));

export default function Projects(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [company, setCompany] = useState(props.company);
  const [profile, setProfile] = useState(null);
  const [open, setOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [projectSelected, setProjectSelected] = useState(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleArchiveOpen = (id) => {
    setProjectId(id);
    setArchiveOpen(true);
  };

  const handleDetailsOpen = () => {
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  const handleArchiveClose = () => {
    setProjectId(null);
    setArchiveOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProfile = async (id, mounted) => {
    const res = await API.get('budeeBackend', 'company/profile?companyId=' + id , {});
    if (mounted) {
      setProfile(res);
    }
  };

  const archiveProject = async(projectId) => {
    return API.del('budeeBackend', 'project/', {body : {projectId : projectId}}).then(res => {
      getProfile(company.details.id, true);
      handleArchiveClose();
      handleDetailsClose();
      if (props.company && props.company.details) {
        props.loadProjects(props.company.details.id);
      }
    });
  }

  useEffect(() => {
    if (company) {
      var mounted = true;
      getProfile(company.details.id, mounted).then(res => {
        if (mounted)
          setLoading(false)
      });
    }
  }, [company]);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  useEffect(() => {
    if (profile) {
      var data = [];
      for (const project of profile) {
        var total = project.employees.basic + project.employees.superadmin.length + project.employees.supervisor.length;
        data.push({
          id : project.details.id,
          name : project.details.name,
          city : project.details.address.city,
          street : project.details.address.line1,
          employees : total,
          project : project
        });
      }
      setRows(data);
    }
  }, [profile]);

  const columns = [
    { field: 'id', hide : true },
    { field: 'name', headerName: 'Name', flex : 1 },
    { field: 'city', headerName: 'City', flex : 1 },
    { field: 'street', headerName: 'Street', flex : 1 },
    { field: 'employees', headerName: 'Employees', flex : 1 }
  ];

  return (
    <Grid container>
      <Grid xs={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6" gutterBottom>Projects</Typography>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loading && rows && rows.length === 0 &&
            <p>Currently you don't have any programs.</p>
          }
          {
            !loading && rows && rows.length > 0 &&
            <>
              <div style={{height:'400px',marginBottom:'5px'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  density="compact"
                  autoPageSize={true}
                  className={classes.row}
                  onRowClick={(data) => {
                    setProjectSelected(data.row.project);
                    handleDetailsOpen();
                  }}
                />
              </div>
            </>
          }
          {
            company.root &&
            <Button size="large" color="primary" variant="outlined" onClick={handleClickOpen}>
              Add New Project
            </Button>
          }
          {
            !company.root && props.project.status === USER_STATUS.SUPERADMIN &&
            <Tooltip title="Only Root Accounts can add new projects, click on the Company Profile to see a list of root account holders for this company.">
              <span>
                <Button disabled size="large" color="primary" variant="outlined" onClick={handleClickOpen}>
                  Add New Project
                </Button>
              </span>
            </Tooltip>
          }
          <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Add a New Project
            </DialogTitle>
            <DialogContent>
              <Registration companyId={company.details.id} onComplete={() => {
                setOpen(false);
                getProfile(company.details.id, true);
              }} first={false} {...props} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={detailsOpen}
            onClose={handleDetailsClose}
            fullScreen={fullScreen}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Project Details
            </DialogTitle>
            <DialogContent>
            {
              projectSelected &&
              <>
                <div style={{display:'flex', flexDirection : 'row'}}>
                  <Typography variant="h6" style={{flexGrow : 1}}>{projectSelected.details.name}</Typography>
                  {
                    company.root &&
                    <Button autoFocus disableRipple onClick={() => handleArchiveOpen(projectSelected.details.id)} variant="outlined" color="secondary">
                      Archive
                    </Button>
                  }
                </div>
                <Typography variant="body2" color="textSecondary" component="p">
                  {projectSelected.details.address.line1}<br/>
                  {projectSelected.details.address.line2}{projectSelected.details.address.line2 !== null && <br/>}
                  {projectSelected.details.address.city}, {projectSelected.details.address.state}<br/>
                  {projectSelected.details.address.postal}, {projectSelected.details.address.country}<br/>
                </Typography>
                <div style={{marginTop:'20px'}}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      ({projectSelected.employees.superadmin.length}) Administrators
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {
                          projectSelected.employees.superadmin.map((user, i) => {
                            return (
                              <ListItem key={'superadmin-' + i} button>
                                <ListItemAvatar>
                                  <ProfilePicture user={props.user} employee={props.employees[user.id]} />
                                </ListItemAvatar>
                                <ListItemText primary={user.name} secondary={<Role user={user}/>} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>({projectSelected.employees.supervisor.length}) Supervisors</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {
                          projectSelected.employees.supervisor.map((user, i) => {
                            return (
                              <ListItem key={'superadmin-' + i} button>
                                <ListItemAvatar>
                                  <ProfilePicture user={props.user} employee={props.employees[user.id]} />
                                </ListItemAvatar>
                                <ListItemText primary={user.name} secondary={<Role user={user}/>} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <Typography className={classes.heading} style={{padding:'10px'}}>({projectSelected.employees.basic}) All other users registered to this company</Typography>
              </>
            }
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleDetailsClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={archiveOpen}
            onClose={handleArchiveClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Are you sure you want to archive this project?
            </DialogTitle>
            <DialogContent>
              Archived projects are not lost, they are only unarchived by root account users and hidden from all others.  Users attached to archived projects will not be billed the following months.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => archiveProject(projectId)} color="primary">
                Archive
              </Button>
              <Button onClick={handleArchiveClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
}
