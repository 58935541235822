import React, { useState, useEffect } from "react";

import { useFormFields } from "../../libs/hooks";
import { API } from "aws-amplify";
import { phone } from 'phone';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const callingCountries = require('country-data').callingCountries;

const useStyles = makeStyles((theme) => ({
  select : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

export default function Information(props) {

  const classes = useStyles();
  const [userId, setUserId] = useState(props.user);
  const [information, setInformation] = useFormFields({
    relationship : "",
    phone : "",
    name : "",
    ethnicity : "",
    resident : "",
    medications : "",
    allergies : ""
  });
  const [errors, setErrors] = useState({
    relationship : false,
    name : false,
    phone : false
  });
  const [gender, setGender] = useState('unselected');
  const [countryCode, setCountryCode] = useState("{\"alpha2\":\"US\",\"alpha3\":\"USA\",\"countryCallingCodes\":[\"+1\"],\"currencies\":[\"USD\"],\"emoji\":\"\uD83C\uDDFA\uD83C\uDDF8\",\"ioc\":\"USA\",\"languages\":[\"eng\"],\"name\":\"United States\",\"status\":\"assigned\"}");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    setUserId(props.user);
  }, [props.user])

  const save = () => {
    const country = JSON.parse(countryCode);
    const phoneObj = phone(country.countryCallingCodes[0] + information.phone,{country: country.alpha3});
    var err = {
      relationship : information.relationship ? false : true,
      name : information.name ? false : true,
      phone : phoneObj.isValid ? false : true
    };
    setErrors(err);
    if (Object.values(err).includes(true)) {
      return;
    } else {
      setSaving(true);
      let user = {
        id : userId,
        emergencyContact : {
          relationship : information.relationship,
          phone : phoneObj.phoneNumber,
          name : information.name,
          medications : information.medications,
          allergies : information.allergies
        },
        gender : gender ? gender : null,
        ethnicity : information.ethnicity ? information.ethnicity : null,
        resident : information.resident ? information.resident : null
      };
      API.post('budeeBackend', 'user/profile', {
        body : {
          user : user,
          projectId : props.projectId
        }
      }).then(val => {
        if (props.handleClose) {
          props.handleClose();
        }
        if (props.removeMissing) {
          props.removeMissing(userId);
        }
        if (props.onComplete) {
          props.onComplete(user);
        }
        setSaving(false);
      })
    }
  }

  const load = () => {
    if (userId) {
      API.get('budeeBackend', 'user/profile?userId=' + userId, {}).then(val => {
        var phoneObj = null;
        if (val && val.emergencyContact && val.emergencyContact.phone) {
          phoneObj = phone(val.emergencyContact.phone, {country:null});
          if (phoneObj.isValid) {
            try {
              setCountryCode(JSON.stringify(callingCountries[phoneObj.countryIso3]));
            } catch(e) {
            }
          }
        }
        setInformation({
          target : [
            {id:'relationship', value: val.emergencyContact ? val.emergencyContact.relationship : ''},
            {id:'name', value: val.emergencyContact ? val.emergencyContact.name : ''},
            {id:'medications', value: val.emergencyContact ? val.emergencyContact.medications : ''},
            {id:'allergies', value: val.emergencyContact ? val.emergencyContact.allergies : ''},
            {id:'phone', value: phoneObj ? phoneObj.phoneNumber.replace(phoneObj.countryCode, '') : ''},
            {id:'ethnicity', value:val.ethnicity},
            {id:'resident', value:val.resident}
          ]
        });
        setGender(val.gender ? val.gender : 'unselected');
        setLoading(false);
      }).catch(e => setLoading(false));
    }
  }

  return (
    <>
      {
        loading &&
        <div>
          <CircularProgress size={80} />
        </div>
      }
      {
        !loading &&
        <div style={{display:'flex',flexDirection:'column'}}>
          <Typography variant="h6">Emergency Contact</Typography>
          <TextField
            variant="outlined"
            error={errors.relationship}
            required
            fullWidth
            size="small"
            id="relationship"
            label="Relationship"
            name="relationship"
            autoFocus
            value={information.relationship}
            onChange={setInformation}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={errors.name}
            required
            fullWidth
            size="small"
            id="name"
            label="Name"
            name="name"
            value={information.name}
            onChange={setInformation}
            margin="normal"
          />
          <div style={{display:'flex',flexDirection:'row'}}>
            <Select
              variant="outlined"
              id="countryCode"
              name="countryCode"
              size="small"
              className={classes.select}
              classes={{root : classes.selectInput}}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              {
                callingCountries && callingCountries.all.map((ctry, i) => {
                  return (
                    <MenuItem key={'country-' + i} value={JSON.stringify(ctry)}>{ctry.emoji} - {ctry.alpha3}</MenuItem>
                  );
                })
              }
            </Select>
            <TextField
              variant="outlined"
              error={errors.phone}
              required
              fullWidth
              size="small"
              id="phone"
              label="Phone"
              name="phone"
              value={information.phone}
              onChange={setInformation}
              margin="normal"
            />
          </div>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="medications"
            label="Medications"
            name="medications"
            value={information.medications}
            onChange={setInformation}
            margin="normal"
            multiline={true}
            rows={3}
          />
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="allergies"
            label="Allergies"
            name="allergies"
            value={information.allergies}
            onChange={setInformation}
            margin="normal"
            multiline={true}
            rows={3}
          />
          <Divider style={{marginTop:'5px',marginBottom:'5px'}} />
          <Typography variant="h6">Additional Information (Non-Required)</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="ethnicity"
            label="Ethnicity"
            name="ethnicity"
            value={information.ethnicity}
            onChange={setInformation}
            margin="normal"
          />
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="resident"
            label="City of Residence"
            name="resident"
            value={information.resident}
            onChange={setInformation}
            margin="normal"
          />
          <Select
            variant="outlined"
            id="gender"
            name="gender"
            size="small"
            className={classes.select}
            classes={{root : classes.selectInput}}
            value={gender}
            fullWidth
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value={'unselected'}>Select Gender</MenuItem>
            <MenuItem value={'Male'}>Male</MenuItem>
            <MenuItem value={'Female'}>Female</MenuItem>
            <MenuItem value={'Non-Binary'}>Non-Binary</MenuItem>
          </Select>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{marginTop:'10px'}}
            onClick={() => save()}
          >
          {
            saving &&
            <div>
              <CircularProgress size={10} />
            </div>
          }
          {
            !saving && "Update"
          }
          </Button>
        </div>
      }
    </>
  );
}
