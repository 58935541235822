import React, { useState, useEffect } from "react";

import { API, Auth, Storage } from 'aws-amplify';
import { useFormFields, useEffectWhen } from "../../../libs/hooks";
import { useTheme } from '@material-ui/core/styles';
import { format } from 'date-fns';

import ProfilePicture from "../../User/ProfilePicture";
import Role from "../../User/Role";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { DataGrid } from '@material-ui/data-grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Lightbox from 'react-image-lightbox';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { isMobile } from "react-device-detect";
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

const { formatDateTime } = require('@budeesolutions/budee-util');
const { USER_STATUS } = require('@budeesolutions/budee-constants');

const columns = [
  { field: 'id', hide : true },
  { field: 'date', headerName: 'Date', flex: 1, renderCell : (val) => {
    return (
      <Typography variant="body2" color="textSecondary">{format(val.value, 'yyyy/MM/dd  hh:mmaaa')}</Typography>
    )
  } },
  { field: 'title', headerName: 'Type', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
  { field: 'acknowledged', headerName: 'Acknowledged', flex: 1, renderCell : (val) => {
    return (
      <Box display="flex" width="100%" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgressWithLabel style={{height:'20px', width:'100%'}} variant="determinate" value={val.value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(val.value)}%`}
          </Typography>
        </Box>
      </Box>
    )
  } },
  { field: 'sentBy', headerName: 'Sent By', flex: 1 }
];

const useStyles = makeStyles((theme) => ({
  radioItem : {
    flexGrow : 1,
    flexBasis : 0
  },
  rowContainer : {
    width:'100%',
    display:'flex'
  },
  listItemText : {
    fontSize : '1.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize : '0.8em'
    }
  },
  title : {
    color : theme.palette.primary.main,
    fontWeight : 'bold'
  },
  listContainer : {
    height : '350px',
    width : '100%'
  },
  alertRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  }
}));

export default function Alerts(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [type, setType] = React.useState(null);
  const [distributed, setDistributed] = React.useState(null);
  const [department, setDepartment] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [contractor, setContractor] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [updating, setUpdating] = useState(false);
  const [distributionList, setDistributionList] = useState([]);

  const [errorSending, setErrorSending] = useState(false);
  const [loadingAlerts, setLoadingAlerts] = React.useState(false);
  const [alerts, setAlerts] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchType, setSearchType] = React.useState('unselected');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [uploadingPicture, setUploadingPicture] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [uploadingPdf, setUploadingPdf] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [link, setLink] = useState('');

  const [alert, setAlert] = useFormFields({
    description : ""
  });
  const [custom, setCustom] = useFormFields({
    message : ""
  });
  const [customErrors, setCustomErrors] = useState({
    message : false
  });
  const [alertDetails, setAlertDetails] = useState(null);
  const [alertErrors, setAlertErrors] = useState({
    description : false,
    type : false,
    distributionList : false
  });
  const [loading, setLoading] = React.useState(false);

  const [filter, setFilter] = useState('');
  const [searchAcknowledged, setSearchAcknowledged] = useState('');

  const handleClickOpen = async (event) => {
    setOpen(true);
    setLoading(true);
    setAlertDetails(await API.get("budeeBackend", "notifications?projectId=" + props.project.details.id + '&notificationId=' + event.row.id, {}));
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleDistributedChange = (event) => {
    setDistributed(event.target.value);
  };

  const clearAlert = async () => {
    setAlert({target:{id:'description',value:''}});
    setDepartment(null);
    setRole(null);
    setContractor(null);
    setDistributionList([]);
    setDistributed(null);
    setType(null);
    setImages([]);
    setImageUrls([]);
    setPdf(null);
    setPdfUrl(null);
  }

  const sendAlert = async () => {
    const check = {
      type : !type ? true : false,
      description : alert.description === "" ? true : false,
      distributionList : distributionList.length === 0 ? true : false
    };
    setAlertErrors(check);
    var checkCustom = {};
    if (type === 'Custom') {
      checkCustom = {
        message : custom.message === "" ? true : false
      }
      setCustomErrors(checkCustom);
    }
    if (!Object.values(check).includes(true)) {
      if (type === 'Custom' && Object.values(checkCustom).includes(true)) {
        return;
      }
      setUpdating(true);
      var distribution = {
        all : false,
        supervisors : false,
        administrators : false,
        departments : [],
        roles : [],
        contractors : [],
        singles : []
      };
      for (const item of distributionList) {
        switch (item.type) {
          case 'All':
            distribution.all = true;
            break;
          case 'Supervisors':
            distribution.supervisors = true;
            break;
          case 'Administrators':
            distribution.administrators = true;
            break;
          case 'Department':
            distribution.departments.push(item.name);
            break;
          case 'Role':
            distribution.roles.push(item.name);
            break;
          case 'Contractor':
            distribution.contractors.push(item.name);
            break;
          case 'Single':
            distribution.singles.push(item.id);
            break;
          default:
        }
      }
      try {
        await API.post('budeeBackend', 'notifications/project', {
          body : {
            notification : {
              title : type === 'Custom' ? custom.message : type,
              description : alert.description,
              distribution : distribution,
              data : {
                pictures : images,
                pdf : pdf,
                link : link
              }
            },
            projectId : props.project.details.id
          }
        });
        loadAlerts(props.project.details.id);
        setErrorSending(false);
      } catch (e) {
        console.error(e);
      }
      setUpdating(false);
      clearAlert();
    }
  }

  const loadAlerts = async (id) => {
    setLoadingAlerts(true);
    API.get("budeeBackend", 'notifications/project?projectId=' + id, {}).catch(err => {
      console.error(err);
      setLoadingAlerts(false);
    }).then(res => {
      setAlerts(res);
      setLoadingAlerts(false);
    });
  };

  const getAdministrators = (employees) => {
    return employees.filter(emp => emp.status === USER_STATUS.SUPERADMIN);
  }

  const getSupervisors = (employees) => {
    return employees.filter(emp => emp.status === USER_STATUS.SUPERVISOR);
  }

  const getDepartmentUsers = (employees, department) => {
    return employees.filter(emp => emp.department && emp.department === department);
  }

  const getRoleUsers = (employees, role) => {
    return employees.filter(emp => emp.role && emp.role === role);
  }

  const getContractorUsers = (employees, contractor) => {
    return employees.filter(emp => emp.contractor && emp.contractor === contractor);
  }

  const searchUser = (name, employees) => {
    if (name !== '') {
      const search = name.replace(/\W/g, '').toUpperCase()
      const results = employees.filter(emp => {
        return emp.name.replace(/\W/g, '').toUpperCase().startsWith(search);
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  const addToDistribution = (employees, name, type, id = null) => {
    for (const item of distributionList) {
      if (type === 'Single') {
        if (item.id === id && item.type === type) {
          return;
        }
      } else {
        if (item.name === name && item.type === type) {
          return;
        }
      }
    }
    var copy = [...distributionList];
    copy.push({
      name : name,
      type : type,
      users : employees,
      id : id
    });
    setDistributionList(copy);
  }

  const removeFromDistribution = (name, type, id = null) => {
    var copy;
    if (type === 'Single') {
      copy = distributionList.filter(item => item.id !== id);
    } else {
      copy = distributionList.filter(item => !(item.name === name && item.type === type));
    }
    setDistributionList(copy);
  }

  useEffect(() => {
    var mounted = true;
    var rows = [];
    for (const notif of alerts) {
      if (searchType !== 'unselected') {
        if (notif.title !== searchType) {
          continue;
        }
      }
      var name = '';
      if (notif.sentBy && props.employees.hasOwnProperty(notif.sentBy)) {
        name = props.employees[notif.sentBy].name;
      }
      const success = notif.success ? notif.success : null;
      const acknowledged = notif.acknowledged ? Math.min((notif.acknowledged / success) * 100, 100) : 0;
      rows.push({
        id : notif.id,
        date : new Date(notif.date),
        title : notif.title,
        description : notif.description,
        sentBy : name,
        acknowledged : acknowledged
      });
    }
    if (mounted) {
      setRows(rows);
    }
    return () => mounted = false;
  }, [alerts, searchType, props.employees]);

  useEffectWhen(() => {
    if (props.project && props.project.details && props.employees) {
      loadAlerts(props.project.details.id);
      setUsers(Object.values(props.employees).filter(e => !e.inactive));
    }
  }, [loadAlerts, props.project.details.id, props.employees], [props.project.details.id, props.employees]);

  useEffectWhen(() => {
    getDepartmentUsers(users, department);
  }, [users, department], [department]);

  useEffectWhen(() => {
    getRoleUsers(users, role);
  }, [users, role], [role]);

  useEffectWhen(() => {
    getContractorUsers(users, role);
  }, [users, contractor], [contractor]);

  useEffectWhen(() => {
    searchUser(search, users);
  }, [search, users], [search]);

  const renderDistribution = (distribution) => {
    var chips = [];
    if (distribution.all) {
      chips.push(<Chip key={'item-all'} label='All' style={{margin:'5px'}} color="primary" />);
    }
    if (distribution.supervisors) {
      chips.push(<Chip key={'item-supervisors'} label='Supervisors' style={{margin:'5px'}} color="primary" />)
    }
    if (distribution.administrators) {
      chips.push(<Chip key={'item-administrators'} label='Administrators' style={{margin:'5px'}} color="primary" />)
    }
    if (distribution.departments) {
      for (const department of distribution.departments) {
        chips.push(<Chip key={'item-' + department} label={department} style={{margin:'5px'}} color="primary" />)
      }
    }
    if (distribution.roles) {
      for (const role of distribution.roles) {
        chips.push(<Chip key={'item-' + role} label={role} style={{margin:'5px'}} color="primary" />)
      }
    }
    if (distribution.contractors) {
      for (const contractor of distribution.contractors) {
        chips.push(<Chip key={'item-' + contractor} label={contractor} style={{margin:'5px'}} color="primary" />)
      }
    }
    if (distribution.singles) {
      for (const id of distribution.singles) {
        let name = '';
        if (props.employees && props.employees[id]) {
          name = props.employees[id].name
        }
        if (name !== '') {
          chips.push(<Chip key={'item-' + id} label={name} style={{margin:'5px'}} color="primary" />)
        }
      }
    }
    return chips;
  }

  function onChange(e) {
    const file = e.target.files[0];
    setUploadingPicture(true);
    const key = uuidv4() + (file.type === 'image/jpeg' ? '.jpeg' : '.png');
    Auth.currentCredentials().then(res => {
      Storage.put(key, file, {
          level : 'protected',
          contentType : file.type
      }).then(result => {
        var copy = [...images];
        copy.push({
          identityId : res.identityId,
          key : key
        });
        setImages(copy);
        setUploadingPicture(false);
      }).catch(err => console.log(err));
    });
  }

  function onPdfChange(e) {
    const file = e.target.files[0];
    setUploadingPdf(true);
    const key = uuidv4() + '.pdf';
    Auth.currentCredentials().then(res => {
      Storage.put(key, file, {
          level : 'protected',
          contentType : file.type
      }).then(result => {
        setPdf({
          identityId : res.identityId,
          key : key
        });
        setUploadingPdf(false);
      }).catch(err => console.log(err));
    });
  }

  const getImageUrls = async (imgs) => {
    var imgUrls = [];
    for (const img of imgs) {
      imgUrls.push(await Storage.get(img.key, {
        level: "protected",
        identityId : img.identityId
      }));
    }
    setImageUrls(imgUrls);
  }

  const getPdfUrl = async (file) => {
    const url = await Storage.get(file.key, {
      level: "protected",
      identityId : file.identityId
    });
    setPdfUrl(url);
  }

  useEffect(() => {
    if (images) {
      getImageUrls(images);
    }
  }, [images]);

  useEffect(() => {
    if (pdf) {
      getPdfUrl(pdf);
    }
  }, [pdf]);

  const removeImage = async (index) => {
    var copy = [...images];
    copy.splice(index, 1);
    setImages(copy);
  }

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} sm={12} item>
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
            <Grid style={{padding:'5px'}} container spacing={3}>
              <Grid style={{borderRight:'1px solid #efefef'}} xs={12} sm={6} item>
                <Typography className={classes.title} variant="h6" gutterBottom>Alert Settings</Typography>
                <Divider />
                <div style={{display:'flex', marginTop:'10px', marginBottom:'10px'}}>
                  <FormControl style={{flexGrow:1,flexBasis:0, borderRight:'1px solid #efefef'}} component="fieldset" error={alertErrors.distributionList}>
                    <FormLabel className={classes.title} component="legend">Distributed To</FormLabel>
                    <RadioGroup aria-label="type" name="type" value={distributed} onChange={handleDistributedChange}>
                      <FormControlLabel value="all" control={<Radio />} label="All" />
                      <FormControlLabel value="administrators" control={<Radio />} label="Administrators" />
                      <FormControlLabel value="supervisors" control={<Radio />} label="Supervisors" />
                      <FormControlLabel value="department" control={<Radio />} label="Department" />
                      <FormControlLabel value="role" control={<Radio />} label="Role" />
                      <FormControlLabel value="contractor" control={<Radio />} label="Contractor" />
                      <FormControlLabel value="single" control={<Radio />} label="Single User" />
                    </RadioGroup>
                  </FormControl>
                  <FormControl style={{flexGrow:3, flexBasis:0, padding:'10px'}} component="fieldset" error={alertErrors.distributionList}>
                    <FormLabel className={classes.title} component="legend">Add to Distribution</FormLabel>
                    {
                      !distributed &&
                      <Typography>Select an option to add users to distribute to.</Typography>
                    }
                    {
                      distributed === "all" &&
                      <>
                        <Typography>All members in the { props.project.details.project }, ({ users.length }) recipients.</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addToDistribution(users, 'All', 'All')}>
                          Add
                        </Button>
                      </>
                    }
                    {
                      distributed === "supervisors" &&
                      <>
                        <Typography>All supervisors in { props.project.details.project }, ({ getSupervisors(users).length }) recipients.</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addToDistribution(getSupervisors(users), 'Supervisors', 'Supervisors')}>
                          Add
                        </Button>
                      </>
                    }
                    {
                      distributed === "administrators" &&
                      <>
                        <Typography>All administrators in { props.project.details.project }, ({ getAdministrators(users).length }) recipients.</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addToDistribution(getAdministrators(users), 'Administrators', 'Administrators')}>
                          Add
                        </Button>
                      </>
                    }
                    {
                      distributed === "department" &&
                      <>
                        {
                          props.departments.length === 0 &&
                          <Typography>Add a department first, then you can assign it to your users.</Typography>
                        }
                        {
                          props.departments.length > 0 &&
                          <Select
                            labelId="departments-label"
                            id="departments"
                            default={"unselected"}
                            style={{width:'200px'}}
                            value={department ? department : "unselected"}
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            <MenuItem key={'sup-unselected'} value={'unselected'}>Select a Department</MenuItem>
                          {
                            props.departments.map((aDepartment, i) => {
                              return (
                                <MenuItem key={'role-' + i} value={aDepartment.name}>{aDepartment.name}</MenuItem>
                              )
                            })
                          }
                          </Select>
                        }
                        {
                          department && getDepartmentUsers(users, department) &&
                          <>
                            <Typography>Total of ({getDepartmentUsers(users, department).length}) user(s) in {department}.</Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addToDistribution(getDepartmentUsers(users, department), department, 'Department')}>
                              Add
                            </Button>
                          </>
                        }
                      </>
                    }
                    {
                      distributed === "role" &&
                      <>
                        {
                          props.roles && props.roles.length === 0 &&
                          <Typography>Add a role first, then you can assign it to your users.</Typography>
                        }
                        {
                          props.roles && props.roles.length > 0 &&
                          <Select
                            labelId="roles-label"
                            id="roles"
                            default={"unselected"}
                            style={{width:'200px'}}
                            value={role ? role : "unselected"}
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <MenuItem key={'sup-unselected'} value={'unselected'}>Select a Role</MenuItem>
                          {
                            props.roles.map((aRole, i) => {
                              return (
                                <MenuItem key={'role-' + i} value={aRole.name}>{aRole.name}</MenuItem>
                              )
                            })
                          }
                          </Select>
                        }
                        {
                          role && getRoleUsers(users, role) &&
                          <>
                            <Typography>Total of ({getRoleUsers(users, role).length}) user(s) in {role}.</Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addToDistribution(getRoleUsers(users, role), role, 'Role')}>
                              Add
                            </Button>
                          </>
                        }
                      </>
                    }
                    {
                      distributed === "contractor" &&
                      <>
                        {
                          props.contractors && props.contractors.length === 0 &&
                          <Typography>Add a contractor first, then you can assign it to your users.</Typography>
                        }
                        {
                          props.contractors && props.contractors.length > 0 &&
                          <Select
                            labelId="roles-label"
                            id="contractors"
                            default={"unselected"}
                            style={{width:'200px'}}
                            value={contractor ? contractor : "unselected"}
                            onChange={(e) => setContractor(e.target.value)}
                          >
                            <MenuItem key={'sup-unselected'} value={'unselected'}>Select a Contractor</MenuItem>
                          {
                            props.contractors.map((aContractor, i) => {
                              return (
                                <MenuItem key={'contractor-' + i} value={aContractor.name}>{aContractor.name}</MenuItem>
                              )
                            })
                          }
                          </Select>
                        }
                        {
                          contractor && getContractorUsers(users, contractor) &&
                          <>
                            <Typography>Total of ({getContractorUsers(users, contractor).length}) user(s) in {contractor}.</Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addToDistribution(getRoleUsers(users, contractor), contractor, 'Contractor')}>
                              Add
                            </Button>
                          </>
                        }
                      </>
                    }
                    {
                      distributed === "single" &&
                      <>
                        <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
                          <SearchIcon />
                          <Input
                            id="search-by-name"
                            type={'text'}
                            value={search}
                            placeholder="Search By Name"
                            onChange={(e) => setSearch(e.target.value)}
                            endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
                          />
                        </FormControl>
                        <div style={{overflowY:'auto',height:'170px', border: '1px solid #efefef', margin:'4px'}}>
                          <List>
                            {
                              searchResults.map((user) => {
                                return (
                                  <ListItem key={user.id} disableRipple style={{cursor:'default'}} button>
                                    {
                                      !isMobile &&
                                      <ListItemAvatar>
                                        <ProfilePicture user={props.user} employee={user} />
                                      </ListItemAvatar>
                                    }
                                    <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user}/>} />
                                    <ListItemSecondaryAction>
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        disabled={distributionList.filter(item => item.type === 'Single' && item.name === user.name && item.id === user.id).length > 0}
                                        onClick={() => addToDistribution([user], user.name, 'Single', user.id)}>
                                        Add
                                      </Button>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })
                            }
                          </List>
                        </div>
                      </>
                    }
                  </FormControl>
                </div>
                <Divider />
                <div style={{marginTop:'10px'}}>
                  <FormControl style={{width:'100%'}} component="fieldset" error={alertErrors.type}>
                    <FormLabel className={classes.title} component="legend">Type of Alert</FormLabel>
                    <RadioGroup style={{display:'block', width:'100%'}} aria-label="type" name="type" value={type} onChange={handleTypeChange}>
                      <div className={classes.rowContainer}>
                        <FormControlLabel className={classes.radioItem} value="Emergency" control={<Radio />} label="Emergency" />
                        <FormControlLabel className={classes.radioItem} value="Recent High Risk Incident" control={<Radio />} label="Recent High Risk Incident" />
                      </div>
                      <div className={classes.rowContainer}>
                        <FormControlLabel className={classes.radioItem} value="Safety Related Condition" control={<Radio />} label="Safety Related Condition" />
                        <FormControlLabel className={classes.radioItem} value="Weather" control={<Radio />} label="Weather" />
                      </div>
                      <div className={classes.rowContainer}>
                        <FormControlLabel className={classes.radioItem} value="Administrative Change" control={<Radio />} label="Administrative Change" />
                        <FormControlLabel className={classes.radioItem} value="Road/Pathway" control={<Radio />} label="Road/Pathway" />
                      </div>
                      <div className={classes.rowContainer}>
                        <FormControlLabel className={classes.radioItem} value="Upcoming Planned Work / Outage" control={<Radio />} label="Upcomming Planned Work / Outage" />
                        <FormControlLabel className={classes.radioItem} value="General" control={<Radio />} label="General" />
                      </div>
                      <div className={classes.rowContainer}>
                        <FormControlLabel className={classes.radioItem} value="Wellness" control={<Radio />} label="Wellness" />
                        <FormControlLabel className={classes.radioItem} value="Custom" control={<Radio />} label="Custom" />
                      </div>
                    </RadioGroup>
                      {
                        type === 'Custom' &&
                        <div className={classes.rowContainer} style={{alignItems:'center'}}>
                          <Typography variant="body2" style={{marginRight:'10px'}}>Message:</Typography>
                          <TextField
                            variant="outlined"
                            error={customErrors.message}
                            required
                            fullWidth
                            size="small"
                            id="message"
                            label="Custom Alert"
                            name="message"
                            value={custom.message}
                            inputProps={{maxLength:75}}
                            onChange={setCustom}
                            margin="normal"
                          />
                        </div>
                      }
                  </FormControl>
                </div>
              </Grid>
              <Grid xs={12} sm={6} item>
                <div>
                  <FormLabel className={classes.title} component="legend">Pictures</FormLabel>
                  {
                    !uploadingPicture &&
                    <div style={{margin:'5px'}}>
                      <Typography variant="body2">Select Picture: (jpeg/png)</Typography>
                      <input type="file" accept='image/png,image/jpeg' onChange={(evt) => onChange(evt)} />
                    </div>
                  }
                  {
                    uploadingPicture &&
                    <CircularProgress size={80} />
                  }
                  {
                    imageUrls && imageUrls.length > 0 &&
                    <>
                      <div className={classes.row} style={{padding:'5px',border:'1px solid #efefef',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {
                          imageUrls.map((url, i) => {
                            return (
                              <div key={'image-' + i} style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                                <div style={{cursor:'pointer',width:'80px',height:'80px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                                </div>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => removeImage(i)}>
                                  Remove
                                </Button>
                              </div>

                            )
                          })
                        }
                      </div>
                      {
                        imageUrls && imageUrls.length > 0 && openLightbox &&
                        <Lightbox
                          mainSrc={imageUrls[photoIndex]}
                          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
                          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
                          onCloseRequest={() => setOpenLightbox(false)}
                          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                          onMoveNextRequest={() => setPhotoIndex((photoIndex + images.length + 1) % images.length)}
                          reactModalStyle={{overlay:{zIndex:1400}}}
                        />
                      }
                    </>
                  }
                </div>
                <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                <div>
                  <FormLabel className={classes.title} component="legend">PDF Attachment</FormLabel>
                  {
                    !uploadingPdf &&
                    <div style={{margin:'5px'}}>
                      <Typography variant="body2">Select PDF: (pdf)</Typography>
                      <input type="file" accept='application/pdf' onChange={(evt) => onPdfChange(evt)} />
                    </div>
                  }
                  {
                    uploadingPdf &&
                    <CircularProgress size={80} />
                  }
                  {
                    pdfUrl &&
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <Link href={pdfUrl} target="_blank">
                        PDF Attachment
                      </Link>
                      <IconButton onClick={() => {
                        setPdf(null);
                        setPdfUrl(null);
                      }}>
                        <HighlightOffIcon color="primary" />
                      </IconButton>
                    </div>
                  }
                </div>
                <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                <FormLabel className={classes.title} component="legend">Link to Website</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="link"
                  label="budeesolutions.com"
                  name="link"
                  value={link}
                  inputProps={{maxLength:400}}
                  onChange={(e) => setLink(e.target.value)}
                  margin="normal"
                />
                <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                {
                  distributionList.map((item,i) => {
                    var description = '';
                    switch (item.type) {
                      case 'All':
                        description = 'All';
                        break;
                      case 'Administrators':
                        description = 'Administrators';
                        break;
                      case 'Supervisors':
                        description = 'Supervisors';
                        break;
                      case 'Department':
                        description = 'Department - ' + item.name;
                        break;
                      case 'Role':
                        description = 'Role - ' + item.name;
                        break;
                      case 'Contractor':
                        description = 'Contractor - ' + item.name;
                        break;
                      case 'Single':
                        description = item.name;
                        break;
                      default:
                    }
                    return (
                      <Chip key={'item-' + i} label={description} style={{margin:'5px'}} onDelete={() => removeFromDistribution(item.name, item.type, item.id)} color="primary" />
                    )
                  })
                }
                <TextField
                  variant="outlined"
                  error={alertErrors.description}
                  required
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  id="description"
                  label="Alert Text (140 chars max)"
                  name="description"
                  value={alert.description}
                  inputProps={{maxLength:140}}
                  onChange={setAlert}
                  margin="normal"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color={"primary"}
                  onClick={() => sendAlert()}>
                  {
                    !updating &&
                    "Distribute Alert"
                  }
                  {
                    updating &&
                    <div>
                      <CircularProgress size={10} color="secondary" />
                    </div>
                  }
                </Button>
                <div style={{padding:'10px'}}>
                  {
                    errorSending &&
                    <div style={{display:'flex', alignItems:'center'}}>
                      <ErrorIcon color="error" />
                      Failed to send, please try again or contact support.
                    </div>
                  }
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main, height:'450px' }}>
            <div style={{display:'flex', alignItems:'center'}}>
              <Typography className={classes.title} variant="h6" gutterBottom>Alerts</Typography>
              <div>
                <Tooltip style={{marginLeft:'15px'}} title="Click the row for a pop-up to view who has viewed the alert.">
                  <HelpIcon color="primary" />
                </Tooltip>
              </div>
              <IconButton onClick={() => loadAlerts(props.project.details.id)}>
                <RefreshIcon color="primary" />
              </IconButton>
              <div style={{flexGrow:1}}>
                <FormControl style={{marginBottom:'10px',display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'flex-end'}}>
                  <SearchIcon />
                  <Select
                    labelId="filter-type-label"
                    id="filter-type"
                    default={"unselected"}
                    style={{width:'200px',textAlign:'left'}}
                    value={searchType ? searchType : "unselected"}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <MenuItem value={'unselected'}>All</MenuItem>
                    <MenuItem value={"Administrative Change"}>Administrative Change</MenuItem>
                    <MenuItem value={"Emergency"}>Emergency</MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"Recent High Risk Incident"}>Recent High Risk Incident</MenuItem>
                    <MenuItem value={"Road/Pathway"}>Road/Pathway</MenuItem>
                    <MenuItem value={"Safety Related Condition"}>Safety Related Condition</MenuItem>
                    <MenuItem value={"Upcomming Planned Work / Outage"}>Upcomming Planned Work / Outage</MenuItem>
                    <MenuItem value={"Weather"}>Weather</MenuItem>
                    <MenuItem value={"Wellness"}>Wellness</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.listContainer}>
            {
              loadingAlerts &&
              <div style={{margin:'auto auto'}}>
                <CircularProgress size={80} color="primary" />
              </div>
            }
            {
              !loadingAlerts &&
              <>
                {
                  alerts && alerts.length === 0 &&
                  <>
                    <div style={{width:'100%',textAlign:'center'}}>
                      <Typography variant="body2">Currently No Alerts Sent.</Typography>
                    </div>
                    <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/7W4yk3uGr3o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                      </div>
                      <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                        <Typography variant="h6">Learn About Alerts</Typography>
                        <Typography variant="body2">Watch this video to learn more about how alerts work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                        <ul>
                          <li>Distribution to Relevant Personnel</li>
                          <li>Understanding Type</li>
                          <li>Attaching Pictures</li>
                          <li>Attaching PDFs</li>
                          <li>URL Links</li>
                          <li>Message Body</li>
                          <li>Audit Trail</li>
                        </ul>
                        <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/alerts?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                      </div>
                    </div>
                  </>
                }
                {
                  alerts && alerts.length > 0 &&
                  <DataGrid
                    rows={rows}
                    disableColumnMenu
                    columns={columns}
                    density="compact"
                    autoPageSize={true}
                    sortModel={[{field: 'date', sort: 'desc'}]}
                    onRowClick={(e) => handleClickOpen(e)}
                    className={classes.alertRow}
                    components={{
                      NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Past Alerts</div>)
                    }}
                  />
                }
              </>
            }
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          loading &&
          <div>
            <CircularProgress size={40} color="secondary" />
          </div>
        }
        {
          !loading && alertDetails &&
          <div>
            <Typography variant="h6" gutterBottom style={{textAlign:'center',color:'grey'}}>Please Allow Up to 1 Minute After First Sent for Alerts to Propagate</Typography>
            <Typography variant="h6" gutterBottom>{alertDetails.title}</Typography>
            <Typography variant="body2" gutterBottom>{alertDetails.description}</Typography>
            {
              alertDetails.data && alertDetails.data.link &&
              <div>
                <Link href={alertDetails.data.link} target="_blank">
                  {alertDetails.data.link}
                </Link>
              </div>
            }
            {
              renderDistribution(alertDetails.distribution)
            }
            <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',justifyContent: isMobile ? 'center' : 'space-between'}}>
              <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'center'}} component="fieldset">
                {
                  !isMobile &&
                  <FormLabel component="legend">Filter</FormLabel>
                }
                <RadioGroup row aria-label="position" name="position" onChange={(e) => setFilter(e.target.value)} defaultValue="all">
                  <FormControlLabel
                    value="all"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="All"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="unacknowledged"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="Unacknowledged"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="acknowledged"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="Acknowledged"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
                <SearchIcon />
                <Input
                  id="search-by-name"
                  type={'text'}
                  value={searchAcknowledged}
                  placeholder="Search By Name"
                  onChange={(e) => {
                    setSearchAcknowledged(e.target.value);
                    setFilter('all');
                  }}
                  endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearchAcknowledged('')} position="end">{searchAcknowledged.length > 0 ? 'X' : ' '}</InputAdornment>}
                />
              </FormControl>
            </div>
            <List>
              {
                alertDetails && alertDetails.users.map((u, i) => {
                  let user = props.employees[u.userId];
                  if (!user) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  } else if ((filter === 'acknowledged' && !u.acknowledged) || (filter === 'unacknowledged' && u.acknowledged)) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  } else if (searchAcknowledged && !user.name.toLowerCase().startsWith(searchAcknowledged.toLowerCase())) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  }
                  return (
                    <ListItem disableRipple key={user.id} button>
                      {
                        !isMobile &&
                        <ListItemAvatar>
                          <ProfilePicture user={props.user} employee={user} />
                        </ListItemAvatar>
                      }
                      <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user}/>} />
                      {
                        u.acknowledged &&
                        <div style={{display:'flex', alignItems:'center'}}>
                          <CheckCircleIcon color="primary" />
                          Acknowledged - {u && u.modified ? formatDateTime(new Date(u.modified)) : ''}
                        </div>
                      }
                      {
                        !u.acknowledged &&
                        <div style={{display:'flex', alignItems:'center'}}>
                          <ErrorIcon color="error" />
                          Unacknowledged
                        </div>
                      }
                    </ListItem>
                  )
                })
              }
            </List>
          </div>
        }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
