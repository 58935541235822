import React, { useEffect, useState } from "react";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//
// }));

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import View from "../../Documents/Dynamic/View";

import { API } from "aws-amplify";

const { formatDateTime } = require('@budeesolutions/budee-util');

export default (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [doc, setDoc] = useState(null);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (props.documents && props.signature) {
      for(const d of props.documents) {
        if (d.id === props.signature.documentId) {
          setDoc(d);
          return;
        }
      }
    }
  }, [props.documents, props.signature]);

  const handleClose = () => {
    setOpen(false);
  };

  const loadSubmission = async () => {
    setLoading(true);
    var calls = [
      API.get('budeeBackend', 'documents/submission/id', {
        queryStringParameters : {
          projectId : props.project.details.id,
          documentId : props.signature.documentId,
          documentVersion : props.signature.documentVersion,
          submissionId : props.signature.submissionId
        }
      }),
      API.get("budeeBackend", "documents/field", {
        queryStringParameters : {
          projectId : props.project.details.id,
          documentId : props.signature.documentId,
          version : props.signature.documentVersion
        }
      })
    ];
    return Promise.all(calls).then(values => {
      setLoading(false);
      setSubmission(values[0]);
      setFields(values[1]);
      setOpen(true);
    })
  }

  return (
    <>
      <Card style={{margin:'4px',border:'2px solid ' + theme.palette.primary.main}} raised>
        <CardActionArea onClick={() => loadSubmission()}>
          <CardContent>
            {
              loading &&
              <div>
                <CircularProgress size={40} />
              </div>
            }
            {
              !loading &&
              <div>
                <Typography gutterBottom variant="body2">
                  { doc && doc.name }
                </Typography>
                <Typography gutterBottom variant="body2">
                  { props.signature && formatDateTime(new Date(props.signature.date)) }
                </Typography>
              </div>
            }
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <View submission={submission} document={doc} user={props.user} employees={props.employees} projectId={props.project.details.id} fields={fields} print={false} {...props} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
