import React, { useState, useRef, useLayoutEffect, useEffect } from "react";

import { useEffectWhen } from '../../../../../../libs/hooks';

import Observation from '../../../Home/Observations/Observation';
import ObservationHeader from '../../../Home/Observations/ObservationHeader';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { API } from "aws-amplify";
const { DOCUMENTS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  row : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'calc(100%-10px)'
  }
}));

export default function TextInput(props) {
  const targetRef = useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const [observationDimensions, setObservationDimensions] = useState({ width:0,height:0 });
  const [selectedObservation, setSelectedObservation] = useState(null);

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);

  useEffect(() => {
    if (props.print) {
      props.updateDimensions({
        width : dimensions.width,
        height : dimensions.height + observationDimensions.height
      });
    }
  }, [props, dimensions, observationDimensions]);

  const classes = useStyles();

  const [value, setValue] = useState(props.value && props.value.value ? props.value.value : '');
  const [delaySave, setDelaySave] = useState(null);
  const firstUpdate = useRef(true);

  const save = async (v) => {
    API.post('budeeBackend', 'documents/value', {
      body : {
        projectId : props.project.details.id,
        documentId : props.documentId,
        documentVersion : props.submission.documentVersion,
        field : {
          type : DOCUMENTS.FIELDS.TEXT.ID,
          value : v,
          fieldId : props.field.id,
          indexed : props.field.indexed
        },
        submissionId : props.submission.id
      }
    }).catch(e => console.error(e));
  }

  useEffectWhen(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (delaySave) {
      clearTimeout(delaySave);
    }
    setDelaySave(setTimeout(async () => {
      try {
        save(value);
      } catch(e) {
        console.error(e);
      }
    }, 700));
    return () => clearTimeout(delaySave)
  }, [value, save], [value]);

  return (
    <Grid xs={12} sm={(props.observations && props.observations.length > 0) ? 12 : (props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
      <div className={classes.row} style={props.print ? {margin:'5px',padding:'5px'} : {}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          {
            !props.print &&
            <IconButton onClick={(e) => props.addObservation(setSelectedObservation)}>
              <VisibilityIcon color="primary" />
            </IconButton>
          }
          <Typography style={props.print ? {textDecorationLine:'underline',marginRight:'5px'} : {marginRight:'5px'}} variant="body2">{props.field.name}</Typography>
        </div>
        {
          !props.print &&
          <TextField
            variant="outlined"
            required
            size="small"
            id={props.field.id}
            value={value}
            inputProps={{maxLength:60}}
            onChange={e => setValue(e.target.value)}
            margin="none"
          />
        }
        {
          props.print &&
          <Typography variant="body2">{props.value && props.value.value ? props.value.value : '-'}</Typography>
        }
        {
          props.observations && props.observations.map((obv, i) => {
            return (
              <React.Fragment key={'obv-' + obv.id}>
                <div style={{width:'100%',cursor:'pointer'}} onClick={() => setSelectedObservation(obv)}>
                  <ObservationHeader print={props.print} index={i} observation={obv} updateDimensions={(dim) => setObservationDimensions(50)} employees={props.employees} user={props.user} />
                </div>
              </React.Fragment>
            )
          })
        }
        <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={selectedObservation != null}
        onClose={() => setSelectedObservation(null)}
        aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          selectedObservation &&
          <Observation observation={selectedObservation} employees={props.employees} user={props.user} project={props.project} onComplete={(obv) => {
            props.updateObservations(obv);
            if (obv && obv.deleted) {
              setSelectedObservation(null);
            }
          }}  />
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedObservation(null)} color="primary">
            Close
          </Button>
        </DialogActions>
        </Dialog>
      </div>
    </Grid>
  );
}
