import React, { useState, useEffect } from "react";

import { API, Auth, Storage } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Lightbox from 'react-image-lightbox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const { PRIVILEGED_USER } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  }
}));

export default function DocumentForm(props) {
  const classes = useStyles();

  const fileRef = React.useRef();
  const imageRef = React.useRef();

  const [selectedContractor, setSelectedContractor] = useState('unselected');
  const [updating, setUpdating] = useState(false);
  const [documentErrors, setDocumentErrors] = useState({
    name : false,
    upload : false
  });
  const [name, setName] = useState('');
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [images, setImages] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleContractorChange = (event) => {
    for (const contractor of props.contractors) {
      if (contractor.id === event.target.value) {
        setSelectedContractor(contractor);
      }
    }
  };

  const addDocument = () => {
    const check = {
      name : name === "" ? true : false,
      upload : files.length === 0 && images.length === 0 ? true : false
    };
    setDocumentErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      var doc = {
        name : name,
        contractor : selectedContractor !== 'unselected' && selectedContractor ? selectedContractor.name : null,
        files : files,
        images : images
      };
      API.post("budeeBackend", "documents/upload", { body : {
        projectId : props.project.details.id,
        document : doc
      }}).then((res) => {
        props.loadDocuments(props.project.details.id);
        setSelectedContractor('unselected');
        setName('');
        setFiles([]);
        setFileUrls([]);
        setImages([]);
        setImageUrls([]);
        setUpdating(false);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  function onFileChange(e) {
    const file = e.target.files[0];
    setUploadingFile(true);
    Auth.currentCredentials().then(res => {
      Storage.put(uuidv4() + '.pdf', file, {
          level: "protected",
          contentType : 'application/pdf'
      })
      .then(result => {
        var copy = [...files];
        copy.push({
          key : result.key,
          identityId : res.identityId,
          name : file.name
        });
        setFiles(copy);
        setUploadingFile(false);
        fileRef.current.value = "";
      })
      .catch(err => console.log(err));
    });
  }

  function onImageChange(e) {
    const file = e.target.files[0];
    if (file) {
      setUploadingImage(true);
      Auth.currentCredentials().then(res => {
        Storage.put(uuidv4() + (file.type === 'image/jpeg' ? '.jpeg' : '.png'), file, {
            level: "protected",
            contentType : file.type
        })
        .then(result => {
          var copy = [...images];
          copy.push({
            key : result.key,
            identityId : res.identityId
          });
          setImages(copy);
          setUploadingImage(false);
          imageRef.current.value = "";
        })
        .catch(err => console.log(err));
      });
    }
  }

  const getUrls = async (objs) => {
    var calls = [];
    for (const f of objs) {
      calls.push(Storage.get(f.key, {
        level: "protected",
        identityId : f.identityId
      }));
    }
    return Promise.all(calls);
  }

  useEffect(() => {
    getUrls(files).then(res => setFileUrls(res));
  }, [files]);

  useEffect(() => {
    getUrls(images).then(res => setImageUrls(res));
  }, [images]);

  const handleRemoveFile = async (index) => {
    const copy = [...files];
    copy.splice(index,1);
    setFiles(copy);
  }

  const handleRemoveImage = async (index) => {
    const copy = [...images];
    copy.splice(index,1);
    setImages(copy);
  }

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  return (
    <>
      <Typography variant="body1">Name and image(s) or PDF(s) required.</Typography>
      {
        props.project && PRIVILEGED_USER.includes(props.project.status) &&
        <div>
          <Select
            labelId="contractor-label"
            id="contractor-select"
            value={selectedContractor !== 'unselected' ? selectedContractor.id : 'unselected'}
            onChange={handleContractorChange}
            style={{fontSize:'1rem'}}
          >
            <MenuItem key={'contractor-unselected'} value={'unselected'}>Select Contractor</MenuItem>
          {
            props.contractors.map((contractor, i) => {
              return (
                <MenuItem key={'contractor-' + i} value={contractor.id}>{contractor.name}</MenuItem>
              )
            })
          }
          </Select>
        </div>
      }
      <TextField
        variant="outlined"
        error={documentErrors.name}
        required
        size="small"
        id="name"
        label="Name"
        name="name"
        value={name}
        inputProps={{maxLength:60}}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
      />
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
        <Typography variant="body1" style={documentErrors.upload ? {color:'red'} : {}}>PDF Attachments:</Typography>
        <input type="file" ref={fileRef} accept='application/pdf' onChange={(evt) => onFileChange(evt)} />
      </div>
      {
        uploadingFile &&
        <div>
          <CircularProgress size={80} />
        </div>
      }
      <div style={{margin:'5px',display:'flex',alignItems:'center'}}>
      {
        fileUrls && fileUrls.map((url, i) => {
          return (
            <div key={"file-" + i} style={{marginRight:'10px',marginLeft:'10px',display:'flex',alignItems:'center'}}>
              <Link href={url} target="_blank" style={{marginTop:'5px'}} rel="noreferrer">
                <PictureAsPdfIcon color="error" />
              </Link>
              <IconButton onClick={() => handleRemoveFile(i)}>
                <DeleteForeverIcon color="primary" />
              </IconButton>
            </div>
          );
        })
      }
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
        <Typography variant="body1" style={documentErrors.upload ? {color:'red'} : {}}>Image Attachments:</Typography>
        <input type="file" ref={imageRef} accept='image/png,image/jpeg' onChange={(evt) => onImageChange(evt)} />
      </div>
      {
        uploadingImage &&
        <div>
          <CircularProgress size={80} />
        </div>
      }
      {
        imageUrls && imageUrls.length > 0 &&
        <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin : '5px 0', width : '100%', padding:'5px',border:'1px solid #efefef',justifyContent:'flex-start'}}>
          {
            imageUrls.map((url, i) => {
              return (
                <React.Fragment key={'image-' + i}>
                  <div style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                    <div style={{cursor:'pointer',width:'80px',height:'80px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                    </div>
                    <div style={{textAlign:'center'}}>
                      <IconButton onClick={() => handleRemoveImage(i)}>
                        <DeleteForeverIcon color="primary" />
                      </IconButton>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      }
      {
        imageUrls && imageUrls.length > 0 && openLightbox &&
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + imageUrls.length + 1) % imageUrls.length)}
          reactModalStyle={{overlay:{zIndex:1400}}}
        />
      }
      <Button
        variant="contained"
        color="primary"
        onClick={() => addDocument()}>
        {
          !updating && "Upload"
        }
        {
          updating &&
          <div>
            <CircularProgress size={10} />
          </div>
        }
      </Button>
    </>
  );
}
