import React, { useState, useEffect } from "react";

import { Auth, Storage, API } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';

import { useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from '@material-ui/core/TextField';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import queryString from 'query-string';
import Divider from '@material-ui/core/Divider';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Input from '@material-ui/core/Input';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import ProfilePicture from '../../../../User/ProfilePicture';
import Role from '../../../../User/Role';
import Lightbox from 'react-image-lightbox';

const { formatDateTime } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  submission : {
    border:'1px solid ' + theme.palette.primary.main,
    borderRadius:'10px',
    margin:'2px',
    cursor:'pointer',
    '&:hover': {
      border:'1px solid ' + theme.palette.accentColor.main,
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  web : {
    borderTop:'1px solid #cccccc',
    borderBottom:'1px solid #cccccc',
  },
  print : {
    border:'1px solid #cccccc',
    borderRadius:'10px',
    marginTop:'5px',
    marginBottom:'5px'
  },
  row : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'100%'
  },
  th : {
    fontSize : '12px'
  },
  td : {
    fontSize : '10px',
    textAlign : 'center'
  },
  subtitle: {
    marginRight:'10px',
    fontWeight:'bold',
    width:'100px'
  },
  descRow : {
    marginTop:'10px',
    marginBottom:'10px',
    display:'flex',
    flexDirection:'row'
  }
}));

export default function Observations(props) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [editAssigned, setEditAssigned] = useState(false);
  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [observation, setObservation] = useState(props.observation);
  const [description, setDescription] = useState(props.observation && props.observation.description ? props.observation.description : '');
  const [comment, setComment] = useState(props.observation ? props.observation.comment : '');
  const [open, setOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const fileRef = React.useRef();

  useEffect(() => {
    setObservation(props.observation);
  }, [props.observation]);

  useEffect(() => {
    if (observation.photos && observation.photos.length > 0) {
      loadImages(observation.photos);
    }
  }, [observation]);

  const loadImages = async (images) => {
    var imgUrls = [];
    for (const img of images) {
      imgUrls.push(await Storage.get(img.key, {
        level: "protected",
        identityId : img.identityId
      }));
    }
    setImageUrls(imgUrls);
  }

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  const resolve = async (obv) => {
    setLoading(true);
    let copy = {
      ...obv,
      comment : comment
    }
    return API.post('budeeBackend', 'user/observation/close', {
      body : {
        projectId : props.project.details.id,
        observationId : obv.id,
        resolvedBy : props.user.id,
        comment : comment
      }
    }).then(res => {
      setLoading(false);
      setObservation(copy);
      if (props.onComplete) {
        copy.status = false;
        props.onComplete(copy);
      }
    });
  }

  const handleDelete = (obv) => {
    return API.del('budeeBackend', 'user/observation', {
      body : {
        observationId : obv.id,
        projectId : props.project.details.id
      }
    }).then(res => {
      if (props.onComplete) {
        props.onComplete({
          ...obv,
          deleted : true
        });
      }
    })
  };

  const updateAssigned = async (e) => {
    let copy = {
      ...observation,
      assigned : e.id
    }
    return API.post('budeeBackend', 'user/observation', {
      body : {
        observation : copy,
        projectId : props.project.details.id
      }
    }).then(res => {
      setObservation(copy);
      setEditAssigned(false);
    });
  }

  const updateFixBy = async (d) => {
    let copy = {
      ...observation,
      fixBy : d.toUTCString()
    }
    return API.post('budeeBackend', 'user/observation', {
      body : {
        observation : copy,
        projectId : props.project.details.id
      }
    }).then(res => {
      setObservation(copy);
    });
  }

  const updateDescription = async (d) => {
    let copy = {
      ...observation,
      description : d
    }
    return API.post('budeeBackend', 'user/observation', {
      body : {
        observation : copy,
        projectId : props.project.details.id
      }
    }).then(res => {
      setObservation(copy);
    });
  }

  const updatePhotos = async (obv) => {
    return API.post('budeeBackend', 'user/observation', {
      body : {
        observation : obv,
        projectId : props.project.details.id
      }
    });
  }

  const lookup = async (name, employees) => {
    setSearch(name);
    if (name === '') {
      setSelection([]);
      return;
    }
    var matches = [];
    for (const e of Object.values(employees)) {
      if (!e.inactive && e.name.toLowerCase().search(name.toLowerCase()) >= 0) {
        matches.push(e);
      }
    }
    setSelection(matches);
  }

  useEffect(() => {
    lookup(search, props.employees);
  }, [search, props.employees]);

  function onChange(e) {
    const file = e.target.files[0];
    setLoadingImage(true);
    Auth.currentCredentials().then(res => {
      Storage.put(uuidv4() + (file.type === 'image/jpeg' ? '.jpeg' : '.png'), file, {
          level: "protected",
          contentType : file.type
      })
      .then(result => {
        var copy = {...observation};
        copy.photos.push({
          key : result.key,
          identityId : res.identityId
        });
        updatePhotos(copy);
        setObservation(copy);
        setLoadingImage(false);
        fileRef.current.value = "";
      })
      .catch(err => console.log(err));
    });
  }

  return (
    <Grid container className={props.print ? classes.print : classes.web} style={props.index && props.index % 2 ? {backgroundColor:'#cdcdcd'} : {backgroundColor:'#efefef'}}>
      {
        !props.print && observation &&
        <>
          <Grid style={{padding:'10px'}} xs={12} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <>
                <Typography variant="h6">Safety Observation</Typography>
                {
                  observation.submissionId &&
                  <IconButton size="small" onClick={() => {
                    history.push('documents?' + queryString.stringify({documentId:observation.document.id,submissionId:observation.submissionId}));
                    history.go(0);
                  }}>
                    <DescriptionIcon color="primary" />
                  </IconButton>
                }
              </>
              <div style={{flex:1}}></div>
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Delete
              </Button>
            </div>
            <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
            <Typography variant="body2">{formatDateTime(new Date(observation.date))}</Typography>
            <div style={{width:'calc(100% - 22px)',backgroundColor:'#FFFFFF',border:'1px solid #cdcdcd',padding:'10px',marginTop:'10px',marginBottom:'10px'}}>
              <TextareaAutosize
                style={{width:'100%'}}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => updateDescription(description)}
                placeholder="Enter text here"
              />
            </div>
            <div>
            {
              observation.roles && observation.roles.map((name, i) => {
                return (
                  <Chip
                    label={name}
                    key={'roles-' + i}
                    style={{backgroundColor:'red',color:'#ffffff',margin:'2px'}}
                  />
                )
              })
            }
            {
              observation.departments && observation.departments.map((name, i) => {
                return (
                  <Chip
                    label={name}
                    key={'departments-' + i}
                    style={{backgroundColor:'blue',color:'#ffffff',margin:'2px'}}
                  />
                )
              })
            }
            {
              observation.contractors && observation.contractors.map((name, i) => {
                return (
                  <Chip
                    label={name}
                    key={'contractors-' + i}
                    style={{backgroundColor:'purple',color:'#ffffff',margin:'2px'}}
                  />
                )
              })
            }
            </div>
            <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
            <Grid container>
              <Grid style={{padding:'10px'}} xs={12} sm={6} item>
              {
                imageUrls && imageUrls.length === 0 &&
                <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',border:'1px solid #cdcdcd'}}>
                  <Typography variant="body2">No Images Submitted</Typography>
                </div>
              }
              {
                imageUrls && imageUrls.length > 0 &&
                <>
                  <Typography variant="h6">Pictures</Typography>
                  <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                  <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin :'5px 0', padding:'5px',border:'1px solid #efefef',justifyContent:'flex-start'}}>
                    {
                      imageUrls.map((url, i) => {
                        return (
                          <React.Fragment key={'image-' + i}>
                            {
                              !props.print &&
                              <div style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                                <div style={{cursor:'pointer',width:'150px',height:'150px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                                </div>
                              </div>
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </>
              }
              {
                imageUrls && imageUrls.length > 0 && openLightbox &&
                <Lightbox
                  mainSrc={imageUrls[photoIndex]}
                  nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
                  prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
                  onCloseRequest={() => setOpenLightbox(false)}
                  onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
                  onMoveNextRequest={() => setPhotoIndex((photoIndex + imageUrls.length + 1) % imageUrls.length)}
                  reactModalStyle={{overlay:{zIndex:1400}}}
                />
              }
              {
                loadingImage &&
                <div>
                  <CircularProgress size={80} />
                </div>
              }
              {
                !loadingImage &&
                <div style={{margin:'5px'}}>
                  <input ref={fileRef} type="file" accept='image/png,image/jpeg' onChange={(evt) => onChange(evt)} />
                </div>
              }
              </Grid>
              <Grid style={{padding:'10px'}} xs={12} sm={6} item>
                <div className={classes.descRow}>
                  <Typography variant="body2" className={classes.subtitle}>Status</Typography>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'10px'}}>
                  <>
                    {
                      !observation.status &&
                      <AssignmentTurnedInIcon color="primary" />
                    }
                    {
                      observation.status &&
                      <AssignmentLateIcon color="error" />
                    }
                  </>
                  </div>
                </div>
                {
                  props.employees.hasOwnProperty(observation.userId) &&
                  <div className={classes.descRow}>
                    <Typography variant="body2" className={classes.subtitle}>Reported By</Typography>
                    <ListItem>
                      <ListItemAvatar>
                        {
                          observation.anonymous &&
                          <ProfilePicture />
                        }
                        {
                          !observation.anonymous &&
                          <ProfilePicture user={props.user} employee={props.employees[observation.userId]} />
                        }
                      </ListItemAvatar>
                      <ListItemText primary={observation.anonymous ? "Anonymous" : props.employees[observation.userId].name} secondary={observation.anonymous ? "" : <Role user={props.employees[observation.userId]}/>} />
                    </ListItem>
                  </div>
                }
                <div className={classes.descRow}>
                  <Typography variant="body2" className={classes.subtitle}>Assigned To</Typography>
                  {
                    (editAssigned || !observation.assigned) &&
                    <div style={{position:'relative'}}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <TextField
                          variant="outlined"
                          required
                          size="small"
                          id='assigned-user'
                          value={search}
                          inputProps={{maxLength:60}}
                          onChange={e => setSearch(e.target.value)}
                          margin="none"
                        />
                        <CloseIcon style={{cursor:'pointer',marginLeft:'10px'}} color="primary" onClick={() => setEditAssigned(false)} />
                      </div>
                      <List>
                        {
                          selection.map((user) => {
                            return (
                              <ListItem disableRipple key={user.id} button onClick={() => updateAssigned(user)}>
                                <ListItemAvatar>
                                  <ProfilePicture user={props.user} employee={user} />
                                </ListItemAvatar>
                                <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user} />} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </div>
                  }
                  {
                    !editAssigned && observation.assigned &&
                    <ListItem>
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={props.employees[observation.assigned]} />
                      </ListItemAvatar>
                      <EditIcon style={{cursor:'pointer',marginRight:'10px'}} color="primary" onClick={() => setEditAssigned(true)} />
                      <ListItemText primary={props.employees[observation.assigned].name} secondary={<Role user={props.employees[observation.assigned]}/>} />
                    </ListItem>
                  }
                </div>
                <div className={classes.descRow}>
                  <Typography variant="body2" className={classes.subtitle}>Fix By</Typography>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'10px'}}>
                    <DatePicker
                      value={observation.fixBy ? new Date(observation.fixBy) : new Date()}
                      format={"MMMM d yyyy"}
                      onChange={(d) => updateFixBy(d)}
                      TextFieldComponent={props => (<Input
                                                      type="text"
                                                      onClick={props.onClick}
                                                      value={props.value}
                                                      onChange={props.onChange}
                                                      fullWidth
                                                      style={{ textAlign : 'center', color : observation.fixBy ? 'black' : '#ededed', cursor : 'pointer' }}
                                                    />)}
                    />
                    <CalendarTodayIcon style={{marginRight:'10px'}} color="primary" />
                  </div>
                </div>
                {
                  !props.document && observation.status &&
                  <>
                    <Typography variant="body2">Would you like to resolve this safety observation?</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      size="small"
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      inputProps={{maxLength:800}}
                      onChange={e => setComment(e.target.value)}
                      margin="normal"
                      style={{backgroundColor:'#FFFFFF'}}
                    />
                    <Button variant="contained" color="primary" onClick={() => resolve(observation)}>
                    {
                      loading && <CircularProgress size={20} color="secondary" />
                    }
                    {
                      !loading && "Resolve"
                    }
                    </Button>
                  </>
                }
                {
                  !observation.status &&
                  <>
                    <div className={classes.descRow}>
                      <Typography variant="body2" className={classes.subtitle}>Resolved By</Typography>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'10px'}}>
                        <ProfilePicture user={props.user} employee={props.employees.hasOwnProperty(observation.resolvedBy) ? props.employees[observation.resolvedBy] : null} />
                        <Typography variant="body2" style={{marginLeft:'10px'}}>
                          {props.employees.hasOwnProperty(observation.resolvedBy) ? props.employees[observation.resolvedBy].name : ''}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.descRow}>
                      <Typography variant="body2" className={classes.subtitle}>Resolved Date</Typography>
                      <Typography variant="body2">
                        {formatDateTime(new Date(observation.modified))}
                      </Typography>
                    </div>
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you'd like to delete this observation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleDelete(observation).then(res => props.onComplete());
            setOpen(false);
          }} color="primary">
            Okay
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
