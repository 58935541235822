import React, { useEffect, useState } from "react";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//
// }));

import { useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { Storage, API } from "aws-amplify";

const { formatDateTime } = require('@budeesolutions/budee-util');

export default (props) => {
  const theme = useTheme();
  // const classes = useStyles();

  const [url, setUrl] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (props.document.key && props.document.identityId) {
      Storage.get(props.document.key, {
        level: "protected",
        identityId : props.document.identityId
      }).then(res => {
        if (mounted) {
          setUrl(res);
        }
      });
    }
    return () => mounted = false;
  }, [props.document])

  const markAsRead = async () => {
    setLoading(true);
    return API.post('budeeBackend', 'documents/readonly/user', {
      body : {
        userId : props.profile.id,
        projectId : props.project.details.id,
        doc : props.document
      }
    }).then(res => {
      props.reload();
      setLoading(false);
    });
  };

  return (
    <>
      <Card style={{margin:'4px',border:'2px solid ' + theme.palette.primary.main}} raised>
        <CardActionArea onClick={() => window.open(url, "_blank")}>
          <CardContent>
            <div style={{display:'flex',flexDirection:'row'}}>
              {
                props.document.type === 'application/pdf' ? <PictureAsPdfIcon style={{marginRight:'10px'}} color="error" /> : <VideoLibraryIcon style={{marginRight:'10px'}} color="primary" />
              }
              <Typography variant="body2">{props.document.name}</Typography>
            </div>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.document.description.length > 50 && showDescription ? props.document.description : props.document.description.substring(0,55)}
              {props.document.description.length > 50 && !showDescription ? '...' : ''}
            </Typography>
            {
              props.document.description.length > 50 &&
              <Link style={{fontSize:'10px',textAlign:'left'}} onClick={() => setShowDescription(!showDescription)}>{showDescription ? 'Hide' : 'Show'} Desc</Link>
            }
            {
              props.document && props.document.read &&
              <Typography color="textSecondary" variant="body2">Viewed: {formatDateTime(new Date(props.document.readDate))}</Typography>
            }
          </CardContent>
        </CardActionArea>
        <CardActions>
          {
            props.document && !props.document.read &&
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <RemoveCircleOutlineIcon color="error" style={{marginRight:'10px'}} />
              <Button onClick={() => markAsRead()} variant="outlined" size="small" color="primary">
                {
                  loading &&
                  <CircularProgress size={15} />
                }
                {
                  !loading &&
                  <>
                    Mark as {props.document.type === 'video/mp4' ? 'Viewed' : 'Read'}
                  </>
                }
              </Button>
            </div>
          }
          {
            props.document && props.document.read &&
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <CheckIcon color="primary" style={{marginRight:'10px'}} />
              <Typography color="primary" variant="body2">Compliant</Typography>
            </div>
          }
        </CardActions>
      </Card>
    </>
  );
}
