import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';


import { Storage } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';

import { API } from "aws-amplify";
const {formatDateTime} = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  print : {
    '@media print': {
      display: 'block',
      pageBreakAfter: 'always'
    },
    width:'8.5in',
    height:'11in',
    margin:'0 0',
    display:'flex',
    flexDirection:'column'
  }
}));

export default function Header(props) {
  const classes = useStyles();

  const [logoUrl, setLogoUrl] = useState(null);
  const [observations, setObservations] = useState([]);

  useEffect(() => {
    API.get('budeeBackend', 'dashboard/observations/submission', {
      queryStringParameters : {
        submissionId : props.submission.id,
        projectId : props.project.id
      }
    }).then(vals => setObservations(vals));
  }, [props.project, props.submission, props.docuemnt]);

  useEffect(() => {
    if (props.company) {
      Storage.get(props.company.id + '/logo.png').then((url) => {
        fetch(url).then((result) => { //fetch the URL
          if(result.status === 200) { //if the file exists
            setLogoUrl(url);
          } else {
            setLogoUrl('NONE');
          }
        });
      }).catch(res => {
        console.log(res);
      });
    }
  }, [props.company]);

  const getPreparedBy = (sub, emps) => {
    if (sub && emps && emps.hasOwnProperty(sub.userId)) {
      return emps[sub.userId].name;
    }
  }

  const getScore = (sub) => {
    if (sub.score && sub.highest) {
      const percent = Math.round((sub.score / sub.highest) * 100);
      return sub.score + '/' + sub.highest + ' ' + percent + '%';
    } else {
      return sub.score;
    }
  }

  return (
    <div className={classes.print}>
      <div style={{width:'100%',display:'flex',flexDirection:'row',height:'125px'}}>
        <div style={{flexGrow:1,flexBasis:0,height:'110px'}}>
          <Typography variant="body2">Powered By Budee Solutions</Typography>
          <img width="75%" src={"./budee-logo-dark-green.png"} alt="Powered By Budee Solutions" />
        </div>
        <div style={{flexGrow:1,flexBasis:0,height:'110px',display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
          <Typography variant="body2">{props.company.name}</Typography>
          {
            logoUrl && props.company &&
            <img width="75%" src={logoUrl} alt={props.company.name} />
          }
        </div>
      </div>
      <div style={props.print ? {marginTop:'250px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'} : {flexGrow:1,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h4">{props.document.name}</Typography>
        <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
          <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Prepared On:</Typography>
          <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">{ formatDateTime(new Date(props.submission.date)) }</Typography>
        </div>
        <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
          <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Prepared By:</Typography>
          <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">{ getPreparedBy(props.submission, props.employees) }</Typography>
        </div>
        <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
          <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Project:</Typography>
          <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">{ props.project.project }</Typography>
        </div>
        {
          observations.length > 0 &&
          <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
            <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Observations:</Typography>
            <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">{ observations.length }</Typography>
          </div>
        }
        {
          props.submission.highest > 0 &&
          <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
            <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Score:</Typography>
            <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">{ getScore(props.submission) }</Typography>
          </div>
        }
        <div style={{display:'flex',width:'60%',flexDirection:'row',marginTop:'2px',marginBottom:'2px'}}>
          <Typography style={{width:'180px',backgroundColor:'#cdcdcd'}} variant="h6">Location:</Typography>
          <Typography style={{flexGrow:1,flexBasis:0}} variant="h6">
            { props.project && props.project.address ? props.project.address.line1 : "" }<br/>
            { props.project && props.project.address ? props.project.address.city : "" }, { props.project && props.project.address ? props.project.address.state : "" }<br/>
            { props.project && props.project.address ? props.project.address.postal : "" }<br/>
            { props.project && props.project.address ? props.project.address.country : "" }
          </Typography>
        </div>
      </div>
    </div>
  );
}
