import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import SubmissionForm from './SubmissionForm';
import ProfilePicture from "../../../User/ProfilePicture";
import { isMobile } from "react-device-detect";

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  certificationList : {
    width : '500px'
  },
  certificationRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  },
  certDates : {
    textAlign:'right'
  },
  filterLabel : {
    fontSize : '0.75rem'
  }
}));

export default function SubmissionDetails(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [certificationOpen, setCertificationOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState('all');
  const [details, setDetails] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [userSubmission, setUserSubmission] = useState(null);
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (details) {
      loadSubmissions(details.id);
    }
  }, [details]);

  useEffect(() => {
    var mounted = true;
    if (submissions && employees && details) {
      var grid = [];
      var roles = null;
      if (details.roles) {
        roles = details.roles.map(obj => obj.name);
      }
      var contractors = null;
      if (details.contractors) {
        contractors = details.contractors.map(obj => obj.name);
      }
      for (const e of Object.values(props.employees)) {
        if (search) {
          if (!e.name.toLowerCase().startsWith(search.toLowerCase())) {
            continue;
          }
        }

        if (roles && contractors) {
          if (!roles.filter(r => e.role.includes(r)).length > 0 && !contractors.includes(e.contractor)) {
            continue;
          }
        } else if (roles) {
          if (!roles.filter(r => e.role.includes(r)).length > 0) {
            continue;
          }
        } else if (contractors) {
          if (!contractors.includes(e.contractor)) {
            continue;
          }
        }

        var status = 'missing';
        var acquired = null;
        var expires = null;
        var sub = null;
        if (submissions.hasOwnProperty(e.id)) {
          sub = submissions[e.id];
          if (sub.verified) {
            status = 'verified';
          } else {
            status = 'unverified';
          }
          acquired = sub.acquired;
          expires = sub.expires;
        }

        if (filter !== 'all' && status !== filter) {
          continue;
        }

        grid.push({
          id : e.id,
          name : e.name,
          picture : e,
          role : e.role,
          contractor : e.contractor,
          status : status,
          acquired : acquired,
          expires : expires,
          user : e,
          submission : sub
        });
      }
      if (mounted) {
        setRows(grid);
      }
    }
    return () => mounted = false;
  }, [submissions, employees, details, filter, props.employees, search]);

  useEffect(() => {
    setDetails(props.details);
  }, [props.details]);

  useEffect(() => {
    setSubmissions(props.submissions);
  }, [props.submissions]);

  useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  const remove = async (cert) => {
    await API.del("budeeBackend", "certifications/project", { body : {
        projectId : props.project.details.id,
        certificationId : cert.id
      }
    });
    props.loadCertifications(props.project.details.id);
    props.setOpen(false);
  }

  const handleCertificationOpen = (sub, user) => {
    setUserSubmission(sub);
    setUser(user);
    setCertificationOpen(true);
  }

  const handleCertificationClose = (e) => {
    setCertificationOpen(false);
  }

  const loadSubmissions = async (certificationId) => {
    setLoading(true);
    API.get("budeeBackend", "certifications/submit?certificationId=" + certificationId, {}).then(res => {
      setSubmissions(res);
      setLoading(false);
    });
  }

  const submissionComplete = () => {
    loadSubmissions(props.details.id);
    setCertificationOpen(false);
  }

  const columns = [
    { field: 'id', hide : true },
    { field: 'picture', headerName: 'Picture', flex: 1, renderCell : (val) => {
      return (
        <ProfilePicture  user={props.user} employee={val.value} size={'small'} />
      )
    }},
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1, renderCell : (val) => {
      return (
        <>
        {
          val.value && val.value.length === 0 &&
          '-'
        }
        {
          val.value && val.value.length > 0 &&
          <>
            <Tooltip title={
              <React.Fragment>
              {
                Array.isArray(val.value) && val.value.map((r,i) => {
                  return (
                    <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r} size="small"/>
                  )
                })
              }
              </React.Fragment>
            }>
              <div style={{marginTop:'10px'}}><AssignmentIndIcon color="primary" /></div>
            </Tooltip>
          </>
        }
        </>
      );
    } },
    { field: 'contractor', headerName: 'Contractor', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1, renderCell : (val) => {
      switch (val.value) {
        case 'missing':
          return (
            <HelpIcon color="error" />
          )
        case 'unverified':
          return (
            <WarningIcon color="secondary" />
          );
        case 'verified':
          return (
            <VerifiedUserIcon color="primary" />
          );
        default:
          return(<></>)
      }
    }},
  ];

  if (!isMobile) {
    columns.push({ field: 'acquired', headerName: 'Acquired', flex: 1 });
    columns.push({ field: 'expires', headerName: 'Expires', flex: 1 });
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        {
          loading &&
          <div>
            <CircularProgress size={80} />
          </div>
        }
        {
          !loading && details &&
          <>
            <div style={{display:'flex',flexDirection:'row'}}>
              <Typography variant="h6">{details.name}</Typography>
              <Button style={{marginLeft:'10px'}} color="secondary" variant="contained" onClick={() => props.setEdit(true)}>Edit</Button>
            </div>
            <Typography variant="body2">{details.description}</Typography>
            <div style={{display:'flex'}}>
              <div style={{flexGrow:1}}>
              {
                !details.roles && !details.contractors &&
                <Chip key='role-req-projectWide' label="Project Wide" style={{margin:'5px'}} color="primary" />
              }
              {
                details.roles && details.roles.map((role, i) => {
                  return (
                    <Chip key={'role-req-' + i} label={role.name} style={{margin:'5px'}} color="primary" />
                  )
                })
              }
              {
                details.contractors && details.contractors.map((contractor, i) => {
                  return (
                    <Chip key={'contractor-req-' + i} label={contractor.name} style={{margin:'5px'}} color="primary" />
                  )
                })
              }
              </div>
              <div>
              {
                props.project.status === USER_STATUS.SUPERADMIN &&
                <Button onClick={() => remove(details)} color="primary" variant="outlined">
                  Archive
                </Button>
              }
              </div>
            </div>
          </>
        }
        <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',justifyContent: isMobile ? 'center' : 'space-between'}}>
          <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'center'}} component="fieldset">
            {
              !isMobile &&
              <FormLabel component="legend">Filter</FormLabel>
            }
            <RadioGroup row aria-label="position" name="position" onChange={(e) => setFilter(e.target.value)} defaultValue="all">
              <FormControlLabel
                value="all"
                classes={{label : isMobile ? classes.filterLabel : null}}
                control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                label="All"
                labelPlacement="top"
              />
              <FormControlLabel
                value="missing"
                classes={{label : isMobile ? classes.filterLabel : null}}
                control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                label="Missing"
                labelPlacement="top"
              />
              <FormControlLabel
                value="unverified"
                classes={{label : isMobile ? classes.filterLabel : null}}
                control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                label="Unverified"
                labelPlacement="top"
              />
              <FormControlLabel
                value="verified"
                classes={{label : isMobile ? classes.filterLabel : null}}
                control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                label="Verified"
                labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
          <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'center'}}>
            <SearchIcon />
            <Input
              id="search-by-name"
              type={'text'}
              value={search}
              placeholder="Search By Name"
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
            />
          </FormControl>
        </div>
        <div style={{height:'370px'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            density="compact"
            autoPageSize={true}
            sortModel={[{field: 'name', sort: 'desc'}]}
            onRowClick={(e) => handleCertificationOpen(e.row.submission, e.row.user)}
            className={classes.certificationRow}
          />
        </div>
        <Dialog
          fullScreen={fullScreen}
          maxWidth="md"
          fullWidth={true}
          open={certificationOpen}
          onClose={handleCertificationClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title"></DialogTitle>
          <DialogContent>
            {
              details &&
              <>
                <Typography variant="h6">{details.name}</Typography>
                <Typography variant="body2">{details.description}</Typography>
                {
                  !details.roles &&
                  <Chip key='role-req-projectWide' label="Project Wide" style={{margin:'5px'}} color="primary" />
                }
                {
                  details.roles && details.roles.map((role, i) => {
                    return (
                      <Chip key={'role-req-' + i} label={role.name} style={{margin:'5px'}} color="primary" />
                    )
                  })
                }
                <Divider />
                <SubmissionForm user={props.user} submitFor={user} submission={userSubmission} certification={details} project={props.project} company={props.company} onComplete={() => submissionComplete()} />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCertificationClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
