import React from "react";

import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import ShortTextIcon from '@material-ui/icons/ShortText';

const { formatDate } = require('@budeesolutions/budee-util');
const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  header: {
    width:'60px'
  },
  data: {
    flexGrow:1
  },
  row : {
    display:'flex',
    flexDirection:'row'
  }
}));

export default function Role(props) {
  const classes = useStyles();

  const formatStatus = (s) => {
    switch(s) {
      case USER_STATUS.BASIC:
        return 'Basic';
      case USER_STATUS.SUPERVISOR:
        return 'Supervisor';
      case USER_STATUS.SUPERADMIN:
        return 'Administrator';
      default:
        return '';
    }
  }

  return (
    <Tooltip interactive title={
      <>
        <div style={{width:'300px',height:'200px'}}>
          <Typography variant="h6">Employee Details</Typography>
          {
            props.user &&
            <>
              <div className={classes.row}>
                <div className={classes.header}>Name</div>
                <div className={classes.data}>{props.user.name}</div>
              </div>
              {
                props.user.email &&
                <div className={classes.row}>
                  <div className={classes.header}>Email</div>
                  <div className={classes.data}>{props.user.email}</div>
                </div>
              }
              {
                props.user.phone &&
                <div className={classes.row}>
                  <div className={classes.header}>Phone</div>
                  <div className={classes.data}>{props.user.phone}</div>
                </div>
              }
              {
                props.user.username &&
                <div className={classes.row}>
                  <div className={classes.header}>Username</div>
                  <div className={classes.data}>{props.user.username}</div>
                </div>
              }
              <div className={classes.row}>
                <div className={classes.header}>Last Login</div>
                <div className={classes.data}>{props.user.login ? formatDate(new Date(props.user.login)) : '-'}</div>
              </div>
              <div className={classes.row}>
                <div className={classes.header}>Status</div>
                <div className={classes.data}>{formatStatus(props.user.status)}</div>
              </div>
              {
                props.user.contractor &&
                <Chip size="small" style={{margin:'4px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={props.user.contractor} />
              }
              {
                props.user.department &&
                <Chip size="small" style={{margin:'4px',backgroundColor:'blue',color:'white'}} icon={<GroupWorkIcon color="primary" style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={props.user.department} />
              }
              {
                props.user && props.user.role && props.user.role.map((r,i) => {
                  return (
                    <Chip size="small" style={{margin:'4px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} key={'role-' + i} label={r} />
                  )
                })
              }
            </>
          }
        </div>
      </>
    }>
      <ShortTextIcon />
    </Tooltip>
  );
}
