import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import { useEffectWhen } from "../../../libs/hooks.js";

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Filters from './Home/Filters';

import { API } from "aws-amplify";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import DarkUnica from 'highcharts/themes/dark-unica';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import IconButton from '@material-ui/core/IconButton';

import Profiles from './Home/Profiles';
import Alerts from './Home/Alerts';
import Certifications from './Home/Certifications';
import Documents from './Home/Documents';
import Observations from './Home/Observations';
import Training from './Home/Training';
import Emergency from './Home/Emergency';

import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { DatePicker } from '@material-ui/pickers';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}
DarkUnica(Highcharts);

const { RANGES } = require('@budeesolutions/budee-constants');
const { formatDate, toDate } = require('@budeesolutions/budee-util');

var async = require("async");

const useStyles = makeStyles((theme) => ({
  container : {
    width:'100%',
    padding : '5px',
    paddingTop : '0px',
    maxWidth : '100vw'
  },
  dateSelectContainer : {
    display : 'flex',
    flexDirection : 'row',
    width : 'calc(100vw - 268px)',
    [theme.breakpoints.down('sm')]: {
      width : '100vw'
    },
    position : 'fixed',
    backgroundColor : '#565656',
    justifyContent : 'center',
    alignItems : 'center',
    zIndex : 1200,
    overflowX : 'auto'
  },
  dateArrow : {
    display : 'flex',
    flexGrow : 1,
    flexBasis:0,
    marginLeft : '20px',
    marginRight : '20px',
    fontWeight : 'bold',
    fontSize : '15px',
    height:'50px',
    alignItems:'center'
  },
  active : {
    color : theme.palette.secondary.main
  },
  inactive : {
    color : '#FFFFFF'
  },
  disabled : {
    color : '#a2a2a2'
  },
  leftIconButton : {
    padding:'3px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function setTab(params) {
  if (params) {
    switch(params.type) {
      case 'documents':
        return 0;
      case 'certifications':
        return 1;
      case 'observations':
        return 2;
      case 'reading':
        return 3;
      case 'alerts':
        return 4;
      case 'profiles':
        return 5;
      case 'emergency':
        return 6;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function setQuery(val) {
  if (val) {
    switch(val) {
      case 0:
        return 'documents';
      case 1:
        return 'certifications';
      case 2:
        return 'observations';
      case 3:
        return 'reading';
      case 4:
        return 'alerts';
      case 5:
        return 'profiles';
      case 6:
        return 'emergency';
      default:
        return 'documents';
    }
  } else {
    return 'documents';
  }
}

const getToday = () => {
  var now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  return now;
}


const getDateKey = (roll, d) => {
  let key;
  switch (roll) {
    case RANGES.DAY:
      key = d;
    break;
    case RANGES.WEEK:
      key = getWeek(d);
    break;
    case RANGES.MONTH:
      key = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    break;
    default:
      key = d;
  }
  return formatDate(key);
}

const getWeek = (d) => {
  var onejan = new Date(d.getFullYear(), 0, 1);
  return new Date(d.getFullYear(), 0, (1 + (Math.ceil((((d - onejan) / 86400000) + onejan.getDay() + 1) / 7)) * 7));
}

const CONTRACTOR_UPLOADS = 'Contractor Uploads';

export default function Home(props) {
  const classes = useStyles();
  const theme = useTheme();
  const refreshInterval = 300000;
  var parsed = queryString.parse(props.location.search);
  const [selectedDate, setSelectedDate] = useState(getToday());

  const [dateRange, setDateRange] = useState(RANGES.MONTH);
  const [rollUp, setRollUp] = useState(RANGES.DAY);
  const [loading, setLoading] = useState(true);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [certifications, setCertifications] = useState(null);
  const [certificationSubmissions, setCertificationSubmissions] = useState(null);
  const [certificationCharts, setCertificationCharts] = useState(null);
  const [certificationsLoading, setCertificationsLoading] = useState(false);
  const [value, setValue] = useState(setTab(parsed));
  const [filters, setFilters] = useState(null);
  const [employees, setEmployees] = useState(props.employees);
  const [endDateRange, setEndDateRange] = useState(new Date());
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');

  const [observationPointData, setObservationPointData] = useState({});
  const [alertsPointData, setAlertsPointData] = useState({});
  const [submissionsPointData, setSubmissionsPointData] = useState({});

  const [uploads, setUploads] = useState([]);
  const [readonlyDocuments, setReadonlyDocuments] = useState([]);
  const [readonlyReceipts, setReadonlyReceipts] = useState(null);
  const [readonlyCharts, setReadonlyCharts] = useState(null);
  const [readonlyLoading, setReadonlyLoading] = useState(false);
  const [delayLoading, setDelayLoading] = useState(null);
  const [selectedReadonly, setSelectedReadonly] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [documentSubmissions, setDocumentSubmissions] = useState([]);
  const [observations, setObservations] = useState([]);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    var numberOfDays = getNumberOfDays(dateRange);
    var copy = new Date(endDateRange);
    copy.setDate(endDateRange.getDate() - numberOfDays);
    setStartDateRange(copy);
  }, [dateRange, endDateRange]);

  const selectSeriesPoint = (x) => {
    var subs = {};
    for (const series of data) {
      subs[series.name] = [];
      for (const d of series.data) {
        if (d[0] === x) {
          subs[series.name] = [...d[2]];
        }
      }
    }

    switch(value) {
      case 0:
        setSubmissionsPointData(subs);
        break;
      case 2:
        setObservationPointData(subs);
        break;
      case 4:
        setAlertsPointData(subs);
        break;
      default:
    }
  };

  useEffect(() => {
    const copy = new Date(toDate(getDateKey(rollUp, endDateRange)));
    setSelectedDate(copy);
  }, [rollUp, endDateRange]);

  useEffectWhen(() => {
    if (selectedDate) {
      selectSeriesPoint(toDate(formatDate(selectedDate)).getTime())
    }
  }, [data, selectedDate, selectSeriesPoint], [data, selectedDate]);

  const getNumberOfDays = (dateRange) => {
    switch(dateRange) {
      case RANGES.WEEK:
        return 7;
      case RANGES.MONTH:
        return 31;
      case RANGES.MONTH3:
        return 93;
      case RANGES.MONTH6:
        return 186;
      case RANGES.YEAR1:
        return 365;
      case RANGES.ALL:
        return 5 * 365;
      default:
        return 1;
    }
  }

  const loadCertifications = async (projectId) => {
    return API.get("budeeBackend", "certifications/project?projectId=" + projectId, {})
      .then(res => setCertifications(res));
  }

  const loadObservations = async (projectId) => {
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    start.setYear(start.getFullYear() - 1);
    var end = new Date();
    end.setTime(end.getTime() + (24*60*60*1000));
    return API.get("budeeBackend", "dashboard/observations?projectId=" + projectId + '&start=' + start.toUTCString() + '&end=' + end.toUTCString(), {})
      .then(val => setObservations(val));
  };

  const loadUploads = async (projectId) => {
    return API.get("budeeBackend", "documents/upload?projectId=" + projectId, {})
      .then(val => setUploads(val));
  };

  const loadDocuments = async (projectId) => {
    return Promise.all([
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=project", {}),
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=company", {})
    ]).then(vals => {
      let docs = [...vals[0],...vals[1]];
      docs.sort((a,b) => {
        return a.name.localeCompare(b.name);
      })
      setDocuments(docs);
    });
  };

  const loadSubmissions = async (projectId) => {
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    start.setYear(start.getFullYear() - 1);
    var end = new Date();
    end.setTime(end.getTime() + (24*60*60*1000));
    return API.get("budeeBackend", "documents/submission/project?projectId=" + projectId + '&start=' + start.toUTCString() + '&end=' + end.toUTCString(), {})
      .then(res => setDocumentSubmissions(res));
  };

  const loadReading = async (projectId) => {
    return Promise.all([
      API.get("budeeBackend", "documents/readonly/admin?projectId=" + projectId, {}),
      API.get("budeeBackend", "documents/readonly/admin?projectId=" + projectId + "&scope=project", {})])
      .then(vals => {
        let docs = [...vals[0],...vals[1]];
        docs.sort((a,b) => {
          return a.name.localeCompare(b.name);
        })
        setReadonlyDocuments(docs);
      });
  };

  const loadAlerts = async (projectId) => {
    return API.get("budeeBackend", 'notifications/project?projectId=' + projectId, {}).then(val => setAlerts(val));
  }

  const load = async (projectId, status) => {
    Promise.all([
      loadCertifications(projectId),
      loadObservations(projectId),
      loadDocuments(projectId),
      loadSubmissions(projectId),
      loadUploads(projectId),
      loadReading(projectId),
      loadAlerts(projectId)
    ]).then(values => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  useEffectWhen(() => {
    if (props.project) {
      load(props.project.details.id, props.project.status);
    }
  }, [props.project, load], [props.project]);

  const changeDate = (days) => {
    var sdate = new Date(startDateRange.getTime());
    sdate.setDate(sdate.getDate() + (days * getNumberOfDays(dateRange)));
    setStartDateRange(sdate);
    var edate = new Date(endDateRange.getTime());
    edate.setDate(edate.getDate() + (days * getNumberOfDays(dateRange)));
    setEndDateRange(edate);
  }

  useEffectWhen(() => {
    if (refreshInterval && refreshInterval > 0) {
      const refresh = setInterval(() => {
        load(props.project.details.id, props.project.status);
      }, refreshInterval);
      return () => clearInterval(refresh);
    }
  }, [props.project, refreshInterval, props], [refreshInterval, props.project]);

  useEffect(() => {
    if (certifications) {
      setCertificationsLoading(true);
      async.mapLimit(certifications.required, 4, async.asyncify(async function(id) {
        let resp = {};
        resp[id] = await API.get('budeeBackend', 'dashboard/certifications', {
          queryStringParameters : {
            projectId : props.project.details.id,
            certificationId : id
          }
        });
        return resp;
      }), (err, results) => {
        if (err) {
          console.error(err);
        } else {
          const certSubs = {};
          for (const r of results) {
            certSubs[Object.keys(r)[0]] = r[Object.keys(r)[0]];
          }
          setCertificationSubmissions(certSubs);
        }
        setCertificationsLoading(false);
      });
    }
  }, [certifications, props.employees, props.project]);

  useEffect(() => {
    if (readonlyDocuments && props.project) {
      setReadonlyLoading(true);
      async.mapLimit(readonlyDocuments, 4, async.asyncify(async function(doc) {
        let resp = {};
        resp[doc.id] = await API.get('budeeBackend', 'documents/readonly/admin/read', {
          queryStringParameters : {
            projectId : props.project.details.id,
            documentId : doc.id,
            scope : doc.scope
          }
        });
        return resp;
      }), (err, results) => {
        if (err) {
          console.error(err);
        } else {
          const readRec = {};
          for (const r of results) {
            readRec[Object.keys(r)[0]] = r[Object.keys(r)[0]];
          }
          setReadonlyReceipts(readRec);
        }
        setReadonlyLoading(false);
      });
    }
  }, [readonlyDocuments, props.project]);

  useEffectWhen(() => {
    if (value === 0 && Array.isArray(documents) && Array.isArray(documentSubmissions)) {
      var filtered = filters ? documentSubmissions.filter(sub => filterDocumentSubmission(sub, filters)) : documentSubmissions;
      var docs = documents.reduce(function(map, obj) {
          map[obj.id] = obj;
          return map;
      }, {});

      // Get all Date Keys Zero'd
      var marker = toDate(getDateKey(rollUp, startDateRange));
      marker.setHours(23);
      marker.setMinutes(59);
      marker.setSeconds(59);
      var seriesRange = {};
      var end = new Date(endDateRange);
      end.setDate(end.getDate() + 1);
      while (marker <= end) {
        let key = getDateKey(rollUp, marker);
        seriesRange[key] = [toDate(key).getTime(),0,[]];
        marker.setDate(marker.getDate() + 1);
      }

      var d = {};
      for (const sub of filtered) {
        let date = new Date(sub.date);
        if (startDateRange <= date && date <= endDateRange) {
          let key = getDateKey(rollUp, date);
          if (d.hasOwnProperty(sub.documentId)) {
            if (d[sub.documentId].hasOwnProperty(key)) {
              d[sub.documentId][key][1]++;
              d[sub.documentId][key][2].push(sub);
            } else {
              d[sub.documentId][key] = [toDate(key).getTime(), 1, [sub]];
            }
          } else {
            var series = JSON.parse(JSON.stringify(seriesRange));
            series[key] = [toDate(key).getTime(), 1, [sub]];
            d[sub.documentId] = series;
          }
        }
      }
      series = [];
      for (const key of Object.keys(d)) {
        if (docs.hasOwnProperty(key)) {
          series.push({
            name : docs[key].name,
            data : Object.values(d[key])
          });
        }
      }

      // Get Uploads
      if (Array.isArray(uploads) && uploads.length > 0) {
        var uploadFiltered = filters && filters.contractors && filters.contractors.length > 0 ? uploads.filter(sub => filterDocumentUpload(sub, filters)) : uploads;
        var up = JSON.parse(JSON.stringify(seriesRange));
        for (const u of uploadFiltered) {
          let date = new Date(u.date);
          if (startDateRange <= date && date <= endDateRange) {
            let key = getDateKey(rollUp, date);
            if (up.hasOwnProperty(key)) {
              up[key][1]++;
              up[key][2].push(u);
            } else {
              up[key] = [toDate(formatDate(date)).getTime(), 1, [u]];
            }
          }
        }
        series.push({
          name : CONTRACTOR_UPLOADS,
          data : Object.values(up)
        });
      }

      setData(series);
      setTitle('Submissions By Document');
    }
  }, [value, documentSubmissions, dateRange, documents, endDateRange, getDateKey, startDateRange, rollUp, filters, uploads],
      [filters, value, documentSubmissions, dateRange, documents, startDateRange, rollUp, uploads]);

  useEffect(() => {
    if (props.employees && certifications && certificationSubmissions) {
      var active = {};
      for (const [id, emp] of Object.entries(props.employees)) {
        if (!emp.inactive && !filters) {
          active[id] = emp;
        } else if (!emp.inactive && filterEmployee(emp, filters)) {
          active[id] = emp;
        }
      }

      var charts = [];
      for (const cert of certifications.certifications) {
        if (!certifications.required.includes(cert.id)) {
          continue;
        }

        var requiredUsers = [];
        const rolesArray = cert.roles ? cert.roles.map(obj => obj.name) : [];
        const contractorsArray = cert.contractors ? cert.contractors.map(obj => obj.name) : [];
        var subs = 0;
        if (certificationSubmissions.hasOwnProperty(cert.id)) {
          for (const empId of Object.keys(certificationSubmissions[cert.id])) {
            if (!active.hasOwnProperty(empId)) {
              continue;
            }

            if (certificationSubmissions[cert.id][empId].expires) {
              var expiry = toDate(certificationSubmissions[cert.id][empId].expires);
              expiry.setHours(23);
              expiry.setMinutes(59);
              expiry.setSeconds(59);
              var today = new Date();
              expiry.setHours(0);
              expiry.setMinutes(0);
              expiry.setSeconds(0);

              if (expiry < today) {
                continue;
              }
            }
            if (cert.contractors || cert.roles) {
              if (cert.contractors && active[empId].contractor && contractorsArray.includes(active[empId].contractor)) {
                subs++;
                continue;
              }
              if (cert.roles && active[empId].role && rolesArray.filter(r => active[empId].role.includes(r)).length > 0) {
                subs++;
                continue;
              }
            } else if (!cert.roles && !cert.contractors) {
              subs++;
            }
          }
        }

        var count = 0;

        if (!cert.roles && !cert.contractors) {
          count = Object.keys(active).length;
          requiredUsers = Object.keys(active);
        } else {
          for (const e of Object.values(active)) {
            let include = false;
            if (cert.roles && cert.contractors && rolesArray.filter(r => e.role.includes(r)).length > 0 && contractorsArray.includes(e.contractor)) {
              include = true;
            } else if (cert.roles && rolesArray.filter(r => e.role.includes(r)).length > 0) {
              include = true;
            } else if (cert.contractors && contractorsArray.includes(e.contractor)) {
              include = true;
            }
            if (include) {
              count++;
              requiredUsers.push(e.id);
            }
          }
        }
        if (count > 0) {
          charts.push({
            name : cert.name,
            cert : cert,
            required : requiredUsers,
            data : [
              {
                name : 'Submitted',
                y : subs === 0 ? 0 : (subs / count) * 100,
                color : 'green'
              },
              {
                name : 'Missing',
                y : ((count - subs)  / count) * 100,
                color : 'red'
              }
            ]
          });
        }
      }
      setCertificationCharts(charts);
    }
  }, [certifications, certificationSubmissions, props.employees, filters]);

  useEffect(() => {
    if (props.employees && readonlyDocuments && readonlyReceipts) {
      var active = {};
      for (const [id, emp] of Object.entries(props.employees)) {
        if (!emp.inactive && !filters) {
          active[id] = emp;
        } else if (!emp.inactive && filterEmployee(emp, filters)) {
          active[id] = emp;
        }
      }
      var charts = [];
      for (const doc of readonlyDocuments) {
        const rolesArray = doc.roles ? doc.roles.map(obj => obj.name) : [];
        const contractorsArray = doc.contractors ? doc.contractors.map(obj => obj.name) : [];
        var subs = 0;
        if (readonlyReceipts.hasOwnProperty(doc.id)) {
          for (const empId of Object.keys(readonlyReceipts[doc.id])) {
            if (!active.hasOwnProperty(empId)) {
              continue;
            }
            if (doc.contractors) {
              if (active[empId].contractor && contractorsArray.includes(active[empId].contractor)) {
                subs++;
                break;
              }
            }
            if (doc.roles) {
              if (active[empId].role && rolesArray.filter(r => active[empId].role.includes(r)).length > 0) {
                subs++;
                break;
              }
            }
            if (!doc.roles && !doc.contractors) {
              subs++;
            }
          }
        }

        var count = 0;
        var requiredUsers = [];
        if (!doc.roles && !doc.contractors) {
          count = Object.keys(active).length;
          requiredUsers = Object.keys(active);
        } else {
          for (const e of Object.values(active)) {
            let include = false;
            if (doc.roles && doc.contractors && rolesArray.filter(r => e.role.includes(r)).length > 0 && contractorsArray.includes(e.contractor)) {
              include = true;
            } else if (doc.roles && rolesArray.filter(r => e.role.includes(r)).length > 0) {
              include = true;
            } else if (doc.contractors && contractorsArray.includes(e.contractor)) {
              include = true;
            }
            if (include) {
              count++;
              requiredUsers.push(e.id);
            }
          }
        }
        if (count > 0) {
          charts.push({
            name : doc.name,
            doc : doc,
            required : requiredUsers,
            data : [
              {
                name : 'Submitted',
                y : subs === 0 ? 0 : (subs / count) * 100,
                color : 'green'
              },
              {
                name : 'Missing',
                y : ((count - subs)  / count) * 100,
                color : 'red'
              }
            ]
          });
        }
      }
      setReadonlyCharts(charts);
    }
  }, [readonlyDocuments, readonlyReceipts, props.employees, filters]);

  useEffectWhen(() => {
    if (value === 2 && Array.isArray(observations)) {
      var filtered = filters ? observations.filter(obv => filterObservation(obv, filters)) : observations;

      // Get all Date Keys Zero'd
      var marker = toDate(getDateKey(rollUp, startDateRange));
      marker.setHours(23);
      marker.setMinutes(59);
      marker.setSeconds(59);
      var seriesRange = {};
      var end = new Date(endDateRange);
      end.setDate(end.getDate() + 1);
      while (marker <= end) {
        let key = getDateKey(rollUp, marker);
        seriesRange[key] = [toDate(key).getTime(),0,[]];
        marker.setDate(marker.getDate() + 1);
      }

      var d = JSON.parse(JSON.stringify(seriesRange));
      for (const obv of filtered) {
        var date = new Date(obv.date);
        if (startDateRange <= date && date <= endDateRange) {
          var key = getDateKey(rollUp, date);
          if (d.hasOwnProperty(key)) {
            d[key][1]++;
            d[key][2].push(obv);
          } else {
            d[key] = [toDate(key).getTime(), 1, [obv]];
          }
        }
      }
      setData([{
        name : 'Safety Observations',
        data : Object.values(d)
      }]);
      setTitle('Safety Observations');
    }
  }, [value, observations, dateRange, startDateRange, rollUp, endDateRange, getDateKey, filters], [filters, value, observations, dateRange, startDateRange, rollUp]);

  useEffectWhen(() => {
    if (value === 4 && Array.isArray(alerts)) {
      var filtered = filters ? alerts.filter(alr => filterAlert(alr, filters)) : alerts;

      // Get all Date Keys Zero'd
      var marker = toDate(getDateKey(rollUp, startDateRange));
      marker.setHours(23);
      marker.setMinutes(59);
      marker.setSeconds(59);
      var seriesRange = {};
      var end = new Date(endDateRange);
      end.setDate(end.getDate() + 1);
      while (marker <= end) {
        let key = getDateKey(rollUp, marker);
        seriesRange[key] = [toDate(key).getTime(),0,[]];
        marker.setDate(marker.getDate() + 1);
      }

      var alr = {};
      for (const a of filtered) {
        var date = new Date(a.date);
        if (startDateRange <= date && date <= endDateRange) {
          var key = getDateKey(rollUp, date);
          if (alr.hasOwnProperty(a.title)) {
            if (alr[a.title].hasOwnProperty(key)) {
              alr[a.title][key][1]++;
              alr[a.title][key][2].push(a);
            } else {
              alr[a.title][key] = [toDate(key).getTime(), 1,[a]];
            }
          } else {
            let s = JSON.parse(JSON.stringify(seriesRange));
            s[key] = [toDate(key).getTime(), 1, [a]];
            alr[a.title] = s;
          }
        }
      }
      let series = [];
      for (const key of Object.keys(alr)) {
        series.push({
          name : key,
          data : Object.values(alr[key])
        });
      }
      setData(series);
      setTitle('Project Communications');
    }
  }, [value, alerts, dateRange, startDateRange, rollUp, endDateRange, getDateKey, filters], [filters, value, alerts, dateRange, startDateRange, rollUp]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify(Object.assign(parsed, {type:setQuery(newValue),dateRange:dateRange}))
    });
  }

  const filterEmployee = (emp, filts) => {
    if (filts.roles && emp.role && filts.roles.filter(r => emp.role.includes(r)).length > 0) {
      return true;
    }
    if (filts.departments && emp.department && filts.departments.includes(emp.department)) {
      return true;
    }
    if (filts.contractors && emp.contractor && filts.contractors.includes(emp.contractor)) {
      return true;
    }
    if (filts.subordinates && filts.subordinates.includes(emp.id)) {
      return true;
    }
    return false;
  }

  const filterObservation = (obv, filts) => {
    if (filts && obv) {
      var empSubmitted = props.employees[obv.userId];
      if (filts.roles && filts.roles.length > 0 && filts.roles.filter(r => empSubmitted.role.includes(r)).length > 0) {
        return true;
      }
      if (filts.departments && filts.departments.length > 0 && filts.departments.includes(empSubmitted.department)) {
        return true;
      }
      if (filts.contractors && filts.contractors.length > 0 && filts.contractors.includes(empSubmitted.contractor)) {
        return true;
      }
      if (filts.subordinates && filts.subordinates.includes(obv.userId)) {
        return true;
      }
    }
    return false;
  }

  const filterAlert = (alert, filts) => {
    if (alert.distribution.all) {
      return true;
    }
    if (filts && alert) {
      if (filts.roles && filts.roles.length > 0 && filts.roles.filter(r => alert.distribution.roles.includes(r)).length > 0) {
        return true;
      }
      if (filts.departments && filts.departments.length > 0 && filts.departments.filter(r => alert.distribution.departments.includes(r)).length > 0) {
        return true;
      }
      if (filts.contractors && filts.contractors.length > 0 && filts.contractors.filter(r => alert.distribution.contractors.includes(r)).length > 0) {
        return true;
      }
      if (filts.subordinates && filts.subordinates.length > 0 && filts.subordinates.filter(r => alert.distribution.singles.includes(r)).length > 0) {
        return true;
      }
    }
    return false;
  }

  const filterDocumentSubmission = (doc, filts) => {
    if (props.employees && props.employees.hasOwnProperty(doc.userId) && filts) {
      var empSubmitted = props.employees[doc.userId];

      if (filts.roles && empSubmitted.role && filts.roles.filter(r => empSubmitted.role.includes(r)).length > 0) {
        return true;
      }
      if (filts.departments && empSubmitted.department && filts.departments.includes(empSubmitted.department)) {
        return true;
      }
      if (filts.contractors && empSubmitted.contractor && filts.contractors.includes(empSubmitted.contractor)) {
        return true;
      }
      if (filts.subordinates && filts.subordinates.includes(empSubmitted.id)) {
        return true;
      }
    }
    return false;
  }

  const filterDocumentUpload = (doc, filts) => {
    if (filts && filts.subordinates && filts.subordinates.includes(doc.userId)) {
      return true;
    }
    return filts && filts.contractors && doc.contractor && filts.contractors.includes(doc.contractor);
  }

  const getRequired = (charts, selected) => {
    if (charts && selected) {
      for (const c of charts) {
        if ((c.cert && c.cert.id === selected.id) || (c.doc && c.doc.id === selected.id)) {
          return c.required;
        }
      }
    }
    return [];
  }

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid xs={12} item>
        {
          loading &&
          <div style={{margin:'0 auto', width:'100px'}}>
            <CircularProgress color="secondary" size={80} />
          </div>
        }
        {
          !loading && employees &&
          <>
            {
              value !== 5 && value !== 6 &&
              <>
                <div style={{width:'100%', height:'50px', marginBottom:'4px'}}>
                  <div className={classes.dateSelectContainer}>
                    <div className={classes.dateArrow} style={{ textAlign : 'right', color : theme.palette.secondary.main }}>
                      <div style={{display:'flex',flexDirection:'row',textAlign:'center',flexGrow:1,paddingTop:'10px'}}>
                        <Filters project={props.project} roles={props.roles} contractors={props.contractors} departments={props.departments} setFilters={setFilters} employees={props.employees} user={props.user}  />
                      </div>
                      {
                        (value === 0 || value === 2 || value === 4) &&
                        <div style={{width:'40px',cursor:'pointer'}} onClick={() => changeDate(-1)}>
                          {'<<'}
                        </div>
                      }
                    </div>
                    {
                      (value === 0 || value === 2 || value === 4) &&
                      <>
                        <div style={{ textAlign : 'center', cursor : 'pointer', color : '#FFFFFF' }}>
                        {
                          dateRange !== RANGES.ALL &&
                          <Typography>{formatDate(startDateRange)}</Typography>
                        }
                        </div>
                        <div style={{width:'50px',textAlign:'center',color:'white'}}>-</div>
                        <div style={{ textAlign : 'center', cursor : 'pointer', color : '#FFFFFF' }}>
                          <DatePicker
                            value={endDateRange}
                            format="MMMM d yyyy"
                            onChange={(d) => setEndDateRange(d)}
                            TextFieldComponent={props => (<Input
                                                            type="text"
                                                            onClick={props.onClick}
                                                            value={props.value}
                                                            onChange={props.onChange}
                                                            fullWidth
                                                            style={{ textAlign : 'center', color : '#FFFFFF', cursor : 'pointer' }}
                                                          />)}
                          />
                        </div>
                        <div className={classes.dateArrow} style={{ textAlign : 'left', color : theme.palette.secondary.main }}>
                          <div style={{width : '40px',cursor : 'pointer'}} onClick={() => changeDate(1)}>
                            {'>>'}
                          </div>
                          <div style={{flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                            <IconButton onClick={() => setRollUp(RANGES.DAY)}>
                              <Typography className={rollUp === RANGES.DAY ? classes.active : classes.inactive}>DAY</Typography>
                            </IconButton>
                            <IconButton disabled={dateRange === RANGES.WEEK} onClick={() => setRollUp(RANGES.WEEK)}>
                              <Typography className={rollUp === RANGES.WEEK ? classes.active : (dateRange === RANGES.WEEK ? classes.disabled : classes.inactive)}>WEEK</Typography>
                            </IconButton>
                            <IconButton disabled={dateRange === RANGES.WEEK || dateRange === RANGES.MONTH} onClick={() => setRollUp(RANGES.MONTH)}>
                              <Typography className={rollUp === RANGES.MONTH ? classes.active : (dateRange === RANGES.WEEK || dateRange === RANGES.MONTH ? classes.disabled : classes.inactive)}>MONTH</Typography>
                            </IconButton>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <div style={{width:'50px',display:'flex',flexDirection:'column'}}>
                    {
                      (value === 0 || value === 2 || value === 4) &&
                      <>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.WEEK)}>
                          <Typography className={dateRange === RANGES.WEEK ? classes.active : classes.inactive}>1W</Typography>
                        </IconButton>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.MONTH)}>
                          <Typography className={dateRange === RANGES.MONTH ? classes.active : classes.inactive}>1M</Typography>
                        </IconButton>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.MONTH3)}>
                          <Typography className={dateRange === RANGES.MONTH3 ? classes.active : classes.inactive}>3M</Typography>
                        </IconButton>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.MONTH6)}>
                          <Typography className={dateRange === RANGES.MONTH6 ? classes.active : classes.inactive}>6M</Typography>
                        </IconButton>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.YEAR1)}>
                          <Typography className={dateRange === RANGES.YEAR1 ? classes.active : classes.inactive}>1Y</Typography>
                        </IconButton>
                        <IconButton className={classes.leftIconButton} onClick={() => setDateRange(RANGES.ALL)}>
                          <Typography className={dateRange === RANGES.ALL ? classes.active : classes.inactive}>All</Typography>
                        </IconButton>
                      </>
                    }
                  </div>
                  <div style={{flexGrow:1,height:'325px'}}>
                  {
                    value === 1 &&
                    <Grid container style={{overflowY:'scroll',height:'325px',maxHeight:'325px'}}>
                    {
                      certificationsLoading &&
                      <div style={{margin:'0 auto', width:'100px'}}>
                        <CircularProgress color="secondary" size={80} />
                      </div>
                    }
                    {
                      !certificationsLoading &&
                      <>
                        {
                          certificationCharts && certificationCharts.length === 0 &&
                          <div style={{width:'100%',height:'325px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography variant="h6">You currently don't have any certification requirements configured.</Typography>
                            <Link style={{color:'#FFFFFF'}} href="/certifications">Go to Certifications to Get Started!</Link>
                          </div>
                        }
                        {
                          certificationCharts && certificationCharts.map((c,i) => {
                            if (i === 0 && !selectedCertification) {
                              setSelectedCertification(c.cert);
                            }
                            return (
                              <Grid key={'chart-' + i} xs={12} sm={3} item style={{ height:'256px',border: selectedCertification && c.cert.id === selectedCertification.id ? '3px solid ' + theme.palette.secondary.main : '3px solid #565656'}} onClick={() => {
                                setSelectedCertification(c.cert);
                              }}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                      exporting: {
                                        chartOptions: { // specific options for the exported image
                                            plotOptions: {
                                                series: {
                                                    dataLabels: {
                                                        enabled: true
                                                    }
                                                }
                                            }
                                        },
                                        fallbackToExportServer: false
                                      },
                                      chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'pie',
                                        height:'250px'
                                      },
                                      title: {
                                          text: c.name,
                                          floating:true,
                                          style:{fontSize:'14px'}
                                      },
                                      tooltip: {
                                          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                      },
                                      accessibility: {
                                          enabled : false
                                      },
                                      plotOptions: {
                                          pie: {
                                              allowPointSelect: true,
                                              cursor: 'pointer',
                                              dataLabels: {
                                                  enabled: false
                                              },
                                              showInLegend: true,
                                              size:'70%'
                                          }
                                      },
                                      series: [{
                                        name : '',
                                        data : c.data
                                      }]
                                  }}
                                />
                              </Grid>
                            )
                          })
                        }
                      </>
                    }
                    </Grid>
                  }
                  {
                    (value === 0 || value === 2 || value === 4) &&
                    <Grid container style={{overflowY:'scroll',height:'325px'}}>
                      <Grid xs={12} item>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            {
                                exporting: {
                                  chartOptions: { // specific options for the exported image
                                      plotOptions: {
                                          series: {
                                              dataLabels: {
                                                  enabled: true
                                              }
                                          }
                                      }
                                  },
                                  fallbackToExportServer: false
                                },
                                chart: {
                                    type: 'spline',
                                    height:'315px'
                                },
                                accessibility : {
                                  enabled : false
                                },
                                title: {
                                    text: title
                                },
                                subtitle: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'datetime',
                                    dateTimeLabelFormats: {
                                        month: '%e. %b',
                                        year: '%b'
                                    },
                                    title: {
                                        text: 'Date'
                                    },
                                    plotLines: [{
                                      color: '#FF0000', // Red
                                      width: 2,
                                      value: selectedDate.getTime()
                                    }]
                                },
                                yAxis: {
                                    title: {
                                        text: '# of Submissions'
                                    },
                                    min: 0
                                },
                                tooltip: {
                                    headerFormat: '<b>{series.name}</b><br>',
                                    pointFormat: '{point.x:%b-%e}: {point.y}'
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        marker: {
                                            enabled: true,
                                            radius: 2.5
                                        },
                                        allowPointSelect: true,
                                        point:{
                                          events:{
                                            select: function(e) {
                                              const { x } = e.target.options;
                                              setSelectedDate(new Date(x));
                                            }
                                          }
                                        }
                                    }
                                },
                                colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
                                series: JSON.parse(JSON.stringify(data))
                          }}
                        />
                      </Grid>
                    </Grid>
                    }
                  {
                    value === 3 &&
                    <Grid container style={{overflowY:'scroll',height:'325px'}}>
                    {
                      readonlyLoading &&
                      <div style={{margin:'0 auto', width:'100px'}}>
                        <CircularProgress color="secondary" size={80} />
                      </div>
                    }
                    {
                      !readonlyLoading &&
                      <>
                        {
                          readonlyCharts && readonlyCharts.length === 0 &&
                          <div style={{width:'100%',height:'325px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography variant="h6">You currently don't have any training requirements configured.</Typography>
                            <Link style={{color:'#FFFFFF'}} href="/training">Go to Training to Get Started!</Link>
                          </div>
                        }
                        {
                          readonlyCharts && readonlyCharts.map((d,i) => {
                            if (i === 0 && !selectedReadonly) {
                              setSelectedReadonly(d.doc);
                            }
                            return (
                              <Grid key={'chart-' + i} xs={12} sm={3} item style={{ height:'256px',border: selectedReadonly && d.doc.id === selectedReadonly.id ? '3px solid ' + theme.palette.secondary.main : '3px solid #565656'}} onClick={() => setSelectedReadonly(d.doc)}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                      exporting: {
                                        chartOptions: { // specific options for the exported image
                                            plotOptions: {
                                                series: {
                                                    dataLabels: {
                                                        enabled: true
                                                    }
                                                }
                                            }
                                        },
                                        fallbackToExportServer: false
                                      },
                                      chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'pie',
                                        height:'250px'
                                      },
                                      title: {
                                          text: d.name,
                                          floating:true,
                                          style:{fontSize:'14px'}
                                      },
                                      tooltip: {
                                          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                      },
                                      accessibility: {
                                          enabled : false
                                      },
                                      plotOptions: {
                                          pie: {
                                              allowPointSelect: true,
                                              cursor: 'pointer',
                                              dataLabels: {
                                                  enabled: false
                                              },
                                              showInLegend: true,
                                              size:'70%'
                                          }
                                      },
                                      series: [{
                                        name : '',
                                        data : JSON.parse(JSON.stringify(d.data))
                                      }]
                                  }}
                                />
                              </Grid>
                            )
                          })
                        }
                      </>
                    }

                    </Grid>
                  }
                  </div>
                </div>
              </>
            }
            <AppBar position="static" style={{width:'100%',marginTop:'10px'}}>
              <Tabs value={value} onChange={handleTabChange} aria-label="Manage Personnel" variant="scrollable" scrollButtons="auto">
                <Tab label={"Documents"} {...a11yProps(0)} />
                <Tab label={"Certifications"} {...a11yProps(1)} />
                <Tab label={"Observations"} {...a11yProps(2)} />
                <Tab label={"Training"} {...a11yProps(3)} />
                <Tab label={"Alerts"} {...a11yProps(4)} />
                <Tab label={"Profiles"} {...a11yProps(5)} />
                <Tab label={"Emergency Contact"} {...a11yProps(6)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Documents rollUp={rollUp} selectedDate={selectedDate} series={submissionsPointData} project={props.project} employees={employees} user={props.user} documents={documents} uploads={uploads} company={props.company} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Certifications loading={certificationsLoading} certification={selectedCertification} required={getRequired(certificationCharts, selectedCertification)} project={props.project} company={props.company} submissions={certificationSubmissions} employees={employees} user={props.user} reload={() => loadCertifications(props.project.details.id)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Observations rollUp={rollUp} selectedDate={selectedDate} project={props.project} employees={employees} user={props.user} observations={observationPointData} reload={() => loadObservations(props.project.details.id)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Training
                loading={readonlyLoading}
                rollUp={rollUp}
                required={getRequired(readonlyCharts, selectedReadonly)}
                project={props.project}
                employees={employees}
                user={props.user}
                document={selectedReadonly}
                reload={() => {
                  if (delayLoading) {
                    clearTimeout(delayLoading);
                  }
                  setDelayLoading(setTimeout(async () => {
                    loadReading(props.project.details.id);
                  }, 2000));
                }}
                receipts={readonlyReceipts && selectedReadonly && readonlyReceipts.hasOwnProperty(selectedReadonly.id) ? readonlyReceipts[selectedReadonly.id] : {}}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Alerts rollUp={rollUp} selectedDate={selectedDate} project={props.project} employees={employees} user={props.user} series={alertsPointData} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Profiles location={props.location} history={props.history} project={props.project} company={props.company} employees={employees} documents={documents} user={props.user} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Emergency employees={employees} user={props.user} project={props.project} loadEmployees={props.loadEmployees} updateEmployee={props.updateEmployee} />
            </TabPanel>
          </>
        }
        </Grid>
      </Grid>
    </div>
  );
}
