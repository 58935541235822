import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';

import View from '../components/Dashboard/Sections/Documents/Dynamic/View';
import Header from '../components/Print/Header';
import { DOCUMENT_PRINT_JOB } from '../config/localStorage';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  print : {
    '@media print': {
      display: 'block',
      pageBreakAfter: 'always'
    },
    width:'8.5in',
    margin:'0 0'
  }
}));

export default function PrintDocument(props) {
  const classes = useStyles();
  const GlobalCss = withStyles({
    "@global": {
      "span": {
        fontSize: '10px'
      },
      "span.MuiTypography-body1" : {
        fontSize: '10px'
      },
      "p.MuiTypography-body1" : {
        fontSize: '10px'
      },
      'img' : {
        breakInside: 'avoid'
      }
    }
  })(() => null);

  const [job, setJob] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const job = JSON.parse(localStorage.getItem(DOCUMENT_PRINT_JOB));
      console.log(job);
      if (job) {
        setJob(job);
      }
    } catch (e) {
      // Do nothing on errors
    }
  }

  return (
    <div className={classes.print}>
      <GlobalCss />
      {
        job &&
        <>
        {
          job && job.submissions.map((s,i) => {
            return (
              <div key={'sub-' + i}>
                <Header submission={s} document={job.document} user={job.user} employees={job.employees} project={job.project} fields={job.fields} company={job.company} print={true} />
                <View submission={s} document={job.document} user={job.user} employees={job.employees} project={job.project} fields={job.fields} print={true} />
              </div>
            );
          })
        }
        </>
      }
      {
        !job &&
        <div style={{width:'100%',height: window.innerHeight + 'px',alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column'}}>
          <Typography variant="h2">No Job Found.</Typography>
          <Typography variant="body2">Try selecting documents and clicking print again, there was no job found to print.</Typography>
        </div>
      }
    </div>
  );
}
