import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { SELECTED_PROJECT } from "../../config/localStorage";
import { PRIVILEGED_USER } from "../../config/constants";
import { API } from "aws-amplify";

import Documents from './Sections/Documents';
import Observations from './Sections/Observations';
import Certifications from './Sections/Certifications';
import CompanyProfile from './Sections/CompanyProfile';
import Home from './Sections/Home';
import Personnel from './Sections/Personnel';
import Organization from './Sections/Organization';
import UserProfile from './Sections/UserProfile';
import Alerts from './Sections/Alerts';
import Training from './Sections/Training';
import Rewards from './Sections/Rewards';
import CompanyRegister from '../../components/CompanyRegister';
import ProjectRegister from '../../components/ProjectRegister';

// Company
import Projects from './Sections/Company/Projects';
import EAP from './Sections/Company/EAP';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import TuneIcon from '@material-ui/icons/Tune';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Paper from '@material-ui/core/Paper';
import BusinessIcon from '@material-ui/icons/Business';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BuildIcon from '@material-ui/icons/Build';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = isMobile ? window.screen.width * 0.8 : 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink : 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    flexGrow:1
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: '0px',
    backgroundColor : '#565656',
    height : '100%'
  },
  nested : {
    paddingLeft: theme.spacing(4)
  }
}));

function localProject(projects) {
  if (projects) {
    const project = localStorage.getItem(SELECTED_PROJECT) !== '' ? JSON.parse(localStorage.getItem(SELECTED_PROJECT)) : null;

    if (project) {
      if (projects && projects.length > 0) {
        for (const proj of projects) {
          if (project.details.id === proj.details.id) {
            if (proj.status === 'basic' && (window.location.pathname !== '/training' && window.location.pathname !== '/documents' && window.location.pathname !== '/user' && window.location.pathname !== '/company')) {
              window.history.replaceState(null, "Training", "/training");
              window.location.reload();
            }
            return proj;
          }
        }
        return projects[0];
      }
      localStorage.removeItem(SELECTED_PROJECT);
      return null;
    } else if (projects && projects.length > 0) {
      return projects[0];
    }
  }
  return null;
}

export default function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [selected, setSelected] = useState(props.page !== '' ? props.page : 'home');
  const [project, setProject] = useState(localProject(props.projects));
  const [projects, setProjects] = useState(props.projects);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [company, setCompany] = useState(props.company);
  const [employees, setEmployees] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [confirmClose, setConfirmClose] = useState(false);

  useEffect(() => {
    if (project) {
      localStorage.setItem(SELECTED_PROJECT, JSON.stringify(project));
      loadEmployees(project.details.id);
      loadRoles(project.details.id);
      loadDepartments(project.details.id);
      loadContractors(project.details.id);
      loadNotifications(project.details.id);
      props.setSelectedProject(project);
    }
  }, [project, props]);

  useEffect(() => {
    if (projects && projects.length > 0) {
      setProject(localProject(projects));
    } else {
      setProject(null);
    }
  }, [projects]);

  useEffect(() => {
    setProjects(props.projects);
  }, [props.projects]);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  const handleCompany = (id) => {
    props.loadProjects(id);
    for (const comp of props.companies) {
      if (comp.details.id === id) {
        props.setCompany(comp);
        setCompany(comp);
      }
    }
  }

  const handleProject = (id) => {
    if (props.projects) {
      for (const proj of props.projects) {
        if (proj.details.id === id) {
          setProject(proj);
        }
      }
    }
  }

  const updateEmployee = (emp) => {
    let copy = {...employees};
    copy[emp.id] = {
      ...copy[emp.id],
      ...emp
    };
    setEmployees(copy);
  }

  const loadEmployees = async (projectId) => {
    var url = 'project/employee?projectId=' + projectId;
    var result = await API.get('budeeBackend', url, {});
    var totalEmployees = {};
    if (!Array.isArray(result) && result.key) {
      while (result.key) {
        totalEmployees = Object.assign(totalEmployees, result.users.reduce(function(map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}));
        result = await API.get('budeeBackend', url + '&PK=' + result.key.PK + '&SK1=' + result.key.SK1, {});
      }
    }
    totalEmployees = Object.assign(totalEmployees, result.reduce(function(map, obj) {
        map[obj.id] = obj;
        return map;
    }, {}));
    setEmployees(totalEmployees);
  }

  const loadRoles = async (id) => {
    return API.get("budeeBackend", 'roles/?projectId=' + id, {}).catch(err => {
      console.error(err);
    }).then(res => {
      if (res) {
        setRoles(res);
      }
    });
  };

  const loadDepartments = async (id) => {
    return API.get("budeeBackend", 'departments/?projectId=' + id, {}).catch(err => {
      console.error(err);
    }).then(res => {
      if (res) {
        setDepartments(res);
      }
    });
  };

  const loadContractors = async (id) => {
    return API.get("budeeBackend", 'contractors/?projectId=' + id, {}).catch(err => {
      console.error(err);
    }).then(res => {
      if (res) {
        setContractors(res);
      }
    });
  };

  const loadNotifications = async (id) => {
    return API.get("budeeBackend", "checkin/notifications", {
      queryStringParameters : {
        projectId : id
      }
    }).then(res => {
      if (res) {
        setNotifications(res);
      }
    });
  };

  const refreshProject = async (companyId, projectId) => {
    return API.get("budeeBackend", "project/?companyId=" + props.company.details.id, {})
      .catch(err => console.error(err))
      .then((res) => {
        for (const proj of res) {
          if (proj.details.id === projectId) {
            setProject(proj);
          }
        }
      });
  }

  const renderSection = (section) => {
    switch (section) {
      case 'home':
        return (<Home {...props} loadEmployees={loadEmployees} updateEmployee={updateEmployee} project={project} employees={employees} roles={roles} departments={departments} contractors={contractors} />);
      case 'dashboard':
        return (<Home {...props} loadEmployees={loadEmployees} updateEmployee={updateEmployee} project={project} employees={employees} roles={roles} departments={departments} contractors={contractors} />);
      case 'organization':
        return (<Organization {...props} roles={roles} departments={departments} contractors={contractors} loadRoles={loadRoles} loadDepartments={loadDepartments} loadContractors={loadContractors} project={project} />);
      case 'personnel':
        return (<Personnel {...props} loadEmployees={loadEmployees} employees={employees} project={project} roles={roles} departments={departments} contractors={contractors} navigate={navigate} />);
      case 'alerts':
        return (<Alerts {...props} project={project} employees={employees} roles={roles} departments={departments} contractors={contractors}></Alerts>);
      case 'company':
        return (<CompanyProfile company={company} employees={employees} project={project} loadEmployees={loadEmployees} {...props} />);
      case 'user':
        return (<UserProfile {...props} employees={employees} project={project} />);
      case 'certifications':
        return (<Certifications {...props} roles={roles} contractors={contractors} project={project} employees={employees} company={company} />);
      case 'documents':
        return (<Documents {...props} roles={roles} contractors={contractors} project={project} employees={employees} company={company} />);
      case 'training':
        return (<Training {...props} roles={roles} contractors={contractors} project={project} employees={employees} company={company} notifications={notifications} loadNotifications={loadNotifications} />);
      case 'observations':
        return (<Observations {...props} roles={roles} contractors={contractors} project={project} employees={employees} company={company} />);
      case 'rewards':
        return (<Rewards {...props} roles={roles} project={project} employees={employees} />);
      case 'company-eap':
        return (<EAP {...props} company={company} project={project} employees={employees} />);
      case 'company-projects':
        return (<Projects {...props} company={company} project={project} employees={employees} />);
      default:
        return (<Home {...props} loadEmployees={loadEmployees} updateEmployee={updateEmployee} project={project} employees={employees} roles={roles} departments={departments} contractors={contractors} />);
    }
  }

  const navigate = (page, query = null) => {
    if (company && project) {
      refreshProject(company.details.id, project.details.id);
    }
    history.push(query ? page + query : page);
    setSelected(page);
  };

  const getTrainingCount = (notifs) => {
    if (notifs && notifs.documents && notifs.documents.length > 0) {
      return notifs.documents.map(d => d.read ? 0 : 1).reduce((result, item) => result + item);
    }
    return 0;
  }

  const deleteCompany = async (company) => {
    return API.del('budeeBackend', 'company/', {
      body : {
        companyId : company.details.id
      }
    }).then(res => window.location.reload(false));
  }

  return (
    <>
      {
        company &&
        <>
          <Drawer
            className={ classes.drawer }
            variant={ isMobile ? "temporary" : "permanent" }
            classes={{ paper: classes.drawerPaper }}
            open={ props.mobileOpen }
            onClose={ props.handleDrawerToggle }>
            <Toolbar />
            <div className={ classes.drawerContainer }>
              <Divider />
              <List>
                <ListItem disableRipple style={{ cursor : 'default' }} button>
                  <ListItemIcon><BusinessIcon/></ListItemIcon>
                  <ListItemText component="div">
                  {
                    props.companies && props.companies.length > 0 &&
                    <Select
                      labelId="company-label"
                      id="company-select"
                      default={"unselected"}
                      style={{ maxWidth : '165px' }}
                      value={company && company.details ? company.details.id : ''}
                      onChange={(e) => handleCompany(e.target.value)}
                    >
                    {
                      props.companies.map((comp, i) => {
                        return (
                          <MenuItem key={'project-' + i} value={comp.details.id}>{ comp.details.name }</MenuItem>
                        )
                      })
                    }
                    </Select>
                  }
                  </ListItemText>
                </ListItem>
                {
                  projects && projects.length > 0 &&
                  <ListItem disableRipple style={{ cursor : 'default' }} button>
                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                    <ListItemText classes={{ root:classes.listItemText }} component="div">
                      <Select
                        labelId="project-label"
                        id="project-select"
                        default={"unselected"}
                        style={{ maxWidth : '165px' }}
                        value={project ? project.details.id : ''}
                        onChange={(e) => handleProject(e.target.value)}
                      >
                      {
                        projects.map((proj, i) => {
                          return (
                            <MenuItem key={'project-' + i} value={proj.details.id}>{ proj.details.project }</MenuItem>
                          )
                        })
                      }
                      </Select>
                    </ListItemText>
                  </ListItem>
                }
              </List>
              <Divider />
              {
                !props.trialExpired &&
                <>
                  <List>
                    {
                      project && !PRIVILEGED_USER.includes(project.status) &&
                      <>
                        <ListItem selected={selected === 'documents'} onClick={() => navigate('documents')} button key="documents">
                          <ListItemIcon><DescriptionIcon/></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Documents" />
                        </ListItem>
                        <ListItem selected={selected === 'training'} onClick={() => navigate('training')} button key="training">
                          <ListItemIcon><Badge badgeContent={getTrainingCount(notifications)} color="error"><BuildIcon/></Badge></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Training" />
                        </ListItem>
                      </>
                    }
                    {
                      project && PRIVILEGED_USER.includes(project.status) &&
                      <>
                        <ListItem selected={selected === 'dashboard'} onClick={() => navigate('dashboard')} button key="dashboard">
                          <ListItemIcon><DashboardIcon></DashboardIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="View Dashboard" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'documents'} onClick={() => navigate('documents')} button key="documents">
                          <ListItemIcon><DescriptionIcon/></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Documents" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'certifications'} onClick={() => navigate('certifications')} button key="certifications">
                          <ListItemIcon><VerifiedUserIcon></VerifiedUserIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Certifications" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'observations'} onClick={() => navigate('observations')} button key="observations">
                          <ListItemIcon><VisibilityIcon></VisibilityIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Observations" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'training'} onClick={() => navigate('training')} button key="training">
                          <ListItemIcon><BuildIcon/></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Training" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'alerts'} onClick={() => navigate('alerts')} button key="alerts">
                          <ListItemIcon><NotificationsActiveIcon></NotificationsActiveIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Alerts" />
                        </ListItem>
                        <ListItem selected={selected === 'personnel'} onClick={() => navigate('personnel')} button key="personnel">
                          <ListItemIcon><GroupAddIcon></GroupAddIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Manage Personnel" />
                        </ListItem>
                        <ListItem selected={selected === 'rewards'} onClick={() => navigate('rewards')} button key="rewards">
                          <ListItemIcon><CardGiftcardIcon></CardGiftcardIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Rewards" />
                        </ListItem>
                      </>
                    }
                  </List>
                  <Divider />
                  <List>
                    <ListItem selected={selected === 'company'} onClick={() => navigate('company')} button key="company-profile">
                      <ListItemIcon><BusinessIcon/></ListItemIcon>
                      <ListItemText classes={{root:classes.listItemText}} primary="Company Profile" />
                    </ListItem>
                    {
                      project && PRIVILEGED_USER.includes(project.status) &&
                      <List component="div" disablePadding>
                        <ListItem className={classes.nested} selected={selected === 'company-projects'} onClick={() => navigate('company-projects')} button key="company-projects">
                          <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Projects" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'organization'} onClick={() => navigate('organization')} button key="organization">
                          <ListItemIcon><GroupIcon></GroupIcon></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="Organization" />
                        </ListItem>
                        <ListItem className={classes.nested} selected={selected === 'company-eap'} onClick={() => navigate('company-eap')} button key="company-eap">
                          <ListItemIcon><AccessibilityNewIcon/></ListItemIcon>
                          <ListItemText classes={{root:classes.listItemText}} primary="EAP" />
                        </ListItem>
                      </List>
                    }
                    <ListItem selected={selected === 'user'} onClick={() => navigate('user')} button key="user-profile">
                      <ListItemIcon><AccountCircleIcon></AccountCircleIcon></ListItemIcon>
                      <ListItemText classes={{root:classes.listItemText}} primary="User Profile" />
                    </ListItem>
                  </List>
                </>
              }
            </div>
          </Drawer>
          <div style={{display:'inline-block', flexGrow:'1', minHeight : '100vh', maxWidth : '100vw'}}>
            <main className={classes.content}>
              <Toolbar />
              {
                props.trialExpired &&
                <Grid container spacing={3}>
                  <Grid xs={12} item>
                    <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main, textAlign:'center' }}>
                      <Typography>Your Free Trial has Expired.  Please contact a Budee representative at <a href="mailto: sales@budeesolutions.com" style={{color:theme.palette.primary.main}}>sales@budeesolutions.com</a> to reactivate your account.</Typography>
                    </Paper>
                  </Grid>
                </Grid>
              }
              {
                !props.trialExpired &&
                <>
                {
                  props.projects && props.projects.length === 0 &&
                  <>
                    <ProjectRegister {...props} />
                    {
                      props.admin &&
                      <>
                        <Grid container spacing={3}>
                          <Grid xs={12} item>
                            <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main, textAlign:'center' }}>
                              <Typography>Close this account instead?</Typography>
                              <Button onClick={() => setConfirmClose(true)} variant="contained" color="primary">
                                Close Account
                              </Button>
                            </Paper>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={confirmClose}
                          onClose={() => setConfirmClose(false)}
                          aria-labelledby="draggable-dialog-title"
                        >
                          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Please Confirm you're Intentions of Closing this Account
                          </DialogTitle>
                          <DialogContent>
                            <Typography variant="body2">The account will no longer be accessible, all data will remain and if you want to re-open the account please contact <Link>sales@budeesolutions.com</Link>.</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => deleteCompany(company)} variant="contained" color="primary">
                              Close Account
                            </Button>
                            <Button autoFocus onClick={() => setConfirmClose(false)} variant="outlined" color="secondary">
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    }
                  </>
                }
                {
                  company && project &&
                  renderSection(selected)
                }
                </>
              }
            </main>
          </div>
        </>
      }
      {
        !props.company && !props.loading &&
        <CompanyRegister onComplete={() => props.loadCompany()} title {...props} />
      }
    </>
  );
}
