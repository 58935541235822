/**
* User object to represent logged in user.
*/
export default class User {
  /**
  *
  * @param Cognito User Model
  */
  constructor(user) {
    if (user === null) {
      throw new Error("Expect user attributes");
    }

    if (user.hasOwnProperty('custom:globalId')) {
      this.id = user['custom:globalId'];
    } else {
      this.id = user.id;
    }
    this.email = user.email;
    this.firstname = user['given_name'];
    this.lastname = user['family_name'];
  }
}
