import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";

import { useEffectWhen } from "../../../../../../libs/hooks";
import queryString from 'query-string';

import { isMobile } from "react-device-detect";

import Badge from '@material-ui/core/Badge';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import HelpIcon from '@material-ui/icons/Help';
import FieldForm from '../FieldForm';
import View from '../View';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MessageIcon from '@material-ui/icons/Message';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { DOCUMENTS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  paper : {
    padding : '10px',
    marginTop:'10px',
    border : '3px solid ' +
    theme.palette.secondary.main
  }
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Edit(props) {
  const classes = useStyles();
  const theme = useTheme();
  var parsed = queryString.parse(props.location.search);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [detailsSelected, setDetailsSelected] = useState(props.detailsSelected)
  const [fields, setFields] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [showField, setShowField] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [types, setTypes] = useState({});
  const [indexed, setIndexed] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [repeatFields, setRepeatFields] = useState(null);
  const [selected, setSelected] = useState([]);
  const [viewType, setViewType] = useState('list');
  const [open, setOpen] = useState(false);
  const [multiple, setMultiple] = useState(1);
  const [duplicating, setDuplicating] = useState(false);
  const [duplicatingProgress, setDuplicatingProgress] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(0);
  const [fieldEdit, setFieldEdit] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [version, setVersion] = useState(props.detailsSelected ? props.detailsSelected.version : 0);
  const [fieldCount, setFieldCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (detailsSelected && props.project && mounted) {
      setLoadingFields(true);
      loadFields(props.project.details.id, detailsSelected.id, detailsSelected.version).then(res => {
        if (mounted) {
          setFields(res);
          setLoadingFields(false);
        }
      });
    }
    return () => mounted = false;
  }, [props.project, detailsSelected]);

  useEffect(() => {
    setDetailsSelected(props.detailsSelected);
  }, [props.detailsSelected]);

  useEffect(() => {
    var obj = {};
    for (const key of Object.keys(DOCUMENTS.FIELDS)) {
      obj[DOCUMENTS.FIELDS[key].ID] = DOCUMENTS.FIELDS[key].TEXT;
    }
    setTypes(obj);
  }, []);

  useEffectWhen(() => {
    if (fields) {
      for (const f of fields) {
        if (f.indexed) {
          setIndexed(f);
        }
      }
      setSelected(new Array(fields.length).fill(false));
    } else {
      setIndexed(null);
    }
    if (repeatFields && fields && fields.length === fieldCount) {
      clearInterval(repeatFields);
      setRepeatFields(null);
      setFieldCount(0);
    }
  }, [fields, repeatFields], [fields]);

  const loadFields = async (projectId, documentId, v, sub = false) => {
    return API.get("budeeBackend", "documents/field?projectId=" + projectId + '&documentId=' + documentId + '&version=' + v, {});
  }

  const saveOrder = (fieldOrder, projectId, documentId, documentVersion) => {
    var ids = [];
    for (const f of fieldOrder) {
      ids.push(f.id);
    }
    API.post("budeeBackend", "documents/field/order", {
      body : {
        projectId : projectId,
        documentId : documentId,
        documentVersion : documentVersion,
        fields : ids
      }
    }).then(res => {
      loadFields(props.project.details.id, detailsSelected.id, detailsSelected.version)
        .then(res => setFields(res));
      setShowChange(false);
    });
  }

  const publish = (doc, s, projectId) => {
    delete parsed.documentId;
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify(parsed)
    });
    API.post("budeeBackend", "documents/publish", {
      body : {
        projectId : projectId,
        document : {
          id : doc.id,
          version : doc.version
        },
        scope : s
      }
    }).then(res => {
      props.loadDocuments(projectId).then(res => goBack());
    });
  }

  const publishNewVersion = async (doc, projectId) => {
    var newDoc = {
      name : doc.name,
      description : doc.description,
      roles : doc.roles ? doc.roles : null,
      version : doc.version + 1,
      id : doc.id,
      draft : true,
      scope : doc.scope,
      translations : doc.translations
    };
    setPublishing(true);
    setFieldCount(fields.length);
    API.post("budeeBackend", "documents/", {
      body : {
        projectId : projectId,
        document : newDoc,
        scope : doc.scope
      }
    }).then((res) => {
      if (fields.length > 0) {
        // New fields will be propagated in backend
        setRepeatFields(setInterval(() => {
          API.get("budeeBackend", "documents/field", {
            queryStringParameters : {
              projectId: props.project.details.id,
              documentId : newDoc.id,
              version : newDoc.version
            }
          }).then(res => {
            if (fields.length === res.length) {
              setFields(res);

            }
          })
        }, 1000));
      }
      setFields([]);
      setVersion(doc.version + 1);
      props.setDetailsSelected(newDoc);
      setPublishing(false);
    })
  }

  const indexField = async (f, index) => {
    return API.post("budeeBackend", "documents/field/index", { body : {
      projectId : props.project.details.id,
      documentId : detailsSelected.id,
      documentVersion : detailsSelected.version,
      field : Object.assign(f, {indexed:index})
    }}).then(res => index ? setIndexed(f) : setIndexed(null));
  }

  const duplicate = async (projectId, documentId, v, fieldsSelected) => {
    setDuplicating(true);
    setDuplicatingProgress(0);
    const fieldsToDuplicate = getFieldsSelected(fieldsSelected);

    var iterations = multiple;
    var current = 0;
    const newFields = [];
    setDuplicatingProgress(15);
    while (iterations > 0) {
      for (const f of fieldsToDuplicate) {
        newFields.push({
          name : f.name,
          description : f.description,
          options : f.options,
          type : f.type,
          width : f.width,
          order : (fields.length + current)
        });
        current++;
      }
      iterations--;
    }
    setDuplicatingProgress(35);
    await API.post("budeeBackend", "documents/field", { body : {
      projectId : projectId,
      documentId : documentId,
      documentVersion : v,
      fields : newFields
    }});
    setDuplicatingProgress(100);
    loadFields(projectId, documentId, v).then(res => setFields(res));
    setOpen(false);
    setDuplicating(false);
  }

  const deleteFields = async (projectId, documentId, v, fieldsSelected) => {
    setDeleting(true);
    const fieldList = getFieldsSelected(fieldsSelected);
    setDeleteProgress(0);
    var ids = [];
    for (const f of fieldList) {
      ids.push(f.id);
    }
    setDeleteProgress(15);
    await API.del("budeeBackend", "documents/field", {
      body : {
        projectId : projectId,
        documentId : documentId,
        documentVersion : v,
        fieldIds : ids,
        version : v
      }
    });
    setDeleteProgress(100);
    loadFields(projectId, documentId, v).then(res => setFields(res));
    setDeleteOpen(false);
    setDeleting(false);
  }

  const getFieldsSelected = (fieldsSelected) => {
    const fieldList = [];
    for (let i = 0; i < fieldsSelected.length; i++) {
      if (fieldsSelected[i]) {
        if (fields.length > i) {
          fieldList.push(fields[i]);
        }
      }
    }
    return fieldList;
  }

  const goBack = () => {
    delete parsed.documentId;
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify(parsed)
    });
    props.setDetailsSelected(null)
  }

  const delaySave = (doc, proj, f) => {
    return API.post("budeeBackend", "documents/field", {
      body : {
        projectId : proj.details.id,
        documentId : doc.id,
        documentVersion : doc.version,
        field : f
      }
    }).then((res) => {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === f.id) {
          fields[i] = f;
          break;
        }
      }
    });
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var copy = [...fields];
    let index = null;
    let item = null;
    for (let i = 0; i < fields.length; i++) {
      if (copy[i].id === result.draggableId) {
        index = i;
        item = copy[i];
        break;
      }
    }
    copy.splice(index,1);
    copy.splice(result.destination.index, 0, item);
    setFields(copy);
  }

  // const changeOrder = (direction, index) => {
  //   var copy = [...fields];
  //   if (direction === 'up') {
  //     if (index > 0) {
  //       copy.splice(index - 1, 0, copy.splice(index, 1)[0]);
  //     }
  //   } else if (direction === 'down') {
  //     if (index < copy.length) {
  //       copy.splice(index +1, 0, copy.splice(index, 1)[0]);
  //     }
  //   }
  //   setFields(copy);
  // }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? theme.palette.secondary.main : null,

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? theme.palette.accentColor.main : null
  });

  const getLabel = (type) => {
    switch(type) {
      case DOCUMENTS.FIELDS.HEADING.ID:
      case DOCUMENTS.FIELDS.PLAINTEXT.ID:
        return "Text";
      case DOCUMENTS.FIELDS.PLAINIMAGE.ID:
        return "Title";
      default:
        return "Field Label";
    }
  }

  return (
    <>
      <Grid xs={12} item>
        <Paper className={classes.paper}>
          {
            (loadingFields || repeatFields) &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loadingFields && !repeatFields &&
            <>
              <Typography variant="h6">Views of Document Template:</Typography>
              <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',width:'100%',justifyContent:'space-between',marginTop:'5px',marginBottom:'5px'}}>
                <RadioGroup row aria-label="position" name="position" value={viewType} onChange={(e) => setViewType(e.target.value)} defaultValue="range">
                  <FormControlLabel
                    value="list"
                    control={<Radio color="primary" />}
                    label="Edit Fields"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="browser"
                    control={<Radio color="primary" />}
                    label="Browser View"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="print"
                    control={<Radio color="primary" />}
                    label="Print Preview"
                    labelPlacement="top"
                  />
                </RadioGroup>
                <FormControl style={{width:'75px'}}>
                  <InputLabel id="version-label">Version</InputLabel>
                  <Select
                    labelId="version-label"
                    value={detailsSelected.version}
                    id="version"
                    onChange={(e) => {
                      var copy = {...detailsSelected};
                      copy.version = e.target.value;
                      setDetailsSelected(copy);
                    }}
                  >
                  {
                    Array.from(Array(version + 1), (e, i) => {
                      return <MenuItem key={'v-' + i} value={version - i}>{version - i}</MenuItem>
                    })
                  }
                  </Select>
                </FormControl>
              </div>
              {
                viewType === 'list' &&
                <>
                  <Divider />
                  <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',marginBottom:'10px',marginTop:'10px'}}>
                    {
                      detailsSelected.draft && !showChange &&
                      <div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',marginBottom:'5px'}}>
                          <Tooltip title="Duplicate will duplicate all selected items, maintain order and place them at the bottom of the list.">
                            <span>
                              <Button disabled={selected.filter(s => s).length === 0} variant="contained" color="primary" style={{marginLeft:'5px',marginRight:'5px'}} onClick={() => setOpen(true)}>Duplicate</Button>
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete will premanently remove all selected fields.">
                            <span>
                              <Button disabled={selected.filter(s => s).length === 0} variant="contained" color="primary" style={{marginLeft:'5px',marginRight:'5px'}} onClick={() => setDeleteOpen(true)}>Delete</Button>
                            </span>
                          </Tooltip>
                        </div>
                        <Typography variant="body2" style={{marginRight:'10px'}} component="div">{selected && selected.length > 0 && <>({selected.filter(s => s).length})</>} Selected Fields</Typography>
                      </div>
                    }
                    <Tooltip title={showChange ? "To cancel the change the order of the fields, click here" : "To change the order of the fields, click here"}>
                      <Button style={{marginLeft:'5px',marginRight:'5px'}} variant="outlined" color="secondary" onClick={() => {
                        if (showChange) {
                          loadFields(props.project.details.id, detailsSelected.id, detailsSelected.version).then((res) => setFields(res));
                        }
                        setShowChange(!showChange);
                      }}>
                      {
                        !showChange &&
                        "Change Order"
                      }
                      {
                        showChange &&
                        "Cancel"
                      }
                      </Button>
                    </Tooltip>
                    {
                      showChange &&
                      <Button style={{marginRight:'10px'}} variant="contained" color="primary" onClick={() => saveOrder(fields, props.project.details.id, detailsSelected.id, detailsSelected.version)}>Save</Button>
                    }
                  </div>
                </>
              }
              <div style={{maxHeight:'400px',overflowY:'auto',padding:'10px',border:'1px solid ' + theme.palette.secondary.main, borderRadius:10}}>
                {
                  viewType === 'print' &&
                  <View submission={{documentId:detailsSelected.id,documentVersion:detailsSelected.version}} document={detailsSelected} user={props.user} employees={props.employees} projectId={props.project.details.id} print={true} {...props} />
                }
                {
                  viewType === 'browser' &&
                  <View inset={true} submission={{documentId:detailsSelected.id,documentVersion:detailsSelected.version}} document={detailsSelected} user={props.user} employees={props.employees} projectId={props.project.details.id} print={false} {...props} />
                }
                {
                  viewType === 'list' &&
                  <>
                  {
                    fields && fields.length === 0 &&
                    <Typography variant="body2">There are currently no fields in this document.</Typography>
                  }
                    <List>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                          {
                            fields && fields.map((field, i) => {
                              if (field.indexed && !indexed) {
                                setIndexed(field);
                              }
                              var text = field.name;
                              if (field.type === DOCUMENTS.FIELDS.DIVIDER.ID) {
                                text = 'Divider';
                              }
                              if (text && text.length > 40) {
                                text = text.substr(0, 40) + '...';
                              }
                              let obvCount = 0;
                              let alertCount = 0;
                              for (const opt of field.options) {
                                if (opt.observation) {
                                  obvCount++;
                                }
                                if (opt.alert) {
                                  alertCount++;
                                }
                              }
                              return (
                                <Draggable isDragDisabled={!showChange} key={field.id} draggableId={field.id} index={i}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                    <ListItem  style={{border:'1px solid ' + theme.palette.primary.main,borderRadius:'10px',padding:showChange ? '0px' : '8px 16px',margin: showChange ? '0px' : '2px',flexWrap:isMobile?'wrap':'nowrap'}}>
                                      {
                                        detailsSelected.draft && !showChange &&
                                        <Tooltip title="Click to add or remove from selection, then you perform operations such as delete or duplicate.  Duplicate will duplicate all selected items, maintain order and place them at the bottom of the list." >
                                          <Checkbox
                                            checked={ selected && selected[i] ? selected[i] : false }
                                            style={{marginLeft:'10px'}}
                                            onChange={ (e) => {
                                              e.stopPropagation();
                                              var copy = [...selected];
                                              copy[i] = !copy[i];
                                              setSelected(copy);
                                            }}
                                            inputProps={ { 'aria-label': 'select for print' } }
                                          />
                                        </Tooltip>
                                      }
                                      {
                                        showChange &&
                                        <ListItemIcon style={{alignItems:'center'}}>
                                          <Tooltip title="Click anywhere to drag and drop for re-ordering">
                                            <DragHandleIcon style={{cursor:'pointer',marginLeft:'10px',marginRight:'10px'}} color="primary" />
                                          </Tooltip>
                                        </ListItemIcon>
                                      }
                                      {
                                        detailsSelected.draft && field.type !== DOCUMENTS.FIELDS.DIVIDER.ID &&
                                        <div style={{display:'flex',flexGrow:1,flexDirection:'row',alignItems:'center'}}>
                                          {
                                            !showChange &&
                                            <TextField label={getLabel(field.type)} value={field.name} style={{width:'400px'}} onChange={(e) => {
                                              let copy = [...fields];
                                              copy[i].name = e.target.value;
                                              setFields(copy);
                                            }} onBlur={(e) => delaySave(detailsSelected, props.project, {
                                                ...field,
                                                name : e.target.value
                                              })}
                                            />
                                          }
                                          {
                                            showChange &&
                                            <Typography>{text}</Typography>
                                          }
                                          <Typography>{' - [' + types[field.type] + ']'}</Typography>
                                        </div>
                                      }
                                      {
                                        (!detailsSelected.draft || field.type === DOCUMENTS.FIELDS.DIVIDER.ID) &&
                                        <ListItemText style={{margin:'0px'}} primary={text + ' - [' + types[field.type] + ']'} />
                                      }
                                      {
                                        obvCount > 0 && !showChange &&
                                        <IconButton disableRipple style={{cursor:'default'}}>
                                          <Tooltip title="There will be a required observation for one or more answers to this field, click the edit button to view the options and see which ones trigger a required observation.">
                                            <Badge badgeContent={obvCount} color="secondary">
                                              <VisibilityIcon color="primary" />
                                            </Badge>
                                          </Tooltip>
                                        </IconButton>
                                      }
                                      {
                                        alertCount > 0 && !showChange &&
                                        <IconButton style={{marginLeft:'10px',marginRight:'10px',cursor:'default'}} disableRipple>
                                          <Tooltip title="There is an active alert for one or more answers to this field, click the edit button to view the options and the alerts.">
                                            <Badge badgeContent={alertCount} color="secondary">
                                              <MessageIcon color="primary" />
                                            </Badge>
                                          </Tooltip>
                                        </IconButton>
                                      }
                                      {
                                        detailsSelected.draft && !showChange &&
                                        <>
                                          {
                                            (field.type === DOCUMENTS.FIELDS.TEXT.ID || field.type === DOCUMENTS.FIELDS.SELECT.ID || field.type === DOCUMENTS.FIELDS.RADIOGROUP.ID) &&
                                            <>
                                              {
                                                indexed && field.id === indexed.id &&
                                                <IconButton style={{marginLeft:'10px',marginRight:'10px'}} edge="end" aria-label="remove search" onClick={() => indexField(field, false)}>
                                                  <Tooltip title="This is the currently indexed field, by clicking this it will remove the field from the index, only 1 field can be indexed at a time in a document.">
                                                    <SearchIcon color="error" />
                                                  </Tooltip>
                                                </IconButton>
                                              }
                                              {
                                                !indexed &&
                                                <IconButton style={{marginLeft:'10px',marginRight:'10px'}} edge="end" aria-label="search" onClick={() => indexField(field, true)}>
                                                  <Tooltip title="By clicking this it will mark this field as indexed, only 1 field can be indexed at a time in a document.">
                                                    <SearchIcon style={{cursor:'pointer'}} color="primary" />
                                                  </Tooltip>
                                                </IconButton>
                                              }
                                            </>
                                          }
                                          <IconButton style={{marginLeft:'10px',marginRight:'10px'}} edge="end" aria-label="search" onClick={() => {
                                            setFieldEdit(field);
                                            setEditOpen(true);
                                          }}>
                                            <Tooltip title="Click this to open a pop-up and edit all field details">
                                              <EditIcon style={{cursor:'pointer'}} color="primary" />
                                            </Tooltip>
                                          </IconButton>
                                        </>
                                      }
                                      {
                                        !detailsSelected.draft &&
                                        <IconButton style={{marginLeft:'10px',marginRight:'10px'}} edge="end" aria-label="search" onClick={() => {
                                          setFieldEdit(field);
                                          setEditOpen(true);
                                        }}>
                                          <Tooltip title="Click this to open a pop-up and view all the field details, if you want to edit a field you'll have to first find the 'Edit Fields/New Version' button at the bottom.">
                                            <ListIcon style={{cursor:'pointer'}} color="primary" />
                                          </Tooltip>
                                        </IconButton>
                                      }
                                      {
                                        field.description && !showChange &&
                                        <IconButton style={{marginLeft:'10px',marginRight:'10px',cursor:'default'}} disableRipple>
                                          <Tooltip title={field.description} >
                                            <HelpIcon color="primary" />
                                          </Tooltip>
                                        </IconButton>
                                      }
                                      {
                                        !field.description && !showChange &&
                                        <IconButton style={{marginLeft:'10px',marginRight:'10px',cursor:'default'}} disableRipple>
                                          <Tooltip title="This field has no description, the description is a helpful tip about what information it should be captured for the end user filling out the report." >
                                            <HelpIcon style={{color:'#cdcdcd'}} />
                                          </Tooltip>
                                        </IconButton>
                                      }
                                      {
                                        field.type !== DOCUMENTS.FIELDS.DIVIDER.ID && field.type !== DOCUMENTS.FIELDS.HEADING.ID && field.type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && field.type !== DOCUMENTS.FIELDS.PLAINIMAGE.ID &&
                                        <IconButton style={{marginLeft:'10px',marginRight:'10px',cursor:'default'}} disableRipple>
                                          <Tooltip title="Checkmark indicates mandatroy and X indicates non-mandatory field." >
                                            {
                                              field && field.config && field.config.mandatory ?
                                              <CheckIcon color="primary" /> : <ClearIcon style={{color:'#cdcdcd'}} />
                                            }
                                          </Tooltip>
                                        </IconButton>
                                      }
                                    </ListItem>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            })
                          }
                          {provided.placeholder}
                        </div>
                      )}
                      </Droppable>
                    </DragDropContext>
                    </List>
                  </>
                }
              </div>
              <Divider style={{marginTop:'10px'}} />
              {
                detailsSelected.draft &&
                <>
                  <div style={{marginTop:'10px',marginBottom:'10px'}}>
                  {
                    fields && fields.length > 0 &&
                    <Button variant="contained" color="primary" onClick={() => publish(detailsSelected, detailsSelected.scope, props.project.details.id)}>Publish Document</Button>
                  }
                  </div>
                  <Divider />
                  <Typography variant="h6">Add More Fields</Typography>
                  {
                    !showField &&
                    <Button onClick={() => setShowField(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                      Add New Field
                    </Button>
                  }
                  {
                    showField &&
                    <>
                      <Button onClick={() => setShowField(false)} style={{margin:'10px'}} color="secondary" variant="outlined">
                        Cancel Field Add
                      </Button>
                      <FieldForm onComplete={() => loadFields(props.project.details.id, detailsSelected.id, detailsSelected.version).then(res => setFields(res))} project={props.project} doc={detailsSelected} {...props} />
                    </>
                  }
                </>
              }
              {
                !detailsSelected.draft &&
                <Button onClick={() => publishNewVersion(detailsSelected, props.project.details.id)} color="secondary" variant="contained" style={{marginTop:'10px'}}>
                {
                  publishing &&
                  <div>
                    <CircularProgress size={20} />
                  </div>
                }
                {
                  !publishing && "Edit Fields/New Version"
                }
                </Button>
              }
            </>
          }
        </Paper>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          duplicating &&
          <LinearProgressWithLabel value={duplicatingProgress} />
        }
        {
          !duplicating &&
          <>
            <Typography variant="body2">Would you like to duplicate {selected && selected.length > 0 && <>({selected.filter(s => s).length})</>} fields?</Typography>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <TextField
                variant="outlined"
                required
                size="small"
                id={'multiple-duplicate'}
                value={multiple}
                inputProps={{maxLength:5}}
                style={{width:'50px'}}
                onChange={e => setMultiple(e.target.value.replace(/[^0-9]/g, ''))}
                margin="none"
              />
              <Typography style={{marginLeft:'10px', marginRight:'10px'}} variant="body2"> x times</Typography>
              <Tooltip title="Duplicate will duplicate all selected items, maintain order and place them at the bottom of the list.">
                <Button variant="contained" color="primary" onClick={() => duplicate(props.project.details.id, detailsSelected.id, detailsSelected.version, selected)}>Duplicate</Button>
              </Tooltip>
            </div>
          </>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          deleting &&
          <LinearProgressWithLabel value={deleteProgress} />
        }
        {
          !deleting &&
          <>
            <Typography variant="body2">Would you like to delete {selected && selected.length > 0 && <>({selected.filter(s => s).length})</>} fields?</Typography>
            <Button variant="contained" color="primary" onClick={() => deleteFields(props.project.details.id, detailsSelected.id, detailsSelected.version, selected)}>Delete</Button>
          </>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <FieldForm onComplete={() => {
            loadFields(props.project.details.id, detailsSelected.id, detailsSelected.version).then(res => setFields(res))
            setFieldEdit(null);
            setEditOpen(false);
          }} doc={detailsSelected} field={fieldEdit} {...props} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
