import React, { useState, useEffect } from "react";

import { Storage } from "aws-amplify";
import Link from '@material-ui/core/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

export default function ReadOnlyFile(props) {

  const [docUrl, setDocUrl] = useState(null);

  useEffect(() => {
    if (props.name && props.identityId) {
      Storage.get(props.name, {
        level: "protected",
        identityId : props.identityId
      }).then(value => {
        setDocUrl(value);
      });
    }
  }, [props.name, props.identityId]);

  return (
    <Link href={docUrl} target="_blank" rel="noreferrer" style={{marginTop:'15px'}}>
      {
        props.type === 'application/pdf' &&
        <PictureAsPdfIcon color="error" />
      }
      {
        props.type === 'video/mp4' &&
        <VideoLibraryIcon color="primary" />
      }
    </Link>
  );
}
