import React, { useState, useEffect, useRef } from "react";

import { useEffectWhen } from '../../../../libs/hooks';

import { doScrolling } from '../../../../libs/util';
import { API } from "aws-amplify";
import DocumentForm from './Dynamic/DocumentForm';
import Edit from './Dynamic/Edit';
import queryString from 'query-string';

import BusinessIcon from '@material-ui/icons/Business';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { isMobile } from "react-device-detect";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/Lock';
import SecurityIcon from '@material-ui/icons/Security';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/Help';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  documentList : {
    width : '500px'
  },
  documentRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  }
}));

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Documents(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  var parsed = queryString.parse(props.location.search);

  const [rows, setRows] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [sortModel, setSortModel] = React.useState([{field: 'name', sort: 'asc'}]);
  const documentFormRef = useRef(null);
  const [search, setSearch] = useState('');
  const [value, setValue] = React.useState(0);

  // Duplicate Document
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [scope, setScope] = useState('company');
  const [selectedDocument, setSelectedDocument] = useState('unselected');
  const [selectedCompany, setSelectedCompany] = useState('unselected');
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('unselected');
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [completedDuplicate, setCompletedDuplicate] = useState(false);

  useEffect(() => {
    var mounted = true;
    var grid = [];
    for (const doc of documents) {
      if (!doc.name.toLowerCase().startsWith(search.toLowerCase())) {
        continue;
      }
      grid.push({
        id : doc.id,
        name : doc.name,
        description : doc.description,
        archive : {
          id : doc.id,
          version : doc.version,
          scope : doc.scope
        },
        settings : {
          locked : doc.locked,
          private : doc.private
        },
        draft : doc.draft,
        required : {
          roles : doc.roles,
          contractors : doc.contractors
        },
        scope : doc.scope
      });
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [documents, search]);

  useEffect(() => {
    if (showAdd) {
      scrollToRef(documentFormRef);
    }
  }, [showAdd]);

  useEffect(() => {
    if (selectedCompany !== 'unselected') {
      API.get("budeeBackend", 'project/?companyId=' + selectedCompany, {}).catch(err => {
        console.error(err);
      }).then((res) => {
        setProjects(res);
      });
    }
  }, [selectedCompany]);

  const loadDocuments = async (projectId) => {
    setLoading(true);
    var results = await Promise.all([
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=project", {}),
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=company", {})
    ]);
    const docs = [...results[0],...results[1]];
    setDocuments(docs);
    if (parsed) {
      for (const doc of docs) {
        if (doc.id === parsed.documentId) {
          setDetailsSelected(doc);
        }
      }
    }
    setLoading(false);
  };

  const handleClickOpen = async (event) => {
    parsed.documentId = event.row.id;
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify(parsed)
    });
    for (const doc of documents) {
      if (doc.id === event.row.id) {
        setDetailsSelected(doc);
      }
    }
  };

  const archiveDocument = async (projectId, documentId, versionId, scope) => {
    return API.del("budeeBackend", "documents/", {
      body : {
        projectId : projectId,
        documentId : documentId,
        versionId : versionId,
        scope : scope
      }
    }).then(res => {
      loadDocuments(projectId);
    });
  }

  useEffectWhen(() => {
    if (props.project) {
      setLoading(true);
      loadDocuments(props.project.details.id);
    }
  }, [props.project, loadDocuments], [props.project]);

  var columns = [
    { field: 'id', hide : true },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'settings', headerName:'Settings', renderCell : (val) => {
      return (
        <div style={{display:'flex',flexDirection:'row'}}>
          <Tooltip style={{marginRight:'10px'}} title={val.value.locked ? "Only administrators can edit submissions after it has been submitted." : "Everyone can edit submisisons after they have been submitted."} >
            <LockIcon color={val.value.locked ? "primary" : "disabled"} />
          </Tooltip>
          <Tooltip style={{marginLeft:'10px',marginRight:'10px'}} title={val.value.private ? "Only administrators can view submissions after they are submitted." : "Everyone can view submissions after they have been submitted."} >
            <SecurityIcon color={val.value.private ? "primary" : "disabled"} />
          </Tooltip>
        </div>
      );
    } }
  ];
  if (!isMobile) {
    columns.push({ field: 'description', headerName: 'Description', flex: 2 });
  }
  columns = columns.concat([
    { field: 'draft', headerName: 'Status', flex: 1, renderCell : (val) => {
      if (val.value) {
        return (
          <Chip label="Draft" color="default" />
        )
      } else {
        return (
          <Chip label="Published" color="primary" />
        )
      }
    } },
    { field: 'required', headerName: 'Required', flex: 1, renderCell : (val) => {
      if (val.value && (val.value.roles || val.value.contractors)) {
        return (
          <Tooltip title={
            <React.Fragment>
            {
              val.value.roles && val.value.roles.map((r,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r.name} size="small"/>
                );
              })
            }
            {
              val.value.contractors && val.value.contractors.map((c,i) => {
                return (
                  <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} label={c.name} size="small" />
                );
              })
            }
            </React.Fragment>}>
            <div><AssignmentIndIcon color="primary" /></div>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="All employees of the project.">
            <div style={{fontWeight:'bold'}}>Project Wide</div>
          </Tooltip>
        );
      }
    } },
    { field: 'scope', headerName: 'Scope', flex: 1, cellClassName : 'no-select', renderCell : (val) => {
      return (
        <Tooltip title={
          val.value === 'project' ? 'All required employees at this project will see this document' : 'All employees in the company, at any project will see this document.'
        }><div>{val.value === 'project' ? 'Project-Wide' : 'Company-Wide'}</div></Tooltip>
      );
    }}
  ]);

  if (props.project.status === USER_STATUS.SUPERADMIN) {
    columns = columns.concat([
      { field: 'archive', headerName: ' ', flex: 1, cellClassName : 'no-select', renderCell : (val) => {
        return (
          <Button variant="outlined" size="small" color="secondary" onClick={() => archiveDocument(props.project.details.id, val.value.id, val.value.version, val.value.scope)}>Archive</Button>
        );
      }}
    ])
  }

  const duplicateDocument = async () => {
    let doc = null;
    for (const d of documents) {
      if (d.id === selectedDocument) {
        doc = d;
        break;
      }
    }
    if (doc) {
      setLoadingDuplicate(true);
      return API.post("budeeBackend", "documents/duplicate", {
        body : {
          toScope : scope,
          documentId : doc.id,
          documentVersion : doc.version,
          toId : scope === 'company' ? selectedCompany : selectedProject
        }
      }).then(() => {
        setLoadingDuplicate(false);
        setCompletedDuplicate(true);
        setSelectedCompany('unselected');
        setSelectedProject('unselected');
        setProjects([]);
        setSelectedDocument('unselected');
        setScope('company');
      })
    }
  }

  const checkForm = () => {
    if (scope === 'company') {
      return selectedCompany === 'unselected';
    } else if (scope === 'project') {
      return selectedCompany === 'unselected' || selectedProject === 'unselected';
    }
  }

  const onClose = () => {
    setShowDuplicate(false);
    setCompletedDuplicate(false);
  }

  return (
    <>
    {
      detailsSelected &&
      <Edit detailsSelected={detailsSelected} project={props.project} setDetailsSelected={setDetailsSelected} loadDocuments={loadDocuments} employees={props.employees} user={props.user} {...props} />
    }
    {
      !detailsSelected &&
      <Grid container spacing={3}>
        <Grid xs={12} item>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loading && documents && documents.length === 0 &&
            <>
              <div style={{width:'100%',textAlign:'center'}}>
                <Typography variant="body2">Currently No Documents.</Typography>
              </div>
              <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
              <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <Tabs value={value} onChange={(e, val) => setValue(val)} aria-label="Manage Personnel" variant="scrollable" scrollButtons="auto">
                    <Tab label="Basic" {...a11yProps(0)} />
                    <Tab label="Advanced" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6ChzoyGkCf8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DH2KpPDxl2A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                  </TabPanel>
                </div>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <Typography variant="h6">Learn About Documents</Typography>
                  <Typography variant="body2">Watch this video to learn more about how safety observations work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                  {
                    value === 0 &&
                    <ul>
                      <li>Overview of Document Form</li>
                      <li>Adding Fields</li>
                      <li>Mobile View</li>
                      <li>Making a Submission</li>
                    </ul>
                  }
                  {
                    value === 1 &&
                    <ul>
                      <li>Scoring</li>
                      <li>Observation Triggers</li>
                      <li>Alert Triggers</li>
                      <li>Indexing Fields</li>
                      <li>Color Code for Printing</li>
                      <li>Required Signatures</li>
                    </ul>
                  }
                  <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/documents?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                </div>
              </div>
            </>
          }
          {
            !loading && documents && documents.length > 0 &&
            <>
              <div style={{display:'flex',flexDirection:'row'}}>
                <p>Employees can fill out documents from their mobile application, submissions will then be available in the web portal.</p>
                <FormControl style={{marginBottom:'10px',display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'flex-end'}}>
                  <SearchIcon />
                  <Input
                    id="search-by-name"
                    type={'text'}
                    value={search}
                    placeholder="Search By Name"
                    onChange={(e) => setSearch(e.target.value)}
                    endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
                  />
                </FormControl>
              </div>
              <div style={{height:'400px'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  density="compact"
                  autoPageSize={true}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model.sortModel)}
                  onCellClick={(e) => {
                    const arr = Array.from(e.element.classList);
                    if (!arr.includes('no-select')) {
                      handleClickOpen(e);
                    }
                  }}
                  className={classes.documentRow}
                />
              </div>
            </>
          }
          <div style={{width:'100%',display:'flex'}}>
            <div ref={documentFormRef}>
            {
              props.project.status === USER_STATUS.SUPERADMIN &&
              <>
              {
                !showAdd &&
                <Button onClick={() => setShowAdd(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                  Add New Document
                </Button>
              }
              {
                showAdd &&
                <>
                  <Button onClick={() => setShowAdd(false)} style={{margin:'10px'}} color="secondary" variant="outlined">
                    Cancel Document Add
                  </Button>
                  <DocumentForm roles={props.roles} user={props.user} contractors={props.contractors} project={props.project} onComplete={() => loadDocuments(props.project.details.id)} edit={false} />
                </>
              }
              </>
            }
            </div>
            {
              props.project.status === USER_STATUS.SUPERADMIN &&
              <div>
                <Button onClick={() => setShowDuplicate(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                  Duplicate Document
                </Button>
              </div>
            }
          </div>
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={showDuplicate}
            onClose={onClose}
            aria-labelledby="duplicate-title"
          >
            <DialogTitle id="duplicate-title"></DialogTitle>
            <DialogContent>
            {
              !completedDuplicate &&
              <>
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <Typography style={{flexGrow:1}}>Select a Document:</Typography>
                  <FormControl style={{flexGrow:1}}>
                    <Select
                      labelId="document-label"
                      id="document-select"
                      value={selectedDocument}
                      fullWidth
                      onChange={(e) => setSelectedDocument(e.target.value)}
                    >
                      <MenuItem key={'unselected-type'} value={'unselected'}>Please Select</MenuItem>
                    {
                      documents.map((doc, i) => {
                        return (
                          <MenuItem key={'doc-' + i} value={doc.id}>{doc.name}</MenuItem>
                        )
                      })
                    }
                    </Select>
                  </FormControl>
                </div>
                {
                  selectedDocument !== 'unselected' &&
                  <>
                    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                      <Typography style={{flexGrow:1}}>Scope:<Tooltip style={{marginLeft:'15px'}} title={"A document will be available to everyone in the company or just personnel of " + props.project.details.project + "."}>
                        <HelpIcon color="primary" />
                      </Tooltip></Typography>
                      <FormControl component="fieldset">
                        <RadioGroup aria-label="scope" name="scope" value={scope} style={{flexDirection : 'row'}} onChange={(e) => setScope(e.target.value)}>
                          <FormControlLabel value="project" labelPlacement="end" control={<Radio disabled={props.document != null} />} label="Project-Wide" />
                          <FormControlLabel value="company" labelPlacement="end" control={<Radio disabled={props.document != null} />} label="Company-Wide" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                      <Typography style={{flexGrow:1}}>Select a Company:</Typography>
                      <FormControl style={{flexGrow:1}}>
                        <Select
                          labelId="company-label"
                          id="company-select"
                          value={selectedCompany}
                          fullWidth
                          onChange={(e) => setSelectedCompany(e.target.value)}
                        >
                          <MenuItem key={'unselected-type'} value={'unselected'}>Please Select</MenuItem>
                          {
                            props.companies.map((comp, i) => {
                              return (
                                <MenuItem key={'comp-' + i} value={comp.details.id}>{comp.details.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                    {
                      scope === 'project' &&
                      <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                        <Typography style={{flexGrow:1}}>Select a Project:</Typography>
                        <FormControl style={{flexGrow:1}}>
                          <Select
                            labelId="company-label"
                            id="company-select"
                            value={selectedProject}
                            fullWidth
                            onChange={(e) => setSelectedProject(e.target.value)}
                          >
                            <MenuItem key={'unselected-type'} value={'unselected'}>Please Select</MenuItem>
                          {
                            projects.map((proj, i) => {
                              return (
                                <MenuItem key={'proj-' + i} value={proj.details.id}>{proj.details.project}</MenuItem>
                              )
                            })
                          }
                          </Select>
                        </FormControl>
                      </div>
                    }
                    {
                      loadingDuplicate &&
                      <div>
                        <CircularProgress size={80} />
                      </div>
                    }
                    {
                      !loadingDuplicate &&
                      <Button disabled={checkForm()} onClick={() => duplicateDocument()} variant="contained" color="primary">Duplicate</Button>
                    }

                  </>
                }
              </>
            }
            {
              completedDuplicate &&
              <>
                <Typography variant="h6">Duplicate Request Completed</Typography>
                <Typography variant="body2">Please allow a minute or two for the process to complete, if there are issues please contact support@budeesolutions.com.</Typography>
              </>
            }
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    }
    </>
  );
}
