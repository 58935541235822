import React, { useState, useEffect } from "react";

import { useEffectWhen } from '../../../../libs/hooks';
import { useTheme } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import Information from '../../../User/Information';

import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { CSVDownload } from "react-csv";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import { makeStyles } from '@material-ui/core/styles';
import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";
import { DataGrid } from '@material-ui/data-grid';

const { formatDate } = require('@budeesolutions/budee-util');
const { PRIVILEGED_USER, USER_STATUS } = require('@budeesolutions/budee-constants');

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({

}));

export default function Manage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = React.useState([{field: 'name', sort: 'asc'}]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('unselected');
  const [department, setDepartment] = useState('unselected');
  const [contractor, setContractor] = useState('unselected');
  const [user, setUser] = useState(null);
  const [userOpen, setUserOpen] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [view, setView] = useState('onsite');
  const [csvData, setCsvData] = useState(null);

  const [removeRoles, setRemoveRoles] = useState([]);
  const [addRoles, setAddRoles] = useState([]);
  const [showRemoved, setShowRemoved] = useState({});
  const [showSelected, setShowSelected] = useState(false);

  useEffect(() => {
    if (props.employees) {
      const data = [];
      for (const emp of Object.values(props.employees)) {
        if (view === 'onsite') {
          if (emp.inactive) {
            continue;
          }
        } else if (view === 'offsite') {
          if (!emp.inactive) {
            continue;
          }
        }
        data.push({
          id : emp.id,
          name : emp.name,
          created : emp.created,
          picture : emp,
          status : formatStatus(emp.status),
          remove : emp.id,
          role : emp.role,
          department : emp.department,
          contractor : emp.contractor,
          view : emp,
          login : emp.login ? formatDate(new Date(emp.login)) : null
        });
      }
      setRows(data);
    }
  }, [props.employees, props.departments, props.roles, search, view]);

  const formatStatus = (status) => {
    switch(status) {
      case USER_STATUS.BASIC:
        return 'Basic';
      case USER_STATUS.SUPERADMIN:
        return 'Admin';
      case USER_STATUS.SUPERVISOR:
        return 'Supervisor';
      default:
        return '';
    }
  }

  const setEmployeesStatus = async (projectId, status, userIds) => {
    return API.post('budeeBackend', 'project/employee/status', {
      body : {
        userIds : userIds,
        status : status,
        projectId : projectId
      }
    });
  }

  const setEmployeesDepartment = async (projectId, value, userIds) => {
    return API.post('budeeBackend', 'personnel/departments', {
      body : {
        department : value,
        userIds : userIds,
        projectId : projectId
      }
    });
  }

  const setEmployeesContractor = async (projectId, value, userIds) => {
    return API.post('budeeBackend', 'personnel/contractors', {
      body : {
        contractor : value,
        userIds : userIds,
        projectId : projectId
      }
    });
  }

  const addEmployees = async (projectId, userIds) => {
    let users = [];
    for (const e of Object.values(props.employees)) {
      if (userIds.includes(e.id)) {
        users.push(e);
      }
    }
    if (users.length > 0) {
      return API.post('budeeBackend', 'project/employee', {
        body : {
          users : users,
          projectId : projectId
        }
      });
    }
  }

  const removeEmployees = async (projectId, userIds) => {
    return API.del('budeeBackend', 'project/employee', {
      body : {
        userIds : userIds,
        projectId : projectId
      }
    });
  }

  const removeEmployeesDepartment = async (projectId, userIds) => {
    return API.del('budeeBackend', 'personnel/departments', {
      body : {
        userIds : userIds,
        projectId : projectId
      }
    });
  }

  const removeEmployeesContractor = async (projectId, userIds) => {
    return API.del('budeeBackend', 'personnel/contractors', {
      body : {
        userIds : userIds,
        projectId : projectId
      }
    });
  }

  const updatePassword = async (password, userId, projectId) => {
    if (password === '') {
      setPasswordRequired(true);
    } else {
      setPasswordRequired(false);
      setPasswordLoading(true);
      return API.post('budeeBackend', 'project/employee/password', {
        body : {
          userId : userId,
          password : password,
          projectId : projectId
        }
      }).then(val => {
        setResetPassword(false);
        setNewPassword('');
        setPasswordLoading(false);
        setUserOpen(false);
      });
    }
  }

  const columns = [
    { field: 'id', hide : true },
    { field: 'picture', headerName: ' ', align:'center', width:40, renderCell : (val) => {
      return (
        <ProfilePicture size={'small'} user={props.user} employee={val.value} />
      )
    }},
    { field: 'name', headerName: 'Name', flex : 1 },
    { field: 'created', headerName: 'Joined', width : 120, renderCell : (val) => {
      var created = null;
      if (val.value) {
        created = formatDate(new Date(val.value));
      }
      return (
        <>
          {created}
        </>
      )
    } },
    { field: 'status', headerName: 'Status', width : 110 },
    { field: 'role', headerName: 'Role(s)', width:100, renderCell : (val) => {
      return (
        <>
        {
          val.value && val.value.length === 0 &&
          '-'
        }
        {
          val.value && val.value.length > 0 &&
          <>
            <Tooltip title={
              <React.Fragment>
              {
                Array.isArray(val.value) && val.value.map((r,i) => {
                  return (
                    <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r} size="small"/>
                  )
                })
              }
              </React.Fragment>
            }>
              <div style={{marginTop:'10px'}}><AssignmentIndIcon color="primary" /></div>
            </Tooltip>
          </>
        }
        </>
      );
    } },
    { field: 'department', headerName: 'Department', flex : 1 },
    { field: 'contractor', headerName: 'Contractor', flex : 1 },
    { field: 'login', headerName: 'Last Login', flex : 1 },
    { field: 'view', headerName: ' ', width : 80, renderCell : (val) => {
      return (
        <IconButton onClick={() => {
          setUser(val.value);
          setResetPassword(false);
          setNewPassword('');
          setUserOpen(true);
        }}>
          <AccountBoxIcon />
        </IconButton>
      )
    } }
  ];

  const bulkProcess = async (ids, caller, args, type) => {
    if (ids && ids.length > 0) {
      setOpen(true);
      setProgress(15);
      if (type === 'set') {
        await caller(args.projectId, args.value, ids);
      } else if (type === 'remove' || type === 'add') {
        await caller(args.projectId, ids);
      }
      setProgress(100);
      props.loadEmployees(args.projectId).then(val => setOpen(false));
    }
  }

  const downloadCsv = async (emps) => {
    setCsvData(null);
    if (emps) {
      var users = [];
      for (const e of Object.values(emps)) {
        users.push({
          email: e.email,
          phone: e.phone,
          username: e.username,
          name: e.name,
          status: e.status,
          role: e.role,
          contractor: e.contractor,
          department: e.department,
          gender: e.resident,
          ethnicity: e.resident,
          resident: e.resident,
          emergencyPhone : e.emergencyContact ? e.emergencyContact.phone : null,
          emergencyName : e.emergencyContact ? e.emergencyContact.name : null,
          emergencyRelationship : e.emergencyContact ? e.emergencyContact.relationship : null,
          joined : e.created ? formatDate(new Date(e.created)) : null,
          login : e.login ? formatDate(new Date(e.login)) : null
        })
      }
      setCsvData(users);
    }
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  useEffectWhen(() => {
    if (status !== 'unselected') {
      bulkProcess(selected, setEmployeesStatus, {
        value : status,
        projectId : props.project.details.id
      }, 'set');
    }
  }, [status, bulkProcess], [status]);

  useEffectWhen(() => {
    if (department === 'unassigned') {
      bulkProcess(selected, removeEmployeesDepartment, {
        projectId : props.project.details.id
      }, 'remove');
      setDepartment('unselected');
    } else if (department !== 'unselected') {
      bulkProcess(selected, setEmployeesDepartment, {
        value : department,
        projectId : props.project.details.id
      }, 'set');
      setDepartment('unselected');
    }
  }, [department, bulkProcess], [department]);

  useEffectWhen(() => {
    if (contractor === 'unassigned') {
      bulkProcess(selected, removeEmployeesContractor, {
        projectId : props.project.details.id
      }, 'remove');
      setContractor('unselected');
    } else if (contractor !== 'unselected') {
      bulkProcess(selected, setEmployeesContractor, {
        value : contractor,
        projectId : props.project.details.id
      }, 'set');
      setContractor('unselected');
    }
  }, [contractor, bulkProcess], [contractor]);

  const handleNavigate = (e, page, query) => {
    e.preventDefault();
    props.navigate(page, query);
  };

  const getActiveRoles = (ids) => {
    var activeRoles = {};
    for (const id of ids) {
      let empRoles = props.employees && props.employees.hasOwnProperty(id) ? props.employees[id].role : [];
      for (const eRole of empRoles) {
        if (!activeRoles.hasOwnProperty(eRole)) {
          activeRoles[eRole] = eRole;
        }
      }
    }
    let keys = Object.keys(activeRoles);
    keys.sort();
    return keys;
  }

  const updateRoles = async (add, remove, userIds) => {
    setOpen(true);
    setProgress(15);
    let users = [];
    for (const id of userIds) {
      if (props.employees.hasOwnProperty(id)) {
        let roles = [...props.employees[id].role];
        for (const r of remove) {
          if (roles.includes(r)) {
            roles.splice(roles.indexOf(r), 1);
          }
        }
        for (const a of add) {
          if (!roles.includes(a)) {
            roles.push(a);
          }
        }
        users.push({
          role : roles,
          id : id
        });
      }
    }
    return API.post('budeeBackend', 'personnel/roles', {
      body : {
        users : users,
        projectId : props.project.details.id
      }
    }).then(res => {
      setOpenRole(false);
      setRemoveRoles([]);
      setAddRoles([]);
      setShowRemoved({});
      setShowSelected(false);
      setProgress(100);
      props.loadEmployees(props.project.details.id).then(val => setOpen(false));
    });
  }

  return (
    <>
      <div style={{display:'flex',width:'100%'}}>
        <FormControl component="fieldset" style={{textAlign : 'center'}}>
          <RadioGroup aria-label="scope" name="scope" value={view} style={{flexDirection : 'row'}} onChange={(e) => setView(e.target.value)}>
            <FormControlLabel className={classes.controlLabel} value={'onsite'} labelPlacement="end" control={<Radio />} label="Onsite" />
            <FormControlLabel className={classes.controlLabel} value={'offsite'} labelPlacement="end" control={<Radio />} label="Offsite" />
            <FormControlLabel className={classes.controlLabel} value={'all'} labelPlacement="end" control={<Radio />} label="All" />
          </RadioGroup>
        </FormControl>
        <IconButton onClick={() => downloadCsv(props.employees)}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <div style={{flexGrow:1}}></div>
        <FormControl style={{marginBottom:'10px',display:'flex',alignItems:'center',flexDirection:'row'}}>
          <SearchIcon />
          <Input
            id="search-by-name"
            type={'text'}
            value={search}
            placeholder="Search By Name"
            onChange={(e) => setSearch(e.target.value)}
            endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
          />
        </FormControl>
      </div>
      <div style={{height:'400px'}}>
        <DataGrid
          rows={rows}
          filterModel={{
            items: [{ columnField: 'name', operatorValue: 'startsWith', value: search }],
          }}
          columns={columns}
          density="compact"
          autoPageSize={true}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model.sortModel)}
          className={classes.certificationRow}
          checkboxSelection
          onSelectionChange={(newSelection) => setSelected(newSelection.rowIds)}
          onRowSelected={(params) => {
            var copy = new Set(selected);
            if (params.isSelected) {
              copy.add(params.data.id);
            } else {
              copy.delete(params.data.id);
            }
            setSelected(Array.from(copy));
          }}
        />
        <Dialog
          fullScreen={fullScreen}
          maxWidth="md"
          fullWidth={true}
          open={userOpen}
          onClose={() => setUserOpen(false)}
          aria-labelledby="user-information"
        >
          <DialogTitle id="user-information"></DialogTitle>
          <DialogContent>
          {
            user &&
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              <Typography variant="h6">{user.name}</Typography>
              <ProfilePicture size={'extra-large'} user={props.user} employee={user} />
              {
                user.email &&
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Email:</Typography>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0}}>{user.email}</Typography>
                </div>
              }
              {
                user.phone &&
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Phone:</Typography>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0}}>{user.phone}</Typography>
                </div>
              }
              {
                user.username &&
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Username:</Typography>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0}}>{user.username}</Typography>
                </div>
              }
              {
                user.role &&
                <>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Role(s):</Typography>
                  <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center'}}>
                    {
                      user.role.map((r,i) => {
                        return (
                          <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r} size="small"/>
                        )
                      })
                    }
                  </div>
                </>
              }
              {
                user.department &&
                <>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Department:</Typography>
                  <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center'}}>
                    <Chip style={{margin:'2px',backgroundColor:'blue',color:'white'}} icon={<GroupWorkIcon style={{color:'white'}} />} label={user.department} size="small"/>
                  </div>
                </>
              }
              {
                user.contractor &&
                <>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Contractor:</Typography>
                  <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center'}}>
                    <Chip style={{margin:'2px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white'}} />} label={user.contractor} size="small"/>
                  </div>
                </>
              }
              {
                user.status &&
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0,textAlign:'right',marginRight:'10px'}}>Status:</Typography>
                  <Typography variant="body2" style={{flexGrow:1,flexBasis:0}}>{formatStatus(user.status)}</Typography>
                </div>
              }
              {
                props.user && PRIVILEGED_USER.includes(props.project.status) &&
                <>
                  {
                    !resetPassword &&
                    <Button style={{marginTop:'10px'}} onClick={() => setResetPassword(true)} variant="outlined" color="secondary">Reset Password</Button>
                  }
                  {
                    resetPassword &&
                    <>
                    {
                      passwordLoading &&
                      <div>
                        <CircularProgress size={30} />
                      </div>
                    }
                    {
                      !passwordLoading &&
                      <>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',marginTop:'10px'}}>
                          <TextField
                            variant="outlined"
                            error={passwordRequired}
                            required
                            size="small"
                            id="password"
                            label="New Password"
                            name="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                            style={{margin:'0px',marginRight:'10px'}}
                          />
                          <Button onClick={() => updatePassword(newPassword, user.id, props.project.details.id)} variant="outlined" color="secondary">Update</Button>
                        </div>
                        <Typography variant="body2">* Minimum 6 characters</Typography>
                      </>
                    }
                    </>
                  }
                </>

              }
              <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                <Information user={user.id} projectId={props.project.details.id} />
              </div>
            </div>
          }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUserOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Typography variant="subtitle2" style={{fontWeight:'bold'}}>Select users above in the table by clicking on the rows and then set their attributes by selected values below.</Typography>
      </div>
      <div style={{border:'1px solid ' + theme.palette.secondary.main, padding:'5px',margin:'5px',borderRadius:10}}>
        {
          props.project.status === USER_STATUS.SUPERADMIN &&
          <div style={{display:'inline-flex',flexDirection:'column',alignItems:'center'}}>
            <Typography variant="h6">Status</Typography>
            <Select
              labelId="employee-status-label"
              id="employee-status-select"
              value={status}
              style={{marginLeft:'10px',marginRight:'10px'}}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={'unselected'}>Please Select</MenuItem>
              <MenuItem value={USER_STATUS.BASIC}>Basic</MenuItem>
              <MenuItem value={USER_STATUS.SUPERADMIN}>Administrator</MenuItem>
              <MenuItem value={USER_STATUS.SUPERVISOR}>Supervisor</MenuItem>
            </Select>
          </div>
        }
        <div style={{display:'inline-flex',flexDirection:'column',alignItems:'center'}}>
          <Typography variant="h6">Roles</Typography>
          <Button color="primary" variant="outlined" onClick={() => setOpenRole(true)}>
            Edit
          </Button>
        </div>
        <div style={{display:'inline-flex',flexDirection:'column',alignItems:'center'}}>
          <Typography variant="h6">Departments</Typography>
          <Select
            labelId="employee-department-label"
            id="employee-department-select"
            value={department}
            style={{marginLeft:'10px',marginRight:'10px'}}
            onChange={(e) => setDepartment(e.target.value)}
          >
            <MenuItem key={'department-unselected'} value={'unselected'}>Please Select</MenuItem>
            <MenuItem key={'department-unassigned'} value={'unassigned'}>No Department</MenuItem>
            {
              props.departments.map((d, i) => {
                return (
                  <MenuItem key={'department-' + i} value={d.name}>{d.name}</MenuItem>
                )
              })
            }
          </Select>
        </div>
        <div style={{display:'inline-flex',flexDirection:'column',alignItems:'center'}}>
          <Typography variant="h6">Contractors</Typography>
          <Select
            labelId="employee-contractor-label"
            id="employee-contractor-select"
            value={contractor}
            style={{marginLeft:'10px',marginRight:'10px'}}
            onChange={(e) => setContractor(e.target.value)}
          >
            <MenuItem key={'contractor-unselected'} value={'unselected'}>Please Select</MenuItem>
            <MenuItem key={'contractor-unassigned'} value={'unassigned'}>No Contractor</MenuItem>
            {
              props.contractors.map((c, i) => {
                return (
                  <MenuItem key={'contractor-' + i} value={c.name}>{c.name}</MenuItem>
                )
              })
            }
          </Select>
        </div>
        {
          props.project.status === USER_STATUS.SUPERADMIN &&
          <div style={{display:'inline-flex',flexDirection:'column',alignItems:'center'}}>
            <Typography variant="h6">Employees</Typography>
            <Button
              variant="outlined"
              color="primary"
              style={{marginLeft:10}}
              onClick={() => bulkProcess(selected, view === 'onsite' ? removeEmployees : addEmployees, {projectId:props.project.details.id}, view === 'onsite' ? 'remove' : 'add')}>{ view === 'onsite' ? 'Move Off-site' : 'Move On-site' }</Button>
          </div>
        }
      </div>
      <div style={{padding:'5px',margin:'5px',borderRadius:10}}>
        <Typography variant="h6">Manage Your Organization</Typography>
        <Typography variant="body2">Click the links below to view your Roles, Departments and Contractors where you can manage these lists.</Typography>
        <div>
          <Link style={{cursor:'pointer'}} onClick={(e) => {handleNavigate(e, 'organization','?type=roles')}}>Add/Remove Roles</Link>
        </div>
        <div>
          <Link style={{cursor:'pointer'}} onClick={(e) => {handleNavigate(e, 'organization','?type=departments')}}>Add/Remove Departments</Link>
        </div>
        <div>
          <Link style={{cursor:'pointer'}} onClick={(e) => {handleNavigate(e, 'organization','?type=contractors')}}>Add/Remove Contractors</Link>
        </div>
      </div>
      <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
      <div style={{display:'flex',flexDirection:'row'}}>
        <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/XI-YkccWpWo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
        </div>
        <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
          <Typography variant="h6">Learn About Managing Personnel</Typography>
          <Typography variant="body2">Watch this video to learn more about how managing personnel works, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
          <ul>
            <li>Adding Individual Users</li>
            <li>Adding Bulk Users</li>
            <li>QR Code for Self Service</li>
            <li>Mobile Add Users</li>
            <li>Managing User Information</li>
            <li>Status, Roles, Departments, Contractors and On-site/Off-site</li>
          </ul>
          <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/manage-personnel?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
        </div>
      </div>
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={openRole}
        onClose={() => setOpenRole(false)}
        aria-labelledby="edit-user-roles"
      >
        <DialogTitle id="redit-user-roles"></DialogTitle>
        <DialogContent>
          {
            selected && selected.length === 0 &&
            <>
              <Typography variant="body2">0 selected employees from Manage Personnel table, in order to edit employees roles please select 1 or more employees and then click the Edit button.</Typography>
            </>
          }
          {
            selected && selected.length > 0 &&
            <>
              <Typography variant="h6">Edit Roles of ({selected.length}) User(s)</Typography>
              <Typography variant="body2">Add roles to users by clicking the checkbox in the green box, Remove roles from users by unclicking the selected boxes, not all users may have that role but any users selected that have it will have that role removed.</Typography>
              <div style={{border:'1px solid ' + theme.palette.secondary.main,margin:'20px',padding:'20px'}}>
                <Typography variant="h6">Add Role(s) to Users</Typography>
                <FormGroup row>
                  {
                    props.roles.map((r, i) => {
                      return (
                        <FormControlLabel
                          key={'role-' + i}
                          control={<Checkbox checked={addRoles.includes(r.name)} onChange={(e) => {
                            let copy = [...addRoles];
                            if (e.target.checked) {
                              copy.push(r.name);
                            } else {
                              copy.splice(copy.indexOf(r.name),1);
                            }
                            setAddRoles(copy);
                          }} name={r.name} />}
                          label={r.name}
                        />
                      )
                    })
                  }
                </FormGroup>
              </div>
              <div style={{border:'1px solid red',margin:'20px',padding:'20px'}}>
                <Typography variant="h6">Remove Role(s) from Users</Typography>
                <FormGroup row>
                  {
                    getActiveRoles(selected).map((r, i) => {
                      var removing = [];
                      for (const id of selected) {
                        if (props.employees.hasOwnProperty(id) && props.employees[id].role && props.employees[id].role.includes(r)) {
                          removing.push(props.employees[id]);
                        }
                      }
                      return (
                        <FormControlLabel
                          key={'selected-role-' + i}
                          control={<Checkbox disabled={addRoles && addRoles.includes(r)} checked={removeRoles.includes(r)} onChange={(e) => {
                            let copy = [...removeRoles];
                            if (e.target.checked) {
                              copy.push(r);
                            } else {
                              copy.splice(copy.indexOf(r),1);
                            }
                            setRemoveRoles(copy);
                          }} name={r} />}
                          label={'(' + removing.length + ') ' + r}
                        />
                      )
                    })
                  }
                </FormGroup>
              </div>
              {
                (addRoles.length > 0 || removeRoles.length > 0) &&
                <>
                  <div style={{borderTop:'1px solid ' + theme.palette.primary.main, padding:'20px',margin:'20px'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',borderBottom:'1px solid #cdcdcd',marginBottom:'10px',paddingBottom:'10px'}}>
                      <div style={{cursor:'pointer',display:'flex',flexDirection:'row',alignItems:'center'}} onClick={() => setShowSelected(!showSelected)}>
                        <Typography variant="h6">({selected.length}) Employees Selected</Typography>
                        {
                          showSelected && <ArrowDropUpIcon color="secondary" />
                        }
                        {
                          !showSelected && <ArrowDropDownIcon color="secondary" />
                        }
                      </div>
                      <div style={{flexGrow:1}}></div>
                      <Button color="primary" variant="contained" onClick={() => updateRoles(addRoles, removeRoles, selected)}>Update Roles</Button>
                    </div>
                    {
                      showSelected &&
                      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                      {
                        selected.map((id, i) => {
                          if (props.employees.hasOwnProperty(id)) {
                            let emp = props.employees[id];
                            return (
                              <ListItem key={'missing-' + emp.id} style={{width:'20%',marginBottom:'2px',borderRadius:10,border: theme.palette.primary.main}}>
                                <ListItemAvatar>
                                  <ProfilePicture user={props.user} employee={emp} />
                                </ListItemAvatar>
                                <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                              </ListItem>
                            )
                          } else {
                            return (<></>);
                          }
                        })
                        }
                      </div>
                    }
                    {
                      addRoles.length > 0 &&
                      <>
                        <Typography variant="body2">Roles to Add:</Typography>
                        {
                          addRoles.map((r,i) => {
                            return (
                              <div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center'}}>
                                <AddIcon color="secondary" />
                                <Typography>{r}</Typography>
                              </div>
                            )
                          })
                        }
                      </>
                    }
                    {
                      removeRoles.length > 0 &&
                      <>
                        <Typography style={{marginTop:'10px'}} variant="body2">Roles to Remove:</Typography>
                        {
                          removeRoles.map((r,i) => {
                            var removing = [];
                            for (const id of selected) {
                              if (props.employees.hasOwnProperty(id) && props.employees[id].role && props.employees[id].role.includes(r)) {
                                removing.push(props.employees[id]);
                              }
                            }
                            return (
                              <>
                                <div style={{cursor:'pointer',display:'flex',flexDirection:'row',width:'100%',alignItems:'center'}} onClick={() => {
                                  let copy = {...showRemoved};
                                  if (copy.hasOwnProperty(i)) {
                                    delete copy[i];
                                  } else {
                                    copy[i] = i;
                                  }
                                  setShowRemoved(copy);
                                }}>
                                  <RemoveIcon color="error" />
                                  <Typography variant="body2">{r}</Typography>
                                  {
                                    showRemoved.hasOwnProperty(i) && <ArrowDropUpIcon color="secondary" />
                                  }
                                  {
                                    !showRemoved.hasOwnProperty(i) && <ArrowDropDownIcon color="secondary" />
                                  }
                                </div>
                                {
                                  showRemoved.hasOwnProperty(i) &&
                                  <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                                  {
                                    removing.map((emp, i) => {
                                      return (
                                        <ListItem key={'missing-' + emp.id} style={{width:'20%',marginBottom:'2px',borderRadius:10,border: theme.palette.primary.main}}>
                                          <ListItemAvatar>
                                            <ProfilePicture user={props.user} employee={emp} />
                                          </ListItemAvatar>
                                          <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                                        </ListItem>
                                      )
                                    })
                                  }
                                  </div>
                                }
                              </>

                            )
                          })
                        }
                      </>
                    }
                  </div>
                </>
              }
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRole(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="loading-bar"
      >
        <DialogTitle id="loading-bar"></DialogTitle>
        <DialogContent>
          <LinearProgressWithLabel value={progress} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
