export const LANGUAGES = [
  {
    code : 'en',
    name : 'English'
  },
  {
    code : 'es',
    name : 'Spanish'
  },
  {
    code : 'fr',
    name : 'French'
  },
  {
    code : 'de',
    name : 'German'
  },
  {
    code : 'pt',
    name : 'Portuguese'
  },
  {
    code : 'it',
    name : 'Italian'
  }
];
