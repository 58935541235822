import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import Information from '../../../User/Information';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import DarkUnica from 'highcharts/themes/dark-unica';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

import { CSVDownload } from "react-csv";
import { isMobile } from "react-device-detect";

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}
DarkUnica(Highcharts);

const { formatDate } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  }
}));

export default function Profiles(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [submittedContact, setSubmittedContact] = useState(0);
  const [unsubmittedContact, setUnsubmittedContact] = useState(0);
  const [employee, setEmployee] = useState(null);
  const [open, setOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (props.employees) {
      let subs = 0;
      let unsubs = 0;
      for (const emp of Object.values(props.employees).filter(e => !e.inactive)) {
        if (emp.emergencyContact) {
          subs++;
        } else {
          unsubs++;
        }
      }
      setSubmittedContact(subs);
      setUnsubmittedContact(unsubs);
    }
  }, [props.employees]);

  const handleClickOpen = (emp) => {
    setEmployee(emp);
    setOpen(true);
  };

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    for (const emp of Object.values(props.employees).filter(e => !e.inactive)) {
      data.push({
        email: emp.email,
        phone: emp.phone,
        username: emp.username,
        name: emp.name,
        status: emp.status,
        role: emp.role.join(','),
        contractor: emp.contractor,
        department: emp.department,
        gender: emp.resident,
        ethnicity: emp.resident,
        resident: emp.resident,
        emergencyPhone : emp.emergencyContact ? emp.emergencyContact.phone : null,
        emergencyName : emp.emergencyContact ? emp.emergencyContact.name : null,
        emergencyRelationship : emp.emergencyContact ? emp.emergencyContact.relationship : null,
        joined : emp.created ? formatDate(new Date(emp.created)) : null,
        login : emp.login ? formatDate(new Date(emp.login)) : null
      })
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <IconButton onClick={() => downloadCsv()}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        <Typography variant="h6">{props.certification && props.certification.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <Typography variant="h6">CSV</Typography>
      </div>
      <Divider style={{margin:'10px'}} />
        <Grid container>
          <Grid xs={12} sm={4} style={{padding:'4px'}} item>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                  exporting: {
                    chartOptions: { // specific options for the exported image
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        }
                    },
                    fallbackToExportServer: false
                  },
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height:'250px'
                  },
                  title: {
                      text: 'Emergency Contact Coverage',
                      floating:true,
                      style:{fontSize:'14px'}
                  },
                  tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  accessibility: {
                      enabled : false
                  },
                  plotOptions: {
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          dataLabels: {
                              enabled: false
                          },
                          showInLegend: true,
                          size:'70%'
                      }
                  },
                  series: [{
                    name : '',
                    data : [
                      {
                        name : 'Submitted',
                        y : submittedContact,
                        color : 'green'
                      },
                      {
                        name : 'Missing',
                        y : unsubmittedContact,
                        color : 'red'
                      }
                    ]
                  }]
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="These employees don't have an emergency contact listed in their information, click to view and update.">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Missing</Typography>
            </div>
            {
              props.employees && Object.values(props.employees).filter(e => !e.inactive).filter(e => !e.emergencyContact).map((emp, i) => {
                return (
                  <ListItem style={{marginBottom:'2px',borderRadius:10,border: "1px solid red"}} key={'missing-' + emp.id} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp} />} />
                  </ListItem>
                )
              })
            }
          </Grid>
          <Grid xs={12} sm={4} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="These employees currently have an emergency contact, click to update if it has changed.">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Completed</Typography>
            </div>
            {
              props.employees && Object.values(props.employees).filter(e => !e.inactive).filter(e => e.emergencyContact).map((emp, i) => {
                return (
                  <ListItem style={{marginBottom:'2px',borderRadius:10,border: "1px solid " + theme.palette.primary.main}} key={'completed-' + emp.id} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                  </ListItem>
                )
              })
            }
          </Grid>
        </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          employee &&
          <Information user={employee.id} projectId={props.project.details.id} onComplete={(emp) => {
            setOpen(false);
            props.updateEmployee(emp);
          }} />
        }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
