import React from 'react';

import Registration from '../components/Project/Registration';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height:'100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  container:{
    marginTop : '64px',
    padding : '100px',
    paddingTop : '20px',
    textAlign:'center'
  },
  title:{
    fontSize:'2em',
    margin:'10px'
  },
  explanation : {
    margin:'10px'
  }
});

export default function ProjectRegister(props) {
  const classes = useStyles();
  return (
    <>
      {
        props.company &&
        <div className={classes.container}>
          <Grid container justify="center" alignItems="center" spacing={6}>
            <Grid item>
              <Paper className={classes.paper}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Registration first={true} companyId={props.company.details.id} {...props} />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    </>
  );
}
