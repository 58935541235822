import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import { makeStyles } from '@material-ui/core/styles';
import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';

import { isMobile } from "react-device-detect";
import { CSVDownload } from "react-csv";
import { API } from "aws-amplify";

const { formatDateTime } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  listContainer : {
    overflowY:'scroll',
    height : '500px'
  },
  checkinButton : {
    [theme.breakpoints.down('sm')]: {
      padding : '0px'
    }
  }
}));

export default function Reading(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [receipts, setReceipts] = useState(props.receipts);
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    setReceipts(props.receipts);
  }, [props.receipts]);

  const updateRead = async(checked, doc, projId, userId) => {
    if (checked) {
      let copy = {...receipts};
      copy[userId] = {date:(new Date()).toISOString()};
      setReceipts(copy);
      return API.post('budeeBackend', 'documents/readonly/user', {
        body : {
          doc : {
            id : doc.id,
            version : doc.version
          },
          projectId : projId,
          userId : userId
        }
      }).then(res => props.reload());
    } else {
      let copy = {...receipts};
      delete copy[userId];
      setReceipts(copy);
      return API.del('budeeBackend', 'documents/readonly/user', {
        body : {
          doc : {
            id : doc.id,
            version : doc.version
          },
          projectId : projId,
          userId : userId
        }
      }).then(res => props.reload());
    }
  }

  const renderItem = (id, i) => {
    var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
    if (!emp) {
      return (<React.Fragment key={'missing-' + i}></React.Fragment>);
    }
    let timestamp = null;
    if (receipts.hasOwnProperty(id)) {
      timestamp = formatDateTime(new Date(receipts[id].date));
    }

    return(
      <ListItem key={'missing-' + emp.id}>
        {
          !isMobile &&
          <ListItemAvatar>
            <ProfilePicture user={props.user} employee={emp} />
          </ListItemAvatar>
        }
        <ListItemText style={{width:'100%'}} primary={emp.name} secondary={<Role user={emp}/>} />
        {
          timestamp &&
          <ListItemText style={{width:'230px'}} primary="Last Reviewed" secondary={timestamp} />
        }
        <ListItemSecondaryAction>
          <Checkbox
            checked={receipts.hasOwnProperty(id)}
            onClick={(e) => updateRead(e.target.checked, props.document, props.project.details.id, id)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    for (const id of props.required) {
      var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
      if (!emp) {
        continue;
      }
      data.push({
        name : emp.name,
        role : emp.role,
        status : receipts.hasOwnProperty(id) ? 'COMPLETED' : 'INCOMPLETE',
        date : receipts.hasOwnProperty(id) ? formatDateTime(new Date(receipts[id].date)) : null
      });
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <IconButton onClick={() => downloadCsv()}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        <Typography variant="h6">{props.certification && props.certification.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <Typography variant="h6">CSV</Typography>
      </div>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {
          props.document &&
          <>
          {
            props.document.type === 'application/pdf' ? <PictureAsPdfIcon style={{marginRight:'10px'}} color="error" /> : <VideoLibraryIcon style={{marginRight:'10px'}} color="primary" />
          }
          </>
        }
        <Typography variant="h6">{props.document && props.document.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
      </div>
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
        {
          props.document && !props.document.contractors && !props.document.roles &&
          <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="Project-Wide - All Employees" />
        }
        {
          props.document && props.document.contractors && props.document.contractors.map((c,i) => {
            return (
              <Chip key={'contractor-' + i} size="small" style={{margin:'4px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={c.name} />
            )
          })
        }
        {
          props.document && props.document.roles && props.document.roles.map((r,i) => {
            return (
              <Chip key={'role-' + i} size="small" style={{margin:'4px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={r.name} />
            )
          })
        }
      </div>
      {
        props.document &&
        <Typography variant="body2">{props.document.description}</Typography>
      }
      {
        props.employees &&
        <Grid container>
          <Grid xs={12} sm={6} item>
            <List component="nav" aria-label="secondary mailbox folders">
              {
                props.required && props.required.slice(0, Math.ceil(props.required.length / 2)).map((id, i) => renderItem(id, i))
              }
              </List>
          </Grid>
          <Grid xs={12} sm={6} item>
            <List component="nav" aria-label="secondary mailbox folders">
              {
                props.required && props.required.slice(Math.ceil(props.required.length / 2), props.required.length).map((id, i) => renderItem(id, i))
              }
              </List>
          </Grid>
        </Grid>
      }
    </div>
  );
}
