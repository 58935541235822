import React, { useState } from "react";

import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import { useFormFields } from "../../../../../libs/hooks";

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px'
  },
  buttonContainer : {
    marginTop : '20px'
  }
}));

export default function RewardForm(props) {
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const [programErrors, setProgramErrors] = useState({
    title : false,
    description : false,
    link : false
  });
  const [program, setProgram] = useFormFields({
    title : "",
    description : "",
    link : ""
  });

  const addProgram = () => {
    const check = {
      title : program.title === "",
      description : program.description === "",
      link : program.link === ""
    };
    setProgramErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "company/program", {
        body : {
          companyId : props.company.details.id,
          program : {
            title : program.title,
            description : program.description,
            link : program.link
          }
        }
      }).then((res) => {
        props.loadPrograms(props.company.details.id);
        props.close();
        setUpdating(false);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <TextField
            variant="outlined"
            error={programErrors.title}
            fullWidth
            required
            size="small"
            id="title"
            label="Title"
            name="title"
            value={program.title}
            inputProps={{maxLength:60}}
            onChange={setProgram}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={programErrors.description}
            fullWidth
            required
            multiline
            rows={3}
            size="small"
            id="description"
            label="Description (300 chars max)"
            name="description"
            value={program.description}
            inputProps={{maxLength:300}}
            onChange={setProgram}
            margin="normal"
          />
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'content'}}>
            <InputLabel style={{marginTop:'16px',marginBottom:'8px'}}>http://&nbsp;</InputLabel>
            <TextField
              variant="outlined"
              error={programErrors.link}
              fullWidth
              required
              multiline
              size="small"
              id="link"
              label="Link"
              name="link"
              value={program.link}
              onChange={setProgram}
              margin="normal"
            />
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => addProgram()}>
          {
            !updating &&
            "Add Program"
          }
          {
            updating &&
            <div>
              <CircularProgress size={10} />
            </div>
          }
        </Button>
      </div>
    </>
  );
}
