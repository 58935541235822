import React from 'react';

import Registration from '../components/Company/Registration';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root : {
    minWidth : 275,
    height : '100%'
  },
  bullet : {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom : 12,
  },
  container : {
    marginTop : '64px',
    [theme.breakpoints.up('sm')] : {
      paddingLeft : '100px',
      paddingRight : '100px',
      paddingTop : '10px',
      paddingBottom : '10px'
    },
    padding : '10px',
    paddingTop : '20px',
    textAlign : 'center'
  },
  title:{
    fontSize : '2em',
    margin : '10px'
  },
  explanation : {
    margin : '10px'
  },
  row : {
    display : 'flex',
    width : '100%',
    borderBottom : '1px solid #000000',
    borderTop : '1px solid #000000'
  },
  data : {
    flexGrow : 1,
    flexBasis : 0,
    textAlign:'center',
    padding:'10px'
  }
}));

export default function CompanyRegister(props) {
  const classes = useStyles();

  return (
    <>
      {
        props.user &&
        <div className={classes.container}>
          <p className={classes.title}>Register with a Company</p>
          <p className={classes.explanation}>It is required to have a corporate entity to administer a Budee account, enter your details here to start your free trial.</p>
          <Grid container justify="center" alignItems="center" spacing={6}>
            <Grid xs={12} sm={6} item>
              <Paper className={classes.paper}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Registration {...props} />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Paper className={classes.paper}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <h1>Existing Company</h1>
                    <Typography variant="body2">If you are trying to join an existing company have your supervisor add you to their team by searching for your email: {props.user.email}</Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    </>
  );
}
