import React, { useRef, useState, useLayoutEffect, useEffect } from "react";

import { useEffectWhen } from '../../../../../../libs/hooks';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { API } from "aws-amplify";
import Observation from '../../../Home/Observations/Observation';
import ObservationHeader from '../../../Home/Observations/ObservationHeader';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const { DOCUMENTS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  row : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'calc(100%-10px)'
  }
}));

export default function CheckList(props) {
  const targetRef = useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const [observationDimensions, setObservationDimensions] = useState({ width:0,height:0 });
  const [selectedObservation, setSelectedObservation] = useState(null);

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);

  useEffect(() => {
    if (props.print) {
      props.updateDimensions({
        width : dimensions.width,
        height : dimensions.height + observationDimensions.height
      });
    }
  }, [props, dimensions, observationDimensions]);

  const classes = useStyles();

  const [value, setValue] = useState(props.value && props.value.value ? props.value.value : {});

  const save = async (v) => {
    if (props.submission) {
      API.post('budeeBackend', 'documents/value', {
        body : {
          projectId : props.project.details.id,
          documentId : props.documentId,
          documentVersion : props.submission.documentVersion,
          field : {
            type : DOCUMENTS.FIELDS.CHECKLIST.ID,
            value : v,
            fieldId : props.field.id,
            indexed : props.field.indexed
          },
          submissionId : props.submission.id
        }
      }).catch(e => console.error(e));
    }
  }

  const handleChange = (val, index) => {
    var copy = {...value};
    if (copy && copy.hasOwnProperty(index)) {
      copy[index] = val;
    } else {
      copy[index] = val;
    }
    setValue(copy);
  }

  useEffectWhen(() => {
    try {
      if (Object.keys(value).length > 0) {
        save(value);
      }
    } catch(e) {
      console.error(e);
    }
  }, [value, save], [value]);

  return (
    <Grid xs={12} sm={(props.observations && props.observations.length > 0) ? 12 : (props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
      <div className={classes.row} style={props.print ? {margin:'5px',padding:'5px'} : {}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          {
            !props.print &&
            <IconButton onClick={(e) => props.addObservation(setSelectedObservation)}>
              <VisibilityIcon color="primary" />
            </IconButton>
          }
          <Typography style={props.print ? {textDecorationLine:'underline',marginRight:'5px'} : {marginRight:'5px'}} variant="body2">{props.field.name}</Typography>
        </div>
        <div>
        {
          props.field && props.field.options.map((entry, i) => {
            var checked = false;
            if (Object.keys(value)) {
              checked = value.hasOwnProperty(entry.name) && value[entry.name];
            }
            if (props.print && !checked) {
              return (<React.Fragment key={'checklist-opt-' + i}></React.Fragment>);
            }
            return (
              <div key={'checklist-opt-' + i} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox
                  disabled={props.print}
                  checked={checked}
                  onChange={(e) => handleChange(e.target.checked, entry.name)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography>{entry.name}</Typography>
              </div>
            )
          })
        }
        </div>
        {
          props.observations && props.observations.map((obv, i) => {
            return (
              <React.Fragment key={'obv-' + obv.id}>
                <div style={{width:'100%',cursor:'pointer'}} onClick={() => setSelectedObservation(obv)}>
                  <ObservationHeader print={props.print} index={i} observation={obv} updateDimensions={(dim) => setObservationDimensions(50)} employees={props.employees} user={props.user} />
                </div>
              </React.Fragment>
            )
          })
        }
      </div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={selectedObservation != null}
        onClose={() => setSelectedObservation(null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          selectedObservation &&
          <Observation observation={selectedObservation} employees={props.employees} user={props.user} project={props.project} onComplete={(obv) => {
            props.updateObservations(obv);
            if (obv && obv.deleted) {
              setSelectedObservation(null);
            }
          }}  />
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedObservation(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
