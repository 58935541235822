import React, { useState, useEffect, useRef } from "react";

import { doScrolling } from '../../../../libs/util';
import { useEffectWhen } from '../../../../libs/hooks';
import { API } from "aws-amplify";
import { useTheme } from '@material-ui/core/styles';
import DocumentForm from './ReadOnly/DocumentForm';
import ViewDetails from './ReadOnly/ViewDetails';
import ReadOnlyFile from './ReadOnly/ReadOnlyFile';

import BusinessIcon from '@material-ui/icons/Business';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  documentList : {
    width : '500px'
  },
  documentRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  }
}));

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

export default function Documents(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [open, setOpen] = useState(false);

  const documentFormRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    var mounted = true;
    var grid = [];
    for (const doc of documents) {
      grid.push({
        id : doc.id,
        name : doc.name,
        description : doc.description,
        archive : {
          id : doc.id,
          scope : doc.scope
        },
        version : doc.version,
        required : {
          roles : doc.roles,
          contractors : doc.contractors
        },
        file : {
          key : doc.key,
          identityId : doc.identityId,
          type : doc.type
        },
        doc : doc,
        scope : doc.scope
      });
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [documents]);

  useEffect(() => {
    if (showAdd) {
      scrollToRef(documentFormRef);
    }
  }, [showAdd]);

  const loadDocuments = async (projectId) => {
    if (!open) {
      setLoading(true);
    }
    var results = await Promise.all([
      API.get("budeeBackend", "documents/readonly/admin?projectId=" + projectId + "&scope=project", {}),
      API.get("budeeBackend", "documents/readonly/admin?projectId=" + projectId + "&scope=company", {})
    ]);
    const docs = [...results[0],...results[1]];
    setDocuments(docs);
    setLoading(false);
  };

  useEffectWhen(() => {
    if (props.project) {
      loadDocuments(props.project.details.id);
    }
  }, [props.project], [props.project]);

  const archiveDocument = async (projectId, documentId, scope) => {
    return API.del("budeeBackend", "documents/readonly", {
      body : {
        projectId : projectId,
        documentId : documentId,
        scope : scope
      }
    }).then(res => {
      loadDocuments(projectId);
    });
  }

  var columns = [
    { field: 'id', hide : true },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { field: 'file', headerName: 'File', renderCell : (val) => {
        return (
          <ReadOnlyFile type={val.value.type} name={val.value.key} identityId={val.value.identityId} />
        );
      }
    },
    { field: 'required', headerName: 'Required', flex: 1, renderCell : (val) => {
      if (val.value && (val.value.roles || val.value.contractors)) {
        return (
          <Tooltip title={
            <React.Fragment>
            {
              val.value.roles && val.value.roles.map((r,i) => {
                console.log(r);
                return (
                  <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r.name} size="small"/>
                );
              })
            }
            {
              val.value.contractors && val.value.contractors.map((c,i) => {
                console.log(c);
                return (
                  <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} label={c.name} size="small" />
                );
              })
            }
            </React.Fragment>}>
            <div><AssignmentIndIcon color="primary" /></div>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="All employees of the project.">
            <div style={{fontWeight:'bold'}}>Project Wide</div>
          </Tooltip>
        );
      }
    } },
    { field: 'scope', headerName: 'Scope', flex: 1, cellClassName : 'no-select', renderCell : (val) => {
      return (
        <Tooltip title={
          val.value === 'project' ? 'All required employees at this project will see this document' : 'All employees in the company, at any project will see this document.'
        }><div>{val.value === 'project' ? 'Project-Wide' : 'Company-Wide'}</div></Tooltip>
      );
    }}
  ];
  if (props.project.status === USER_STATUS.SUPERADMIN) {
    columns = columns.concat([
      { field: 'archive', headerName: ' ', flex: 1, cellClassName : 'no-select', renderCell : (val) => {
        return (
          <Button variant="outlined" size="small" color="secondary" onClick={() => archiveDocument(props.project.details.id, val.value.id, val.value.scope)}>Archive</Button>
        );
      }}
    ])
  }

  const handleClickOpen = async (event) => {
    setDetailsSelected(event.row.doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        {
          loading &&
          <div>
            <CircularProgress size={80} />
          </div>
        }
        {
          rows && rows.length > 0 &&
          <p>Employees can read PDF documents or watch MP4 videos from their mobile devices and read receipts are captured, click a row edit or view the audit history.</p>
        }
        {
          !loading && rows && rows.length === 0 &&
          <>
            <div style={{width:'100%',textAlign:'center'}}>
              <Typography variant="body2">Currently No Training Materials.</Typography>
            </div>
            <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
            <div style={{display:'flex',flexDirection:'row'}}>
              <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vfeMHhwPANA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
              </div>
              <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                <Typography variant="h6">Learn About Training Materials</Typography>
                <Typography variant="body2">Watch this video to learn more about how training materials work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                <ul>
                  <li>Training Form PDF/Video</li>
                  <li>Example PDF</li>
                  <li>Example Video</li>
                  <li>Dashboard View for Compliance</li>
                  <li>Administratively Managing Workforce Compliance</li>
                </ul>
                <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/training?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
              </div>
            </div>
          </>
        }
        {
          !loading && rows && rows.length > 0 &&
          <>
            <div style={{height:'400px'}}>
              <DataGrid
                rows={rows}
                disableColumnMenu
                columns={columns}
                density="compact"
                autoPageSize={true}
                pageSize={5}
                sortModel={[{field: 'name', sort: 'asc'}]}
                onCellClick={(e) => {
                  const arr = Array.from(e.element.classList);
                  if (!arr.includes('no-select')) {
                    handleClickOpen(e);
                  }
                }}
                className={classes.documentRow}
              />
              <Dialog
                fullScreen={fullScreen}
                maxWidth="xl"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title"></DialogTitle>
                <DialogContent>
                  <ViewDetails user={props.user} details={detailsSelected} setDetailsSelected={setDetailsSelected} setOpen={setOpen}  employees={props.employees} project={props.project} company={props.company} loadDocuments={loadDocuments} roles={props.roles} contractors={props.contractors} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        }
        <div ref={documentFormRef}>
        {
          props.project.status === USER_STATUS.SUPERADMIN &&
          <>
            {
              !showAdd &&
              <Button onClick={() => setShowAdd(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                Add New Training
              </Button>
            }
            {
              showAdd &&
              <>
                <Button onClick={() => setShowAdd(false)} style={{margin:'10px'}} color="secondary" variant="outlined">
                  Cancel Training Add
                </Button>
                <DocumentForm roles={props.roles} contractors={props.contractors} project={props.project} onComplete={() => loadDocuments(props.project.details.id)} />
              </>
            }
          </>
        }
        </div>
      </Grid>
    </Grid>
  );
}
