import { format } from 'date-fns';

export function formatDate(date) {
  return date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

export function toDate(dateStr) {
  if (!dateStr) {
    return new Date();
  }
  var parts = dateStr.split("-")
  return new Date(parts[0], parts[1] - 1, parts[2])
}

export function doScrolling(elementY, duration) {
  var startingY = window.pageYOffset;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

export function utcStrToDate(dateStr) {
  if (!dateStr) {
    return new Date();
  }
  var parts = dateStr.split(' ');
  var date = parts[0].split('-');
  var time = parts[1].split(':');
  var dateObj = new Date(date[0], date[1] - 1, date[2], time[0], time[1]);
  var offset = dateObj.getTimezoneOffset() / 60;
  var hours = dateObj.getHours();
  dateObj.setHours(hours - offset);
  return dateObj;
}

export const buildAxis = (date) => {
  var start;
  if (date) {
    start = new Date(formatDate(date));
  } else {
    start = new Date();
  }

  var days = [];
  // Inclusive to first date
  start.setDate(start.getDate() + 1);
  days.push(format(new Date(start), 'LL/dd/yy'));
  for (let i = 0; i < 14; i++) {
    start.setDate(start.getDate() - 1);
    days.push(format(new Date(start), 'LL/dd/yy'));
  }
  days.sort();
  return days;
}

export const datediff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}
