import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import { useFormFields } from "../../libs/hooks";
import { PROVINCES, STATES, AUSTRALIAN_PROVINCES, IRELAND_PROVINCES } from '../../config/constants';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  country : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

const setupCountryOptions = () => {
  var options = [];
  options.push(<MenuItem key={'country-unselected'}  value={'unselected'}>Select Country *</MenuItem>);
  options.push(<MenuItem key={'country-au'}  value={'Australia'}>Australia</MenuItem>);
  options.push(<MenuItem key={'country-ca'}  value={'Canada'}>Canada</MenuItem>);
  options.push(<MenuItem key={'country-uk'}  value={'Ireland'}>Ireland</MenuItem>);
  options.push(<MenuItem key={'country-uk'}  value={'United Kingdom'}>United Kingdom</MenuItem>);
  options.push(<MenuItem key={'country-us'}  value={'United States'}>United States</MenuItem>);
  return options;
}

const getProvinceState = (country) => {
  const items = [<MenuItem key={'country-unselected'}  value={'unselected'}>Select Province/State</MenuItem>];
  switch (country) {
    case 'Australia':
      for (const state of Object.values(AUSTRALIAN_PROVINCES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    case 'Canada':
      for (const province of Object.values(PROVINCES)) {
        items.push(<MenuItem key={'province-' + province}  value={province}>{province}</MenuItem>);
      }
      break;
    case 'United States':
      for (const state of Object.values(STATES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    case 'Ireland':
      for (const state of Object.values(IRELAND_PROVINCES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    default:
  }
  return items;
}

export default function Register(props) {
  const classes = useStyles();
  const [updating, setUpdating] = useState(false);
  const countries = setupCountryOptions();
  const [companyDetails, setCompanyDetails] = useFormFields({
    name : ""
  });
  const [companyAddress, setCompanyAddress] = useFormFields({
    line1 : "",
    line2 : "",
    city : "",
    state : "unselected",
    postal : "",
    country : "unselected"
  });
  const [detailErrors, setDetailErrors] = useState({
    name : false,
  });
  const [addressErrors, setAddressErrors] = useState({
    line1 : false,
    line2 : false,
    city : false,
    state : false,
    postal : false,
    country : false
  });

  useEffect(() => {
    setupCountryOptions();
    // eslint-disable-next-line
  }, []);

  const submitForm = () => {
    const checkAddress = {
      line1 : companyAddress.line1 === "" ? true : false,
      line2 : false,
      city : companyAddress.city === "" ? true : false,
      state : companyAddress.country !== "United Kingdom" && (companyAddress.state === "unselected" || companyAddress.state === "--") ? true : false,
      postal : companyAddress.postal === "" ? true : false,
      country : companyAddress.country === "unselected" ? true : false
    };
    setAddressErrors(checkAddress);
    const checkDetails = {
      name : companyDetails.name === "" ? true : false
    };

    setDetailErrors(checkDetails);
    if (!Object.values(checkAddress).includes(true) && !Object.values(checkDetails).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "project/", { body : {
          companyId : props.companyId,
          project : Object.assign(companyDetails, { address : companyAddress })
        }}).then((res) => {
          setUpdating(false);
          if (props.admin) {
            props.loadProjects(props.companyId);
          } else {
            props.loadProjects();
          }
          if (props.onComplete) {
            props.onComplete();
          }
        }).catch(err => {
          setUpdating(false);
        });
    }
  }

  return (
    <div>
      {
        props.first &&
        <>
          <h1>Project Profile</h1>
          <p className={classes.explanation}>Add your first project to your company, this may be the entire company or you may have many projects within your company.  You can add more later.</p>
        </>
      }
      <Grid container spacing={6}>
        <Grid xs={6} style={{margin:'0 auto'}} item>
          <TextField
            variant="outlined"
            error={detailErrors.name}
            required
            fullWidth
            size="small"
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={setCompanyDetails}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={addressErrors.line1}
            required
            id="line1"
            name="line1"
            label="Address Line 1"
            size="small"
            fullWidth
            autoComplete="address-line1"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <TextField
            variant="outlined"
            id="line2"
            name="line2"
            label="Address Line 2"
            size="small"
            fullWidth
            autoComplete="address-line2"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={addressErrors.city}
            required
            id="city"
            name="city"
            label="City"
            size="small"
            fullWidth
            autoComplete="address-level2 city"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              required
              variant="outlined"
              error={addressErrors.country}
              id="country"
              name="country"
              size="small"
              className={classes.country}
              classes={{root : classes.selectInput}}
              value={companyAddress.country}
              fullWidth
              autoComplete="country country-name"
              onChange={setCompanyAddress}
            >
              {countries}
            </Select>
          </FormControl>
          {
            companyAddress.country !== 'United Kingdom' &&
            <FormControl fullWidth>
              <InputLabel id="state-label">Province/State</InputLabel>
              <Select
                labelId="state-label"
                required
                variant="outlined"
                error={addressErrors.state}
                id="state"
                name="state"
                size="small"
                className={classes.country}
                classes={{root : classes.selectInput}}
                value={companyAddress.state}
                fullWidth
                autoComplete="address-level1 state province"
                onChange={setCompanyAddress}
              >
                {getProvinceState(companyAddress.country)}
              </Select>
            </FormControl>
          }
          <TextField
            variant="outlined"
            error={addressErrors.postal}
            required
            id="postal"
            name="postal"
            label="Zip / Postal code"
            size="small"
            fullWidth
            autoComplete="postal-code"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => submitForm()}
          >
            {
              !updating &&
              <>
                Add Project
              </>
            }
            {
              updating &&
              <div>
                <CircularProgress size={10} />
              </div>
            }
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
