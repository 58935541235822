import React, { useState, useRef, useLayoutEffect, useEffect } from "react";

import { useEffectWhen } from '../../../../../../libs/hooks';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TimePicker } from '@material-ui/pickers';

import { API } from "aws-amplify";
const { DOCUMENTS } = require('@budeesolutions/budee-constants');
const { formatTime } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  row : {
    display : 'flex',
    flexDirection : 'row',
    alignItems : 'center',
    width:'100%',
  },
  column : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'100%',
  }
}));

export default function Timestamp(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);

  const classes = useStyles();

  const [value, setValue] = useState(props.value && props.value.value ? new Date(props.value.value) : null);
  const firstUpdate = useRef(true);

  const save = async (v) => {
    API.post('budeeBackend', 'documents/value', {
      body : {
        projectId : props.projectId,
        documentId : props.documentId,
        documentVersion : props.submission.documentVersion,
        field : {
          type : DOCUMENTS.FIELDS.TIMESTAMP.ID,
          value : v ? v.toString() : null,
          fieldId : props.field.id,
          indexed : props.field.indexed
        },
        submissionId : props.submission.id
      }
    }).catch(e => console.error(e));
  }

  useEffectWhen(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    try {
      save(value);
    } catch(e) {
      console.error(e);
    }
  }, [value, save], [value]);

  return (
    <Grid xs={12} sm={props.field && props.field.width ? props.field.width : 4} item ref={targetRef}>
      <div className={classes.column} style={props.print ? {margin:'5px',padding:'5px'} : {}}>
        <Typography style={props.print ? {textDecorationLine:'underline',marginRight:'5px'} : {marginRight:'5px'}} variant="body2">{props.field.name}</Typography>
        {
          props.print &&
          <Typography variant="body2">{value ? formatTime(value) : ''}</Typography>
        }
        {
          !props.print &&
          <TimePicker
            clearable
            ampm={false}
            label="24 hours"
            value={value}
            onChange={setValue}
          />
        }
      </div>
    </Grid>
  );
}
