import React, { useState } from "react";

import queryString from 'query-string';
import Submissions from './Edit/Submissions';
import Builder from './Edit/Builder';
import View from './View';
import DocumentForm from './DocumentForm';

import { isMobile } from "react-device-detect";

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockIcon from '@material-ui/icons/Lock';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

export default function Edit(props) {
  var parsed = queryString.parse(props.location.search);

  const [view, setView] = useState(props.detailsSelected.draft ? 'builder' : 'submissions');
  const [edit, setEdit] = useState(false);
  const [submission, setSubmission] = useState(null);

  const goBack = () => {
    delete parsed.documentId;
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify(parsed)
    });
    props.setDetailsSelected(null)
  }

  return (
    <>
      {
        submission &&
        <View submission={submission} document={props.detailsSelected} setSubmission={setSubmission} user={props.user} employees={props.employees} project={props.project} print={false} {...props} />
      }
      {
        !submission &&
        <Grid container spacing={3}>
            <Grid xs={12} style={{margin:'0px',padding:'4px'}} item>
              <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',alignItems:'center'}}>
                <ArrowBackIcon style={{cursor:'pointer', marginRight:'30px'}} onClick={() => goBack()} />
                <Typography variant="h6">
                {
                  view === 'submissions' && 'Document Submissions'
                }
                {
                  view === 'builder' && 'Document Builder'
                }
                </Typography>
                <Divider orientation="vertical" style={{marginLeft:'10px',marginRight:'10px'}} flexItem />
                <Typography variant="h6" style={{marginRight:'10px',maxWidth:'380px'}}>{props.detailsSelected.name}</Typography>
                {
                  !edit &&
                  <Button variant="contained" color="secondary" onClick={() => setEdit(true)}>Edit</Button>
                }
                {
                  edit &&
                  <Button variant="contained" color="secondary" onClick={() => setEdit(false)}>Back</Button>
                }
                <Tooltip style={{marginLeft:'10px',marginRight:'10px'}} title={"Only privileged users can view submissions after they are submitted."} >
                  <SecurityIcon color={props.detailsSelected.private ? "primary" : "disabled"} />
                </Tooltip>
                <Tooltip style={{marginRight:'10px'}} title={"Only administrators can edit submissions after it has been submitted."} >
                  <LockIcon color={props.detailsSelected.locked ? "primary" : "disabled"} />
                </Tooltip>
                <div style={{flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <ButtonGroup valye={view} color="primary" aria-label="outlined primary button group">
                    <Button variant={view === 'submissions' ? 'contained' : 'outlined'} color={view === 'submissions' ? 'primary' : 'secondary'} value={'submissions'} onClick={() => setView('submissions')}>Submissions</Button>
                    {
                      props.project.status === USER_STATUS.SUPERADMIN &&
                      <Button variant={view === 'builder' ? 'contained' : 'outlined'} color={view === 'builder' ? 'primary' : 'secondary'} value={'builder'} onClick={() => setView('builder')}>Builder View</Button>
                    }
                  </ButtonGroup>
                </div>
              </div>
            </Grid>
            {
              edit &&
              <Grid xs={12} item>
                <DocumentForm roles={props.roles} contractors={props.contractors} project={props.project} document={props.detailsSelected} edit={true} onComplete={(doc) => {
                  setEdit(false);
                  props.setDetailsSelected(doc);
                  props.loadDocuments(props.project.details.id, props.detailsSelected.scope);
                }} user={props.user} />
              </Grid>
            }
            {
              !edit &&
              <>
                {
                  view === 'submissions' &&
                  <Submissions setSubmission={setSubmission} {...props} />
                }
                {
                  view === 'builder' &&
                  <Builder {...props} />
                }
              </>
            }
        </Grid>
      }
    </>
  );
}
