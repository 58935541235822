import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function Text(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);

  return (
    <Grid xs={12} sm={(props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
      <Typography variant={props.print ? "body2" : "subtitle2"} style={{width:'100%',fontSize: props.print ? '12px' : '16px', fontWeight: props.field && props.field.config && props.field.config.bold ? 'bold' : 'normal'}}>{props.field.name}</Typography>
    </Grid>
  );
}
