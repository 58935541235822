import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Role from "../../../User/Role";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@material-ui/data-grid';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import Observation from './Observations/Observation';

import { CSVDownload } from "react-csv";

const { formatDate } = require('@budeesolutions/budee-util');
const { RANGES } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  submission : {
    border:'1px solid ' + theme.palette.primary.main,
    borderRadius:'10px',
    margin:'2px',
    cursor:'pointer',
    '&:hover': {
      border:'1px solid ' + theme.palette.accentColor.main,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const columns = [
  { field: 'id', hide : true },
  { field: 'date', headerName: 'Created', width : 175, renderCell : (val) => {
    return (
      <Typography variant="body2" color="textSecondary">{format(val.value, 'yyyy/MM/dd  hh:mmaaa')}</Typography>
    )
  } },
  { field: 'name', width : 200, headerName: 'Reported By', renderCell : (val) => {
      return (
        <>
          {
            val.value !== 'Anonymous' && val.value &&
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span>{val.value.name}</span>
              <Role user={val.value} />
            </div>
          }
          {
            val.value === 'Anonymous' && 'Anonymous'
          }
        </>
      );
  } },
  { field: 'description', headerName: 'Description', flex: 1 },
  { field: 'assigned', width : 200, headerName: 'Assigned To', renderCell : (val) => {
      return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {
          val.value &&
          <>
            <span>{val.value.name}</span>
            <Role user={val.value} />
          </>
        }
        </div>
      );
  }},
  { field: 'resolvedBy', width : 200, headerName: 'Resolved By', renderCell : (val) => {
      return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {
          val.value &&
          <>
            <span>{val.value.name}</span>
            <Role user={val.value} />
          </>
        }
        </div>
      );
  }},
  { field: 'status', headerName: 'Status', renderCell : (val) => {
    return (
      <>
        {
          !val.value &&
          <AssignmentTurnedInIcon color="primary" />
        }
        {
          val.value &&
          <AssignmentLateIcon color="error" />
        }
      </>
    )
  } },
  { field: 'fixby', headerName: 'Fix By', width : 100, renderCell : (val) => {
      return (
        <Typography variant="body2" color="textSecondary">{val.value && format(val.value, 'yyyy/MM/dd')}</Typography>
      );
  } }
];

export default function Observations(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [observation, setObservation] = useState(null);
  const [observations, setObservations] = useState([]);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (props.observations && props.observations.hasOwnProperty('Safety Observations')) {
      setObservations(props.observations['Safety Observations']);
    }
  }, [props.observations]);

  useEffect(() => {
    var mounted = true;
    if (props.employees) {
      var rows = [];
      for (const obv of observations) {
        rows.push({
          id : obv.id,
          date : new Date(obv.date),
          description : obv.description,
          name : obv.anonymous ? 'Anonymous' : props.employees[obv.userId],
          status : obv.status,
          assigned : props.employees[obv.assigned],
          resolvedBy : props.employees[obv.resolvedBy],
          observation : obv,
          fixby : obv.fixBy ? new Date(obv.fixBy) : null
        });
      }
      if (mounted) {
        setRows(rows);
      }
    }
    return () => mounted = false;
  }, [observations, props.employees]);

  const handleClickOpen = (event) => {
    setObservation(event.row.observation);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const getSelectedDate = () => {
    switch (props.rollUp) {
      case RANGES.DAY:
        return 'on ' + formatDate(props.selectedDate);
      case RANGES.WEEK:
        let weekLater = new Date(props.selectedDate);
        weekLater.setDate(weekLater.getDate() + 7);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(weekLater);
      case RANGES.MONTH:
        let monthLater = new Date(props.selectedDate);
        monthLater.setDate(monthLater.getDate() + 31);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(monthLater);
      default:
        return formatDate(props.selectedDate);
    }
  }

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    for (const obv of observations) {
      data.push({
        date : new Date(obv.date),
        description : obv.description,
        name : obv.anonymous ? 'Anonymous' : props.employees[obv.userId].name,
        status : obv.status ? 'Open' : 'Resolved',
        assigned : obv.assigned ? props.employees[obv.assigned].name : '',
        resolvedBy : obv.resolvedBy ? props.employees[obv.resolvedBy].name : '',
        fixby : obv.fixBy ? new Date(obv.fixBy) : null
      });
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <>
      <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <IconButton onClick={() => downloadCsv()}>
            <CloudDownloadIcon color="primary" />
          </IconButton>
          <Typography variant="h6">{props.certification && props.certification.name}</Typography>
          {
            csvData &&
            <CSVDownload data={csvData} target="_blank" />
          }
          <Typography variant="h6">CSV</Typography>
        </div>
        <Grid container>
          <Grid xs={12} item>
            <div style={{height:'300px'}}>
              <DataGrid
                rows={rows}
                density="compact"
                autoPageSize={true}
                columns={columns}
                sortModel={[{field: 'date', sort: 'desc'}]}
                onRowClick={(e) => handleClickOpen(e)}
                className={classes.alertRow}
                components={{
                  NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Safety Observations {getSelectedDate()}</div>)
                }}
              />
            </div>
            <Dialog
              fullScreen={fullScreen}
              maxWidth="xl"
              fullWidth={true}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title"></DialogTitle>
              <DialogContent>
                <Observation observation={observation} employees={props.employees} user={props.user} project={props.project} onComplete={() => {
                  setOpen(false);
                  props.reload();
                }}  />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
