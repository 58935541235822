import React, { useState, useEffect } from "react";

import { API, Storage, Auth } from "aws-amplify";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormFields } from "../../../../../libs/hooks";

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { isMobile } from "react-device-detect";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MessageIcon from '@material-ui/icons/Message';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { v4 as uuidv4 } from 'uuid';

const { DOCUMENTS } = require('@budeesolutions/budee-constants');

const showOptions = [
  DOCUMENTS.FIELDS.CHECKLIST.ID,
  DOCUMENTS.FIELDS.SELECT.ID,
  DOCUMENTS.FIELDS.RADIOGROUP.ID
];

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    cursor:'pointer',
    textAlign:'center'
  },
  paperFill : {
    width: '100%',
    backgroundColor : theme.palette.secondary.main,
    cursor:'pointer',
    textAlign:'center',
    color:'#FFFFFF'
  }
}));

export default function FieldForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fileRef = React.useRef();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  var allFields = Object.values(DOCUMENTS.FIELDS);
  allFields.sort((a,b) => {
    if (a.TEXT > b.TEXT) {
      return 1;
    } else if (a.TEXT < b.TEXT) {
      return -1
    } else {
      return 0;
    }
  });
  const [doc, setDoc] = useState(props.document);
  const [project, setProject] = useState(props.project);
  const [optionErrors, setOptionErrors] = useState({
    name : false
  });
  const [width, setWidth] = useState(props.field && props.field.width ? props.field.width : null);
  const [option, setOption] = useFormFields({
    name : "",
    score : ""
  });
  const [optionColor, setOptionColor] = useState('transparent');
  const [options, setOptions] = useState(props.field && props.field.options ? props.field.options : []);
  const [fieldErrors, setFieldErrors] = useState({
    name : false
  });
  const [field, setField] = useFormFields({
    name : props.field && props.field.name ? props.field.name : "",
    description : props.field && props.field.description ? props.field.description : ""
  });
  const [type, setType] = useState(props.field ? props.field.type : 'unselected');
  const [operatingSystem, setOperatingSystem] = useState('ios');
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);
  const [observationOpen, setObservationOpen] = useState(false);
  const [optionIndex, setOptionIndex] = useState(null);
  const [tipTitle, setTipTitle] = useState('');
  const [tipDescription, setTipDescription] = useState('');
  const [tipErrors, setTipErrors] = useState({
    title : false,
    description : false
  });
  const [signature, setSignature] = useState(props.field && props.field.config && props.field.config.hasOwnProperty('signature') && props.field.config.signature);
  const [bold, setBold] = useState(props.field && props.field.config && props.field.config.hasOwnProperty('bold') && props.field.config.bold);
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [mandatory, setMandatory] = useState(props.field && props.field.config && props.field.config.hasOwnProperty('mandatory') && props.field.config.mandatory);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    var mounted = true;

    if (DOCUMENTS.FIELDS.PLAINIMAGE.ID === type && props.field && props.field.config && props.field.config.image) {
      Storage.get(props.field.config.image.key, {
        level: "protected",
        identityId : props.field.config.image.identityId
      }).then(value => {
        if (mounted) {
          setImage(props.field.config.image);
          setImageUrl(value);
          if (fileRef && fileRef.current) {
            fileRef.current.value = "";
          }
        }
      });
    }

    var w = 4;
    switch (type) {
      case DOCUMENTS.FIELDS.TEXTAREA.ID:
        w = 8;
        break;
      case DOCUMENTS.FIELDS.DATE.ID:
      case DOCUMENTS.FIELDS.TEXT.ID:
      case DOCUMENTS.FIELDS.TEXT_LIST.ID:
      case DOCUMENTS.FIELDS.USER.ID:
      case DOCUMENTS.FIELDS.USER_LIST.ID:
      case DOCUMENTS.FIELDS.CHECKLIST.ID:
      case DOCUMENTS.FIELDS.PHOTO.ID:
      case DOCUMENTS.FIELDS.RADIOGROUP.ID:
      case DOCUMENTS.FIELDS.SELECT.ID:
        w = 4;
        break;
      case DOCUMENTS.FIELDS.DIVIDER.ID:
      case DOCUMENTS.FIELDS.HEADING.ID:
      case DOCUMENTS.FIELDS.PLAINIMAGE.ID:
        w = 12;
        break;
      case 'unselected':
        w = null;
        break;
      default:
        w = 4;
    }
    if (mounted && props.field && props.field.width) {
      setWidth(props.field.width);
    } else if (mounted) {
      setWidth(w);
    }
    return () => mounted = false;
  }, [type, props.field]);

  const addField = () => {
    const check = {
      name : field.name === "" && type !== DOCUMENTS.FIELDS.DIVIDER.ID ? true : false,
      type : type === "unselected" ? true : false
    };
    setFieldErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      var f = {};
      if (type === DOCUMENTS.FIELDS.USER.ID || type === DOCUMENTS.FIELDS.USER_LIST.ID) {
        f.config = {signature : signature}
      }
      if (type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID)
      if (type === DOCUMENTS.FIELDS.PLAINTEXT.ID) {
        f.config = {bold : bold}
      }
      if (type === DOCUMENTS.FIELDS.PLAINIMAGE.ID) {
        f.config = {
          image : image
        }
      }
      if (type !== DOCUMENTS.FIELDS.DIVIDER.ID && type !== DOCUMENTS.FIELDS.HEADING.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && type !== DOCUMENTS.FIELDS.PLAINIMAGE.ID) {
        f.config = {
          mandatory : mandatory
        }
      }
      if (props.field) {
        f = Object.assign(field, {
          type : type,
          options : options,
          width : width,
          id : props.field.id,
          order : props.field.order,
          config : f.config
        });
      } else {
        f = Object.assign(field, {
          type : type,
          options : options,
          width : width,
          config : f.config
        });
      }
      API.post("budeeBackend", "documents/field", { body : {
        projectId : project.details.id,
        documentId : doc.id,
        documentVersion : doc.version,
        field : f
      }}).then((res) => {
        setType('unselected');
        setField({target:[{id:'name',value:''},{id:'description',value:''}]});
        setSignature(false);
        setImage(null);
        setImageUrl(null);
        setUploadProgress(0);
        setMandatory(false);
        setOptions([]);
        setUpdating(false);
        if (props.onComplete) {
          props.onComplete();
        }
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  const addOption = (opt) => {
    if (!opt) {
      setOptionErrors({
        name:true
      });
    } else {
      setOptionErrors({
        name:false
      });
    }

    var copy = [...options];
    if (!copy.includes(opt)) {
      copy.push(opt);
      setOptions(copy);
    }
    setOption({target:[{id:'name', value:''},{id:'score', value:''}]});
    setOptionColor('transparent');
  }

  useEffect(() => {
    setDoc(props.doc);
  }, [props.doc]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project])

  const remove = (i) => {
    if (props.doc && props.doc.draft) {
      var copy = [...options];
      copy.splice(i, 1);
      setOptions(copy);
    }
  }

  const getImage = (t, os) => {
    if (t === 'unselected') {
      return props.getBaseUrl() + '/images/' + os + '/default.jpeg';
    } else {
      return props.getBaseUrl() + '/images/' + os + '/' + t + '.jpeg';
    }
  }

  const setOptionAlert = (index) => {
    setOptionIndex(index);
    setOpen(true);
  }

  const setOptionObservation = (index) => {
    setOptionIndex(index);
    setObservationOpen(true);
  }

  const removeOptionAlert = (index) => {
    var copy = [...options];
    var opt = copy[index];
    delete opt.alert;
    setOptions(copy);
  }

  const removeOptionObservation = (index) => {
    var copy = [...options];
    var opt = copy[index];
    delete opt.observation;
    setOptions(copy);
  }

  const closeOption = () => {
    setOpen(false);
    setTipTitle('');
    setTipDescription('');
    setTipErrors({
      title : false,
      description : false
    });
    setOptionIndex(null);
  };

  const closeObservationOption = () => {
    setObservationOpen(false);
  }

  const addOptionAlert = () => {
    var errors = {
      title : tipTitle === '' ? true : false,
      description : tipDescription === '' ? true : false
    };
    setTipErrors(errors);
    if (!Object.values(errors).includes(true)) {
      var copy = [...options];
      copy[optionIndex] = Object.assign(copy[optionIndex], {
        alert : {
          title : tipTitle,
          description : tipDescription
        }
      });
      setOptions(copy);
      closeOption();
    }
  }

  const addOptionObservation = () => {
    var copy = [...options];
    copy[optionIndex] = Object.assign(copy[optionIndex], {
      observation : true
    });
    setOptions(copy);
    closeObservationOption(false);
  }

  const getLabel = (type) => {
    switch(type) {
      case DOCUMENTS.FIELDS.HEADING.ID:
      case DOCUMENTS.FIELDS.PLAINTEXT.ID:
        return "Text";
      case DOCUMENTS.FIELDS.PLAINIMAGE.ID:
        return "Title";
      default:
        return "Field Label";
    }
  }

  function onChange(e) {
    const file = e.target.files[0];
    setUploading(true);
    Auth.currentCredentials().then(res => {
      Storage.put(project.details.id + '/' + doc.id + '/' + uuidv4() + '.' + (file.type === 'image/png' ? 'png' : 'jpeg'), file, {
          level: "protected",
          contentType : file.type,
          completeCallback : (event) => {},
          progressCallback: (prog) => setUploadProgress(prog),
          errorCallback: (err) => {}
      })
      .then (result => {
        setUploading(false);
        const uploaded = {
          key : result.key,
          identityId : res.identityId
        };
        setImage(uploaded);
        Storage.get(result.key, {
          level: "protected",
          identityId : res.identityId
        }).then(value => {
          setImageUrl(value);
          if (fileRef && fileRef.current) {
            fileRef.current.value = "";
          }
        });
      });
    });
  }

  return (
    <>
      <Grid container>
        <Grid xs={12} sm={9} item>
          <div style={{display:'flex',flexDirection:'column',margin:'10px'}}>
            <FormControl className={classes.formControl} style={{flexGrow : 1,margin:'5px'}}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                error={fieldErrors.type}
                id="type-select"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem key={'unselected-type'} value={'unselected'}>Select a Type</MenuItem>
              {
                allFields.map((field, i) => {
                  return (
                    <MenuItem key={'field-' + i} value={field.ID}>{field.TEXT}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            {
              type !== DOCUMENTS.FIELDS.DIVIDER.ID &&
              <TextField
                variant="outlined"
                error={fieldErrors.name}
                required
                size="small"
                id="name"
                label={getLabel(type)}
                name="name"
                value={field.name}
                onChange={setField}
                margin="normal"
              />
            }
            {
              (type === DOCUMENTS.FIELDS.USER.ID || type === DOCUMENTS.FIELDS.USER_LIST.ID) &&
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox
                  checked={signature}
                  style={{marginLeft:'10px'}}
                  onChange={ (e) => setSignature(e.target.checked) }
                  inputProps={ { 'aria-label': 'set signature required' } }
                />
                <Typography variant="body2">Signature Required</Typography>
              </div>
            }
            {
              type === DOCUMENTS.FIELDS.PLAINTEXT.ID &&
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox
                  checked={bold}
                  style={{marginLeft:'10px'}}
                  onChange={ (e) => setBold(e.target.checked) }
                  inputProps={ { 'aria-label': 'Bold the text.' } }
                />
                <Typography variant="body2">Bold Text</Typography>
              </div>
            }
            {
              type === DOCUMENTS.FIELDS.PLAINIMAGE.ID &&
              <>
                {
                  imageUrl &&
                  <div style={{margin:'5px'}}>
                  {
                    imageUrl !== 'NONE' &&
                    <img style={{width:'400px',maxHeight:'300px'}} src={imageUrl} alt={field.name} />
                  }
                  </div>
                }
                <div style={{margin:'5px'}}>
                {
                  uploading &&
                  <div style={{width:'100%',margin:'5px'}}>
                    <LinearProgress variant="determinate" value={uploadProgress ? (uploadProgress.loaded / uploadProgress.total) * 100 : 0} />
                  </div>
                }
                {
                  !uploading &&
                  <input type="file" accept='image/png,image/jpeg' ref={fileRef} onChange={(evt) => onChange(evt)} />
                }
                </div>
              </>
            }
            {
              type !== DOCUMENTS.FIELDS.DIVIDER.ID && type !== DOCUMENTS.FIELDS.HEADING.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID &&
              <TextField
                variant="outlined"
                error={fieldErrors.description}
                size="small"
                id="description"
                label="Help Tip"
                name="description"
                value={field.description}
                onChange={setField}
                margin="normal"
              />
            }
            {
              type !== 'unselected' && type !== DOCUMENTS.FIELDS.DIVIDER.ID && type !== DOCUMENTS.FIELDS.HEADING.ID && type !== DOCUMENTS.FIELDS.PLAINTEXT.ID && type !== DOCUMENTS.FIELDS.PLAINIMAGE.ID &&
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox
                  checked={mandatory}
                  style={{marginLeft:'10px'}}
                  onChange={ (e) => setMandatory(e.target.checked) }
                  inputProps={ { 'aria-label': 'set signature required' } }
                />
                <Typography variant="body2">Mandatory</Typography>
              </div>
            }
            {
              type !== DOCUMENTS.FIELDS.DIVIDER.ID && type !== DOCUMENTS.FIELDS.HEADING.ID && type !== 'unselected' &&
              <div style={{marginTop:'10px',marginBottom:'10px',width:'100%'}}>
                <Typography variant="h6">Page Width</Typography>
                <Typography variant="body2">Set the render width of the element on the page, this is just for styling the documents on the Web and for Print Outs.  1/3rd, 2/3rds or the full row of width, mobile layouts are all full row.</Typography>
                <Paper style={{padding:'5px'}}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid xs={4} onClick={() => setWidth(4)} item>
                          <Paper className={width >= 4 ? classes.paperFill : classes.paper}>
                            <Typography variant="h6">1/3rd</Typography>
                          </Paper>
                        </Grid>
                        <Grid xs={4} onClick={() => setWidth(8)} item>
                          <Paper className={width >= 8 ? classes.paperFill : classes.paper}>
                            <Typography variant="h6">2/3rd</Typography>
                          </Paper>
                        </Grid>
                        <Grid xs={4} onClick={() => setWidth(12)} item>
                          <Paper className={width >= 12 ? classes.paperFill : classes.paper}>
                            <Typography variant="h6">3/3rd</Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            }
            {
              showOptions.includes(type) && props.doc && props.doc.draft &&
              <>
                <Typography variant="body2">Add New Option:</Typography>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <TextField
                    variant="outlined"
                    error={optionErrors.name}
                    required
                    size="small"
                    id="name"
                    label="Name"
                    name="name"
                    value={option.name}
                    onChange={setOption}
                    margin="normal"
                    style={{marginTop:'6px',marginRight:'10px',minWidth:'350px'}}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    id="score"
                    label="Score"
                    name="score"
                    value={option.score}
                    onChange={(e) => {
                      var copy = {...e};
                      copy.target.value = copy.target.value.replace(/\D/g, '');
                      setOption(copy);
                    }}
                    margin="normal"
                    style={{marginTop:'6px',marginRight:'10px',width:'75px'}}
                  />
                  <FormControl>
                    <InputLabel id="color-label">Color</InputLabel>
                    <Select
                      id="color"
                      labelId="color-label"
                      value={optionColor}
                      onChange={(e) => setOptionColor(e.target.value)}
                      style={{width:'100px',marginRight:'10px',backgroundColor:optionColor}}
                    >
                      <MenuItem value="transparent">None</MenuItem>
                      <MenuItem value="#e5341a" style={{backgroundColor:'#e5341a',height:'36px'}}></MenuItem>
                      <MenuItem value="#30cf52" style={{backgroundColor:'#30cf52',height:'36px'}}></MenuItem>
                      <MenuItem value="#ebda14" style={{backgroundColor:'#ebda14',height:'36px'}}></MenuItem>
                      <MenuItem value="#4f2fe7" style={{backgroundColor:'#4f2fe7',height:'36px'}}></MenuItem>
                      <MenuItem value="#e40ed8" style={{backgroundColor:'#e40ed8',height:'36px'}}></MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" color="primary" onClick={() => addOption({
                    color : optionColor,
                    ...option
                  })}>Add</Button>
                </div>
              </>
            }
            {
              options.length > 0 &&
              <>
                <Typography variant="body2">Options:</Typography>
                <List component="nav" aria-label="secondary mailbox folders">
                {
                  options.map((opt, i) => {
                    return (
                      <ListItem alignItems="flex-start" key={'opt-' + i} disableRipple style={{alignItems:'center'}} button>
                        <ListItemIcon style={{marginRight:'10px'}}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size={isMobile ? "small" : "medium"}
                            onClick={() => remove(i)}
                            className={classes.deleteButton}>
                            <DeleteForeverIcon />
                          </Button>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <TextField
                              variant="outlined"
                              required
                              size="small"
                              id="name"
                              label="Name"
                              name="name"
                              fullWidth
                              value={opt.name}
                              onChange={(e) => {
                                var copy = [...options];
                                copy[i].name = e.target.value;
                                setOptions(copy);
                              }}
                              margin="normal"
                              style={{marginTop:'6px',marginRight:'10px'}}
                            />
                          }
                          secondary={
                            <TextField
                              variant="outlined"
                              size="small"
                              id="score"
                              label="Score"
                              name="score"
                              value={opt.score}
                              onChange={(e) => {
                                var copy = [...options];
                                copy[i].score = e.target.value;
                                setOptions(copy);
                              }}
                              margin="normal"
                              style={{marginTop:'6px',marginRight:'10px',width:'75px'}}
                            />
                        } />
                        <ListItemIcon style={{marginRight:'10px',marginLeft:'10px',alignItems:'center'}}>
                          {
                            opt.color &&
                            <FormControl>
                              <InputLabel id="color-label">Color</InputLabel>
                              <Select
                                id="color"
                                labelId="color-label"
                                value={opt.color}
                                onChange={(e) => {
                                  var copy = [...options];
                                  copy[i].color = e.target.value;
                                  setOptions(copy);
                                }}
                                style={{width:'100px',marginRight:'10px',backgroundColor:opt.color}}
                              >
                                <MenuItem value="transparent">None</MenuItem>
                                <MenuItem value="#e5341a" style={{backgroundColor:'#e5341a',height:'36px'}}></MenuItem>
                                <MenuItem value="#30cf52" style={{backgroundColor:'#30cf52',height:'36px'}}></MenuItem>
                                <MenuItem value="#ebda14" style={{backgroundColor:'#ebda14',height:'36px'}}></MenuItem>
                                <MenuItem value="#4f2fe7" style={{backgroundColor:'#4f2fe7',height:'36px'}}></MenuItem>
                                <MenuItem value="#e40ed8" style={{backgroundColor:'#e40ed8',height:'36px'}}></MenuItem>
                              </Select>
                            </FormControl>
                          }
                          <div></div>
                          <IconButton onClick={() => {
                            if (opt.observation && props.doc.draft) {
                              removeOptionObservation(i);
                            } else if (props.doc.draft) {
                              setOptionObservation(i);
                            }
                          }}>
                          {
                            opt.observation &&
                            <VisibilityIcon color="primary" />
                          }
                          {
                            !opt.observation && props.doc.draft &&
                            <VisibilityOffIcon />
                          }
                          </IconButton>
                          {
                            opt.alert &&
                            <>
                            <IconButton
                              variant="outlined"
                              color="primary"
                              size={isMobile ? "small" : "medium"}
                              onClick={() => props.doc.draft ? removeOptionAlert(i) : {}}
                              className={classes.deleteButton}>
                                <Tooltip title={<>
                                    <Typography>{opt.alert.title}</Typography>
                                    <Typography>{opt.alert.description}</Typography>
                                  </>}>
                                  <MessageIcon color="primary" />
                                </Tooltip>
                              </IconButton>
                            </>
                          }
                          {
                            !opt.alert && props.doc.draft &&
                            <IconButton
                              variant="outlined"
                              size={isMobile ? "small" : "medium"}
                              onClick={() => setOptionAlert(i)}
                              className={classes.deleteButton}>
                              <ChatBubbleIcon />
                            </IconButton>
                          }
                        </ListItemIcon>
                      </ListItem>
                    )
                  })
                }
                </List>
              </>
            }
            {
              props.doc && props.doc.draft &&
              <Button
                variant="contained"
                color="primary"
                style={{marginTop:'20px'}}
                onClick={() => addField()}>
                {
                  !updating && !props.field &&
                  "Add Field"
                }
                {
                  !updating && props.field &&
                  "Update Field"
                }
                {
                  updating &&
                  <div>
                    <CircularProgress size={10} />
                  </div>
                }
              </Button>
            }
          </div>
        </Grid>
        <Grid xs={12} sm={3} item>
          <div style={{display:'flex',flexDirection:'column',margin:'10px',alignItems:'center'}}>
            <RadioGroup row aria-label="position" name="position" value={operatingSystem} onChange={e => setOperatingSystem(e.target.value)} defaultValue="ios">
              <FormControlLabel
                value="ios"
                control={<Radio color="primary" />}
                label="iOS"
                labelPlacement="top"
              />
              <FormControlLabel
                value="android"
                control={<Radio color="primary" />}
                label="Android"
                labelPlacement="top"
              />
            </RadioGroup>
            <img width="200" src={getImage(type,operatingSystem)} alt="Preview" />
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <>
            <Typography variant="body2">Would you like to add a pop up note if this option is selected?  This can be a general safety tip or reminder to the user filling out the document.</Typography>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',marginTop:'10px'}}>
              <div style={{width:'80%',padding:'10px'}}>
                <TextField
                  label="Title"
                  variant="outlined"
                  required
                  error={tipErrors.title}
                  fullWidth
                  size="small"
                  id={'tip-title'}
                  value={tipTitle}
                  inputProps={{maxLength:50}}
                  onChange={e => setTipTitle(e.target.value)}
                  margin="none"
                />
              </div>
              <div style={{width:'80%',padding:'10px'}}>
                <TextField
                  label="Description"
                  variant="outlined"
                  required
                  error={tipErrors.description}
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  id={'tip-description'}
                  value={tipDescription}
                  inputProps={{maxLength:300}}
                  onChange={e => setTipDescription(e.target.value)}
                  margin="none"
                />
              </div>
              <Button variant="contained" color="primary" onClick={() => addOptionAlert()}>Add Alert</Button>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeOption()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={observationOpen}
        onClose={() => setObservationOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <>
            <Typography variant="body2">Would you like to add an automatic safety observation created?</Typography>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',marginTop:'10px'}}>
              <Button variant="contained" color="primary" onClick={() => addOptionObservation()}>Add Required Observation</Button>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeObservationOption()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
