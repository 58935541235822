import React, { useEffect, useState } from "react";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//
// }));

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ProfilePicture from "../../../../User/ProfilePicture";
import Role from "../../../../User/Role";
import Observation from "../Observations/Observation";

import { Storage } from "aws-amplify";

export default (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const classes = useStyles();

  const [url, setUrl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (props.observation.photos && props.observation.photos.length > 0) {
      Storage.get(props.observation.photos[0].key, {
        level: "protected",
        identityId : props.observation.photos[0].identityId
      }).then(res => {
        if (mounted) {
          setUrl(res);
        }
      });
    }
    return () => mounted = false;
  }, [props.observation])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card style={{margin:'4px',border:'2px solid ' + theme.palette.primary.main}} raised>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia
            component="img"
            height="140"
            image={url ? url : './placeholder-image.jpg'}
            title="First image of photos"
          />
          <CardContent>
            <div>
              <Typography gutterBottom variant="body2">
                Reported By:
              </Typography>
              <ListItem>
                <ListItemAvatar>
                  {
                    props.observation.anonymous &&
                    <ProfilePicture />
                  }
                  {
                    !props.observation.anonymous &&
                    <ProfilePicture user={props.user} employee={props.employees[props.observation.userId]} />
                  }
                </ListItemAvatar>
                <ListItemText primary={props.observation.anonymous ? 'Anonymous' : props.employees[props.observation.userId].name} secondary={props.observation.anonymous ? '' : <Role user={props.employees[props.observation.userId]}/>} />
              </ListItem>
            </div>

            <Typography variant="body2" color="textSecondary" component="p">
              {props.observation.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <Observation observation={props.observation} employees={props.employees} user={props.user} project={props.project} onComplete={() => {
            setOpen(false);
            props.reload();
          }}  />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
