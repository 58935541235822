import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function Heading(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);


  return (
    <Grid xs={12} item ref={targetRef}>
      <Typography variant={"subtitle2"} style={{fontWeight:'bold',width:'calc(100%-20px)',backgroundColor:'#cdcdcd',borderTop:'1px solid black',paddingLeft:'10px',borderBottom:'1px solid black',fontSize: props.print ? '18px' : '20px'}}>{props.field.name}</Typography>
    </Grid>
  );
}
