import React, { useState } from "react";

import Login from './Login';
import Signup from './Signup';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  container: {
    width : '40vw',
    maxWidth : '500px',
    minWidth : '300px',
    margin : '0 auto',
    marginTop : '64px',
    paddingTop : '15vh',
    textAlign:'right'
  }
}));

export default function LoginForm(props) {
  var location = useLocation();
  const params = queryString.parse(location.search);

  const classes = useStyles();
  const [login, setLogin] = useState(params.trial ? false : true);

  return (
    <div className={classes.container}>
      {
        login &&
        <>
          <Login setLogin={setLogin} {...props} />
        </>
      }
      {
        !login &&
        <>
          <Signup setLogin={setLogin} trial={params.trial} {...props} />
        </>
      }
    </div>
  );
}
