import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { PROJECT } from '../config/localStorage';
import { Storage } from "aws-amplify";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  print : {
    '@media print': {
      display: 'block',
      pageBreakAfter: 'always'
    },
    width:'8.5in',
    height:'11in',
    margin:'0 0',
    display:'flex',
    flexDirection:'column'
  }
}));

export default function PrintDocument(props) {
  const classes = useStyles();
  const theme = useTheme();

  const GlobalCss = withStyles({
    "@global": {
      "span": {
        fontSize: '10px'
      },
      "span.MuiTypography-body1" : {
        fontSize: '10px'
      },
      "p.MuiTypography-body1" : {
        fontSize: '10px'
      },
      'img' : {
        breakInside: 'avoid'
      }
    }
  })(() => null);

  const [project, setProject] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const project = JSON.parse(localStorage.getItem(PROJECT));
      if (project) {
        setProject(project);
      }
    } catch (e) {
      // Do nothing on errors
    }
  }

  useEffect(() => {
    if (props.company) {
      Storage.get(props.company.details.id + '/logo.png').then((url) => {
        fetch(url).then((result) => { //fetch the URL
          if(result.status === 200) { //if the file exists
            setLogoUrl(url);
          } else {
            setLogoUrl('NONE');
          }
        });
      }).catch(res => {
        console.log(res);
      });
    }
  }, [props.company]);

  return (
    <div className={classes.print}>
      <GlobalCss />
      {
        project &&
        <>
          <div style={{height:'90px',backgroundColor:theme.palette.primary.main,display:'flex',alignItems:'center'}}>
            <img style={{marginLeft:'10px'}} src={'./budee-logo-green.png'} height="70px" alt="Budee Solutions" />
          </div>

          <div style={{display:'flex',flexDirection:'row',margin:'20px',padding:'10px',border:'1px solid ' + theme.palette.secondary.main,borderRadius:10}}>
            <div><img style={{width:'150px'}} src={props.getBaseUrl() + '/qr/appstore.png'} alt="iOS Appstore" /></div>
            <div style={{flex:1,padding:'20px'}}>
              <Typography variant="h6">Step 1 - Download the Mobile App</Typography>
              <Typography variant="body2">1.  Scan the QR code for your appstore of preference from your mobile device</Typography>
              <Typography variant="body2">2.  Download and install the app</Typography>
            </div>
            <div><img style={{width:'150px'}} src={props.getBaseUrl() + '/qr/playstore.png'} alt="Android Play Store" /></div>
          </div>
          {
            logoUrl && logoUrl !== 'NONE' &&
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
              <img style={{maxHeight:'150px'}} src={logoUrl} alt={props.company ? props.company.name : 'No logo'} />
            </div>
          }
          <div style={{display:'flex',flexDirection:'row',margin:'20px',padding:'10px',border:'1px solid ' + theme.palette.secondary.main,borderRadius:10}}>
            <div><img style={{width:'150px'}} src={props.getBaseUrl() + '/qr/login-qr.png'} alt="Login QR" /></div>
            <div style={{flex:1,padding:'20px'}}>
              <Typography variant="h6">Step 2 - From the Mobile App</Typography>
              <Typography variant="body2">1.  After picking your Region/Language, on the Login screen</Typography>
              <Typography variant="body2">2.  Tap the QR Code Sign-Up</Typography>
              <Typography variant="body2">3.  Scan the code to the right of this text</Typography>
              <Typography variant="body2">4.  Provide an email or phone with your name</Typography>
              <Typography variant="body2">5.  System will log you in and ask for a new password</Typography>
            </div>
            <div><img src={project.qr} alt={project.name} /></div>
          </div>
          <div style={{display:'flex',flexDirection:'column',margin:'20px',padding:'10px',border:'1px solid ' + theme.palette.secondary.main,borderRadius:10}}>
            <Typography variant="h6">Want to Learn More?</Typography>
            <Typography variant="body2">Visit <Link style={{fontWeight:'bold'}} href="https://training.budeesolutions.com">training.budeesolutions.com</Link> for a full product guide.</Typography>
          </div>
        </>
      }
      {
        !project &&
        <div style={{width:'100%',height: window.innerHeight + 'px',alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column'}}>
          <Typography variant="h2">No Project Found.</Typography>
          <Typography variant="body2">Try going back to your Invite Users tab under Manage Personnel and click the button again to retry.</Typography>
        </div>
      }
    </div>
  );
}
