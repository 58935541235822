import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Storage } from "aws-amplify";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { makeStyles } from '@material-ui/core/styles';

import ProfilePicture from '../../../../User/ProfilePicture';
import Role from '../../../../User/Role';

const { formatDateTime, formatDate } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  submission : {
    border:'1px solid ' + theme.palette.primary.main,
    borderRadius:'10px',
    margin:'2px',
    cursor:'pointer',
    '&:hover': {
      border:'1px solid ' + theme.palette.accentColor.main,
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  web : {
    borderTop:'1px solid #cccccc',
    borderBottom:'1px solid #cccccc',
  },
  print : {
    border:'1px solid #cccccc',
    borderRadius:'10px',
    marginTop:'5px',
    marginBottom:'5px'
  },
  row : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'100%'
  },
  th : {
    fontSize : '12px'
  },
  td : {
    fontSize : '10px',
    textAlign : 'center'
  },
  subtitle: {
    marginRight:'10px',
    fontWeight:'bold',
    width:'100px'
  },
  descRow : {
    marginTop:'10px',
    marginBottom:'10px',
    display:'flex',
    flexDirection:'row'
  }
}));

export default function ObservationHeader(props) {
  const classes = useStyles();

  const [imageUrls, setImageUrls] = useState([]);
  const [dimensions, setDimensions] = useState({ width:0, height:0 });
  const targetRef = useRef();

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [imageUrls, props.print]);
  useEffect(() => {
    if (props.print && props.updateDimensions) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);

  useEffect(() => {
    if (props.observation.photos && props.observation.photos.length > 0) {
      loadImages(props.observation.photos);
    }
  }, [props.observation]);

  const loadImages = async (images) => {
    var imgUrls = [];
    for (const img of images) {
      imgUrls.push(await Storage.get(img.key, {
        level: "protected",
        identityId : img.identityId
      }));
    }
    setImageUrls(imgUrls);
  }

  const calculateDimensions = () => {
    setDimensions({
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight
    });
  }

  return (
    <Grid container className={props.print ? classes.print : classes.web} style={{backgroundColor:props.index && props.index % 2 ? '#cdcdcd' : '#efefef'}}>
      {
        !props.print && props.observation &&
        <Grid xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',padding:'5px'}} item>
          <div style={{marginRight:'20px'}}>
            {
              !props.observation.status &&
              <AssignmentTurnedInIcon color="primary" />
            }
            {
              props.observation.status &&
              <AssignmentLateIcon color="error" />
            }
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'10px'}}>
            <Typography variant="body2" style={{marginRight:'10px',fontWeight:'bold'}}>
            {
              !props.observation.anonymous && "Reported By:"
            }
            </Typography>
            {
              props.observation.anonymous &&
              <ProfilePicture />
            }
            {
              !props.observation.anonymous &&
              <ProfilePicture user={props.user} employee={props.employees[props.observation.userId]} />
            }
            <div style={{display:'flex',flexDirection:'column'}}>
              <Typography variant="body2" style={{marginLeft:'10px'}}>
              {
                props.observation.anonymous && "Anonymous"
              }
              {
                !props.observation.anonymous && props.employees[props.observation.userId].name
              }
              </Typography>
              {
                !props.observation.anonymous &&
                <div style={{marginLeft:'5px'}}>
                  <Role user={props.employees[props.observation.userId]} />
                </div>
              }
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'10px'}}>
            <Typography variant="body2" style={{marginRight:'10px',fontWeight:'bold'}}>
              Assigned To:
            </Typography>
            <ProfilePicture user={props.user} employee={props.employees[props.observation.assigned]} />
            <div style={{display:'flex',flexDirection:'column'}}>
              <Typography variant="body2" style={{marginLeft:'10px'}}>
                {
                  props.observation.assigned && props.employees[props.observation.assigned].name
                }
                {
                  !props.observation.assigned && "Unassigned"
                }
              </Typography>
              {
                props.observation.assigned &&
                <div style={{marginLeft:'5px'}}>
                  <Role user={props.employees[props.observation.assigned]} />
                </div>
              }
            </div>
          </div>
          <div>
            <Typography variant="body2">
            {
              props.observation.description && props.observation.description.length > 50 ? props.observation.description.substring(0,50) + '...' : props.observation.description
            }
            </Typography>
          </div>
        </Grid>
      }
      {
        props.print &&
        <>
          <Grid xs={12} item>
            <Typography variant="body2" style={{width:'100%',padding:'5px',textDecoration:'underline'}}>Description</Typography>
            <Typography variant="body2" style={{width:'100%',padding:'5px'}}>{ props.observation.description }</Typography>
            <table style={{width:'100%'}}>
              <tr>
                <th className={classes.th}>Created</th>
                <th className={classes.th}>Reported By</th>
                <th className={classes.th}>Assigned</th>
                <th className={classes.th}>Status</th>
                <th className={classes.th}>Fix By</th>
              </tr>
              <tr>
                <td className={classes.td}>{formatDateTime(new Date(props.observation.date))}</td>
                <td className={classes.td}>{ props.observation.anonymous ? 'Anonymous' : props.employees[props.observation.userId].name }</td>
                <td className={classes.td}>{ props.employeess && props.employees.hasOwnProperty(props.observation.assigned) ? props.employees[props.observation.assigned].name : '-' }</td>
                <td className={classes.td}>{ props.observation.status ? 'Open' : 'Resolved' }</td>
                <td className={classes.td}>{ props.observation.fixBy ? formatDate(new Date(props.observation.fixBy)) : '-' }</td>
              </tr>
            </table>
          </Grid>
          {
            imageUrls && imageUrls.length > 0 &&
            <Grid xs={12} item ref={targetRef}>
              <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'5px 0',width:'100%',justifyContent:'flex-start'}}>
                {
                  imageUrls.map((url, i) => {
                    return (
                      <React.Fragment key={'image-' + i}>
                        {
                          props.print &&
                          <div style={{width:'345px',height:'462px'}}>
                            <img width="345px" src={url} alt={'picture-' + i} style={{margin:'2px'}} onLoad={() => calculateDimensions()} />
                          </div>
                        }
                      </React.Fragment>
                    )
                  })
                }
              </div>
            </Grid>
          }
        </>
      }

    </Grid>
  );
}
