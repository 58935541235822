import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import config from "./config";
import 'react-image-lightbox/style.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  API: {
    endpoints: [
      {
        name: "budeeBackend",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  },
  Storage : {
    bucket : config.s3.BUCKET,
    region : config.s3.REGION
  },
  aws_appsync_graphqlEndpoint: config.appSync.URL,
  aws_appsync_region: config.appSync.REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
