import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { useTheme } from '@material-ui/core/styles';
import RewardForm from './Rewards/RewardForm';
import { toDate } from '../../../libs/util';

import RewardDetail from './Rewards/RewardDetail';
import { isMobile } from "react-device-detect";

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  certificationList : {
    width : '500px'
  },
  certificationRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  },
  certDates : {
    textAlign:'right'
  }
}));

const columns = isMobile ? [
  { field: 'id', hide : true },
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'start', headerName: 'Start', flex: 1 },
  { field: 'end', headerName: 'End', flex: 1 }
] : [
  { field: 'id', hide : true },
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
  { field: 'start', headerName: 'Start', flex: 1 },
  { field: 'end', headerName: 'End', flex: 1 },
  { field: 'createdBy', headerName : 'Created By', flex: 1 },
  { field: 'participating', headerName: 'Participating', flex: 1, renderCell : ({value}) => {
    var title = '';
    if (value.roles.length === 0 && value.users.length === 0) {
      title = 'Project Wide';
    } else {
      for (const role of value.roles) {
        title += role + ' ';
      }
      for (const name of value.users) {
        title += name + ' ';
      }
    }
    return (
      <Tooltip style={{marginLeft:'15px'}} title={title}>
        <PeopleAltIcon color="primary" />
      </Tooltip>
    );
  }}
];

function doScrolling(elementY, duration) {
  var startingY = window.pageYOffset;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

export default function Rewards(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rewards, setRewards] = useState(null);
  const [reward, setReward] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [type, setType] = useState('current');
  const rewardFormRef = useRef(null);

  useEffect(() => {
    loadRewards(props.project.details.id);
  }, [props.project.details.id]);

  useEffect(() => {
    var mounted = true;
    var grid = [];
    if (rewards && rewards.length > 0) {
      for (const r of rewards) {
        var startDate = toDate(r.start);
        var endDate = toDate(r.end);
        endDate.setHours(23);
        var today = new Date();
        var participants = {
          roles : r.roles,
          users : r.users.map((userId) => props.employees && props.employees.hasOwnProperty(userId) ? props.employees[userId].name : '')
        }
        var item = {
          id : r.id,
          title : r.title,
          description : r.description,
          start : r.start,
          end : r.end,
          createdBy : props.employees && props.employees.hasOwnProperty(r.createdBy) ? props.employees[r.createdBy].name : '',
          participating : participants
        }
        if (type === 'past' && endDate.getTime() < today.getTime()) {
          grid.push(item);
        } else if (type === 'current' && (startDate.getTime() < today.getTime() && today.getTime() < endDate.getTime())) {
          grid.push(item);
        } else if (type === 'upcoming' && startDate.getTime() > today.getTime()) {
          grid.push(item);
        } else if (type === 'all') {
          grid.push(item);
        }
      }
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [rewards, type, props.employees]);

  const loadRewards = async (projectId, t) => {
    setLoading(true);
    return API.get("budeeBackend", "project/reward?projectId=" + projectId, {}).then(res => {
      setRewards(res);
      setLoading(false);
    });
  };

  const handleDelete = async (projectId, rewardId) => {
    await API.del("budeeBackend", "project/reward", {
      body : {
        projectId : projectId,
        rewardId : rewardId
      }
    }).then(res => {
      setLoading(true);
      loadRewards(projectId, type);
    });
    setDialogOpen(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (showAdd) {
      scrollToRef(rewardFormRef);
    }
  }, [showAdd]);

  const handleClickOpen = async (event) => {
    for (const r of rewards) {
      if (r.id === event.row.id) {
        setReward(r);
      }
    }
    setDialogOpen(true);
  };

  const showAddForm = async (show) => {
    setShowAdd(!show);
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6" gutterBottom>Rewards</Typography>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loading && rewards && rewards.length === 0 &&
            <>
              <div style={{width:'100%',textAlign:'center'}}>
                <Typography variant="body2">Currently No Rewards.</Typography>
              </div>
              <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
              <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/tYssOJASua4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                </div>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <Typography variant="h6">Learn About Rewards</Typography>
                  <Typography variant="body2">Watch this video to learn more about how rewards work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                  <ul>
                    <li>How to Add New Reward</li>
                    <li>How Rewards are Scored</li>
                    <li>Mobile View for Employees of Rewards</li>
                    <li>Viewing Draw After Reward Completed</li>
                  </ul>
                  <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/observations?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                </div>
              </div>
            </>
          }
          {
            !loading && rewards && rewards.length > 0 &&
            <>
              {
                !loading && rows && rows.length === 0 &&
                <p>Currently you don't have any {type} rewards.</p>
              }
              {
                !loading && rows && rows.length > 0 &&
                <>
                  <p>Rewards History:</p>
                  <div style={{height:'400px',marginBottom:'5px'}}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      density="compact"
                      autoPageSize={true}
                      sortModel={[{field: 'id', sort: 'desc'}]}
                      onRowClick={(e) => handleClickOpen(e)}
                      className={classes.certificationRow}
                    />
                    <Dialog
                      fullScreen={fullScreen}
                      maxWidth="md"
                      fullWidth={true}
                      open={dialogOpen}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title"></DialogTitle>
                      <DialogContent>
                        <RewardDetail project={props.project} reward={reward} employees={props.employees} user={props.user} />
                      </DialogContent>
                      <DialogActions>
                        {
                          props.project.status === USER_STATUS.SUPERADMIN &&
                          <Button onClick={() => handleDelete(props.project.details.id, reward.id)} color="secondary">
                            Delete
                          </Button>
                        }
                        <Button onClick={handleClose} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </>
              }
              <FormControl component="fieldset">
                <FormLabel component="legend">Show Rewards:</FormLabel>
                <RadioGroup row aria-label="type" name="type" value={type} onChange={(e) => setType(e.target.value)}>
                  <FormControlLabel value="past" control={<Radio />} label="Past" />
                  <FormControlLabel value="current" control={<Radio />} label="Current" />
                  <FormControlLabel value="upcoming" control={<Radio />} label="Upcoming" />
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                </RadioGroup>
              </FormControl>
            </>
          }
          <div ref={rewardFormRef} style={{display:'flex',alignItems:'center'}}>
          {
            props.project.status === USER_STATUS.SUPERADMIN &&
            <>
              <Button onClick={() => showAddForm(showAdd)} style={{margin:'10px'}} color="primary" variant="outlined">
              {
                !showAdd &&
                "Add New Reward"
              }
              {
                showAdd &&
                "Cancel New Reward"
              }
              </Button>
            </>
          }
          </div>
          {
            showAdd &&
            <RewardForm project={props.project} employees={props.employees} user={props.user} roles={props.roles} loadRewards={loadRewards} close={() => setShowAdd(false)} getBaseUrl={props.getBaseUrl} />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}
