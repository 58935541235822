import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import { useFormFields, useEffectWhen } from "../../../libs/hooks";
import { useTheme } from '@material-ui/core/styles';
import queryString from 'query-string';

import AppBar from '@material-ui/core/AppBar';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { isMobile } from "react-device-detect";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  formContainer : {
    width : '400px',
    [theme.breakpoints.down('sm')]: {
      width : '100%'
    }
  },
  deleteButton : {
    [theme.breakpoints.down('sm')]: {
      padding : '0px'
    }
  },
  listItemText : {
    fontSize : '1.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize : '0.8em'
    }
  }
}));

function setTab(params) {
  if (params) {
    switch(params.type) {
      case 'roles':
        return 0;
      case 'departments':
        return 1;
      case 'contractors':
        return 2;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function setQuery(val) {
  if (val) {
    switch(val) {
      case 0:
        return 'roles';
      case 1:
        return 'departments';
      case 2:
        return 'contractors';
      default:
        return 'roles';
    }
  } else {
    return 'roles';
  }
}

export default function Organization(props) {
  const classes = useStyles();
  const theme = useTheme();
  var parsed = queryString.parse(props.location.search);

  const [project, setProject] = useState(props.project);
  const [updating, setUpdating] = useState(false);
  const [roleErrors, setRoleErrors] = useState({
    role : false
  });
  const [departmentErrors, setDepartmentErrors] = useState({
    department : false
  });
  const [contractorErrors, setContractorErrors] = useState({
    department : false
  });
  const [form, setForm] = useFormFields({
    role : "",
    department : "",
    contractor : ""
  });
  const [value, setValue] = React.useState(setTab(parsed));

  const handleChange = (event, newValue) => {
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify({type:setQuery(newValue)})
    });
    setValue(newValue);
  };

  useEffectWhen(() => {
    if (project) {
      props.loadRoles(project.details.id);
      props.loadDepartments(project.details.id);
      props.loadContractors(project.details.id);
    }
  }, [project, props.loadRoles, props.loadDepartments, props.loadContractors], [project]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  const deleteRole = (id, name) => {
    if (!id) {
      return;
    }
    setUpdating(true);
    API.del("budeeBackend", "roles/", { body : { name : name, projectId : id } }).then((res) => {
      setUpdating(false);
      props.loadRoles(id);
    }).catch(err => {
      setUpdating(false);
    });
  }

  const addRole = (id) => {
    if (!id) {
      return;
    }
    const checkRole = {
      role : form.role === "" ? true : false,
    };
    setRoleErrors(checkRole);
    if (!Object.values(checkRole).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "roles/", { body : { name : form.role, projectId : id } }).then((res) => {
        setUpdating(false);
        setForm({target:{id:'role',value:''}});
        props.loadRoles(id);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  const deleteDepartment = (id, name) => {
    if (!id) {
      return;
    }
    setUpdating(true);
    API.del("budeeBackend", "departments/", { body : { name : name, projectId : id } }).then((res) => {
      setUpdating(false);
      props.loadDepartments(id);
    }).catch(err => {
      setUpdating(false);
    });
  }

  const addDepartment = (id) => {
    if (!id) {
      return;
    }
    const checkDepartment = {
      department : form.department === "" ? true : false,
    };
    setDepartmentErrors(checkDepartment);
    if (!Object.values(checkDepartment).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "departments/", { body : { name : form.department, projectId : id } }).then((res) => {
        setUpdating(false);
        props.loadDepartments(id);
        setForm({target:{id:'department',value:''}});
      }).catch(err => {
        console.error(err);
        setUpdating(false);
      });
    }
  }

  const deleteContractors = (id, name) => {
    if (!id) {
      return;
    }
    setUpdating(true);
    API.del("budeeBackend", "contractors/", { body : { name : name, projectId : id } }).then((res) => {
      setUpdating(false);
      props.loadContractors(id);
    }).catch(err => {
      setUpdating(false);
    });
  }

  const addContractor = (id) => {
    if (!id) {
      return;
    }
    const checkContractor = {
      contractor : form.contractor === "" ? true : false,
    };
    setContractorErrors(checkContractor);
    if (!Object.values(checkContractor).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "contractors/", { body : { name : form.contractor, projectId : id } }).then((res) => {
        setUpdating(false);
        props.loadContractors(id);
        setForm({target:{id:'contractor',value:''}});
      }).catch(err => {
        console.error(err);
        setUpdating(false);
      });
    }
  }

  return (
    <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="organization management">
          <Tab label="Manage Roles" {...a11yProps(0)} />
          <Tab label="Manage Departments" {...a11yProps(1)} />
          <Tab label="Manage Contractors" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel style={{backgroundColor:'#FFFFFF'}} value={value} index={0}>
        <Grid container spacing={6}>
          <Grid xs={12} sm={6} item>
            <Paper>
              <Card variant="outlined">
                <CardContent>
                  <p>Add a role:</p>
                  <TextField
                    variant="outlined"
                    error={roleErrors.role}
                    required
                    fullWidth
                    size="small"
                    id="role"
                    label="Name of Role"
                    name="role"
                    autoComplete="role"
                    autoFocus
                    value={form.role}
                    onChange={setForm}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addRole(project ? project.details.id : null)}
                  >
                    Add Role
                  </Button>
                  {
                    updating &&
                    <div>
                      <CircularProgress size={80} />
                    </div>
                  }
                  <div>
                  {
                    props.roles.length === 0 &&
                    <p>Currently your project does not have any roles</p>
                  }
                  {
                    props.roles.length > 0 &&
                    <>
                      <p>Roles:</p>
                      <List>
                        {
                          props.roles.map((role) => {
                            return (
                              <ListItem key={role.name} button>
                                {
                                  !isMobile &&
                                  <ListItemIcon>
                                    <AssignmentIndIcon />
                                  </ListItemIcon>
                                }
                                <ListItemText classes={{primary : classes.listItemText}} primary={role.name} />
                                <ListItemSecondaryAction>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size={isMobile ? "small" : "medium"}
                                    onClick={() => deleteRole(project ? project.details.id : null, role.name)}
                                    className={classes.deleteButton}>
                                    <DeleteForeverIcon />
                                  </Button>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </>
                  }
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel style={{backgroundColor:'#FFFFFF'}} value={value} index={1}>
        <Grid container spacing={6}>
          <Grid xs={12} sm={6} item>
            <Paper>
              <Card variant="outlined">
                <CardContent>
                  <p>Add a department:</p>
                  <TextField
                    variant="outlined"
                    error={departmentErrors.department}
                    required
                    fullWidth
                    size="small"
                    id="department"
                    label="Name of Department"
                    name="department"
                    autoFocus
                    value={form.department}
                    onChange={setForm}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addDepartment(project ? project.details.id : null)}
                  >
                    Add Department
                  </Button>
                  {
                    updating &&
                    <div>
                      <CircularProgress size={80} />
                    </div>
                  }
                  <div>
                  {
                    props.departments.length === 0 &&
                    <p>Currently your project does not have any departments.</p>
                  }
                  {
                    props.departments.length > 0 &&
                    <>
                      <p>Departments:</p>
                      <List>
                        {
                          props.departments.map((department) => {
                            return (
                              <ListItem key={department.name} button>
                                {
                                  !isMobile &&
                                  <ListItemIcon>
                                    <AssignmentIndIcon />
                                  </ListItemIcon>
                                }
                                <ListItemText classes={{primary : classes.listItemText}} primary={department.name} />
                                <ListItemSecondaryAction>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size={isMobile ? "small" : "medium"}
                                    onClick={() => deleteDepartment(project ? project.details.id : null, department.name)}
                                    className={classes.deleteButton}>
                                    <DeleteForeverIcon />
                                  </Button>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </>
                  }
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel style={{backgroundColor:'#FFFFFF'}} value={value} index={2}>
        <Grid container spacing={6}>
          <Grid xs={12} sm={6} item>
            <Paper>
              <Card variant="outlined">
                <CardContent>
                  <p>Add a contractor:</p>
                  <TextField
                    variant="outlined"
                    error={contractorErrors.contractor}
                    required
                    fullWidth
                    size="small"
                    id="contractor"
                    label="Name of Contractor"
                    name="contractor"
                    autoFocus
                    value={form.contractor}
                    onChange={setForm}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addContractor(project ? project.details.id : null)}
                  >
                    Add Contractor
                  </Button>
                  {
                    updating &&
                    <div>
                      <CircularProgress size={80} />
                    </div>
                  }
                  <div>
                  {
                    props.contractors.length === 0 &&
                    <p>Currently your project does not have any contractors.</p>
                  }
                  {
                    props.contractors.length > 0 &&
                    <>
                      <p>Contractors:</p>
                      <List>
                        {
                          props.contractors.map((contractor) => {
                            return (
                              <ListItem key={contractor.name} button>
                                {
                                  !isMobile &&
                                  <ListItemIcon>
                                    <AssignmentIndIcon />
                                  </ListItemIcon>
                                }
                                <ListItemText classes={{primary : classes.listItemText}} primary={contractor.name} />
                                <ListItemSecondaryAction>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size={isMobile ? "small" : "medium"}
                                    onClick={() => deleteContractors(project ? project.details.id : null, contractor.name)}
                                    className={classes.deleteButton}>
                                    <DeleteForeverIcon />
                                  </Button>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </>
                  }
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
    </Paper>
  );
}
