import React, { useState, useEffect } from "react";

import { COUNTRY_CODE, PROJECT } from "../../../config/localStorage";
import { phone } from 'phone';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import { useFormFields } from "../../../libs/hooks";
import { useTheme } from '@material-ui/core/styles';
import Papa from 'papaparse/papaparse';
import { useEffectWhen } from "../../../libs/hooks.js";
import ProfilePicture from "../../User/ProfilePicture";
import Manage from "./Personnel/Manage";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { isMobile } from "react-device-detect";
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

const callingCountries = require('country-data').callingCountries;
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component='div' p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  inviteList : {
    width : '450px'
  },
  root : {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container : {
    width:'100%'
  },
  paper : {
    padding : '20px'
  },
  listItemText : {
    fontSize : '1.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize : '0.8em'
    }
  },
  lineBreakListItem : {
    display : 'flex',
    flexDirection : 'column'
  },
  usersSecondary : {
    display:'flex',
    alignItems:'center'
  },
  select : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

function setTab(params) {
  if (params) {
    switch(params.type) {
      case 'manage':
        return 0;
      case 'invite':
        return 1;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function setQuery(val) {
  if (val) {
    switch(val) {
      case 0:
        return 'manage';
      case 1:
        return 'invite';
      default:
        return 'manage';
    }
  } else {
    return 'dynamic';
  }
}

function localCountryCode() {
  const countryCode = localStorage.getItem(COUNTRY_CODE);
  return countryCode && countryCode !== '' ? countryCode : "{\"alpha2\":\"US\",\"alpha3\":\"USA\",\"countryCallingCodes\":[\"+1\"],\"currencies\":[\"USD\"],\"emoji\":\"\uD83C\uDDFA\uD83C\uDDF8\",\"ioc\":\"USA\",\"languages\":[\"eng\"],\"name\":\"United States\",\"status\":\"assigned\"}";
}

export default function Personnel(props) {
  const classes = useStyles();
  const theme = useTheme();
  var parsed = queryString.parse(props.location.search);
  const [user, setUser] = useFormFields({
    email : "",
    phone : "",
    username : "",
    firstname : "",
    lastname : ""
  });
  const [addErrors, setAddErrors] = useState({
    email : false,
    phone : false,
    username : false,
    firstname : false,
    lastname : false
  });
  // const [newResults, setNewResults] = useState([]);
  const [results, setResults] = useState([]);
  const [searchParameters, setSearchParameters] = useState(null);
  const [company, setCompany] = useState(props.company);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [addResponse, setAddResponse] = useState(null);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkResponse, setBulkResponse] = useState(null);
  const [value, setValue] = React.useState(setTab(parsed));
  const [project, setProject] = useState(props.project);
  const [expanded, setExpanded] = React.useState(false);
  const [progress, setProgress] = React.useState(null);
  const [countryCode, setCountryCode] = useState(localCountryCode());

  const handleChange = (event, newValue) => {
    props.history.push({
      pathname : props.history.location.pathname,
      search : queryString.stringify({type:setQuery(newValue)})
    });
    setValue(newValue);
  };

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getPhone = (phoneNumber) => {
    const country = JSON.parse(countryCode);
    var phoneObj;
    if (phoneNumber.startsWith(country.countryCallingCodes[0])) {
      phoneObj = phone(phoneNumber,{country: country.alpha3});
    } else {
      phoneObj = phone(country.countryCallingCodes[0] + phoneNumber,{country: country.alpha3});
    }
    return phoneObj;
  }

  const add = async (projectId, user) => {
    var identifier = false;
    var phoneObj = null;
    if (user.email && user.email !== '') {
      if (emailRE.test(String(user.email).toLowerCase())) {
        identifier = true;
      }
    }
    if (user.phone && user.phone !== '') {
      phoneObj = getPhone(user.phone);
      identifier = phoneObj.isValid;
    }
    if (user.username !== '') {
      identifier = true;
    }
    const errors = {
      identifier : !identifier,
      email : (!identifier && user.email === '') || (user.email !== '' && !emailRE.test(String(user.email).toLowerCase())),
      phone : (!identifier && user.phone === '') || (phoneObj && !phoneObj.isValid),
      username : !identifier,
      firstname : user.firstname === '' ? true : false,
      lastname : user.lastname === '' ? true : false
    };
    setAddErrors(errors);
    console.log(user, errors);
    if (user.status === 'CONFIRMED' || !Object.values(errors).includes(true)) {
      setAddUserLoading(true);
      try {
        var newUser = {
          firstname : user.firstname,
          lastname : user.lastname
        }
        if (user.email && user.email !== '') {
          newUser.email = user.email;
        }
        if (user.status === 'CONFIRMED') {
          newUser.phone = user.phone;
        } else {
          if (user.phone !== '' && phoneObj && phoneObj.isValid) {
            newUser.phone = phoneObj.phoneNumber;
          }
        }
        if (user.username && user.username !== '') {
          newUser.username = user.username;
        }
        const response = await API.post('budeeBackend', 'project/employee/', {
          body : {
            users : [newUser],
            projectId : projectId
          }
        });
        setUser({target:[{id:'email',value:''},{id:'phone',value:''},{id:'username',value:''},{id:'firstname',value:''},{id:'lastname',value:''}]});
        setAddErrors({
          email : false,
          phone : false,
          username : false,
          firstname : false,
          lastname : false
        });
        setAddResponse(response);
        props.loadEmployees(projectId);
      } catch(e) {
        console.error(e);
      }
      setResults([]);
      setAddUserLoading(false);
    }
  }

  const searchUser = async (projectId, email = null, phoneNumber = null, username = null) => {
    var params = {
      projectId : projectId
    }
    if (email) {
      if (emailRE.test(String(email).toLowerCase())) {
        params.email = email;
      }
    } else if (phoneNumber) {
      const country = JSON.parse(countryCode);
      const phoneObj = phone(country.countryCallingCodes[0] + phoneNumber,{country: country.alpha3});
      if (phoneObj.isValid) {
        params.phone = phoneObj.phoneNumber;
      }
    } else if (username) {
      params.username = username;
    }
    try {
      if (Object.keys(params).length > 1) {
        setResults(await API.get('budeeBackend', 'user/search/', {
          queryStringParameters : params
        }));
        setSearchParameters(params);
      }
    } catch(e) {
      console.error(e);
    }
  }

  const uploadBulk = (projectId, file) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      bulkAddEmployee(projectId, reader.result);
    }
    if (file) {
      setBulkLoading(true);
      reader.readAsText(file);
    }
  }

  const bulkAddEmployee = async (projectId, data) => {
    const result = Papa.parse(data);
    const users = [];
    // Remove header
    for (const user of result.data) {
      if (Array.isArray(user) && user.length === 5) {
        if (user[0] === 'firstname') {
          continue;
        }
        var newUser = {
          firstname : user[0],
          lastname : user[1]
        };
        if (user[2] && user[2] !== '') {
          newUser.email = user[2];
        }
        if (user[3] && user[3] !== '') {
          newUser.phone = user[3];
        }
        if (user[4] && user[4] !== '') {
          newUser.username = user[4];
        }
        users.push(newUser);
      }
    }

    var batchCount = 0;
    var totalBatches = Math.floor(users.length / 50) + 1;
    var totalUsers = {
      existing : [],
      failed : [],
      added : []
    };
    setProgress(1);
    while (users.length > 0) {
      var batch = [];
      for (let i = 0; i < 50 && users.length - 1 >= 0; i++) {
        batch.push(users.shift());
      }
      try {
        var usersAdded = await API.post('budeeBackend', 'project/employee/', {
          body : {
            users : batch,
            projectId : projectId
          }
        });
        totalUsers.existing = totalUsers.existing.concat(usersAdded.existing);
        totalUsers.failed = totalUsers.failed.concat(usersAdded.failed);
        totalUsers.added = totalUsers.added.concat(usersAdded.added);
      } catch(e) {
        console.error(e);
      }
      batchCount++;
      setProgress((batchCount/totalBatches) * 100);
    }
    setProgress(100);
    setBulkResponse(totalUsers);
    setBulkLoading(false);
    props.loadEmployees(projectId);
  }

  useEffectWhen(() => {
    if (project) {
      props.loadEmployees(project.details.id);
    }
  }, [project, props.loadEmployees], [project]);

  useEffectWhen(() => {
    if (value === 1) {
      props.loadEmployees(project.details.id);
    }
  }, [project, props.loadEmployees, value], [value]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  const getLogin = (user) => {
    var login = '';
    if (user.email) {
      login += user.email;
    } else if (user.phone) {
      login += user.phone;
    } else if (user.username) {
      login += user.username;
    }
    return login;
  }

  return (
    <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="Manage Personnel" variant="scrollable" scrollButtons="auto">
          <Tab label="Manage Users" {...a11yProps(0)} />
          <Tab label="Invite Users" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Manage project={project} company={company} {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>Employee Self-Service QR Code</Typography>
              <Typography variant="body2">Managing employees in the OHS system is a difficult task, we provide a QR code poster you can post at your job site to have employees add themselves to the system.  Click the button below to retrieve your poster.</Typography>
              <div style={{display:'flex',flexDirection:'column'}}>
                <img width="150px" src={props.project.qr} alt="Printable QR Poster, click button below" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    localStorage.setItem(PROJECT, JSON.stringify(project));
                    window.open('/project', '_blank');
                  }}>
                  Get Printable Poster
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>Add User to Project:</Typography>
              <div>Add a new user to <strong>{ project && project.details.project }</strong>:</div>
              <div style={{border:'1px solid #cdcdcd',borderRadius:'10px',padding:'5px',marginTop:'20px'}}>
                {
                  results.length === 0 &&
                  <>
                    <Typography variant="h6">Login Information (Only One Required)</Typography>
                    <Typography variant="body2">Users can login with any email, phone or username.  Each field is required to be unique throughout Budee, existing users will be presented with link to Add.</Typography>
                    <div>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="email"
                        label="Email"
                        name="email"
                        error={addErrors.email}
                        value={user.email}
                        onChange={(e) => {
                          setUser(e);
                          setResults([]);
                        }}
                        onBlur={(e) => {
                          var error = false;
                          if (emailRE.test(String(e.target.value).toLowerCase())) {
                            searchUser(project.details.id, e.target.value)
                          } else if (e.target.value !== '') {
                            error = true;
                          }
                          setAddErrors(Object.assign({...addErrors}, {
                            email : error
                          }));
                        }}
                        margin="normal"
                      />
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <Select
                        variant="outlined"
                        id="countryCode"
                        name="countryCode"
                        size="small"
                        className={classes.select}
                        classes={{root : classes.selectInput}}
                        value={countryCode}
                        onChange={(e) => {
                          localStorage.setItem(COUNTRY_CODE, e.target.value);
                          setCountryCode(e.target.value);
                        }}
                      >
                        {
                          callingCountries && callingCountries.all.map((ctry, i) => {
                            return (
                              <MenuItem key={'country-' + i} value={JSON.stringify(ctry)}>{ctry.emoji} - {ctry.alpha3}</MenuItem>
                            );
                          })
                        }
                      </Select>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="phone"
                        label="Phone"
                        name="phone"
                        error={addErrors.phone}
                        value={user.phone}
                        onChange={(e) => {
                          setUser({target:[{id:'phone',value: e.target.value.replace(/[^0-9]/g, '')}]});
                          setResults([]);
                        }}
                        onBlur={(e) => {
                          searchUser(project.details.id, null, user.phone);
                          var error = false;
                          if (e.target.value !== '') {
                            error = !getPhone(e.target.value).isValid;
                          }
                          setAddErrors(Object.assign({...addErrors}, {
                            phone : error
                          }));
                        }}
                        margin="normal"
                      />
                    </div>
                    {
                      addErrors.phone &&
                      <Typography variant="body2">If you believe the phone number is correct, double check the correct country code and phone, even if USA/CAN are both +1 for example, it will validate area codes between USA/CAN.</Typography>
                    }
                    <div>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="username"
                        label="Username"
                        name="username"
                        error={addErrors.username}
                        value={user.username}
                        onChange={(e) => {
                          setUser(e);
                          setResults([]);
                        }}
                        onBlur={() => searchUser(project.details.id, null, null, user.username)}
                        margin="normal"
                      />
                    </div>
                  </>
                }
                {
                  results.length > 0 &&
                  <div style={{paddingTop : '10px'}}>
                    <Typography variant="h6" gutterBottom>Existing User</Typography>
                    <div>Only users that are not a part of the <strong>{ project && project.details.project }</strong> project will show up in these results.  Click the button "Add" to add this user to your project.</div>
                    <List>
                      {
                        results.map((user) => {
                          var existing = props.employees && props.employees.hasOwnProperty(user.id) && !props.employees[user.id].inactive;
                          var login = getLogin(user);
                          return (
                            <ListItem disableRipple style={{cursor:'default'}} key={user.id} button>
                              {
                                !isMobile &&
                                <ListItemAvatar>
                                  <ProfilePicture user={props.user} employee={user} />
                                </ListItemAvatar>
                              }
                              <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={login} secondary={user.firstname + ' ' + user.lastname} />
                              <ListItemSecondaryAction>
                              {
                                existing &&
                                <Typography>Already Member</Typography>
                              }
                              {
                                !existing &&
                                <div>
                                  {
                                    searchParameters && searchParameters.username &&
                                    <Tooltip style={{marginRight:'10px'}} title={"This was a search result from a username, since usernames are globally unique this could be anyone from another Budee account.  Please make sure you are adding the correct person, you can always remove them but just be aware."} >
                                      <WarningIcon size={25} color="error" />
                                    </Tooltip>
                                  }
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => add(project.details.id, user)}>
                                    Add
                                  </Button>
                                </div>
                              }
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        })
                      }
                    </List>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setResults([]);
                        setUser({target:[{id:'email',value:''},{id:'phone',value:''},{id:'username',value:''},{id:'firstname',value:''},{id:'lastname',value:''}]});
                      }}>
                      Cancel/Clear
                    </Button>
                  </div>
                }
              </div>
              {
                results.length === 0 &&
                <>
                  <div>
                      <TextField
                        variant="outlined"
                        required
                        size="small"
                        id="firstname"
                        label="Firstname"
                        name="firstname"
                        error={addErrors.firstname}
                        value={user.firstname}
                        onChange={setUser}
                        margin="normal"
                      />
                  </div>
                  <div>
                      <TextField
                        variant="outlined"
                        required
                        size="small"
                        id="lastname"
                        label="Lastname"
                        name="lastname"
                        error={addErrors.lastname}
                        value={user.lastname}
                        onChange={setUser}
                        margin="normal"
                      />
                  </div>
                  <Button
                      variant="contained"
                      color="primary"
                      disabled={results && results.length > 0}
                      onClick={() => add(project.details.id, user)}>
                      {
                        !addUserLoading &&
                        <>
                          Add
                        </>
                      }
                      {
                        addUserLoading &&
                        <div>
                          <CircularProgress color={"secondary"} size={10} />
                        </div>
                      }
                  </Button>
                </>
              }
              {
                  addResponse &&
                  <div style={{ margin : '10px' }}>
                  {
                    addResponse && addResponse.added.length > 0 &&
                    <List>
                    {
                      addResponse.added.map((user, i) => {
                        return (
                          <ListItem key={'added-' + i} button>
                            <ListItemText classes={{primary : classes.listItemText}} primary={"Added: " + getLogin(user)} />
                          </ListItem>
                        )
                      })
                    }
                    </List>
                  }
                  {
                    addResponse && addResponse.existing.length > 0 &&
                    <List>
                    {
                      addResponse.existing.map((user, i) => {
                        return (
                          <ListItem key={'added-' + i} button>
                            <ListItemText classes={{primary : classes.listItemText}} primary={"Already Existing: " + getLogin(user)} />
                          </ListItem>
                        )
                      })
                    }
                    </List>
                  }
                  {
                    addResponse && addResponse.failed.length > 0 &&
                    <List>
                    {
                      addResponse.failed.map((user, i) => {
                        return (
                          <ListItem key={'added-' + i} button>
                            <ListItemText classes={{primary : classes.listItemText}} primary={"Failed: " + getLogin(user)} />
                          </ListItem>
                        )
                      })
                    }
                    </List>
                  }
                  </div>
                }
            </Paper>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>Bulk Upload:</Typography>
              <div>CSV format (firstname,lastname,email,phone,username) headers to bulk add to <strong>{ project && project.details.project }</strong>:</div>
              <form>
                <div style={{ marginTop : '16px', marginBottom : '8px' }}>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={event => setBulkFile(event.target.files[0])}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={() => uploadBulk(project.details.id, bulkFile)}
                >
                {
                  !bulkLoading &&
                  <>
                    Add All Users
                  </>
                }
                {
                  bulkLoading &&
                  <CircularProgress color={"secondary"} style={{paddingLeft : '20px', paddingRight : '20px'}} size={20} />
                }
                </Button>
                {
                  progress &&
                  <LinearProgress style={{height:'20px',marginTop:'10px'}} variant="determinate" value={progress} />
                }
              </form>
              {
                bulkResponse &&
                <div style={{margin:'10px'}}>
                  {
                    bulkResponse && bulkResponse.added.length > 0 &&
                    <Accordion expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        ({bulkResponse.added.length}) Added
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {
                            bulkResponse.added.map((user, i) => {
                              return (
                                <ListItem key={'added-' + i} button>
                                  <ListItemText classes={{primary : classes.listItemText}} primary={getLogin(user)} />
                                </ListItem>
                              )
                            })
                          }
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  }
                  {
                    bulkResponse && bulkResponse.existing.length > 0 &&
                    <Accordion expanded={expanded === 'panel2'} onChange={handlePanelChange('panel2')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        ({bulkResponse.existing.length}) Existing
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {
                            bulkResponse.existing.map((user, i) => {
                              return (
                                <ListItem key={'added-' + i} button>
                                  <ListItemText classes={{primary : classes.listItemText}} primary={getLogin(user)} />
                                </ListItem>
                              )
                            })
                          }
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  }
                  {
                    bulkResponse && bulkResponse.failed.length > 0 &&
                    <Accordion expanded={expanded === 'panel3'} onChange={handlePanelChange('panel3')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        ({bulkResponse.failed.length}) Failed
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {
                            bulkResponse.failed.map((user, i) => {
                              return (
                                <ListItem key={'added-' + i} button>
                                  <ListItemText classes={{primary : classes.listItemText}} primary={getLogin(user)} />
                                </ListItem>
                              )
                            })
                          }
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  }
                </div>
              }
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
    </Paper>
  );
}
