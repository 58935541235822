import React, { useRef, useState, useEffect, useLayoutEffect } from "react";

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lightbox from 'react-image-lightbox';
import { Storage } from "aws-amplify";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  row : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    width:'calc(100%-10px)'
  },
  title : {
    marginRight:'5px',
    alignItems:'center',
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap'
  }
}));

export default function Photo(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);

  const classes = useStyles();

  const [imageUrls, setImageUrls] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loadingImages, setLoadingImages] = useState(false);

  useEffect(() => {
    if (props.value) {
      getImageUrls(props.value.value);
    }
  }, [props.value]);

  const getImageUrls = async (imgs) => {
    setLoadingImages(true);
    var imgUrls = [];
    for (const img of imgs) {
      imgUrls.push(await Storage.get(img.key, {
        level: "protected",
        identityId : img.identityId
      }));
    }
    setImageUrls(imgUrls);
    setLoadingImages(false);
  }

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  return (
    <>
      <Grid xs={12} sm={props.print ? 12 : (props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
        <div className={classes.row} style={props.print ? {margin:'5px',padding:'5px'} : {}}>
          <Typography variant={"body2"} className={classes.title} style={props.print ? {textDecorationLine:'underline'} : {}}>{!props.print && <PhotoCameraIcon color="primary" />}{props.field.name}</Typography>
          {
            loadingImages &&
            <div>
              <CircularProgress size={20} />
            </div>
          }
          {
            imageUrls && imageUrls.length > 0 &&
            <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin : '5px 0', width : 'calc(100%-10px)', padding:'5px',border:'1px solid #efefef',justifyContent:'flex-start'}}>
              {
                imageUrls.map((url, i) => {
                  return (
                    <React.Fragment key={'image-' + i}>
                      {
                        !props.print &&
                        <div style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                          <div style={{cursor:'pointer',width:'80px',height:'80px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                          </div>
                        </div>
                      }
                      {
                        props.print &&
                        <img width="250px" src={url} alt={props.field.name + '-' + i} style={{margin:'2px'}} />
                      }
                    </React.Fragment>
                  )
                })
              }
            </div>
          }
          {
            imageUrls && imageUrls.length === 0 &&
            <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin : '5px 0', width : 'calc(100%-10px)', padding:'5px',border:'1px solid #efefef',justifyContent:'flex-start'}}>
              <Typography variant="body2">No Pictures.</Typography>
            </div>
          }
          {
            !loadingImages && imageUrls && imageUrls.length > 0 && openLightbox &&
            <Lightbox
              mainSrc={imageUrls[photoIndex]}
              nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
              prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
              onCloseRequest={() => setOpenLightbox(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + imageUrls.length + 1) % imageUrls.length)}
              reactModalStyle={{overlay:{zIndex:1400}}}
            />
          }
        </div>
      </Grid>
    </>
  );
}
