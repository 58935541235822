import React, { useState } from "react";

import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import { useFormFields } from "../../../../libs/hooks";

import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import HelpIcon from '@material-ui/icons/Help';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  }
}));

export default function CertificationForm(props) {
  const classes = useStyles();

  const [required, setRequired] = useState(props.certification && (props.certification.roles || props.certification.contractors) ? 'roles' : 'all');
  const [certificationRoles, setCertificationRoles] = useState(props.certification && props.certification.roles ? props.certification.roles : []);
  const [certificationContractors, setCertificationContractors] = useState(props.certification && props.certification.contractors ? props.certification.contractors : []);
  const [certificationErrors, setCertificationErrors] = useState({
    name : false,
    description : false
  });
  const [certification, setCertification] = useFormFields({
    name : props.certification ? props.certification.name : "",
    description : props.certification ? props.certification.description : ""
  });
  const [selectedRole, setSelectedRole] = useState('unselected');
  const [selectedContractor, setSelectedContractor] = useState('unselected');
  const [daysTillExpire, setDaysTillExpire] = useState(props.certification ? props.certification.warning : 30);
  const [updating, setUpdating] = useState(false);

  const handleChange = (event) => {
    if (event.target.value === 'all') {
      setCertificationRoles([]);
      setCertificationContractors([]);
    }
    setRequired(event.target.value);
  };

  const handleRoleChange = (event) => {
    for (const role of props.roles) {
      if (role.id === event.target.value) {
        setSelectedRole(role);
      }
    }
  };

  const addContractor = async () => {
    if (selectedContractor !== 'unselected') {
      for (const contractor of certificationContractors) {
        if (contractor.id === selectedContractor.id) {
          return;
        }
      }
      var copy = [...certificationContractors];
      copy.push(selectedContractor);
      setCertificationContractors(copy);
    }
  }

  const handleContractorChange = (event) => {
    for (const contractor of props.contractors) {
      if (contractor.id === event.target.value) {
        setSelectedContractor(contractor);
      }
    }
  };

  const addRole = async () => {
    if (selectedRole !== 'unselected') {
      for (const role of certificationRoles) {
        if (role.id === selectedRole.id) {
          return;
        }
      }
      var copy = [...certificationRoles];
      copy.push(selectedRole);
      setCertificationRoles(copy);
    }
  }

  const handleRoleDelete = async (id) => {
    setCertificationRoles(certificationRoles.filter(role => role.id !== id));
  }

  const handleContractorDelete = async (id) => {
    setCertificationContractors(certificationContractors.filter(contractor => contractor.id !== id));
  }

  const addCertification = () => {
    const check = {
      name : certification.name === "" ? true : false,
      description : certification.description === "" ? true : false,
      roles : required === 'roles' && certificationRoles.length === 0 ? true : false,
      contractors : required === 'contractors' && certificationContractors.length === 0 ? true : false
    };
    setCertificationErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      const cert = {
        name : certification.name,
        description : certification.description,
        roles : certificationRoles.length > 0 ? certificationRoles : null,
        contractors : certificationContractors.length > 0 ? certificationContractors : null,
        warning : daysTillExpire,
        id : props.certification ? props.certification.id : null
      };
      API.post("budeeBackend", "certifications/project", { body : {
        projectId : props.project.details.id,
        certification : cert
      }}).then((res) => {
        if (props.certification && props.setEdit && props.setDetailsSelected) {
          props.setEdit(false);
          props.setDetailsSelected(cert);
        } else {
          props.loadCertifications(props.project.details.id);
          setCertificationRoles([]);
          setCertificationContractors([]);
          setRequired('all');
          setCertification({target:[{id:'name', value:''},{id:'description', value:''}]});
          setDaysTillExpire(30);
        }
        setUpdating(false);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  return (
    <>
      {
        props.certification &&
        <Button style={{marginBottom:'10px'}} color="secondary" variant="contained" onClick={() => props.setEdit(false)}>Cancel</Button>
      }
      <div style={{display : 'flex', flexDirection : 'row'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Required By:</FormLabel>
          <RadioGroup aria-label="required" name="required" value={required} onChange={handleChange}>
            <FormControlLabel value="all" control={<Radio />} label="Project Wide" />
            <FormControlLabel value="roles" control={<Radio />} label="Specific Role(s)" />
            <FormControlLabel value="contractors" control={<Radio />} label="Specific Contractor(s)" />
          </RadioGroup>
        </FormControl>
        {
          required === 'roles' && props.roles.length > 0 &&
          <div style={{display:'flex', flexGrow:1, flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                error={certificationErrors.roles}
                id="role-select"
                value={selectedRole === 'unselected' ? 'unselected' : selectedRole.id}
                onChange={handleRoleChange}
              >
                <MenuItem key={'role-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.roles.map((role, i) => {
                  return (
                    <MenuItem key={'role-' + i} value={role.id}>{role.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addRole()} color="secondary" variant="contained" style={{}}>Add Role</Button>
            </div>
          </div>
        }
        {
          required === 'roles' && props.roles && props.roles.length === 0 &&
          <Typography>There currently are no roles to choose from, <Link href="/organization?type=roles">click here to add some in the Roles section</Link>.</Typography>
        }
        {
          required === 'contractors' && props.contractors.length > 0 &&
          <div style={{display:'flex', flexGrow:1, flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="role-label">Contractor</InputLabel>
              <Select
                labelId="contractor-label"
                error={certificationErrors.contractors}
                id="contractor-select"
                value={selectedContractor === 'unselected' ? 'unselected' : selectedContractor.id}
                onChange={handleContractorChange}
              >
                <MenuItem key={'role-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.contractors.map((contractor, i) => {
                  return (
                    <MenuItem key={'role-' + i} value={contractor.id}>{contractor.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addContractor()} color="secondary" variant="contained" style={{}}>Add Contractor</Button>
            </div>
          </div>
        }
        {
          required === 'contractors' && props.contractors.length === 0 &&
          <Typography>There currently are no contractors to choose from, <Link href="/organization?type=contractors">click here to add some in the Contractors section</Link>.</Typography>
        }
      </div>
      {
        certificationRoles.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          certificationRoles.map((role,i) => {
            return (
              <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} onDelete={() => handleRoleDelete(role.id)} key={'role-' + i} label={role.name} size="small"/>
            )
          })
        }
        </div>
      }
      {
        certificationContractors.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          certificationContractors.map((contractor,i) => {
            return (
              <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} onDelete={() => handleContractorDelete(contractor.id)} label={contractor.name} size="small" />
            )
          })
        }
        </div>
      }
      <TextField
        variant="outlined"
        error={certificationErrors.name}
        fullWidth
        required
        size="small"
        id="name"
        label="Name"
        name="name"
        value={certification.name}
        inputProps={{maxLength:60}}
        onChange={setCertification}
        margin="normal"
      />
      <TextField
        variant="outlined"
        error={certificationErrors.description}
        fullWidth
        required
        multiline
        rows={3}
        size="small"
        id="description"
        label="Description (140 chars max)"
        name="description"
        value={certification.description}
        inputProps={{maxLength:140}}
        onChange={setCertification}
        margin="normal"
      />
      <Typography variant="body2" component="div">Please check your spelling on the name and details. Once created, required project certifications can not be edited.</Typography>
      <div style={{display:'flex', alignItems:'center'}}>
        <TextField
          variant="outlined"
          required
          size="small"
          id="days"
          label="Days to Expiration"
          name="days"
          inputProps={{type:'number'}}
          value={daysTillExpire}
          onChange={(e) => setDaysTillExpire(e.target.value)}
          margin="normal"
        />
        <div style={{marginLeft:'15px'}}>
          <Tooltip interactive title="When employees check-in, we want to give them ample warning that a certification will expire so they have enough time to update their certifications.  Try to consider the process of acquiring this certification and make a best guess for an appropriate timed reminder for your employee to stay certified.">
            <HelpIcon color="primary" />
          </Tooltip>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => addCertification()}>
        {
          !updating &&
          <>
            {props.certification ? "Update Certification" : "Add Certification"}
          </>

        }
        {
          updating &&
          <div>
            <CircularProgress size={10} />
          </div>
        }
      </Button>
    </>
  );
}
