const dev = {
  us_west_2 : {
    apiGateway : {
      REGION: "us-west-2",
      URL: "https://36giyxdndc.execute-api.us-west-2.amazonaws.com/dev/"
    },
    cognito : {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_O4VWsjwCB",
      APP_CLIENT_ID: "1l8vua0nu6sgseapvfkt90klen",
      IDENTITY_POOL_ID: "us-west-2:61ea3062-63e5-445a-9a64-dda0c428dcec"
    },
    s3 : {
      BUCKET : "dev-budeemedia",
      REGION : "us-west-2"
    },
    appSync : {
      URL : "https://wnlbuycm5rewjozt7kaphg3ezi.appsync-api.us-west-2.amazonaws.com/graphql",
      REGION : "us-west-2"
    }
  },
  eu_west_2 : {
    apiGateway : {
      REGION: "eu-west-2",
      URL: "https://q3h7mz82o7.execute-api.eu-west-2.amazonaws.com/dev-lon/"
    },
    cognito : {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_y60pjuSv9",
      APP_CLIENT_ID: "6a2pdcig6emcg1lks36tcdalbe",
      IDENTITY_POOL_ID: "eu-west-2:6b76621d-fc20-43d1-adac-4bf4fce72345"
    },
    s3 : {
      BUCKET : "dev-lon-budeemedia",
      REGION : "eu-west-2"
    },
    appSync : {
      URL : "https://pxv5mvi2azhghi7lkzbmq377me.appsync-api.eu-west-2.amazonaws.com/graphql",
      REGION : "eu-west-2"
    }
  }
};

const prod = {
  us_west_2 : {
    apiGateway: {
      REGION: "us-west-2",
      URL: "https://25mpq485j8.execute-api.us-west-2.amazonaws.com/prod/"
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_8CtVkbJkV",
      APP_CLIENT_ID: "5onltlqgu0n22ratn49rq145bc",
      IDENTITY_POOL_ID: "us-west-2:6bb534a9-db49-49a1-b9f6-7d77b1c6b6a6"
    },
    s3 : {
      BUCKET : "prod-budeemedia",
      REGION : "us-west-2"
    },
    appSync : {
      URL : "https://ghhaeiz5uba4pnzh4qqkasyyrq.appsync-api.us-west-2.amazonaws.com/graphql",
      REGION : "us-west-2"
    }
  },
  eu_west_2 : {
    apiGateway : {
      REGION: "eu-west-2",
      URL: "https://6rx4i84c2l.execute-api.eu-west-2.amazonaws.com/prod-lon/"
    },
    cognito : {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_6xgLiKvlc",
      APP_CLIENT_ID: "7pqvv1oi3cb13pdajpb3cnps72",
      IDENTITY_POOL_ID: "eu-west-2:1325acda-ab33-4c08-9053-014297e4aa73"
    },
    s3 : {
      BUCKET : "prod-lon-budeemedia",
      REGION : "eu-west-2"
    },
    appSync : {
      URL : "https://jui7ve45p5fq3hssmanvq7d6va.appsync-api.eu-west-2.amazonaws.com/graphql",
      REGION : "eu-west-2"
    }
  }
};

const stage = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : 'dev';
const region = process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION.replaceAll('-','_') : 'us_west_2';
const config = stage === 'prod' ? prod[region] : dev[region];

export default config;
