import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

export default function DividerField(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);


  return (
    <Grid xs={12} item ref={targetRef}>
      <Divider />
    </Grid>
  );
}
