import React from "react";

import ReadOnly from './Training/ReadOnly';
import BasicTraining from './Training/BasicTraining';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

export default function Training(props) {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
            <Typography variant="h6" gutterBottom>Training</Typography>
            {
              (props.project.status === USER_STATUS.SUPERADMIN || props.project.status === USER_STATUS.SUPERVISOR) &&
              <ReadOnly {...props} />
            }
            {
              props.project.status === USER_STATUS.BASIC &&
              <BasicTraining {...props} />
            }
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
