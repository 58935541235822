import React, { useState, useEffect } from "react";

import { API, Auth, Storage } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import { toDate, formatDate } from "../../../../libs/util";
import { v4 as uuidv4 } from 'uuid';
import { useFormFields } from "../../../../libs/hooks";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Lightbox from 'react-image-lightbox';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TextField from '@material-ui/core/TextField';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Link from '@material-ui/core/Link';

const { NOTIFICATIONS } = require('@budeesolutions/budee-constants');
const KSUID = require('ksuid');

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  },
  row : {
    display : 'flex',
    alignItems : 'center',
    margin : '5px 0',
    width : '100%'
  },
  label : {
    width : '200px',
    paddingRight:'10px'
  },
  iconLabel : {
    paddingRight : '10px'
  }
}));

export default function SubmissionForm(props) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [rejectErrors, setRejectErrors] = useState({
    description : false
  });
  const [reject, setReject] = useFormFields({
    description : ""
  });
  const [rejectOpen, setRejectOpen] = useState(false);
  const [acquiredDate, handleAcquiredDateChange] = useState(new Date());
  const [expires, setExpires] = useState(false);
  const [expiredDate, handleExpiredDateChange] = useState(new Date());
  const [imageUpload, setImageUpload] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState(null);
  const [pdfUrls, setPdfUrls] = useState(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  function onChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setImageUpload(true);
    let extension = null;
    if (file.type === 'image/jpeg') {
      extension = '.jpeg';
    } else if (file.type === 'image/png') {
      extension = '.png';
    } else if (file.type === 'application/pdf') {
      extension = '.pdf';
    }

    const key = uuidv4() + extension;
    Auth.currentCredentials().then(res => {
      Storage.put(key, file, {
          level : 'protected',
          contentType : file.type
      }).then(result => {
        var copy = [...images];
        copy.push({
          identityId : res.identityId,
          key : key
        });
        setImages(copy);
        setImageUpload(false);
      }).catch(err => console.log(err));
    });
  }

  const addSubmission = async () => {
    if (expires) {
      if (acquiredDate.getTime() >= expiredDate.getTime()) {
        alert('Acquired date must be prior to expired date.  If certification does not expire uncheck expires.');
        return;
      }
    }
    setUpdating(true);
    API.post("budeeBackend", "certifications/admin/submit", {
      body : {
        userId : props.submitFor.id,
        projectId : props.project.details.id,
        submission : {
          certificationId : props.certification.id,
          companyId : props.company.details.id,
          name : props.certification.name,
          acquired : formatDate(acquiredDate),
          expires : expires ? formatDate(expiredDate) : null,
          certificationExpires : expires,
          images : images
        }
      }
    }).then((res) => {
      setUpdating(false);
      props.onComplete();
    });
  }

  const rejectCertification = async () => {
    if (reject.description === "") {
      setRejectErrors({description:true});
      return;
    } else {
      setRejectErrors({description:false});
    }
    setRejecting(true);
    Promise.all([
      API.del("budeeBackend", "certifications/admin/submit", { body : {
        userId : props.submitFor.id,
        projectId : props.project.details.id,
        certificationId : props.certification.id
      }}),
      API.graphql({
        query: `mutation ($notification : Alert!, $userId : String!) {
          createNotification(notification: $notification, userId: $userId) {
            acknowledged
            description
            id
            projectId
            title
            userId
          }
        }`,
        variables: {
          notification : {
            id : KSUID.randomSync().string,
            description : reject.description,
            title : 'Rejected ' + props.certification.name,
            projectId : props.project.details.id,
            acknowledged : false,
            type : NOTIFICATIONS.CERTIFICATION
          },
          userId : props.submission.userId
        }
      })
    ]).then(values => {
      setRejecting(false);
      setRejectOpen(false);
      props.onComplete();
    }).catch(err => console.error(err));
  }

  useEffect(() => {
    if (props.submission) {
      handleAcquiredDateChange(toDate(props.submission.acquired));
      if (props.submission.expires) {
        setExpires(true);
      }
      handleExpiredDateChange(toDate(props.submission.expires));
      if (props.submission.images) {
        setImages(props.submission.images);
      }
    }
  }, [props]);

  useEffect(() => {
    if (images) {
      getImageUrls(images);
      getPdfUrls(images);
    }
  }, [images]);

  const getImageUrls = async (imgs) => {
    var imgUrls = [];
    for (const img of imgs) {
      if (img.key.endsWith('.jpeg') || img.key.endsWith('.png')) {
        imgUrls.push(await Storage.get(img.key, {
          level: "protected",
          identityId : img.identityId
        }));
      }
    }
    setImageUrls(imgUrls);
  }

  const getPdfUrls = async (files) => {
    var pdfUrls = [];
    for (const file of files) {
      if (file.key.endsWith('.pdf')) {
        pdfUrls.push(await Storage.get(file.key, {
          level: "protected",
          identityId : file.identityId
        }));
      }
    }
    setPdfUrls(pdfUrls);
  }

  const removeImage = async (index) => {
    var copy = [...images];
    copy.splice(index, 1);
    setImages(copy);
  }

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  return (
    <>
      <div className={classes.row}>
        <div className={classes.label}>Status:</div>
        <div style={{display:'flex', alignItems:'center'}}>
          {
            props.submission &&
            <>
              {
                props.submission.verified &&
                <>
                  <Typography className={classes.iconLabel}>Verified</Typography>
                  <VerifiedUserIcon color="primary" />
                </>
              }
              {
                !props.submission.verified &&
                <>
                  <Typography className={classes.iconLabel}>Pending Verification</Typography>
                  <WarningIcon color="secondary" style={{marginLeft:'10px'}} />
                  <Button
                    variant="contained"
                    style={{marginLeft:'10px'}}
                    color="primary"
                    onClick={() => addSubmission()}>
                    {
                      !updating &&
                      "Accept"
                    }
                    {
                      updating &&
                      <div>
                        <CircularProgress size={10} />
                      </div>
                    }
                  </Button>
                </>
              }
              <Button
                variant="contained"
                style={{marginLeft:'10px'}}
                color="secondary"
                onClick={() => setRejectOpen(true)}>
                {
                  !rejecting &&
                  "Reject"
                }
                {
                  rejecting &&
                  <div>
                    <CircularProgress size={10} />
                  </div>
                }
              </Button>
              <Dialog
                fullScreen={fullScreen}
                maxWidth="md"
                fullWidth={true}
                open={rejectOpen}
                onClose={() => setRejectOpen(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title"></DialogTitle>
                <DialogContent>
                  <Typography variant="body2">Include message with rejection criteria:</Typography>
                  <TextField
                    variant="outlined"
                    error={rejectErrors.description}
                    fullWidth
                    required
                    multiline
                    rows={3}
                    size="small"
                    id="description"
                    label="Description (140 chars max)"
                    name="description"
                    value={reject.description}
                    inputProps={{maxLength:140}}
                    onChange={setReject}
                    margin="normal"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    setReject({target:{description:''}});
                    setRejectOpen(false);
                  }} color="secondary" variant="contained">
                    Cancel
                  </Button>
                  <Button onClick={() => rejectCertification()} color="primary" variant="contained">
                    Reject
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          }
          {
            !props.submission &&
            <>
              <Typography className={classes.iconLabel}>Missing</Typography>
              <HelpIcon color="error" />
            </>
          }
        </div>
      </div>
      <div className={classes.row}>
        <div  className={classes.label}>Date Acquired:</div>
        <div style={{width:'200px',display:'flex'}}>
          <DatePicker
            value={acquiredDate}
            format="EEEE MMMM d yyyy"
            onChange={(e) => handleAcquiredDateChange(e)}
            TextFieldComponent={props => (<Input
                                            type="text"
                                            onClick={props.onClick}
                                            value={props.value}
                                            onChange={props.onChange}
                                            fullWidth
                                            style={{ textAlign : 'center', color : '#000000', cursor : 'pointer' }}
                                          />)}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>Expires:</div>
        <div style={{width:'200px',display:'flex'}}>
          <Checkbox
            checked={expires}
            onChange={() => setExpires(!expires)}
            name="expires"
            color="primary"
          />
        </div>
      </div>
      {
        expires &&
        <div className={classes.row}>
          <div className={classes.label}>Date Expires:</div>
          <div style={{width:'200px',display:'flex'}}>
            <DatePicker
              value={expiredDate}
              format="EEEE MMMM d yyyy"
              onChange={handleExpiredDateChange}
              TextFieldComponent={props => (<Input
                                              type="text"
                                              onClick={props.onClick}
                                              value={props.value}
                                              onChange={props.onChange}
                                              fullWidth
                                              style={{ textAlign : 'center', color : '#000000', cursor : 'pointer' }}
                                            />)}
            />
          </div>
        </div>
      }
      <div className={classes.row}>
        <div className={classes.label}>Upload Image or PDF:</div>
        <div style={{width:'200px',display:'flex'}}>
          <input type="file" accept='image/png,image/jpeg,application/pdf' onChange={(evt) => onChange(evt)} />
        </div>
        {
          imageUpload &&
          <CircularProgress size={40} />
        }
      </div>
      {
        pdfUrls && pdfUrls.length > 0 &&
        <div className={classes.row} style={{padding:'5px',border:'1px solid #efefef',display:'flex',justifyContent:'center',alignItems:'center'}}>
          {
            pdfUrls.map((url, i) =>
              <Link key={'pdf-' + i} href={url} target="_blank" rel="noreferrer" style={{marginTop:'15px',display:'flex',flexDirection:'row'}}>
                <PictureAsPdfIcon color="error" />
                <Typography variant="body2">#{(i + 1)} - PDF Attachment</Typography>
              </Link>
            )
          }
        </div>
      }
      {
        imageUrls && imageUrls.length > 0 &&
        <>
          <div className={classes.row} style={{padding:'5px',border:'1px solid #efefef',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {
              imageUrls.map((url, i) => {
                return (
                  <div key={'image-' + i} style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                    <div style={{cursor:'pointer',width:'80px',height:'80px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => removeImage(i)}>
                      Remove
                    </Button>
                  </div>
                )
              })
            }
          </div>
          {
            imageUrls && imageUrls.length > 0 && openLightbox &&
            <Lightbox
              mainSrc={imageUrls[photoIndex]}
              nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
              prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
              onCloseRequest={() => setOpenLightbox(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + images.length + 1) % images.length)}
              reactModalStyle={{overlay:{zIndex:1400}}}
            />
          }
        </>
      }
      <Button
        variant="contained"
        color="primary"
        onClick={() => addSubmission()}>
        {
          !updating &&
          "Update Certification"
        }
        {
          updating &&
          <div>
            <CircularProgress size={10} />
          </div>
        }
      </Button>
    </>
  );
}
