import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import { Storage } from "aws-amplify";

import CompanyRegistration from '../../Company/Registration';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import RootUser from '../../User/RootUser';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { API } from "aws-amplify";
import { PRIVILEGED_USER } from "../../../config/constants";

export default function CompanyProfile(props) {
  const theme = useTheme();

  const [company, setCompany] = useState(props.company);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [selectedUser, setSelectedUser] = useState('unselected');
  const [rootUserIds, setRootUserIds] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCompanyClickOpen = () => {
    setCompanyOpen(true);
  };

  const handleCompanyClose = () => {
    setCompanyOpen(false);
  };

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
      loadRootUsers(props.company.details.id);
    }
  }, [props.company]);

  useEffect(() => {
    if (company) {
      Storage.get(company.details.id + '/logo.png').then((url) => {
        fetch(url).then((result) => { //fetch the URL
          if(result.status === 200) { //if the file exists
            setLogoUrl(url);
          } else {
            setLogoUrl('NONE');
          }
        });
      }).catch(res => {
        console.log(res);
      });
    }
  }, [company, logoUrl]);

  function onChange(e) {
    const file = e.target.files[0];
    setUploadingLogo(true);
    Storage.put(company.details.id + '/logo.png', file, {
        contentType : 'image/png'
    })
    .then (result => {
      Storage.get(result.key).then(value => {
        setLogoUrl(value);
        setUploadLogo(false);
        setUploadingLogo(false);
      });
    })
    .catch(err => console.log(err));
  }

  const setRootUser = async (employeeId, companyId, projectId) => {
    return API.post('budeeBackend', 'company/root', {
      body : {
        userId : employeeId,
        companyId : companyId
      }
    }).then(val => {
      setSelectedUser('unselected');
      props.loadEmployees(projectId);
    });
  }

  const loadRootUsers = async (companyId) => {
    API.get('budeeBackend', 'company/root?companyId=' + companyId, {}).then(val => {
      setRootUserIds(val);
    });
  }

  const deleteCompany = async (company) => {
    return API.del('budeeBackend', 'company/', {
      body : {
        companyId : company.details.id
      }
    }).then(res => window.location.reload(false));
  }

  return (
    <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
      <Grid container spacing={6}>
        <Grid xs={12} sm={6} item>
          <div style={{margin:'5px'}}>
            {
              !logoUrl &&
              <div>
                <CircularProgress size={80} />
              </div>
            }
            {
              logoUrl && company &&
              <div style={{margin:'5px'}}>
              {
                logoUrl !== 'NONE' &&
                <img style={{width:'400px',maxHeight:'300px'}} src={logoUrl} alt={company.details.name} />
              }
              {
                logoUrl === 'NONE' &&
                <img style={{width:'400px',maxHeight:'300px'}} src='https://dev-budeestatic.s3.us-west-2.amazonaws.com/images/placeholder.jpeg' alt={company.details.name} />
              }
              </div>
            }
            {
              uploadLogo &&
              <div style={{margin:'5px'}}>
                <input type="file" accept='image/png,image/jpeg' onChange={(evt) => onChange(evt)} />
              </div>
            }
            {
              ((props.project && PRIVILEGED_USER.includes(props.project.status)) || (props.company && props.company.root)) &&
              <Button variant="contained" size="small" color="primary" onClick={() => setUploadLogo(true)}>
                Upload Logo
              </Button>
            }
            {
              uploadingLogo &&
              <CircularProgress size={80} />
            }
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
            <Typography variant="h6">Company Details</Typography>
            {
              props.admin &&
              <Button variant="contained" size="small" color="primary" onClick={() => setConfirmClose(true)}>
                Close Company Account
              </Button>
            }
          </div>

          {
            company !== null &&
            <Typography variant="body2" color="textSecondary" component="p">
              {company.details.name}<br/>
              {company.details.phone}<br/>
              {company.details.website}<br/>
              {company.details.industry}<br/><br/>
              {company.details.address.line1}<br/>
              {company.details.address.line2}{company.details.address.line2 !== null && <br/>}
              {company.details.address.city}, {company.details.address.state}<br/>
              {company.details.address.postal}, {company.details.address.country}<br/>
            </Typography>
          }
          {
            props.project && props.employees && props.company && props.company.root &&
            <>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'20px'}}>
                <FormControl style={{width:'200px'}}>
                  <InputLabel id="root-user-label">New Root</InputLabel>
                  <Select
                    labelId="root-user-label"
                    id="root-user-select"
                    value={selectedUser !== 'unselected' ? selectedUser : 'unselected'}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <MenuItem key={'root-user-unselected'} value={'unselected'}>Select User</MenuItem>
                  {
                    Object.values(props.employees).filter(emp => !emp.root && !emp.inactive).map((emp, i) => {
                      return (
                        <MenuItem key={'root-user-' + i} value={emp.id}>{emp.name}</MenuItem>
                      )
                    })
                  }
                  </Select>
                </FormControl>
                <Button
                  disabled={selectedUser === 'unselected'}
                  variant="contained"
                  color="primary"
                  style={{marginLeft:'10px',marginRight:'10px'}}
                  onClick={() => setRootUser(selectedUser, company.details.id, props.project.details.id)}>
                  Add Root
                </Button>
              </div>
            </>
          }
          <div style={{marginTop:'20px'}}>
            <Typography variant="h6">Root Users</Typography>
            <List>
              {
                rootUserIds.map((id, i) => {
                  return (
                    <RootUser key={'root-' + i} userId={id} user={props.user} />
                  )
                })
              }
            </List>
          </div>
        </Grid>
      </Grid>
      <div style={{display:'flex', marginBottom : '10px'}}>
        <Button size="large" color="primary" variant="contained" onClick={handleCompanyClickOpen}>
          Add New Company
        </Button>
      </div>
      <Dialog
        open={companyOpen}
        onClose={handleCompanyClose}
        fullScreen={fullScreen}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Add a New Company
        </DialogTitle>
        <DialogContent>
          <CompanyRegistration onComplete={(id) => {
            setCompanyOpen(false);
            props.loadCompany(id)
          }} first={false} {...props} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCompanyClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmClose}
        onClose={() => setConfirmClose(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Please Confirm you're Intentions of Closing this Account
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">The account will no longer be accessible, all data will remain and if you want to re-open the account please contact <Link>sales@budeesolutions.com</Link>.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteCompany(company)} variant="contained" color="primary">
            Close Account
          </Button>
          <Button autoFocus onClick={() => setConfirmClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
