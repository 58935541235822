import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";

import { useEffectWhen } from "../../../../../../libs/hooks";
import { DOCUMENT_PRINT_JOB } from "../../../../../../config/localStorage";
import queryString from 'query-string';

import Submission from '../Submission';

import { DatePicker } from '@material-ui/pickers';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import TextField from '@material-ui/core/TextField';

import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import PostAddIcon from '@material-ui/icons/PostAdd';

const { DOCUMENTS } = require('@budeesolutions/budee-constants');
const KSUID = require('ksuid');

const useStyles = makeStyles((theme) => ({
  paper : {
    padding : '10px',
    marginTop:'10px',
    border : '3px solid ' +
    theme.palette.secondary.main
  }
}));

export default function Submissions(props) {
  const classes = useStyles();
  const today = new Date();
  var parsed = queryString.parse(props.location.search);

  const [employees, setEmployees] = useState(props.employees);
  const [detailsSelected, setDetailsSelected] = useState(props.detailsSelected)
  const [submissions, setSubmissions] = useState([]);
  const [startDate, handleStartDateChange] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, handleEndDateChange] = useState(today);
  const [display, setDisplay] = useState(parsed.display ? parsed.display : 'all');
  const [fields, setFields] = useState([]);

  const [indexed, setIndexed] = useState(null);
  const [indexValue, setIndexValue] = useState('');
  const [repeatFields, setRepeatFields] = useState(null);

  const getSubmissions = async (projId, docId, v, status, start, end, disp) => {
    let params = {
      projectId : projId,
      documentId : docId,
      version : v,
      status : status
    };
    var startOffset = null;
    var endOffset = null;

    switch (disp) {
      case 'today':
        let hoy = new Date();
        let manana = new Date(hoy + 1);
        startOffset = new Date(hoy);
        startOffset.setHours(0, 0, 0, 0);
        endOffset = new Date(manana);
        endOffset.setHours(23, 59, 59, 999);
        params.start = startOffset.toUTCString();
        params.end = endOffset.toUTCString();
        break;
      case 'range':
        startOffset = new Date(start);
        startOffset.setHours(0, 0, 0, 0);
        endOffset = new Date(end);
        endOffset.setHours(23, 59, 59, 999);
        params.start = startOffset.toUTCString();
        params.end = endOffset.toUTCString();
        break;
      default:
    }
    return API.get("budeeBackend", 'documents/submission', {
      queryStringParameters : params
    });
  }

  const loadFields = async (projectId, documentId, v, sub = false) => {
    return API.get("budeeBackend", "documents/field?projectId=" + projectId + '&documentId=' + documentId + '&version=' + v, {});
  }

  useEffect(() => {
    let mounted = true;
    loadFields(props.project.details.id, props.detailsSelected.id, props.detailsSelected.version).then(res => {
      if (mounted) {
        setFields(res);
      }
    });
    return () => mounted = false;
  }, [props.detailsSelected, props.project]);

  useEffect(() => {
    setDetailsSelected(props.detailsSelected);
  }, [props.detailsSelected]);

  useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  useEffectWhen(() => {
    if (fields) {
      for (const f of fields) {
        if (f.indexed) {
          setIndexed(f);
        }
      }
    } else {
      setIndexed(null);
    }
    if (repeatFields && fields && fields.length > 0) {
      clearInterval(repeatFields);
      setRepeatFields(null);
    }
  }, [fields, repeatFields], [fields]);

  useEffect(() => {
    let mounted = true;
    if (detailsSelected && props.project && indexValue !== '') {
      API.get("budeeBackend", "documents/submission/index", {
        queryStringParameters : {
          projectId : props.project.details.id,
          documentId : detailsSelected.id,
          documentVersion : detailsSelected.version,
          search : indexValue
        }
      }).then(res => {
        if (res && mounted) {
          setSubmissions(res);
        }
      });
    }
    return () => mounted = false;
  }, [props.project, detailsSelected, indexValue, startDate, endDate, display])

  useEffect(() => {
    let mounted = true;
    var qStr = queryString.parse(props.location.search);
    if (detailsSelected && props.project && indexValue === '' && display === qStr.display) {
      getSubmissions(props.project.details.id, detailsSelected.id, detailsSelected.version, display === 'draft' ? 'DRAFT' : 'COMPLETED', startDate, endDate, display).then(res => {
        if (res) {
          if (qStr.submissionId) {
            for(const sub of res) {
              if (sub.id === qStr.submissionId) {
                if (mounted) {
                  props.setSubmission(sub);
                }
              }
            }
          }
          if (mounted) {
            setSubmissions(res);
          }
        }
      });
    }
    if (display !== qStr.display) {
      qStr.display = display;
      props.history.push({
        pathname : props.history.location.pathname,
        search : queryString.stringify(qStr)
      });
    }
    return () => mounted = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsSelected, props.project, props.location, props.history, indexValue, startDate, endDate, display]);

  const printSelected = (i) => {
    localStorage.setItem(DOCUMENT_PRINT_JOB, JSON.stringify({
      submissions : [submissions[i]],
      document : detailsSelected,
      employees : props.employees,
      user : props.user,
      project : props.project,
      fields : fields,
      company : props.company.details
    }));
    window.open('/print', '_blank');
  };

  const createSubmission = async (projId, docId, docVersion) => {
    return API.post('budeeBackend', 'documents/submission', {
      body : {
        projectId : projId,
        documentId : docId,
        documentVersion : docVersion
      }
    }).then(res => {
      const created = KSUID.parse(res.id);
      parsed.submissionId = res.id;
      props.history.push({
        pathname : props.history.location.pathname,
        search : queryString.stringify(parsed)
      });
      props.setSubmission({
        id : res.id,
        documentId : docId,
        documentVersion : docVersion,
        status : 'DRAFT',
        userId : props.user.id,
        date : created.date
      });
    });
  };

  const reload = async () => {
    if (detailsSelected && props.project && indexValue && indexValue !== '') {
      API.get("budeeBackend", "documents/submission/index", {
        queryStringParameters : {
          projectId : props.project.details.id,
          documentId : detailsSelected.id,
          documentVersion : detailsSelected.version,
          search : indexValue
        }
      }).then(res => {
        if (res) {
          setSubmissions(res);
        }
      });
    } else if (detailsSelected && props.project) {
      getSubmissions(props.project.details.id, detailsSelected.id, detailsSelected.version, display === 'draft' ? 'DRAFT' : 'COMPLETED', startDate, endDate, display).then(res => {
        if (res) {
          if (parsed.submissionId) {
            for(const sub of res) {
              if (sub.id === parsed.submissionId) {
                props.setSubmission(sub);
              }
            }
          }
          setSubmissions(res);
        }
      });
    }
  }

  return (
    <>
      <Grid xs={12} item>
        <Paper className={classes.paper}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
            <div style={{minWidth:'275px',margin:'5px'}}>
              <RadioGroup row aria-label="position" name="position" value={display} onChange={(e) => setDisplay(e.target.value)} defaultValue="range">
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="All"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="range"
                  control={<Radio color="primary" />}
                  label="Date Range"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="today"
                  control={<Radio color="primary" />}
                  label="Today"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="draft"
                  control={<Radio color="primary" />}
                  label="Drafts"
                  labelPlacement="top"
                />
              </RadioGroup>
              {
                display === 'range' &&
                <>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Typography style={{width:'75px'}}>Start:</Typography>
                    <div style={{width:'200px'}}>
                      <DatePicker
                        value={startDate}
                        format="EEEE MMMM d yyyy"
                        onChange={handleStartDateChange}
                        TextFieldComponent={props => (<Input
                                                        type="text"
                                                        onClick={props.onClick}
                                                        value={props.value}
                                                        onChange={props.onChange}
                                                        fullWidth
                                                        style={{ textAlign : 'center', color : '#000000', cursor : 'pointer' }}
                                                      />)} />
                    </div>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Typography style={{width:'75px'}}>End:</Typography>
                    <div style={{width:'200px'}}>
                      <DatePicker
                        value={endDate}
                        format="EEEE MMMM d yyyy"
                        onChange={handleEndDateChange}
                        TextFieldComponent={props => (<Input
                                                        type="text"
                                                        onClick={props.onClick}
                                                        value={props.value}
                                                        onChange={props.onChange}
                                                        fullWidth
                                                        style={{ textAlign : 'center', color : '#000000', cursor : 'pointer' }}
                                                      />)} />
                    </div>
                  </div>
                </>
              }
            </div>
            {
              indexed &&
              <div style={{flexGrow:1,margin:'5px',marginLeft:'20px'}}>
                <Typography gutterBottom variant="body2">Search by indexed field for submissions that match:</Typography>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <Typography variant="body2" style={{marginRight:'10px'}}>{indexed.name}:</Typography>
                  {
                    (indexed.type === DOCUMENTS.FIELDS.SELECT.ID || indexed.type === DOCUMENTS.FIELDS.RADIOGROUP.ID) &&
                    <Select
                      labelId="version-label"
                      value={indexValue === '' ? 'unselected' : indexValue}
                      id="version"
                      onChange={(e) => setIndexValue(e.target.value)}
                    >
                      <MenuItem value='unselected'>Search For ...</MenuItem>
                      {
                         indexed.options.map((opt, i)=> {
                          return <MenuItem key={'opt-' + i} value={opt.name}>{opt.name}</MenuItem>
                        })
                      }
                    </Select>
                  }
                  {
                    indexed.type === DOCUMENTS.FIELDS.TEXT.ID &&
                    <TextField
                      variant="outlined"
                      size="small"
                      id="searchIndex"
                      label="Search For ..."
                      name="searchIndex"
                      value={indexValue}
                      inputProps={{maxLength:60}}
                      onChange={(e) => setIndexValue(e.target.value)}
                      margin="normal"
                      style={{margin:'0px',width:'200px'}}
                    />
                  }
                </div>
              </div>
            }
            <div style={{flexGrow:1}}></div>
            <IconButton onClick={() => createSubmission(props.project.details.id, detailsSelected.id, detailsSelected.version)}>
              <PostAddIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => reload()}>
              <RefreshIcon color="primary" />
            </IconButton>
          </div>
          <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
          <List>
            {
              submissions && submissions.length === 0 &&
              <Typography variant="body2">There are no submissions yet for this document.</Typography>
            }
            {
              submissions && submissions.map((sub, i) => {
                var employee = null;
                if (employees && employees.hasOwnProperty(sub.userId)) {
                  employee = employees[sub.userId];
                }
                return (
                  <div key={'field-' + i} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <IconButton style={{marginLeft:'10px',marginRight:'10px'}} edge="end" aria-label="up" onClick={() => printSelected(i)}>
                      <PrintIcon style={{cursor:'pointer'}} color={"primary"} />
                    </IconButton>
                    <Submission user={props.user} project={props.project} employee={employee} employees={props.employees} indexed={indexed} submission={sub} reload={() => reload()} onClick={() => {
                      parsed.submissionId = sub.id;
                      props.history.push({
                        pathname : props.history.location.pathname,
                        search : queryString.stringify(parsed)
                      });
                      props.setSubmission(sub);
                    }} />
                  </div>
                )
              })
            }
          </List>
        </Paper>
      </Grid>
    </>
  );
}
