import React, { useState, useEffect } from "react";

import { Storage } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Lightbox from 'react-image-lightbox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  }
}));

export default function ViewDetails(props) {
  const classes = useStyles();

  const [fileUrls, setFileUrls] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const getUrls = async (objs) => {
    var calls = [];
    for (const f of objs) {
      calls.push(Storage.get(f.key, {
        level: "protected",
        identityId : f.identityId
      }));
    }
    return Promise.all(calls);
  }

  useEffect(() => {
    if (props.details.files) {
      getUrls(props.details.files).then(res => {
        setFileUrls(res);
      });
    }
  }, [props.details.files]);

  useEffect(() => {
    if (props.details.images) {
      getUrls(props.details.images).then(res => {
        setImageUrls(res);
      });
    }
  }, [props.details.images]);

  const handleOpenLightbox = async (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  }

  return (
    <>
      <div style={{display:'flex',flexDirection:'row'}}>
        <Typography variant="body1" style={{marginRight:'10px'}}>Contractor:</Typography>
        <Typography variant="body1">{props.details.contractor}</Typography>
      </div>
      <div style={{display:'flex',flexDirection:'row'}}>
        <Typography variant="body1" style={{marginRight:'10px'}}>Submission Name:</Typography>
        <Typography variant="body1">{props.details.name}</Typography>
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
        <Typography variant="body1">PDF Attachments:</Typography>
      </div>
      <div style={{margin:'5px',display:'flex',alignItems:'center'}}>
      {
        fileUrls && fileUrls.map((url, i) => {
          return (
            <Link key={"file-" + i} style={{marginRight:'10px',marginLeft:'10px'}} href={url} target="_blank" rel="noreferrer">
              <PictureAsPdfIcon color="error" />
            </Link>
          );
        })
      }
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
        <Typography variant="body1">Image Attachments:</Typography>
      </div>
      {
        imageUrls && imageUrls.length > 0 &&
        <div className={classes.row} style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin : '5px 0', width : '100%', padding:'5px',border:'1px solid #efefef',justifyContent:'flex-start'}}>
          {
            imageUrls.map((url, i) => {
              return (
                <React.Fragment key={'image-' + i}>
                  {
                    !props.print &&
                    <div style={{display:'flex',flexDirection:'column',margin:'5px'}}>
                      <div style={{cursor:'pointer',width:'80px',height:'80px',backgroundImage:'url("' + url + '")',margin:'5px',backgroundRepeat:'no-repeat',backgroundPosition: 'center center'}} onClick={() => handleOpenLightbox(i)}>
                      </div>
                    </div>
                  }
                  {
                    props.print &&
                    <img width="345px" src={url} alt={props.field.name + '-' + i} style={{margin:'2px'}} />
                  }
                </React.Fragment>
              )
            })
          }
        </div>
      }
      {
        imageUrls && imageUrls.length > 0 && openLightbox &&
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + imageUrls.length + 1) % imageUrls.length)}
          reactModalStyle={{overlay:{zIndex:1400}}}
        />
      }
    </>
  );
}
