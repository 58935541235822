import React, { useState } from 'react';

import { Auth } from "aws-amplify";
import { useFormFields } from "../../libs/hooks";

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { phone } from 'phone';
import { v4 as uuidv4 } from 'uuid';

const callingCountries = require('country-data').callingCountries;

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  link : {
    cursor : 'pointer',
    marginTop:'100px'
  },
  select : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px',
    marginRight:'5px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function SignupForm(props) {
  const classes = useStyles();
  const [fields, handleFieldChange] = useFormFields({
    confirmationCode: "",
    firstName : "",
    lastName : "",
    email: "",
    phone : "",
    password: "",
    marketing: false,
    confirmPassword: ""
  });
  const [errors, setErrors] = useState({
    phone : false,
    email : false
  });
  // const [experience, setExperience] = useState('n/a');
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("{\"alpha2\":\"US\",\"alpha3\":\"USA\",\"countryCallingCodes\":[\"+1\"],\"currencies\":[\"USD\"],\"emoji\":\"\uD83C\uDDFA\uD83C\uDDF8\",\"ioc\":\"USA\",\"languages\":[\"eng\"],\"name\":\"United States\",\"status\":\"assigned\"}");
  const [username, setUsername] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  function validateForm() {
    return (
      fields.firstName.length > 0 &&
      fields.lastName.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const country = JSON.parse(countryCode);
    const phoneObj = phone(country.countryCallingCodes[0] + fields.phone,{country: country.alpha3});
    var err = {
      email : !validateEmail(fields.email),
      phone : phoneObj.isValid ? false : true
    };
    setErrors(err);
    if (!Object.values(err).includes(true)) {
      setErrorMessage('');
      setIsLoading(true);
      try {
        var attr = {
          email : fields.email,
          given_name : fields.firstName,
          family_name : fields.lastName,
          phone_number : phoneObj.phoneNumber
        }

        const username = uuidv4();
        setUsername(username);
        const newUser = await Auth.signUp({
          username: username,
          password: fields.password,
          attributes : attr
        });
        setIsLoading(false);
        setNewUser(newUser);
      } catch (e) {
        if (e.message.includes('EmailExistsException')) {
          setErrorMessage('An account with this email already exists.');
        } else if (e.message.includes('PhoneExistsException')) {
          setErrorMessage('An account with this phone number already exists.');
        } else {
          alert(e.message);
        }
        setIsLoading(false);
      }
    }
  }

  // async function resendConfirmationCode(event) {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     await Auth.verifyUserAttribute(newUser.user, 'email');
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  //   setIsLoading(false);
  // }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(username, fields.confirmationCode);
      await props.handleLogin(fields.email, fields.password, '/dashboard');
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function renderForm() {
    return (
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              size="medium"
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              size="medium"
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
          <div style={{display:'flex',flexDirection:'row'}}>
            <Select
              variant="outlined"
              id="countryCode"
              name="countryCode"
              size="small"
              className={classes.select}
              classes={{root : classes.selectInput}}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              {
                callingCountries && callingCountries.all.map((ctry, i) => {
                  return (
                    <MenuItem key={'country-' + i} value={JSON.stringify(ctry)}>{ctry.emoji} - {ctry.alpha3}</MenuItem>
                  );
                })
              }
            </Select>
            <TextField
              variant="outlined"
              error={errors.phone}
              required
              fullWidth
              size="small"
              id="phone"
              label="Phone"
              name="phone"
              value={fields.phone}
              onChange={handleFieldChange}
              margin="normal"
            />
          </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              error={errors.email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              size="medium"
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              size="medium"
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              size="medium"
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
        {
          errorMessage && errorMessage.length > 0 &&
          <Typography variant="body2" style={{color:'red',textAlign:'center'}}>{errorMessage}</Typography>
        }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!validateForm()}
          className={classes.submit}
        >
          Sign Up
        </Button>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form className={classes.form} onSubmit={handleConfirmationSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">A confirmation code has been sent to your email.</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="confirmationCode"
              label="Confirmation Code"
              name="confirmationCode"
              value={fields.confirmationCode}
              onChange={handleFieldChange}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!validateConfirmationForm()}
            className={classes.submit}
          >
            Verify
          </Button>
        </Grid>
      </form>
    );
  }

  return (
    <>
      <Typography style={{textAlign:'center'}} component="h1" variant="h5">
        Sign up - Start Your Free Trial Today!
      </Typography>
      { newUser === null ? renderForm() : renderConfirmationForm() }
      <Link classes={{root:classes.link}} onClick={() => props.setLogin(true)} variant="body2">
        {"Already have an account?  Log in"}
      </Link>
      {
        isLoading &&
        <div style={{textAlign:'center'}}>
          <CircularProgress size={80} />
        </div>
      }
    </>
  );
}
