import React, { useState, useEffect } from "react";

import { Storage } from "aws-amplify";
import { API } from "aws-amplify";

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

export default function ProfilePicture(props) {
  const [user, setUser] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var mounted = true;
    if (props.userId) {
      API.get('budeeBackend', 'user/root/profile?userId=' + props.userId, {}).then(res => {
        if (mounted) {
          setUser(res);
          setLoading(false);
        }
      });
    }
    return () => mounted = false;
  }, [props.userId, props.user]);

  useEffect(() => {
    var mounted = true;
    load(user, props.user).then(val => {
      if (mounted) {
        setProfilePicture(val)
      }
    });
    return () => mounted = false;
  }, [user, props.user])

  const load = async (employee, user) => {
    var pictureUrl = null;
    if (employee.picture) {
      if (employee.id === user.id) {
        pictureUrl = await Storage.get(employee.picture.key, {
          level: "protected"
        });
      } else {
        pictureUrl = await Storage.get(employee.picture.key, {
          level: "protected",
          identityId : employee.picture.identityId
        });
      }
    }
    return pictureUrl;
  }

  return (
    <>
    {
      !loading &&
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={user ? user.firstname + ' ' + user.lastname : ''} src={profilePicture} />
        </ListItemAvatar>
        <ListItemText primary={user ? user.firstname + ' ' + user.lastname : ''} />
      </ListItem>
    }
    </>
  );
}
