import React, { useState } from "react";

import { API, Storage, Auth } from "aws-amplify";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormFields, useEffectWhen } from "../../../../libs/hooks";
import { formatDate } from "../../../../libs/util";

import { DatePicker } from '@material-ui/pickers';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import ProfilePicture from '../../../User/ProfilePicture';
import Role from '../../../User/Role';

import { isMobile } from "react-device-detect";
const KSUID = require('ksuid');

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px'
  },
  configRow : {
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  }
}));

export default function RewardForm(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [search, setSearch] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [required, setRequired] = useState('all');
  const [specificUsers, setSpecificUsers] = useState([]);
  const [rewardRoles, setRewardRoles] = useState([]);
  const [rewardErrors, setRewardErrors] = useState({
    title : false,
    description : false,
    participants : false,
    picture : false,
    date : false
  });
  const [reward, setReward] = useFormFields({
    title : "",
    description : ""
  });
  const [selectedRole, setSelectedRole] = useState('unselected');
  const [updating, setUpdating] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [uploadingPicture, setUploadingPicture] = useState(false);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [picture, setPicture] = useState(null);
  /*
  * TIP: Make sure these match the backend string values for ease of detection
  * Look in base.js for backend and find the shared increment function
  */
  const [config, setConfig] = useState({
    certificationSubmissions : true,
    checkins : true,
    documentsRead : true,
    documentSubmissions : true,
    documentsUpload : true,
    notifications : true,
    personal : true,
    profile : true,
    safetyObservations : true
  });

  const handleChange = (event) => {
    if (event.target.value === 'all') {
      setRewardRoles([]);
      setSpecificUsers([]);
    }
    setRequired(event.target.value);
  };

  const handleRoleChange = (event) => {
    for (const role of props.roles) {
      if (role.id === event.target.value) {
        setSelectedRole(role);
      }
    }
  };

  const addRole = async () => {
    if (selectedRole !== 'unselected') {
      for (const role of rewardRoles) {
        if (role.id === selectedRole.id) {
          return;
        }
      }
      var copy = [...rewardRoles];
      copy.push(selectedRole);
      setRewardRoles(copy);
    }
  }

  const handleDeleteRole = async (id) => {
    const copy = rewardRoles.filter(role => role.id !== id);
    setRewardRoles(copy);
  }

  const handleDeleteUser = async (id) => {
    const copy = specificUsers.filter(user => user.id !== id);
    setSpecificUsers(copy);
  }

  const addReward = () => {
    const check = {
      title : reward.title === "",
      description : reward.description === "",
      participants : required !== 'all' && rewardRoles.length === 0 && specificUsers.length === 0,
      picture : picture === null,
      date : start.getTime() >= end.getTime()
    };
    setRewardErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "project/reward", { body : {
        projectId : props.project.details.id,
        reward : {
          title : reward.title,
          description : reward.description,
          roles : rewardRoles ? rewardRoles.map(r => r.name) : [],
          start : formatDate(start),
          end : formatDate(end),
          identityId : picture ? picture.identityId : null,
          key : picture ? picture.key : null,
          createdBy : props.user.id,
          users : specificUsers ? specificUsers.map(u => u.id) : [],
          excluded : [],
          config : config
        }
      }}).then((res) => {
        props.loadRewards(props.project.details.id, 'active');
        props.close();
        setRewardRoles([]);
        setSpecificUsers([]);
        setRequired('all');
        setReward({target:[{id:'title', value:''},{id:'description', value:''}]});
        setUpdating(false);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  const searchUser = (name, employees) => {
    if (name !== '' && employees) {
      const search = name.replace(/\W/g, '').toUpperCase()
      const results = Object.values(employees).filter(user => {
        return user.name.replace(/\W/g, '').toUpperCase().startsWith(search) && !user.inactive;
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  const addUser = (user) => {
    const copy = [...specificUsers];
    copy.push(user);
    setSpecificUsers(copy);
  }

  const isIncluded = (user) => {
    for (const r of rewardRoles) {
      if (user.role === r.name) {
        return true;
      }
    }
    for (const u of specificUsers) {
      if (u.id === user.id) {
        return true;
      }
    }
    return false;
  }

  const onChange = (e) => {
    const file = e.target.files[0];
    setUploadingPicture(true);
    const key = KSUID.randomSync().string + '.png';
    Auth.currentCredentials().then(res => {
      Storage.put('rewards/' + key, file, {
          contentType : 'image/png'
      })
      .then (result => {
        Storage.get(result.key).then(value => {
          setPictureUrl(value);
          setPicture({
            key : result.key,
            identityId : res.identityId
          });
          setUploadingPicture(false);
        });
      })
      .catch(err => console.log(err));
    });
  }

  useEffectWhen(() => {
    searchUser(search, props.employees);
  }, [search, props.employees], [search]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <InputLabel error={rewardErrors.title || rewardErrors.description || rewardErrors.date} style={{marginTop:'5px',marginBottom:'5px'}} id="picture-label">New Reward</InputLabel>
          <TextField
            variant="outlined"
            error={rewardErrors.title}
            fullWidth
            required
            size="small"
            id="title"
            label="Title"
            name="title"
            value={reward.title}
            inputProps={{maxLength:60}}
            onChange={setReward}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={rewardErrors.description}
            fullWidth
            required
            multiline
            rows={3}
            size="small"
            id="description"
            label="Description (140 chars max)"
            name="description"
            value={reward.description}
            inputProps={{maxLength:140}}
            onChange={setReward}
            margin="normal"
          />
          <div style={{marginTop:'10px',display:'flex',alignItems:'center'}}>
            <DatePicker
              value={start}
              format="EEEE MMMM d yyyy"
              onChange={setStart}
              TextFieldComponent={props => (
                <>
                  <InputLabel style={{marginLeft:'5px',marginRight:'5px'}} error={rewardErrors.date} id="reward-start">Start</InputLabel>
                  <Input
                    type="text"
                    onClick={props.onClick}
                    value={props.value}
                    onChange={props.onChange}
                    style={{ textAlign : 'center', color : theme.palette.primary.main, cursor : 'pointer', width:'215px' }}
                  />
                </>)}
            />
            <DatePicker
              value={end}
              format="EEEE MMMM d yyyy"
              onChange={setEnd}
              TextFieldComponent={props => (
                <>
                  <InputLabel style={{marginLeft:'5px',marginRight:'5px'}} error={rewardErrors.date} id="reward-end">End</InputLabel>
                  <Input
                    type="text"
                    onClick={props.onClick}
                    value={props.value}
                    onChange={props.onChange}
                    style={{ textAlign : 'center', color : theme.palette.primary.main, cursor : 'pointer', width:'215px' }}
                  />
                </>)}
            />
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <InputLabel error={rewardErrors.picture} style={{marginTop:'5px',marginBottom:'5px'}} id="picture-label">Image of Reward</InputLabel>
            {
              !uploadingPicture &&
              <img style={{width:pictureUrl ? '300px' : '200px',marginBottom:'5px'}} src={pictureUrl ? pictureUrl : props.getBaseUrl() + '/images/placeholder.jpeg'} alt={reward.title} />
            }
            <input type="file" name="rewardPicture" accept='image/png,image/jpeg' onChange={(evt) => onChange(evt)} />
            {
              uploadingPicture &&
              <CircularProgress size={80} />
            }
          </div>
        </Grid>
        <Grid xs={12} item>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
              How will this reward be scored?
            </FormLabel>
            <div className={classes.configRow} style={{marginTop:'10px'}}>
              <Checkbox
                checked={config.certificationSubmissions}
                onChange={() => setConfig({
                  ...config,
                  certificationSubmissions : !config.certificationSubmissions
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Certification Submitted
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="When a required certificate gets submitted a user awarded 1 point.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
              checked={config.checkins}
              onChange={() => setConfig({
                ...config,
                checkins : !config.checkins
              })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Check-In
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="Anytime a user opens their mobile app and views their home screen, a check-in is recorded.  Only 1 point per day awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.documentSubmissions}
                onChange={() => setConfig({
                  ...config,
                  documentSubmissions : !config.documentSubmissions
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Document Submitted
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="Anytime a user fills out one of the forms on their mobile app, 1 point is awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.documentsUpload}
                onChange={() => setConfig({
                  ...config,
                  documentsUpload : !config.documentsUpload
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Document Uploaded
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="Anytime a user uploads a document, this can be a collection of pictures and/or PDF attachments.  1 point is awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.notifications}
                onChange={() => setConfig({
                  ...config,
                  notifications : !config.notifications
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Notification Acknowledged
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="When a user opens any notification and reads it, 1 point is awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.personal}
                onChange={() => setConfig({
                  ...config,
                  personal : !config.personal
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Personal Picture(s)
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="When a user uploads personal pictures, existing personal pictures count.  There is a maximum of 5 points awarded for personal pictures.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.profile}
                onChange={() => setConfig({
                  ...config,
                  profile : !config.profile
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Profile Picture
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="When a user uploads a profile picture, as long as a user maintains a profile picture 1 point is awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.documentsRead}
                onChange={() => setConfig({
                  ...config,
                  documentsRead : !config.documentsRead
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Training Completed
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="If an updated or unread document is viewed by the user on the mobile app, it will record 1 point.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
            <div className={classes.configRow}>
              <Checkbox
                checked={config.safetyObservations}
                onChange={() => setConfig({
                  ...config,
                  safetyObservations : !config.safetyObservations
                })}
                name="private"
                color="primary"
                style={{padding:'0px'}}
              />
              <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
                Safety Observation
              </FormLabel>
              <Tooltip style={{marginLeft:'15px'}} title="When a user records a safety observation, 1 point is awarded.">
                <HelpIcon color="primary" />
              </Tooltip>
            </div>
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <div style={{display : 'flex', flexDirection : 'row'}}>
            <FormControl component="fieldset">
              <FormLabel error={rewardErrors.participants} component="legend">Who will participate?</FormLabel>
              <RadioGroup aria-label="required" name="required" value={required} onChange={handleChange}>
                <FormControlLabel value="all" control={<Radio />} label="Project Wide" />
                <FormControlLabel value="roles" control={<Radio />} label="Specific Role(s)" />
                <FormControlLabel value="specific" control={<Radio />} label="Specific People(s)" />
              </RadioGroup>
            </FormControl>
            {
              required === 'roles' && props.roles.length > 0 &&
              <div style={{display:'flex', flexDirection:'row', alignItems:'top'}}>
                <FormControl className={classes.formControl} style={{flexGrow : 1}}>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    id="role-select"
                    value={selectedRole !== 'unselected' ? selectedRole.id : 'unselected'}
                    onChange={handleRoleChange}
                  >
                    <MenuItem key={'role-unselected'} value={'unselected'}>Please Select</MenuItem>
                  {
                    props.roles.map((role, i) => {
                      return (
                        <MenuItem key={'role-' + i} value={role.id}>{role.name}</MenuItem>
                      )
                    })
                  }
                  </Select>
                </FormControl>
                <div style={{marginLeft:'10px'}}>
                  <Button onClick={() => addRole()} variant="contained" color="secondary">Add Role</Button>
                </div>
                {
                  required === 'roles' && props.roles.length === 0 &&
                  <Typography>There currently are no roles to choose from, add some in the Roles section.</Typography>
                }
              </div>
            }
            {
              required === 'specific' &&
              <div style={{display:'flex', flexGrow:1, flexDirection:'column', alignItems:'top'}}>
                <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'top'}}>
                  <div style={{display : 'flex', flexDirection : 'row', alignItems : 'center', height:'35px'}}>
                    <SearchIcon />
                    <Input
                      id="search-by-name"
                      type={'text'}
                      value={search}
                      placeholder="Search By Name"
                      onChange={(e) => setSearch(e.target.value)}
                      endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
                    />
                  </div>
                </FormControl>
                <div style={{overflowY:'auto',flexGrow:1,height:'170px', border: '1px solid #efefef', margin:'4px'}}>
                  <List>
                    {
                      searchResults.map((user) => {
                        return (
                          <ListItem key={user.id} disableRipple style={{cursor:'default'}} button>
                            {
                              !isMobile &&
                              <ListItemAvatar>
                                <ProfilePicture employee={user} user={props.user} />
                              </ListItemAvatar>
                            }
                            <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user}/>} />
                            <ListItemSecondaryAction>
                              <Button
                                variant="contained"
                                color="secondary"
                                disabled={isIncluded(user)}
                                onClick={() => addUser(user)}>
                                Add to List
                              </Button>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </div>
              </div>
            }
          </div>
          <div style={{width:'100%',marginTop:'5px',marginBottom:'5px'}}>
          {
            required === 'all' &&
            <Chip key={'all-members'} label={'All project members'} style={{margin:'5px'}} color="primary" />
          }
          {
            required !== 'all' &&
            <>
            {
              rewardRoles.map((role,i) => {
                return (
                  <Chip key={'role-add-' + i} label={role.name} style={{margin:'5px'}} onDelete={() => handleDeleteRole(role.id)} color="primary" />
                )
              })
            }
            {
              specificUsers.map((user, i) => {
                return (
                  <Chip key={'user-add-' + i} label={user.name + ''} style={{margin:'5px'}} onDelete={() => handleDeleteUser(user.id)} color="primary" />
                )
              })
            }
            </>
          }
          </div>
        </Grid>
      </Grid>
      <div style={{marginTop:'20px'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => addReward()}>
          {
            !updating &&
            "Add Reward"
          }
          {
            updating &&
            <div>
              <CircularProgress size={10} />
            </div>
          }
        </Button>
      </div>
    </>
  );
}
