import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import Routes from "./Routes";

import { Auth } from "aws-amplify";
import User from './components/Authentication/User';
import { API } from "aws-amplify";
import { isMobile } from "react-device-detect";
import { toDate, datediff } from './libs/util';
import { useEffectWhen } from './libs/hooks';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CreditCard from '@material-ui/icons/CreditCard';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { SELECTED_DATE, SELECTED_COMPANY } from "./config/localStorage";

const regionCodes = {
  us_west_2 : 'ore',
  ap_southeast_2 : 'syd'
}

const theme = createMuiTheme({
  palette: {
    primary : {
      main : "#2e6946"
    },
    secondary : {
      main : "#a1ca8a"
    },
    accentColor : {
      main : "#fce54d"
    }
  },
  typography: {
    fontFamily: [
      'Nunito'
    ]
  }
});

const useStyles = makeStyles((theme) => ({
    title : {
      flexGrow: 1
    },
    appBar : {
      backgroundColor : "#2e6946",
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
}));

function localCompany(companies) {
  const company = localStorage.getItem(SELECTED_COMPANY) !== '' ? JSON.parse(localStorage.getItem(SELECTED_COMPANY)) : null;
  if (company) {
    if (companies && companies.length > 0) {
      for (const comp of companies) {
        if (company.details.id === comp.details.id) {
          return comp;
        }
      }
      return companies[0];
    }
    localStorage.removeItem(SELECTED_COMPANY);
    return null;
  } else if (companies && companies.length > 0) {
    return companies[0];
  } else {
    return null;
  }
}

function App(props) {
  const classes = useStyles();
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [user, setUser] = useState(null);
  const [company, handleSetCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(null);
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [trialExpired, setTrialExpired] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(isMobile ? false : true);
  const [selectedProject, setSelectedProject] = useState(null);

  const menuId = 'primary-search-account-menu';

  useEffect(() => {
    checkAuthentication();
    // On load, we will set to todays date
    const today = new Date();
    localStorage.setItem(SELECTED_DATE, today.toJSON());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated !== null) {
      setAuthenticating(false);
    }
  }, [isAuthenticated]);

  async function checkAuthentication() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setSession(await Auth.currentSession());
      setUser(new User(user.attributes));
      userHasAuthenticated(true);
      loadCompany();
      checkAdmin();
      return user.attributes;
    } catch(e) {
      if (e !== 'not authenticated') {
        console.error(e);
      }
    }
    props.history.push({pathname:'/login',search:props.location.search});
    userHasAuthenticated(false);
  }

  async function handleLogout() {
    localStorage.clear();
    await Auth.signOut();
    userHasAuthenticated(null);
    props.history.push({pathname:'/login',search:props.location.search});
  }

  async function handleLogin(email, password, redirect) {
    try {
      const result = await Auth.signIn(email, password);
      if (result && result.challengeName === "NEW_PASSWORD_REQUIRED") {
        return result;
      } else {
        window.location = redirect;
        return true;
      }
    } catch (e) {
      if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
        return false;
      } else {
        console.error(e);
      }
    }
  }

  async function refreshSession() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, newSession) => {
        setSession(newSession);
      });
    } catch (e) {
      console.error('Unable to refresh Token', e);
    }
  }

  async function checkAdmin() {
    return API.get("budeeBackend", "user/admin", {}).catch(err => {
        console.error(err);
      }).then((res) => setAdmin(res));
  };

  async function loadCompany(id = null) {
    return API.get("budeeBackend", "company/", {}).catch(err => {
        console.error(err);
      }).then((res) => {
        if (Array.isArray(res)) {
          res.sort((a,b) => {
            if (a && a.details && b && b.details) {
              if (a.details.name > b.details.name) {
                return 1;
              } else if (a.details.name < b.details.name) {
                return -1
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });
        }
        setCompanies(res);
        if (res.length === 0) {
          handleSetCompany(null);
          return;
        }
        if (id) {
          for (const comp of res) {
            if (comp.details.id === id) {
              handleSetCompany(comp);
            }
          }
        }
      });
  };

  async function loadProjects(id = null) {
    var url = 'project/';
    if (id) {
      url += '?companyId=' + id;
    }
    return API.get("budeeBackend", url, {}).catch(err => {
        console.error(err);
      }).then((res) => {
        setProjects(res);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffectWhen(() => {
    if (!company && companies && companies.length > 0) {
      const comp = localCompany(companies);
      if (comp) {
        handleSetCompany(comp);
      } else {
        handleSetCompany(companies[0]);
      }
    }
  }, [companies, company], [companies]);

  const setCompany = (comp) => {
    localStorage.setItem(SELECTED_COMPANY, JSON.stringify(comp));
    handleSetCompany(comp);
  }

  useEffect(() => {
    if (company && company.details) {
      loadProjects(company.details.id);
    }
    if (company && company.details && !company.details.confirmed && company.details.created) {
      var cutoff = toDate(company.details.created);
      cutoff.setDate(cutoff.getDate() + 14);
      const today = new Date();
      if (cutoff < today) {
        setTrialExpired(true);
      }
    } else {
      setTrialExpired(false);
    }
  }, [company]);

  useEffect(() => {
    if (companies) {
      setLoading(false);
    }
  }, [companies]);

  const getBaseUrl = () => {
    const region = process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : 'us-west-2';
    const stage = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : 'dev';

    if (region === 'us-west-2') {
      return 'https://' + stage + '-budeestatic.s3.' + region + '.amazonaws.com';
    } else {
      const key = region.replaceAll('-', '_');
      const code = regionCodes.hasOwnProperty(key) ? regionCodes[key] : '';
      return 'https://' + stage + '-' + code + '-budeestatic.s3.' + region + '.amazonaws.com';
    }
  }

  const getDaysLeft = (created) => {
    if (created) {
      var cutoff = toDate(created);
      cutoff.setDate(cutoff.getDate() + 14);
      return Math.max(datediff(new Date(), cutoff), 0);
    }
  }

  const renderStatus = (proj) => {
    if (proj) {
      // Check root
      for (const c of companies) {
        if (c.id === proj.companyId) {
          if (c.root) {
            return 'Root Account';
          } else {
            break;
          }
        }
      }

      switch(proj.status) {
        case 'basic':
          return 'Basic';
        case 'supervisor':
          return 'Supervisor';
        case 'superadmin':
          return 'Administrator';
        default:
          return '';
      }
    }
  }

  const renderTip = (proj) => {
    if (proj) {
      // Check root
      for (const c of companies) {
        if (c.id === proj.companyId) {
          if (c.root) {
            return 'Root Account is the highest privilege level possible, it is automatically assigned to the user who creates the account.  Other users can be added as root accounts on the company profile page only by other root accounts.  It is the equivalent to an Administrator on every project for the entire company, only root accounts can add projects to a company.';
          } else {
            break;
          }
        }
      }

      switch(proj.status) {
        case 'basic':
          return 'Basic is the least privieged account type, very limited console access will be provided to this account at the selected project.';
        case 'supervisor':
          return 'Supervisor provides essentially read-only privileges to the user at the selected project.';
        case 'superadmin':
          return 'Administrator provides essentially full access to the user at the selected project.  The only higher privilege is a root account, this is the account that registered the company and has the privilege to create new projects.';
        default:
          return '';
      }
    }
  }

  return (
    <>
      {
        !isAuthenticating &&
        <div id="budee-app" style={{display:'flex'}}>
          {
            (location.pathname !== '/print' && location.pathname !== '/project') &&
            <AppBar classes={{root:classes.appBar}} position="fixed">
              <Toolbar>
              {
                isMobile &&
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              }
              <Typography variant="h6" className={classes.title}>
              {
                !isMobile &&
                <img height="30px" src="./budee-logo-white.png" alt="Budee Solutions Inc." />
              }

              </Typography>
              {
                isAuthenticated &&
                <div>
                  <Button onClick={() => window.open('https://training.budeesolutions.com/intro?platform=web&user=admin', '_blank')} variant="outlined" style={{borderColor:'#a1ca8a',color:'#a1ca8a',marginRight:'20px'}}>Training Documentation</Button>
                  {
                    selectedProject &&
                    <div style={{color : '#a1ca8a', display : 'inline-block', marginRight:'10px', paddingRight:'10px', borderRight:'1px solid #FFFFFF'}}>
                      <Tooltip title={renderTip(selectedProject)}>
                        <span>
                        {
                          renderStatus(selectedProject)
                        }
                        </span>
                      </Tooltip>
                    </div>
                  }
                  {
                    company && company.details && !company.details.confirmed &&
                    <Chip style={{marginRight:'10px'}} icon={<CreditCard />} label={getDaysLeft(company.details.created) + (isMobile ? '' : ' Days Remaining')} />
                  }
                  {
                    user &&
                    <div style={{color : '#ffffff', display : 'inline-block', paddingRight:'10px', borderRight:'1px solid #FFFFFF'}}>{ user.firstname + ' ' + user.lastname }</div>
                  }
                  <Button onClick={() => handleLogout()} color="inherit">Logout</Button>
                  <IconButton
                    edge="end"
                    aria-label="current user account"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={() => window.location = '/user'}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              }
              </Toolbar>
            </AppBar>
          }
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Routes appProps={{
                isAuthenticated,
                handleLogin,
                handleLogout,
                isAuthenticating,
                checkAuthentication,
                user,
                admin,
                session,
                refreshSession,
                loadCompany,
                companies,
                company,
                setCompany,
                setSelectedProject,
                loadProjects,
                projects,
                loading,
                mobileOpen,
                handleDrawerToggle,
                getBaseUrl,
                trialExpired }} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      }
    </>
  )
}

export default withRouter(App);
