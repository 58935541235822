export const USER_STATUS = {
  SUPERADMIN : 'superadmin',
  SUPERVISOR : 'supervisor',
  BASIC : 'basic'
};

export const PRIVILEGED_USER = [USER_STATUS.SUPERADMIN, USER_STATUS.SUPERVISOR];

export const METRIC = 'Metric';
export const IMPERIAL = 'Imperial';

export const SLEEP = {
  HOURS : [
    {
      value : 1,
      label : 1
    },
    {
      value : 2,
      label : 2
    },
    {
      value : 3,
      label : 3
    },
    {
      value : 4,
      label : 4
    },
    {
      value : 5,
      label : 5
    },
    {
      value : 6,
      label : 6
    },
    {
      value : 7,
      label : 7
    },
    {
      value : 8,
      label : 8
    },
    {
      value : 9,
      label : 9
    },
    {
      value : 10,
      label : '10+'
    }
  ],
  QUALITY : [
    {
      value : 1,
      label : 'Exhausted, low energy'
    },
    {
      value : 2,
      label : 'Tired, need a nap'
    },
    {
      value : 3,
      label : 'Energy Low'
    },
    {
      value : 4,
      label : 'Neither Alert nor Sleepy'
    },
    {
      value : 5,
      label : 'Rested, energetic'
    },
    {
      value : 6,
      label : 'Wide Awake, Energetic'
    }
  ]
};

export const DASS = {
  normal : {
    depression : {
      low : 0,
      high : 7
    },
    anxiety : {
      low : 0,
      high : 5
    },
    hostility : {
      low : 0,
      high : 11
    }
  },
  mild : {
    depression : {
      low : 7,
      high : 12
    },
    anxiety : {
      low : 5,
      high : 9
    },
    hostility : {
      low : 11,
      high : 17
    }
  },
  moderate : {
    depression : {
      low : 12,
      high : 20
    },
    anxiety : {
      low : 9,
      high : 14
    },
    hostility : {
      low : 17,
      high : 26
    }
  },
  severe : {
    depression : {
      low : 20,
      high : 27
    },
    anxiety : {
      low : 14,
      high : 19
    },
    hostility : {
      low : 26,
      high : 34
    }
  },
  extreme : {
    depression : {
      low : 27,
      high : 126
    },
    anxiety : {
      low : 19,
      high : 126
    },
    hostility : {
      low : 34,
      high : 126
    }
  }
};

export const STATES = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

export const PROVINCES = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NS": "Nova Scotia",
  "NT": "Northwest Territories",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Québec",
  "SK": "Saskatchewan",
  "YT": "Yukon"
};

export const AUSTRALIAN_PROVINCES = {
  "ACT": "Australian Capital Territory",
  "NSW": "New South Wales",
  "NT": "Northern Territory",
  "QLD": "Queensland",
  "SA": "South Australia",
  "TAS": "Tasmania",
  "VIC": "Victoria",
  "WA": "Western Australia"
};

export const IRELAND_PROVINCES = {
  "CW":"Carlow",
  "CN":"Cavan",
  "CE":"Clare",
  "CO":"Cork",
  "DL":"Donegal",
  "D":"Dublin",
  "G":"Galway",
  "KY":"Kerry",
  "KE":"Kildare",
  "KK":"Kilkenny",
  "LS":"Laois",
  "LM":"Leitrim",
  "LK":"Limerick",
  "LD":"Longford",
  "LH":"Louth",
  "MO":"Mayo",
  "MH":"Meath",
  "MN":"Monaghan",
  "OY":"Offaly",
  "RN":"Roscommon",
  "SO":"Sligo",
  "TA":"Tipperary",
  "WD":"Waterford",
  "WH":"Westmeath",
  "WX":"Wexford",
  "WW":"Wicklow"
};

export const UNITED_KINGDOM_PROVINCES = {
  "GB-ENG": "England",
  "GB-SCT": "Scotland",
  "GB-WLS": "Wales",
  "GB-NIR": "Northern Ireland"
};
