import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dashboard from '../components/Dashboard/Dashboard';

import { useParams } from "react-router";
import { useEffectWhen } from "../libs/hooks.js";

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { API } from "aws-amplify";

export default function Home(props) {
  let { page } = useParams();

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [version, setVersion] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkLicenseAgreement = async (company, mounted) => {
    const result = await API.get('budeeBackend', 'company/license/check', {});
    if (!result) {
      if (mounted) {
        setVersion(await API.get('budeeBackend', 'company/license', {}));
        handleClickOpen();
      }
    }
  }

  const acceptTerms = async () => {
    const version = API.post('budeeBackend', 'company/license', {
      body : {
        metadata : null
      }
    });
    setVersion(version);
    handleClose();
  }

  useEffectWhen(() => {
    var mounted = true;
    if (props.company && props.company.root) {
      checkLicenseAgreement(props.company, mounted);
    }
    return () => mounted = false;
  }, [props.company, checkLicenseAgreement], [props.company]);

  return (
    <>
    {
      props.loading &&
      <>
        <div style={{margin:'100px auto'}}>
          <CircularProgress size={80} />
        </div>
      </>
    }
    {
      !props.loading &&
      <Dashboard page={page} {...props} />
    }
    <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent>
          <Typography>Please review and accept the current Terms and Conditions.  If you have previously accepted, this is a new version and changes have been made.  In any case, please review and accept to continue.</Typography>
          <div><a target="_blank" rel="noopener noreferrer" href={props.getBaseUrl() + '/license/' + version + '.pdf'}>Terms and Conditions</a></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleLogout()} color="primary">
            Decline
          </Button>
          <Button onClick={() => acceptTerms()} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
