import React, { useState } from "react";
import { Auth } from 'aws-amplify';

import { COUNTRY_CODE, LOGIN_TYPE } from "../../config/localStorage";
import { useFormFields } from "../../libs/hooks";
import queryString from 'query-string';
import { phone } from 'phone';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const callingCountries = require('country-data').callingCountries;

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  unrecognized: {
    color:'red'
  },
  container:{
    textAlign:'left'
  },
  link : {
    cursor : 'pointer',
    marginTop:'100px'
  },
  select : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px',
    marginRight:'5px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

function localCountryCode() {
  const countryCode = localStorage.getItem(COUNTRY_CODE);
  return countryCode && countryCode !== '' ? countryCode : "{\"alpha2\":\"US\",\"alpha3\":\"USA\",\"countryCallingCodes\":[\"+1\"],\"currencies\":[\"USD\"],\"emoji\":\"\uD83C\uDDFA\uD83C\uDDF8\",\"ioc\":\"USA\",\"languages\":[\"eng\"],\"name\":\"United States\",\"status\":\"assigned\"}";
}

function localType() {
  const login = localStorage.getItem(LOGIN_TYPE);
  return login && login !== '' ? login : 'login';
}

export default function LoginForm(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [unrecognized, setUnrecognized] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [user, setUser] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    code: "",
    newPassword:""
  });
  const [type, setType] = useState(localType());
  const [countryCode, setCountryCode] = useState(localCountryCode());
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    // Check if the URL contained a redirect parameter
    const urlParams = queryString.parse(props.location.search);
    var redirect;
    if (urlParams.redirect) {
      redirect = urlParams.redirect;
    } else {
      redirect = "/";
    }

    var login = '';
    if (type === 'phone') {
      const phoneObj = getPhone(phoneNumber);
      if (phoneObj.isValid) {
        setPhoneError(false);
        login = phoneObj.phoneNumber
      } else {
        setPhoneError(true);
        setIsLoading(false);
        return;
      }
    } else {
      login = fields.email;
    }

    props.handleLogin(login, fields.password, redirect)
      .then(result => {
        setIsLoading(false);
        if (!result)
          setUnrecognized(true);
        if (result && result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setNewPasswordRequired(true);
          setUser(result);
        }
      })
      .catch(e => console.error(e));
  }

  async function handleForgotPasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.forgotPassword(fields.email);
      setConfirmNewPassword(true);
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  async function handleConfirmNewPasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.newPassword);
      setForgotPassword(false);
      setConfirmNewPassword(false);
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  async function handleNewPasswordRequired(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      Auth.completeNewPassword(
        user,
        fields.newPassword,
      ).then(user => {
        window.location = '/dashboard';
      }).catch(e => {
        console.error(e);
      });
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  function validateForm() {
    if (type === 'login') {
      return (
        fields.email.length > 0 &&
        fields.password.length > 0
      );
    } else {
      return (
        phoneNumber.length > 0 &&
        fields.password.length > 0
      );
    }

  }

  const getPhone = (phoneNumber) => {
    const country = JSON.parse(countryCode);
    var phoneObj = phone(country.countryCallingCodes[0] + phoneNumber,{country: country.alpha3});
    return phoneObj;
  }

  return (
    <div className={classes.container}>
      <div style={{display:'flex',flexDirection:'row'}}>
        <Typography component="h1" variant="h5">
          { !forgotPassword && 'Sign in' }
          { forgotPassword && 'Password Reset' }
        </Typography>
        <RadioGroup value={type} onChange={(e) => {
          setType(e.target.value);
          localStorage.setItem(LOGIN_TYPE, e.target.value);
        }} style={{display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'flex-end'}}>
          <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          <FormControlLabel value="login" control={<Radio />} label="Login" />
        </RadioGroup>
      </div>
      {
        !newPasswordRequired && !forgotPassword && !confirmNewPassword &&
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {
            type === 'login' &&
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email/Username"
              name="email"
              autoComplete="email"
              size="medium"
              autoFocus
              onChange={handleFieldChange}
            />
          }
          {
            type === 'phone' &&
            <div style={{display:'flex',flexDirection:'row'}}>
              <Select
                variant="outlined"
                id="countryCode"
                name="countryCode"
                size="small"
                className={classes.select}
                classes={{root : classes.selectInput}}
                value={countryCode}
                onChange={(e) => {
                  localStorage.setItem(COUNTRY_CODE, e.target.value);
                  setCountryCode(e.target.value);
                }}
              >
                {
                  callingCountries && callingCountries.all.map((ctry, i) => {
                    return (
                      <MenuItem key={'country-' + i} value={JSON.stringify(ctry)}>{ctry.emoji} - {ctry.alpha3}</MenuItem>
                    );
                  })
                }
              </Select>
              <TextField
                variant="outlined"
                required
                fullWidth
                size="medium"
                id="phone"
                label="Phone"
                name="phone"
                value={phoneNumber}
                autoComplete="tel-national"
                onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))}
                margin="normal"
              />
            </div>
          }
          <div style={{display:'flex', alignItems:'center'}}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={ showPassword ? "text" : "password" }
              id="password"
              autoComplete="current-password"
              size="medium"
              onChange={handleFieldChange}
              style={{marginRight:'10px'}}
            />
            {
              showPassword &&
              <VisibilityIcon onClick={() => setShowPassword(!showPassword)}/>
            }
            {
              !showPassword &&
              <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
            }
          </div>
          {
            phoneError &&
            <p className={classes.unrecognized}>Invalid phone number, please ensure correct country code and valid phone number provided.</p>
          }
          {
            unrecognized &&
            <p className={classes.unrecognized}>Unrecognized email and password.</p>
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!validateForm()}
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      }
      {
        !forgotPassword && !newPasswordRequired &&
        <>
          {
            !props.checkout &&
            <>
              <Grid container>
                <Grid item xs>
                  <div style={{width:'100%', display: 'flex', flexDirection : 'row'}}>
                    <Link href="#" onClick={() => setForgotPassword(true)} variant="body2">
                      Forgot password?
                    </Link>
                    <Link classes={{root:classes.link}} style={{flexGrow:1, margin : '0', textAlign : 'right'}} onClick={() => props.setLogin(false)} variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </>
          }
          { isLoading &&
            <div style={{textAlign:'center'}}>
              <CircularProgress size={80} />
            </div>
          }
        </>
      }
      {
        forgotPassword && !confirmNewPassword &&
        <form className={classes.form} onSubmit={handleForgotPasswordSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleFieldChange}
            value={fields.email}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Code
          </Button>
        </form>
      }
      {
        newPasswordRequired && !forgotPassword &&
        <form className={classes.form} onSubmit={handleNewPasswordRequired} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="Reset Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            onChange={handleFieldChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
        </form>
      }
      {
        forgotPassword && confirmNewPassword &&
        <form className={classes.form} onSubmit={handleConfirmNewPasswordSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Verification Code"
            name="code"
            autoFocus
            onChange={handleFieldChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            onChange={handleFieldChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Set New Password
          </Button>
        </form>
      }
      { forgotPassword && isLoading &&
        <div className={classes.loadingSpinnerContainer}>
          <CircularProgress size={80} />
        </div>
      }
      {
        forgotPassword &&
        <>
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={() => setForgotPassword(false)} variant="body2">
                Back to Login
              </Link>
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
}
