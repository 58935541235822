import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";
import SubmissionForm from "../Certifications/SubmissionForm";

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';

import { isMobile } from "react-device-detect";
import { CSVDownload } from "react-csv";

const { toDate } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  listContainer : {
    overflowY:'scroll',
    height : '500px'
  },
  checkinButton : {
    [theme.breakpoints.down('sm')]: {
      padding : '0px'
    }
  }
}));

export default function Certifications(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [required, setRequired] = useState(props.required);
  const [employee, setEmployee] = useState(null);
  const [certification, setSelectedCertification] = useState(props.certification);
  const [submissions, setSubmissions] = useState(props.submissions);
  const [submission, setSubmission] = useState(null);

  const [expires, setExpires] = useState([]);
  const [missing, setMissing] = useState([]);
  const [unverified, setUnverified] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [csvData, setCsvData] = useState(null);

  const handleClickOpen = (emp) => {
    setEmployee(emp);
    if (submissions && submissions.hasOwnProperty(certification.id) && submissions[certification.id].hasOwnProperty(emp.id)) {
      setSubmission(submissions[certification.id][emp.id]);
    } else {
      setSubmission(null);
    }
    setOpen(true);
  };

  useEffect(() => {
    setSelectedCertification(props.certification)
  }, [props.certification]);

  useEffect(() => {
    setSubmissions(props.submissions);
  }, [props.submissions]);

  useEffect(() => {
    setRequired(props.required);
  }, [props.required]);

  useEffect(() => {
    if (required && certification && submissions && submissions.hasOwnProperty(certification.id)) {
      var certSubmissions = submissions[certification.id];
      var m = [];
      var u = [];
      var exp = [];
      var comp = [];
      for (const id of Object.values(required)) {
        if (!certSubmissions.hasOwnProperty(id)) {
          m.push(id);
        } else {
          let empCert = certSubmissions[id];
          if (empCert.expires) {
            const expiryDate = toDate(empCert.expires);
            var today = new Date();
            today.setDate(today.getDate() + certification.warning);
            if (expiryDate < today) {
              exp.push(id);
              continue;
            }
          }
          if (!empCert.verified) {
            u.push(id);
            continue;
          }
          comp.push(id);
        }
      }
      setMissing(m);
      setUnverified(u);
      setExpires(exp);
      setCompleted(comp);
    }
  }, [required, submissions, certification]);

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    var certSubmissions = submissions[certification.id];
    for (const id of expires) {
      const u = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
      if (u) {
        if (certSubmissions.hasOwnProperty(id)) {
          let empCert = certSubmissions[id];
          if (empCert.expires) {
            const expiryDate = toDate(empCert.expires);
            var today = new Date();
            let status = 'EXPIRES SOON';
            if (expiryDate < today) {
              status = 'EXPIRED'
            }
            data.push({
              name : u.name,
              role : u.role,
              status : status
            });
          }
        }
      }
    }
    for (const id of missing) {
      const u = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
      if (u) {
        data.push({
          name : u.name,
          role : u.role,
          status : 'MISSING'
        });
      }
    }
    for (const id of unverified) {
      const u = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
      if (u) {
        data.push({
          name : u.name,
          role : u.role,
          status : 'COMPLETED'
        });
      }
    }
    for (const id of completed) {
      const u = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
      if (u) {
        data.push({
          name : u.name,
          role : u.role,
          status : 'COMPLETED'
        });
      }
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <IconButton onClick={() => downloadCsv()}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <Typography variant="h6">CSV</Typography>
      </div>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Typography variant="h6">{props.certification && props.certification.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
      </div>
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
        {
          props.certification && !props.certification.contractors && !props.certification.roles &&
          <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="Project-Wide - All Employees" />
        }
        {
          props.certification && props.certification.contractors && props.certification.contractors.map((c,i) => {
            return (
              <Chip key={'contractor-' + i} size="small" style={{margin:'4px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={c.name} />
            )
          })
        }
        {
          props.certification && props.certification.roles && props.certification.roles.map((r,i) => {
            return (
              <Chip key={'role-' + i} size="small" style={{margin:'4px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white',fontSize:20,marginRight:'5px'}} />} label={r.name} />
            )
          })
        }
      </div>
      {
        props.employees &&
        <Grid container>
          <Grid sm={3} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="There currently is no record of this certification for the employee">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Missing</Typography>
            </div>
            {
              missing.map((id, i) => {
                var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
                if (!emp) {
                  return (<React.Fragment key={'missing-' + i}></React.Fragment>);
                }
                return (
                  <ListItem style={{marginBottom:'2px',borderRadius:10,border: "1px solid red"}} key={'missing-' + emp.id} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                  </ListItem>
                )
              })
            }
          </Grid>
          <Grid sm={3} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="An employee has submitted a certification from the mobile application, but it has not been reviewed by an administrator">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Pending Verification</Typography>
            </div>
            {
              unverified.map((id, i) => {
                var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
                if (!emp) {
                  return (<React.Fragment key={'missing-' + i}></React.Fragment>);
                }
                return(
                  <ListItem key={'missing-' + emp.id} style={{marginBottom:'2px',borderRadius:10,border: "1px solid " + theme.palette.secondary.main}} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                  </ListItem>
                )
              })
            }
          </Grid>
          <Grid sm={3} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="A previously submitted certification is either expired (red) or is within the warning time range (yellow) of being expired.">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Expired or Expires Soon</Typography>
            </div>
            {
              expires.map((id) => {
                var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
                if (!emp) {
                  return (<React.Fragment key={'missing-' + emp.id}></React.Fragment>);
                }

                let color = 'gold';
                if (!submissions[certification.id].hasOwnProperty(id)) {
                  color = 'red';
                } else {
                  let empCert = submissions[certification.id][id];
                  if (empCert.expires) {
                    const expiryDate = toDate(empCert.expires);
                    var today = new Date();
                    if (expiryDate < today) {
                      color = 'red';
                    }
                  }
                }

                return(
                  <ListItem key={'missing-' + emp.id} style={{marginBottom:'2px',borderRadius:10,border: "1px solid " + color}} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                  </ListItem>
                )
              })
            }
          </Grid>
          <Grid sm={3} style={{padding:'4px'}} item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'5px'}}>
              <Tooltip style={{marginRight:'15px'}} title="Employee has a certification record and is not near expiry date">
                <HelpIcon color="primary" />
              </Tooltip>
              <Typography variant="body2">Completed</Typography>
            </div>
            {
              completed.map((id, i) => {
                var emp = props.employees.hasOwnProperty(id) ? props.employees[id] : null;
                if (!emp) {
                  return (<React.Fragment key={'missing-' + emp ? emp.id : i}></React.Fragment>);
                }
                return(
                  <ListItem key={'missing-' + emp.id} style={{marginBottom:'2px',borderRadius:10,border: "1px solid " + theme.palette.primary.main}} onClick={() => handleClickOpen(emp)} button>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={emp} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={emp.name} secondary={<Role user={emp}/>} />
                  </ListItem>
                )
              })
            }
          </Grid>
        </Grid>
      }
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          certification &&
          <>
            <Typography variant="h6">{certification.name}</Typography>
            <Typography variant="body2">{certification.description}</Typography>
            {
              !certification.roles &&
              <Chip key='role-req-projectWide' label="Project Wide" style={{margin:'5px'}} color="primary" />
            }
            {
              certification.roles && certification.roles.map((role, i) => {
                return (
                  <Chip key={'role-req-' + i} label={role.name} style={{margin:'5px'}} color="primary" />
                )
              })
            }
            <Divider />
            <SubmissionForm user={props.user} submitFor={employee} submission={submission} certification={certification} project={props.project} company={props.company} onComplete={() => {
              setOpen(false);
              props.reload();
            }} />
          </>
        }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
