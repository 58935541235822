import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

import { useEffectWhen } from '../../../../../../libs/hooks';

import ProfilePicture from "../../../../../User/ProfilePicture";
import Role from "../../../../../User/Role";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isMobile } from "react-device-detect";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { API, Storage } from "aws-amplify";
const { DOCUMENTS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  row : {
    display : 'flex',
    flexDirection : 'column',
    justifyContent:'flex-start',
    width:'calc(100%-10px)'
  },
  title : {
    flexGrow : 2,
    flexBasis : 0
  },
  value : {
    flexGrow : 5,
    flexBasis : 0,
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  valuePrint : {
    width : '100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'center',
    paddingLeft:'10px'
  }
}));

export default function UserList(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && props.print) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [props.print]);
  useEffect(() => {
    if (props.print) {
      props.updateDimensions(dimensions);
    }
  }, [props, dimensions]);

  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState([]);
  const [value, setValue] = useState(props.value && props.value.value ? props.value.value : []);
  const [signatures, setSignatures] = useState({});
  const [signatureImages, setSignatureImages] = useState({});
  const firstUpdate = useRef(true);

  useEffect(() => {
    var mounted = true;
    if (props.field && props.field.config && props.field.config.signature && props.projectId && props.submission && props.field && props.documentId) {
      API.get('budeeBackend', 'documents/signature', {
        queryStringParameters : {
          projectId : props.projectId,
          documentId : props.documentId,
          documentVersion : props.submission.documentVersion,
          submissionId : props.submission.id,
          fieldId : props.field.id
        }
      }).then(res => {
        if (mounted) {
          setSignatures(res.reduce(function(map, obj) {
            map[obj.userId] = obj;
            return map;
          }, {}));
        }
      })
    }
    return () => mounted = false;
  }, [value, props]);

  useEffect(() => {
    var mounted = true;
    if (signatures) {
      var calls = [];
      for (const sig of Object.values(signatures)) {
        if (sig.key && sig.identityId) {
          calls.push(Storage.get(sig.key, {
            level: "protected",
            identityId : sig.identityId
          }))
        } else {
          calls.push(null);
        }
      }
      Promise.all(calls).then(res => {
        let i = 0;
        var imageUrls = {};
        for (const key of Object.keys(signatures)) {
          imageUrls[key] = res[i];
          i++;
        }
        if (mounted) {
          setSignatureImages(imageUrls);
        }
      })
    }
    return () => mounted = false;
  }, [signatures]);

  const getEmployee = (e) => {
    if (props.employees && props.employees.hasOwnProperty(e.id)) {
      return props.employees[e.id];
    } else {
      return null;
    }
  }

  const remove = (index) => {
    var copy = [...value];
    copy.splice(index, 1);
    setValue(copy);
  }

  const add = (v) => {
    var copy = [...value];
    copy.push({
      name : v.name
    });
    setValue(copy);
    setSearch('');
    setSelection([]);
  }

  const addUser = (v) => {
    var copy = [...value];
    for (const u of copy) {
      if (u.id === v.id) {
        return;
      }
    }
    copy.push({
      id : v.id,
      name : v.name
    });
    setValue(copy);
    setSelection([]);
    setSelection([]);
  }

  const lookup = async (name) => {
    setSearch(name);
    if (name === '') {
      setSelection([]);
      return;
    }
    var matches = [];
    for (const e of Object.values(props.employees)) {
      if (!e.inactive && e.name.toLowerCase().search(name.toLowerCase()) >= 0) {
        matches.push(e);
      }
    }
    setSelection(matches);
  }

  const save = async (v) => {
    API.post('budeeBackend', 'documents/value', {
      body : {
        projectId : props.projectId,
        documentId : props.documentId,
        documentVersion : props.submission.documentVersion,
        field : {
          type : DOCUMENTS.FIELDS.USER_LIST.ID,
          value : v,
          fieldId : props.field.id,
          indexed : props.field.indexed
        },
        submissionId : props.submission.id
      }
    }).catch(e => console.error(e));
  }

  useEffectWhen(() => {
    lookup(search);
  }, [search, lookup], [search]);

  useEffectWhen(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    save(value);
  }, [value, save], [value]);

  return (
    <Grid xs={12} sm={(props.field && props.field.width ? props.field.width : 4)} item ref={targetRef}>
      <div className={classes.row} style={props.print ? {margin:'5px',padding:'5px'} : {}}>
        <div className={classes.title}>
          <Typography variant="body2" style={props.print ? {textDecorationLine:'underline'} : {}}>{props.field.name}</Typography>
        </div>
        {
          !props.print &&
          <div style={{position:'relative'}}>
            <TextField
              variant="outlined"
              required
              size="small"
              id={props.field.id}
              value={search}
              inputProps={{maxLength:60}}
              onChange={e => setSearch(e.target.value)}
              margin="none"
            />
            <Button variant="contained" color="primary" style={{marginLeft:'10px'}} onClick={() => add(search)}>Add</Button>
            <List>
              {
                selection.map((user) => {
                  return (
                    <ListItem disableRipple key={user.id} button onClick={() => addUser(user)}>
                      {
                        !isMobile &&
                        <ListItemAvatar>
                          <ProfilePicture user={props.user} employee={user} />
                        </ListItemAvatar>
                      }
                      <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user}/>} />
                    </ListItem>
                  )
                })
              }
            </List>
          </div>
        }
        {
          value && value.map((v, i) => {
            return (
              <div key={"user-list-" + i} className={classes.valuePrint}>
                <div className={classes.value}>
                  {
                    !props.print &&
                    <DeleteForeverIcon style={{cursor:'pointer',marginRight:'10px'}} color="primary" onClick={() => remove(i)} />
                  }
                  {
                    !isMobile && !props.print &&
                    <ProfilePicture user={props.user} employee={getEmployee(v)} />
                  }

                  <Typography style={{marginLeft:'10px',marginRight:'10px',flexGrow:1}} variant="body2">{v ? v.name : ''}</Typography>
                </div>
                {
                  signatureImages && signatureImages.hasOwnProperty(v.id) && signatureImages[v.id] &&
                  <img src={signatureImages[v.id]} alt={v.name + ' signature'} height="30px" style={{marginLeft:'10px',border:'1px solid black',padding:'2px'}} />
                }
              </div>
            )
          })
        }
      </div>
    </Grid>
  );
}
