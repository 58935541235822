import React, { useState, useEffect, useRef } from "react";

import { doScrolling } from '../../../../libs/util';
import { API } from "aws-amplify";
import { useTheme } from '@material-ui/core/styles';
import DocumentForm from './Upload/DocumentForm';
import ViewDetails from './Upload/ViewDetails';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const { formatDateTime } = require('@budeesolutions/budee-util');
const { PRIVILEGED_USER } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  documentList : {
    width : '500px'
  },
  documentRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  }
}));

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

export default function Documents(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [open, setOpen] = useState(false);

  const documentFormRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    var mounted = true;
    var grid = [];
    for (const doc of documents) {
      var username = '';
      if (props.employees && props.employees.hasOwnProperty(doc.userId)) {
        username = props.employees[doc.userId].name;
      }
      grid.push({
        id : doc.id,
        date : doc.date ? formatDateTime(new Date(doc.date)) : '',
        user : username,
        name : doc.name,
        contractor : doc.contractor,
        files : doc.files ? doc.files.length : 0,
        images : doc.images ? doc.images.length : 0,
        archive : doc.id,
        doc : doc
      });
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [documents, props.employees]);

  useEffect(() => {
    if (props.project) {
      setLoading(true);
      if (PRIVILEGED_USER.includes(props.project.status)) {
        loadDocuments(props.project.details.id);
      }
    }
  }, [props.project]);

  useEffect(() => {
    if (showAdd) {
      scrollToRef(documentFormRef);
    }
  }, [showAdd]);

  const loadDocuments = async (projectId) => {
    return API.get("budeeBackend", "documents/upload?projectId=" + projectId, {}).then(res => {
      if (res) {
        setDocuments(res);
      }
      setLoading(false);
    });
  };

  const archiveDocument = async (projectId, documentId) => {
    return API.del("budeeBackend", "documents/upload", {
      body : {
        projectId : projectId,
        documentId : documentId
      }
    }).then(res => {
      loadDocuments(projectId);
    });
  }

  var columns = [
    { field: 'id', hide : true },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'user', headerName: 'Employee', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'contractor', headerName: 'Contractor', flex: 1 },
    { field: 'files', headerName: 'Files' },
    { field: 'images', headerName: 'Images' },
    { field: 'archive', headerName: ' ', cellClassName : 'no-select', renderCell : (val) => {
      return (
        <Button variant="outlined" size="small" color="secondary" onClick={() => archiveDocument(props.project.details.id, val.value)}>Archive</Button>
      );
    }}
  ];

  const handleClickOpen = async (event) => {
    setDetailsSelected(event.row.doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
      {
        props.project && PRIVILEGED_USER.includes(props.project.status) &&
        <>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            rows && rows.length > 0 &&
            <p>Upload external documents for record keeping, any documents uploaded from mobile will appear here.</p>
          }
          {
            !loading && rows && rows.length === 0 &&
            <>
              <div style={{width:'100%',textAlign:'center'}}>
                <Typography variant="body2">Currently No Documents Uploaded.</Typography>
              </div>
              <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
              <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-0dNSdVQ-ps" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                </div>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <Typography variant="h6">Learn About Document Uploads</Typography>
                  <Typography variant="body2">Watch this video to learn more about how document uploads work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                  <ul>
                    <li>Upload via Web</li>
                    <li>Upload via Mobile</li>
                    <li>Searching on Dashboard</li>
                    <li>Searching all Submitted</li>
                  </ul>
                  <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                </div>
              </div>
            </>
          }
          {
            !loading && rows && rows.length > 0 &&
            <>
              <div style={{height:'400px'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  density="compact"
                  autoPageSize={true}
                  pageSize={5}
                  sortModel={[{field: 'date', sort: 'desc'}]}
                  onCellClick={(e) => {
                    const arr = Array.from(e.element.classList);
                    if (!arr.includes('no-select')) {
                      handleClickOpen(e);
                    }
                  }}
                  className={classes.documentRow}
                />
                <Dialog
                  fullScreen={fullScreen}
                  maxWidth="md"
                  fullWidth={true}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title"></DialogTitle>
                  <DialogContent>
                    <ViewDetails user={props.user} details={detailsSelected} employees={props.employees} setOpen={setOpen} project={props.project} company={props.company} loadDocuments={loadDocuments} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          }
          <div ref={documentFormRef}>
            {
              !showAdd &&
              <Button onClick={() => setShowAdd(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                Upload Document
              </Button>
            }
            {
              showAdd &&
              <>
                <Button onClick={() => setShowAdd(false)} style={{margin:'10px'}} color="secondary" variant="outlined">
                  Cancel Upload Document
                </Button>
                <DocumentForm contractors={props.contractors} project={props.project} loadDocuments={loadDocuments} />
              </>
            }
          </div>
        </>
      }
      {
        props.project && !PRIVILEGED_USER.includes(props.project.status) &&
        <DocumentForm contractors={props.contractors} project={props.project} loadDocuments={loadDocuments} />
      }
      </Grid>
    </Grid>
  );
}
