export const SELECTED_DATE = 'selected-date';
export const SELECTED_PROJECT = 'selected-project';
export const PROJECT = 'project';
export const SELECTED_COMPANY = 'selected-company';
export const SELECTED_FILTERS = 'selected-filters';
export const ACTIVE_NODES = 'active-nodes';
export const FREE_TRIAL = 'free-trial';
export const DOCUMENT_PRINT_JOB = 'document-print-job';
export const COUNTRY_CODE = 'country-code';
export const LOGIN_TYPE = 'login-type';
export const CHART_BRIGHTNESS = 'chart-brightness';
export const OBSERVATION_FILTERS = 'observation-filters';
