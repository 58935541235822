import React, { useState, useEffect } from "react";

import { API, Auth, Storage } from "aws-amplify";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormFields } from "../../../../../libs/hooks";
import { LANGUAGES } from "../../../../../config/languages";
import { v4 as uuidv4 } from 'uuid';

import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const { formatDateTime, formatBytes } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  }
}));

export default function DocumentForm(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fileRef = React.useRef();

  const [required, setRequired] = useState('all');
  const [documentRoles, setDocumentRoles] = useState(props.document && props.document && props.document.roles ? props.document.roles : []);
  const [documentContractors, setDocumentContractors] = useState(props.document && props.document && props.document.contractors ? props.document.contractors : []);
  const [documentErrors, setDocumentErrors] = useState({
    name : false,
    description : false
  });
  const [document, setDocument] = useFormFields({
    name : props.document && props.document.name ? props.document.name : "",
    description : props.document && props.document.description ? props.document.description : ""
  });
  const [selectedRole, setSelectedRole] = useState('unselected');
  const [selectedContractor, setSelectedContractor] = useState('unselected');
  const [updating, setUpdating] = useState(false);
  const [showUpload, setShowUpload] = useState(null);
  const [uploadingLangDoc, setUploadingLangDoc] = useState(false);
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [translationFiles, setTranslationFiles] = useState(null);
  const [type, setType] = useState(props.document && props.document.type ? props.document.type : 'application/pdf');
  const [scope, setScope] = useState(props.document && props.document.scope ? props.document.scope : 'company');
  const [language, setLanguage] = useState('unselected');
  const [documentProgress, setDocumentProgress] = useState({
    loaded : 0,
    total : 100
  });

  const handleChange = (event) => {
    if (event.target.value === 'all') {
      setDocumentRoles([]);
      setDocumentContractors([]);
    }
    setRequired(event.target.value);
  };

  const handleRoleChange = (event) => {
    for (const role of props.roles) {
      if (role.id === event.target.value) {
        setSelectedRole(role);
      }
    }
  };

  const handleContractorChange = (event) => {
    for (const contractor of props.contractors) {
      if (contractor.id === event.target.value) {
        setSelectedContractor(contractor);
      }
    }
  };

  const addRole = async () => {
    if (selectedRole !== 'unselected') {
      for (const role of documentRoles) {
        if (role.id === selectedRole.id) {
          return;
        }
      }
      var copy = [...documentRoles];
      copy.push(selectedRole);
      setDocumentRoles(copy);
    }
  }

  const addContractor = async () => {
    if (selectedContractor !== 'unselected') {
      for (const contractor of documentContractors) {
        if (contractor.id === selectedContractor.id) {
          return;
        }
      }
      var copy = [...documentContractors];
      copy.push(selectedContractor);
      setDocumentContractors(copy);
    }
  }

  const handleDeleteRole = async (id) => {
    const copy = documentRoles.filter(role => role.id !== id);
    setDocumentRoles(copy);
  }

  const handleDeleteContractor = async (id) => {
    const copy = documentContractors.filter(contractor => contractor.id !== id);
    setDocumentContractors(copy);
  }

  useEffect(() => {
    var mounted = true;
    if (props.document && props.document.identityId && props.document.key) {
      Storage.get(props.document.key, {
        level: "protected",
        identityId : props.document.identityId
      }).then(value => {
        if (mounted) {
          setFile({
            key : props.document.key,
            identityId : props.document.identityId
          });
          setDocUrl(value);
        }
      });
    }
    return () => mounted = false;
  }, [props.document]);

  const addDocument = () => {
    const check = {
      name : document.name === "" ? true : false,
      description : document.description === "" ? true : false,
      file : file && file.key && file.identityId ? false : true
    };
    setDocumentErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      var doc = {
        name : document.name,
        description : document.description,
        roles : documentRoles.length > 0 ? documentRoles : null,
        contractors : documentContractors.length > 0 ? documentContractors : null,
        type : type,
        key : file.key,
        identityId : file.identityId
      };
      if (props.document && props.document.id) {
        doc = Object.assign(doc, {
          id : props.document.id
        });
      }
      if (props.document && props.document.hasOwnProperty('version')) {
        let version = parseInt(props.document.version);
        version++;
        doc = Object.assign(doc, {
          version : version
        });
      }
      API.post("budeeBackend", "documents/readonly", { body : {
        projectId : props.project.details.id,
        document : doc,
        scope : scope
      }}).then((res) => {
        setDocumentRoles([]);
        setDocumentContractors([]);
        setRequired('all');
        setDocument({target:[{id:'name', value:''},{id:'description', value:''}]});
        setFile(null);
        setDocUrl(null);
        setUpdating(false);
        if (props.onComplete) {
          props.onComplete(doc);
        }
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  function _getKey(lang, langKey, t) {
    var pieces;
    if (langKey.indexOf('_') >= 0) {
      pieces = langKey.split('_');
    } else {
      pieces = langKey.split('.');
    }
    return pieces[0] + '_' + lang + (t === 'application/pdf' ? '.pdf' : '.mp4');
  }

  function uploadFile(e, lang = null, langKey = null) {
    const file = e.target.files[0];
    if (lang && langKey) {
      setUploadingLangDoc(true);
    } else {
      setUploadingDoc(true);
    }
    Auth.currentCredentials().then(res => {
      let key = null;
      if (lang && langKey) {
        key = _getKey(lang, langKey, type);
      } else {
        if (lang) {
          key = uuidv4() + '_' + lang + (type === 'application/pdf' ? '.pdf' : '.mp4');
        } else {
          key = uuidv4() + (type === 'application/pdf' ? '.pdf' : '.mp4');
        }
      }
      Storage.put(key, file, {
          level: "protected",
          contentType : type,
          completeCallback : (event) => {},
          progressCallback: (prog) => setDocumentProgress(prog),
          errorCallback: (err) => {}
      })
      .then (result => {
        setUploadingDoc(false);
        setUploadingLangDoc(false);
        const uploaded = {
          key : result.key,
          identityId : res.identityId
        };
        setFile(uploaded);
        getTranslationFiles(uploaded, type).then(res => setTranslationFiles(res));
        Storage.get(result.key, {
          level: "protected",
          identityId : res.identityId
        }).then(value => {
          setDocUrl(value);
          if (fileRef && fileRef.current) {
            fileRef.current.value = "";
          }
        });
        if (showUpload != null) {
          setShowUpload(null);
        }
      })
      .catch(err => console.log(err));
    });
  }

  const getTranslationFiles = async (f, t) => {
    var pieces;
    if (f.key.indexOf('_') >= 0) {
      pieces = f.key.split('_');
    } else {
      pieces = f.key.split('.');
    }
    const translationKey = pieces[0];
    const objects = await Storage.list(translationKey, {
      level: "protected",
      identityId : f.identityId
    });
    var files = {};
    for (const obj of objects) {
      if (obj.key.indexOf('_') >= 0) {
        const url = await Storage.get(obj.key, {
          level : 'protected',
          identityId : f.identityId
        })
        files[obj.key.substring(obj.key.indexOf('_') + 1,(obj.key.indexOf('_') + 3))] = {
          ...obj,
          url : url
        };
      }
    }
    return files;
  };

  useEffect(() => {
    let mounted = true;
    if (file) {
      getTranslationFiles(file, type).then(res => {
        if (mounted) {
          setTranslationFiles(res);
        }
      });
    }
    return () => mounted = false;
  }, [file, type]);

  return (
    <>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            Scope:
            <Tooltip style={{marginLeft:'15px'}} title={
              props.document ? "Unable to change scope of document, must archive/create a new one." : "A document will be available to everyone in the company or just personnel of " + props.project.details.project + "."
            }>
              <HelpIcon color="primary" />
            </Tooltip>
          </FormLabel>
          <RadioGroup aria-label="scope" name="scope" value={scope} style={{flexDirection : 'row'}} onChange={(e) => setScope(e.target.value)}>
            <FormControlLabel value="project" labelPlacement="end" control={<Radio disabled={props.document ? true : false} />} label="Project-Wide" />
            <FormControlLabel value="company" labelPlacement="end" control={<Radio disabled={props.document ? true : false} />} label="Company-Wide" />
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{display : 'flex', flexDirection : 'row'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Required By:</FormLabel>
          <RadioGroup aria-label="required" name="required" value={required} onChange={handleChange}>
            <FormControlLabel value="all" control={<Radio />} label="Project Wide" />
            <FormControlLabel value="roles" control={<Radio />} label="Specific Role(s)" />
            <FormControlLabel value="contractors" control={<Radio />} label="Specific Contractor(s)" />
          </RadioGroup>
        </FormControl>
        {
          required === 'roles' && props.roles.length > 0 &&
          <div style={{display:'flex', flexGrow:1, flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role-select"
                value={selectedRole !== 'unselected' ? selectedRole.id : 'unselected'}
                onChange={handleRoleChange}
              >
                <MenuItem key={'role-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.roles.map((role, i) => {
                  return (
                    <MenuItem key={'role-' + i} value={role.id}>{role.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addRole()} color="secondary" variant="contained" style={{}}>Add Role</Button>
            </div>
            {
              required === 'roles' && props.roles.length === 0 &&
              <Typography>There currently are no roles to choose from, add some in the Roles section.</Typography>
            }
          </div>
        }
        {
          required === 'contractors' && props.contractors.length > 0 &&
          <div style={{display:'flex', flexGrow:1, flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="contractor-label">Contractor</InputLabel>
              <Select
                labelId="contractor-label"
                id="contractor-select"
                value={selectedContractor !== 'unselected' ? selectedContractor.id : 'unselected'}
                onChange={handleContractorChange}
              >
                <MenuItem key={'contractor-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.contractors.map((contractor, i) => {
                  return (
                    <MenuItem key={'contractor-' + i} value={contractor.id}>{contractor.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addContractor()} color="secondary" variant="contained" style={{}}>Add Contractor</Button>
            </div>
            {
              required === 'contractors' && props.contractors.length === 0 &&
              <Typography>There currently are no contractors to choose from, add some in the Contractors section.</Typography>
            }
          </div>
        }
      </div>
      {
        documentRoles.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          documentRoles.map((role,i) => {
            return (
              <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} onDelete={() => handleDeleteRole(role.id)} key={'role-' + i} label={role.name} size="small"/>
            )
          })
        }
        </div>
      }
      {
        documentContractors.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          documentContractors.map((contractor,i) => {
            return (
              <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} onDelete={() => handleDeleteContractor(contractor.id)} label={contractor.name} size="small" />
            )
          })
        }
        </div>
      }
      <TextField
        variant="outlined"
        error={documentErrors.name}
        fullWidth
        required
        size="small"
        id="name"
        label="Name"
        name="name"
        value={document.name}
        inputProps={{maxLength:120}}
        onChange={setDocument}
        margin="normal"
      />
      <TextField
        variant="outlined"
        error={documentErrors.description}
        fullWidth
        required
        multiline
        rows={3}
        size="small"
        id="description"
        label="Description"
        name="description"
        value={document.description}
        onChange={setDocument}
        margin="normal"
      />
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          Type:
          <Tooltip style={{marginLeft:'15px'}} title={
            props.document ? "Unable to change scope of document, must archive/create a new one." : "A document will be available to everyone in the company or just personnel of " + props.project.details.project + "."
          }>
            <HelpIcon color="primary" />
          </Tooltip>
        </FormLabel>
        <RadioGroup aria-label="scope" name="scope" value={type} style={{flexDirection : 'row'}} onChange={(e) => setType(e.target.value)}>
          <FormControlLabel value="application/pdf" labelPlacement="end" control={<Radio />} label="PDF Document" />
          <FormControlLabel value="video/mp4" labelPlacement="end" control={<Radio />} label="MP4 Video" />
        </RadioGroup>
      </FormControl>
      {
        type === 'application/pdf' &&
        <>
          <div style={{margin:'5px'}}>
            <input type="file" disabled={type === 'video/mp4' && language === 'unselected'} ref={fileRef} accept={type} onChange={(evt) => uploadFile(evt)} />
          </div>
          {
            docUrl &&
            <div style={{margin:'5px'}}>
              <Link href={docUrl} target="_blank" style={{marginTop:'15px',padding:'5px',borderRadius:10,width:'200px',display:'flex',flexDirection:'row', border:'1px solid ' + theme.palette.secondary.main}} rel="noreferrer">
                  <PictureAsPdfIcon style={{marginRight:'10px'}} color="error" />
                  <Typography variant="body2">Read Training Document</Typography>
              </Link>
            </div>
          }
        </>
      }
      {
        type === 'video/mp4' &&
        <>
          <FormControl component="fieldset" style={{flexDirection:'row',alignItems:'flex-start'}} fullWidth>
            <div style={{marginRight:'20px'}}>
              <InputLabel id="lang-label">Language</InputLabel>
              <Select
                labelId="lang-label"
                id="lang-select"
                style={{width:'200px'}}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <MenuItem key={'language-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                LANGUAGES.map((lang, i) => {
                  return (
                    <MenuItem key={'lang-' + i} value={lang.code}>{lang.name}</MenuItem>
                  )
                })
              }
              </Select>
              <div style={{margin:'10px'}}>
                <input type="file" disabled={type === 'video/mp4' && language === 'unselected'} ref={fileRef} accept={type} onChange={(evt) => uploadFile(evt, language)} />
              </div>
              {
                docUrl &&
                <div style={{margin:'10px'}}>
                <Link href={docUrl} target="_blank" style={{marginTop:'15px',padding:'5px',borderRadius:10,width:'200px',display:'flex',flexDirection:'row', border:'1px solid ' + theme.palette.secondary.main}} rel="noreferrer">
                  <VideoLibraryIcon style={{marginRight:'10px'}} color="primary" />
                  <Typography variant="body2">View Training Video</Typography>
                </Link>
                </div>
              }
            </div>
            {
              translationFiles && file && props.document &&
              <div style={{border:'1px solid #cdcdcd',borderRadius:10,padding:'10px',marginBottom:'10px'}}>
                <Typography style={{fontWeight:'bold'}} variant="body1">Language Files Currently Uploaded:</Typography>
                <Typography variant="body2" style={{marginTop:'5px',marginBottom:'5px'}}>Google translate does not currently handle video translation, so here we provide the ability to upload the video with a correct subtitle or audio language.</Typography>
                {
                  LANGUAGES.map((lang, i) => {
                    let file = null
                    if (translationFiles && translationFiles.hasOwnProperty(lang.code)) {
                      file = translationFiles[lang.code];
                    }

                    return (
                      <div key={"lang-" + i} style={{display:'flex',flexDirection:'row'}}>
                        <Link style={{width:'100px'}} href={file ? file.url : ''} disabled={!file} target="_blank" rel="noreferrer">
                          { lang.name }
                        </Link>
                        {
                          !uploadingLangDoc &&
                          <>
                          {
                            file &&
                            <Typography variant="body2" style={{width:'80px'}}>{formatBytes(file.size, 2)}</Typography>
                          }
                          {
                            file &&
                            <Typography variant="body2" style={{marginLeft:'5px',marginRight:'5px',width:'125px'}}>{formatDateTime(new Date(file.lastModified))}</Typography>
                          }
                          {
                            file  &&
                            <CheckCircleOutlineIcon style={{marginLeft:'5px',marginRight:'5px'}} color="primary" />
                          }
                          {
                            !file &&
                            <HighlightOffIcon style={{marginLeft:'5px',marginRight:'5px'}} color="error" />
                          }
                          </>
                        }
                        {
                          uploadingLangDoc && showUpload === i &&
                          <div style={{width:'100%',margin:'5px'}}>
                            <LinearProgress variant="determinate" value={documentProgress ? (documentProgress.loaded / documentProgress.total) * 100 : 0} />
                          </div>
                        }
                        {
                          !uploadingLangDoc && showUpload == null &&
                          <input style={{marginLeft:'5px',marginRight:'5px'}} type="file" accept={type} onChange={(evt) => {
                            setShowUpload(i);
                            uploadFile(evt, lang.code, file.key);
                          }} />
                        }
                      </div>
                    )
                  })
                }
                <Typography variant="body2" style={{marginTop:'5px',marginBottom:'5px'}}>To update a langauge use the file input beside it to select the file to upload for that language.  If a language file is missing and on mobile a user is in that language, it will default to the original video.</Typography>
              </div>
            }
          </FormControl>
        </>
      }
      {
        uploadingDoc &&
        <div style={{width:'100%',marginTop:'5px',marginBottom:'5px'}}>
          <LinearProgress variant="determinate" value={documentProgress ? (documentProgress.loaded / documentProgress.total) * 100 : 0} />
        </div>
      }
      <Button
        variant="contained"
        color="primary"
        disabled={uploadingDoc}
        style={{marginTop:'10px'}}
        onClick={() => addDocument()}>
        {
          !updating &&
          <>
          {
            props.document &&
            "Update Training"
          }
          {
            !props.document &&
            "Add Training"
          }
          </>

        }
        {
          updating &&
          <div>
            <CircularProgress size={10} />
          </div>
        }
      </Button>
    </>
  );
}
