import React, { useState, useEffect } from "react";

import { API, Storage } from "aws-amplify";
import ProfilePicture from "../../../../User/ProfilePicture";
import queryString from 'query-string';
import { format } from 'date-fns';

import DateField from "./Fields/DateField";
import DividerField from "./Fields/DividerField";
import TextArea from "./Fields/TextArea";
import TextInput from "./Fields/TextInput";
import TextList from "./Fields/TextList";
import PlainText from "./Fields/PlainText";
import PlainImage from "./Fields/PlainImage";
import Timestamp from "./Fields/Timestamp";
import Heading from "./Fields/Heading";
import User from "./Fields/User";
import UserList from "./Fields/UserList";
import CheckList from "./Fields/CheckList";
import Photo from "./Fields/Photo";
import RadioGroupField from "./Fields/RadioGroupField";
import SelectValue from "./Fields/SelectValue";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { isMobile } from "react-device-detect";
import { useTheme } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//
// }));

const { DOCUMENTS } = require('@budeesolutions/budee-constants');

export default function View(props) {
  // const classes = useStyles();
  const theme = useTheme();

  const [submission, setSubmission] = useState(props.submission);
  const [data, setData] = useState(null);
  const [doc, setDocument] = useState(props.document);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [user, setUser] = useState(null);
  const [observations, setObservations] = useState({});
  const [fieldSizes, setFieldSizes] = useState({});
  const [breakPoints, setBreakPoints] = useState([]);
  const [publishing, setPublishing] = useState(false);

  const [attachments, setAttachments] = useState([]);
  const [attachmentUrls, setAttachmentUrls] = useState([]);

  var parsed = queryString.parse(props.location && props.location.search ? props.location.search : '');

  useEffect(() => {
    let mounted = true;
    if (props.print) {
      const breaks = calculateLineBreaks(fieldSizes);
      if (mounted) {
        setBreakPoints(breaks);
      }
    }
    return () => mounted = false;
  }, [fieldSizes, props.print]);

  useEffect(() => {
    setSubmission(props.submission);
  }, [props.submission]);

  useEffect(() => {
    setDocument(props.document);
  }, [props.document]);

  const load = (sub, projId) => {
    if (sub.id) {
      var calls = [
        API.get("budeeBackend", "documents/submission/view", {
          queryStringParameters : {
            submissionId : sub.id,
            projectId : projId,
            documentId : sub.documentId,
            documentVersion : sub.documentVersion,
            status : sub.status
          }
        }),
        API.get("budeeBackend", "documents/field", {
          queryStringParameters : {
            documentId : sub.documentId,
            projectId : projId,
            version : sub.documentVersion
          }
        }),
        API.get('budeeBackend', 'dashboard/observations/submission', {
          queryStringParameters : {
            submissionId : sub.id,
            projectId : projId
          }
        })
      ];
      return Promise.all(calls);
    } else {
      return API.get("budeeBackend", "documents/field", {
        queryStringParameters : {
          documentId : sub.documentId,
          projectId : projId,
          version : sub.documentVersion
        }
      });
    }
  }

  const addEntry = (dimension, index) => {
    if (!fieldSizes[index] || fieldSizes[index].width !== dimension.width || fieldSizes[index].height !== dimension.height) {
      setFieldSizes((prev) => ({
        ...prev,
        [index]:dimension
      }));
    }
  }

  const calculateLineBreaks = (sizes) => {
    let width = 0;
    let height = 0;
    let totalHeight = 0;
    var insertBreak = [];
    for (let i = 0; i < Object.values(sizes).length; i++) {
      const size = sizes[i];
      if (width > 0 && size.width === 816) {
        totalHeight += height;
        height = 0;
        width = 0;
        totalHeight += size.height;
      } else if (width === 0 && size.width === 816) {
        height = 0;
        width = 0;
        totalHeight += size.height;
      } else if ((width + size.width) > 816) {
        totalHeight += height;
        height = size.height;
        width = size.width;
      } else {
        width += size.width;
        height = height <= size.height ? size.height : height;
      }
      if (totalHeight > 1040) {
        insertBreak.push(i);
        totalHeight = size.height > 1040 ? size.height - 1040 : size.height;
        height = 0;
      }
      // console.log('--');
      // console.log('Index:', i);
      // console.log('Height:', height);
      // console.log('Total Height:', totalHeight);
      // console.log('Width:', width);
      // console.log('Item:', size);
    }
    return insertBreak;
  }

  const renderField = (field, value, index) => {

    return (
      <React.Fragment key={'field-' + index}>
        {
          breakPoints.includes(index) &&
          <div style={{pageBreakAfter:'always'}}></div>
        }
        {
          renderFieldType(field, value, index)
        }
      </React.Fragment>
    )
  }

  const updateObservations = (obv) => {
    if (obv) {
      let copy = {...observations};
      for (const id of Object.keys(observations)) {
        let obvs = [...copy[id]];
        for (let i = 0; i < obvs.length; i++) {
          if (obvs[i].id === obv.id) {
            if (obv.deleted) {
              obvs.splice(i, 1);
            } else {
              obvs[i] = obv;
            }
            copy[id] = obvs
            break;
          }
        }
      }
      setObservations(copy);
    }
  }

  const addObservation = async (fieldId, setSelectedObservation) => {
    let obv = {
      title : '',
      description : '',
      photos : [],
      roles : [],
      departments : [],
      contractors : [],
      anonymous : false,
      assigned : null,
      resolved : false,
      status : true,
      date : (new Date()).toUTCString(),
      submissionId : submission.id,
      document : {
        id : submission.documentId,
        version : submission.documentVersion
      },
      fixBy : null,
      field : fieldId,
      userId : props.user.id
    };
    return API.post('budeeBackend', 'user/observation',{
      body : {
        observation : obv,
        projectId : props.project.details.id
      }
    }).then(val => {
      let copy = {...observations};
      let copyObv = {
        ...obv,
        id : val
      };
      if (copy.hasOwnProperty(fieldId)) {
        copy[fieldId].push(copyObv);
      } else {
        copy[fieldId] = [copyObv];
      }
      setObservations(copy);
      setSelectedObservation(copyObv);
    })
  }

  const renderFieldType = (field, value, index) => {
    var obvs = [];
    if (observations.hasOwnProperty(field.id)) {
      obvs = observations[field.id];
    }

    switch (field.type) {
      case DOCUMENTS.FIELDS.DATE.ID:
        return (<DateField key={field.id} projectId={props.project.details.id} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.TEXT.ID:
        return (<TextInput key={field.id} employees={props.employees} observations={obvs} project={props.project} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.TEXTAREA.ID:
        return (<TextArea key={field.id} employees={props.employees} observations={obvs} project={props.project} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.TEXT_LIST.ID:
        return (<TextList key={field.id} employees={props.employees} observations={obvs} project={props.project} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.USER.ID:
        return (<User key={field.id} projectId={props.project.details.id} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.USER_LIST.ID:
        return (<UserList key={field.id} projectId={props.project.details.id} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.CHECKLIST.ID:
        return (<CheckList key={field.id} project={props.project} observations={obvs} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.PHOTO.ID:
        return (<Photo key={field.id} projectId={props.project.details.id} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.RADIOGROUP.ID:
        return (<RadioGroupField key={field.id} project={props.project} observations={obvs} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.SELECT.ID:
        return (<SelectValue key={field.id} project={props.project} observations={obvs} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} user={props.user} employees={props.employees} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} addObservation={(setSelectedObservation) => addObservation(field.id, setSelectedObservation)} updateObservations={updateObservations} />);
      case DOCUMENTS.FIELDS.DIVIDER.ID:
        return (<DividerField key={field.id} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.HEADING.ID:
        return (<Heading key={field.id} field={field} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.PLAINTEXT.ID:
        return (<PlainText key={field.id} field={field} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.PLAINIMAGE.ID:
        return (<PlainImage key={field.id} field={field} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      case DOCUMENTS.FIELDS.TIMESTAMP.ID:
        return (<Timestamp key={field.id} projectId={props.project.details.id} documentId={doc.id} submission={submission ? submission : null} field={field} value={value} print={props.print} updateDimensions={(dimensions) => addEntry(dimensions, index)} />);
      default:
        return (<></>);
    }
  }

  const getEmployee = (e) => {
    if (props.employees && props.employees.hasOwnProperty(e.id)) {
      return props.employees[e.id];
    } else {
      return null;
    }
  }

  useEffect(() => {
    let mounted = true;
    if (submission && props.project.details.id && doc && mounted) {
      setLoading(true);
      load(submission, props.project.details.id).then(values => {
        if (submission.id) {
          if (values[0]) {
            const valueMap = Object.values(values[0].values).reduce(function(map, obj) {
              map[obj.fieldId] = obj;
              return map;
            }, {});
            if (mounted) {
              setData(valueMap);
              setUser(values[0].user);
            }
          }
          if (values[1]) {
            const fieldMap = values[1].reduce(function(map, obj) {
              map[obj.id] = obj;
              return map;
            }, {});
            if (mounted) {
              setFields(fieldMap);
            }
          }
          if (values[2]) {
            const valueMap = values[2].reduce(function(map, obj) {
              if (map.hasOwnProperty(obj.field)) {
                map[obj.field].push(obj);
              } else {
                map[obj.field] = [obj];
              }
              return map;
            }, {});
            if (mounted) {
              setObservations(valueMap);
            }
          }
          if (mounted) {
            setLoading(false);
          }
        } else {
          const fieldMap = values.reduce(function(map, obj) {
            map[obj.id] = obj;
            return map;
          }, {});
          if (mounted) {
            setFields(fieldMap);
            setLoading(false);
          }
        }
      });
      loadAttachments(submission, props.project.details.id, doc).then(res => {
        if (mounted) {
          setAttachments(res);
        }
      });
    }
    return () => mounted = false;
  }, [submission, props.project, doc]);

  useEffect(() => {
    let mounted = true;
    if (attachments) {
      var files = [];
      for(const file of attachments) {
        files.push(Storage.get(file.key, {
          level: "protected",
          identityId : file.identityId
        }));
      }
      Promise.all(files).then(vals => {
        if (mounted) {
          setAttachmentUrls(vals);
        }
      });
    }
    return () => mounted = false;
  }, [attachments]);

  const getScore = (sub) => {
    if (sub.score && sub.highest) {
      const percent = Math.round((sub.score / sub.highest) * 100);
      return sub.score + '/' + sub.highest + ' ' + percent + '%';
    } else {
      return sub.score;
    }
  }

  const loadAttachments = async (sub, projId, d) => {
    return API.get('budeeBackend', 'documents/submission/attachment', {
      queryStringParameters : {
        documentId : d.id,
        documentVersion : sub.documentVersion,
        projectId : projId,
        submissionId : sub.id
      }
    });
  }

  const publish = async (projId, docId, docVersion, subId) => {
    setPublishing(true);
    return API.post('budeeBackend', 'documents/submission/publish', {
      body : {
        projectId : projId,
        documentId : docId,
        documentVersion : docVersion,
        submissionId : subId
      }
    }).then(res => {
      let sub = {...submission};
      sub.status = 'COMPLETED';
      setSubmission(sub);
      setPublishing(false);
    });
  }

  const render = () => {
    return(
      <>
      {
        loading &&
        <div>
          <CircularProgress size={80} />
        </div>
      }
      {
        !loading &&
        <>
          {
            !props.print &&
            <>
              <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',alignItems:'center', marginBottom:'20px',borderBottom:'1px solid #cdcdcd'}}>
                {
                  props.setSubmission &&
                  <ArrowBackIcon style={{cursor:'pointer', marginRight:'30px'}} onClick={() => {
                    delete parsed.submissionId;
                    props.history.push({
                      pathname : props.history.location.pathname,
                      search : queryString.stringify(parsed)
                    });
                    props.setSubmission(null);
                  }} />
                }
                <Typography variant="h6">{doc.name}</Typography>
                {
                  (doc.draft || submission.status === 'DRAFT') &&
                  <>
                    <Alert style={{margin:'5px'}} severity="info">Draft Mode</Alert>
                    {
                      submission.status === 'DRAFT' &&
                      <Button variant="contained" color="primary" onClick={() => publish(props.project.details.id, submission.documentId, submission.documentVersion, submission.id)}>
                        {
                          publishing &&
                          <CircularProgress size={20} />
                        }
                        {
                          !publishing && "Publish"
                        }
                      </Button>
                    }
                  </>
                }
                <div style={{flexGrow:1,display:'flex', flexDirection:'column', alignItems:'flex-end',justifyContent:'flex-end'}}>
                  <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                    <div style={{flexGrow:1,display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                    {
                      attachments && attachments.length > 0 &&
                      <>
                        <Typography variant="body2">File Attachments:</Typography>
                        {
                          attachments.map((att, i) => {
                            let url = attachmentUrls && attachmentUrls.length > i ? attachmentUrls[i] : '';
                            return (
                              <a key={'attach-' + i} style={{marginLeft:'5px',marginRight:'5px'}} href={url} onClick={(e) => {
                                e.preventDefault();
                                var a = document.createElement("a");
                                a.href = url;
                                a.setAttribute("download", att.name);
                                a.setAttribute("target", '_blank');
                                a.click();
                              }}>
                                {att.name}
                              </a>
                            )
                          })
                        }
                      </>
                    }
                    </div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <Typography style={{marginRight:'5px',width:'150px',textAlign:'right'}}>Author:</Typography>
                      {
                        user &&
                        <div style={{flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center',width:'150px'}}>
                        {
                          !isMobile && !props.print &&
                          <ProfilePicture user={props.user} employee={getEmployee(user)} />
                        }
                          <Typography style={{marginLeft:'5px'}} variant="body2">{user.firstname + ' ' + user.lastname}</Typography>
                        </div>
                      }
                    </div>
                  </div>
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <Typography style={{marginRight:'5px',width:'150px',textAlign:'right',alignItems:'center'}}>Date:</Typography>
                    {
                      submission && submission.date &&
                      <div style={{flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center',width:'150px'}}>
                        <Typography variant="body2">{format(new Date(submission.date), 'yyyy/MM/dd  hh:mmaaa')}</Typography>
                      </div>
                    }
                  </div>
                  {
                    submission && submission.score > 0 &&
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <Typography style={{marginRight:'5px',width:'150px',textAlign:'right',alignItems:'center'}}>Score:</Typography>
                      <div style={{flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center',width:'150px'}}>
                        <Typography variant="body2">{getScore(submission)}</Typography>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          }
          <Grid container spacing={props.print ? 0 : 3}>
            {
              fields && Object.values(fields).map((f, i) => {
                const fieldData = fields && fields.hasOwnProperty(f.id) ? fields[f.id] : null;
                if (!fieldData) {
                  return (<React.Fragment key={'field-' + i}></React.Fragment>);
                }
                const fieldValue = data && data.hasOwnProperty(f.id) ? data[f.id] : null;
                return (
                  <React.Fragment key={'field-' + i}>
                    { renderField(fieldData, fieldValue, i) }
                  </React.Fragment>
                );
              })
            }
          </Grid>
        </>
      }
      </>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        {
          !props.print && !props.inset &&
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
            {
              render()
            }
          </Paper>
        }
        {
          !props.print && props.inset &&
          render()
        }
        {
          props.print &&
          <>
            {
              render()
            }
          </>
        }
      </Grid>
    </Grid>
  );
}
