import React, { useState, useEffect } from "react";

import { useEffectWhen } from "../../../libs/hooks";
import { OBSERVATION_FILTERS } from "../../../config/localStorage";

import { API } from 'aws-amplify';
import { format } from 'date-fns';
import queryString from 'query-string';

import ProfilePicture from "../../User/ProfilePicture";
import Role from "../../User/Role";
import Observation from "./Home/Observations/Observation";

import { DatePicker } from '@material-ui/pickers';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import BackspaceIcon from '@material-ui/icons/Backspace';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

import { isMobile } from "react-device-detect";
import { useTheme } from '@material-ui/core/styles';
import { CSVDownload } from "react-csv";

import { makeStyles } from '@material-ui/core/styles';

const KSUID = require('ksuid');
const { formatDateTime, formatDate } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  alertRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  },
  radioLabel : {
    fontSize:'12px'
  },
  dateRangeTag : {
    width:'37px',
    textAlign:'right',
    marginRight:'5px'
  },
  statusTag : {
    fontSize:'12px'
  },
  header : {
    borderBottom:'1px solid #cdcdcd',
    width:'100%'
  }
}));

const columns = [
  { field: 'id', hide : true },
  { field: 'date', headerName: 'Created', width : 175, renderCell : (val) => {
    return (
      <Typography variant="body2" color="textSecondary">{format(val.value, 'yyyy/MM/dd  hh:mmaaa')}</Typography>
    )
  } },
  { field: 'name', width : 200, headerName: 'Reported By'},
  { field: 'description', headerName: 'Description', flex: 1 },
  { field: 'assigned', width : 200, headerName: 'Assigned To'},
  { field: 'resolvedBy', width : 200, headerName: 'Resolved By'},
  { field: 'status', headerName: 'Status', renderCell : (val) => {
    return (
      <>
        {
          !val.value &&
          <AssignmentTurnedInIcon color="primary" />
        }
        {
          val.value &&
          <AssignmentLateIcon color="error" />
        }
      </>
    )
  } },
  { field: 'fixby', headerName: 'Fix By', width : 100, renderCell : (val) => {
      return (
        <Typography variant="body2" color="textSecondary">{val.value && format(val.value, 'yyyy/MM/dd')}</Typography>
      );
  } }
];

export default function Observations(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  var parsed = queryString.parse(props.location.search);
  var filters = localStorage.getItem(OBSERVATION_FILTERS) !== '' ? JSON.parse(localStorage.getItem(OBSERVATION_FILTERS)) : null;
  const today = new Date();

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(filters && filters.selected ? filters.selected : null);
  const [observations, setObservations] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [observation, setObservation] = useState(null);
  const [selectedObservations, setSelectedObservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(filters && filters.startDate ? new Date(filters.startDate) : new Date(new Date(today).setMonth(today.getMonth() - 1)));
  const [endDate, setEndDate] = useState(filters && filters.endDate ? new Date(filters.endDate) : new Date());
  const [fixByDate, setFixByDate] = useState(filters && filters.fixByDate ? new Date(filters.fixByDate) : new Date());
  const [viewing, setViewing] = useState(filters && filters.viewing ? filters.viewing : 'project');
  const [status, setStatus] = useState(filters && filters.status ? filters.status : 'all');
  const [documents, setDocuments] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(filters && filters.selectedDoc ? filters.selectedDoc : 'unselected');
  const [selectedSub, setSelectedSub] = useState(filters && filters.selectedSub ? filters.selectedSub : 'unselected');
  const [csvData, setCsvData] = useState(null);

  useEffectWhen(() => {
    if (fixByDate > endDate) {
      setEndDate(new Date(fixByDate));
    }
  }, [fixByDate, endDate], [fixByDate]);

  useEffect(() => {
    if (selectedSub !== 'unselected') {
      loadBySubmission(props.project.details.id, selectedSub.id);
    }
  }, [selectedSub, props.project]);

  useEffect(() => {
    if (props.project && startDate && endDate) {
      load(props.project.details.id, startDate, endDate);
      loadSubmissions(props.project.details.id, startDate, endDate);
    }
  }, [props.project, startDate, endDate]);

  useEffectWhen(() => {
    searchUser(search, users);
  }, [search, users], [search]);

  useEffect(() => {
    if (selected) {
      setSearchResults([]);
      setSearch('');
    }
  }, [selected]);

  useEffect(() => {
    if (!open) {
      load(props.project.details.id, true);
    }
  }, [open, props.project])

  useEffect(() => {
    localStorage.setItem(OBSERVATION_FILTERS, JSON.stringify({
      viewing : viewing,
      startDate : startDate.getTime(),
      endDate : endDate.getTime(),
      status : status,
      selectedDoc : selectedDoc,
      selectedSub : selectedSub,
      selected : selected
    }));
  }, [viewing, startDate, endDate, status, fixByDate, selectedDoc, selectedSub, selected]);

  useEffectWhen(() => {
    loadDocuments(props.project.details.id).then(vals => {
      let docs = [...vals[0],...vals[1]];
      docs.sort((a,b) => {
        return a.name.localeCompare(b.name);
      })
      setDocuments(docs);
      if (parsed && props.project && parsed.submissionId && parsed.documentId && parsed.documentVersion) {
        loadSubmissionById(props.project.details.id, parsed.submissionId, parsed.documentId, parsed.documentVersion).then(res => {
          if (res) {
            for (const d of docs) {
              if (d.id === parsed.documentId) {
                setSelectedDoc(d);
              }
            }
            setSelectedSub(res);
            setViewing('document');
          }
        });
      }
    });
  }, [props.project, parsed], [props.project]);

  const load = async (projectId, quiet = false) => {
    if (!quiet) {
      setLoading(true);
    }
    let result = await API.get('budeeBackend', 'dashboard/observations', {
      queryStringParameters : {
        projectId : projectId
      }
    });
    let obvs = [];
    if (result.key) {
      let obvs = result.observations;
      while (result.key) {
        result = await API.get('budeeBackend', 'dashboard/observations', {
          queryStringParameters : {
            projectId : projectId,
            key : result.key
          }
        });
        obvs = [...obvs, result.observations];
      }
      obvs = [...obvs,result];
    } else {
      obvs = result;
    }
    if (!quiet) {
      setLoading(false);
    }
    setObservations(obvs);
  }

  const loadBySubmission = async (projectId, submissionId) => {
    return API.get('budeeBackend', 'dashboard/observations/submission', {
      queryStringParameters : {
        projectId : projectId,
        submissionId : submissionId
      }
    }).then(val => setObservations(val));
  }

  const searchUser = (name, employees) => {
    if (name !== '') {
      const search = name.replace(/\W/g, '').toUpperCase()
      const results = employees.filter(emp => {
        return emp.name.replace(/\W/g, '').toUpperCase().startsWith(search) && !emp.inactive;
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  useEffectWhen(() => {
    setUsers(Object.values(props.employees));
  }, [props.employees]);

  const loadDocuments = async (projectId) => {
    return Promise.all([
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=project", {}),
      API.get("budeeBackend", "documents/admin?projectId=" + projectId + "&scope=company", {})
    ]);
  };

  const loadSubmissions = async (projectId, start, end) => {
    return API.get("budeeBackend", "documents/submission/project", {
      queryStringParameters : {
        projectId : projectId,
        start : start.toUTCString(),
        end : end.toUTCString()
      }
    }).then(res => setSubmissions(res));
  };

  const loadSubmissionById = async(projectId, submissionId, documentId, documentVersion) => {
    return API.get("budeeBackend", "documents/submission/id", {
      queryStringParameters : {
        projectId : projectId,
        documentId : documentId,
        submissionId : submissionId,
        documentVersion : documentVersion
      }
    });
  }

  useEffect(() => {
    var mounted = true;
    let lastMidnight = new Date(startDate);
    lastMidnight.setHours(0,0,0,0);
    let start = KSUID.randomSync(lastMidnight).string;
    let midnight = new Date(endDate);
    midnight.setHours(24,0,0,0);
    let end = KSUID.randomSync(midnight).string;
    if (props.employees) {
      var rows = [];
      for (const obv of observations) {
        if (obv.id <= start || obv.id >= end) {
          continue;
        }
        switch (viewing) {
          case 'project':
            break;
          case 'assigned':
            if (!selected || obv.assigned !== selected.id) {
              continue;
            }
            break;
          case 'resolved':
            if (!selected || obv.resolvedBy !== selected.id) {
              continue;
            }
            break;
          case 'reported':
            if (!selected || obv.userId !== selected.id) {
              continue;
            }
            break;
          case 'fixby':
            if (!obv.fixBy) {
              continue;
            } else {
              const fixBy = new Date(obv.fixBy);
              if (fixBy > fixByDate) {
                continue;
              }
            }
            break;
          case 'document':
            break;
          default:
        }
        switch (status) {
          case 'all':
            break;
          case 'open':
            if (!obv.status) {
              continue;
            }
            break;
          case 'closed':
            if (obv.status) {
              continue;
            }
            break;
          default:
        }
        var name = '';
        if (obv.userId && props.employees.hasOwnProperty(obv.userId)) {
          name = props.employees[obv.userId].name;
        }
        var assigned = '';
        if (obv.assigned && props.employees.hasOwnProperty(obv.assigned)) {
          assigned = props.employees[obv.assigned].name;
        }
        var resolvedBy = '';
        if (obv.resolvedBy && props.employees.hasOwnProperty(obv.resolvedBy)) {
          resolvedBy = props.employees[obv.resolvedBy].name;
        }
        rows.push({
          id : obv.id,
          date : new Date(obv.date),
          description : obv.description,
          name : obv.anonymous ? 'Anonymous' : name,
          status : obv.status,
          assigned : assigned,
          resolvedBy : resolvedBy,
          observation : obv,
          fixby : obv.fixBy ? new Date(obv.fixBy) : null
        });
      }
      if (mounted) {
        setRows(rows);
      }
    }
    return () => mounted = false;
  }, [observations, startDate, endDate, viewing, status, selected, props.employees, fixByDate]);

  const handleClickOpen = (event) => {
    setObservation(event.row.observation);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const deleteObservations = async (ids) => {
    return API.del('budeeBackend', 'user/observation', {
      body : {
        observationIds : ids,
        projectId : props.project.details.id
      }
    }).then(res => {
      load(props.project.details.id);
    });
  }

  const closeObservations = async (ids) => {
    return API.post('budeeBackend', 'user/observation/close', {
      body : {
        projectId : props.project.details.id,
        observationIds : ids,
        resolvedBy : props.user.id,
        comment : 'Bulk Close - No Comment'
      }
    }).then(res => {
      load(props.project.details.id);
    });
  }

  const getLabel = (view) => {
    switch (view) {
      case 'project':
        return 'Project Wide';
      case 'assigned':
        return 'Assigned To';
      case 'reported':
        return 'Reported To';
      case 'resolved':
        return 'Resolved By';
      case 'fixby':
        return 'Fix By';
      case 'document':
        return 'Document Submission';
      default:
        return '';
    }
  }

  const getStatus = (s) => {
    switch (s) {
      case 'all':
        return 'All';
      case 'open':
        return 'Open';
      case 'closed':
        return 'Closed';
      default:
        return '';
    }
  }

  const downloadCsv = async (obvs) => {
    setCsvData(null);
    var data = [];
    for (const row of obvs) {
      let obv = row.observation;
      data.push({
        date : new Date(obv.date),
        description : obv.description,
        name : obv.anonymous ? 'Anonymous' : props.employees[obv.userId].name,
        status : obv.status ? 'Open' : 'Resolved',
        assigned : obv.assigned ? props.employees[obv.assigned].name : '',
        resolvedBy : obv.resolvedBy ? props.employees[obv.resolvedBy].name : '',
        fixby : obv.fixBy ? new Date(obv.fixBy) : null
      });
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  const addObservation = () => {
    let obv = {
      title : '',
      description : '',
      photos : [],
      roles : [],
      departments : [],
      contractors : [],
      anonymous : false,
      assigned : null,
      resolved : false,
      status : true,
      date : (new Date()).toUTCString(),
      fixBy : null,
      field : null,
      userId : props.user.id
    };
    return API.post('budeeBackend', 'user/observation',{
      body : {
        observation : obv,
        projectId : props.project.details.id
      }
    }).then(val => {
      setObservation({
        ...obv,
        id : val
      });
      setOpen(true);
    })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main  }}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <IconButton onClick={() => downloadCsv(rows)}>
                  <CloudDownloadIcon color="primary" />
                </IconButton>
                <Typography variant="h6">{props.certification && props.certification.name}</Typography>
                {
                  csvData &&
                  <CSVDownload data={csvData} target="_blank" />
                }
                <Typography variant="h6" gutterBottom>Observations</Typography>
              </div>
              <div style={{flexGrow:1}}></div>
              <IconButton onClick={() => {
                if (viewing === 'document' && selectedSub !== 'unselected') {
                  loadBySubmission(props.project.details.id, selectedSub.id);
                } else {
                  load(props.project.details.id, startDate, endDate)
                }
                loadSubmissions(props.project.details.id, startDate, endDate);
              }}>
                <RefreshIcon color="primary" />
              </IconButton>
            </div>
            {
              loading &&
              <div>
                <CircularProgress size={80} />
              </div>
            }
            {
              !loading &&
              <>
                {
                  observations && observations.length > 0 &&
                  <>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{backgroundColor: '#cdcdcd'}}
                      >
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
                          <FilterListIcon color="primary" style={{marginRight:'10px'}} />
                          <div style={{flexGrow:1,display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                            <Chip label={'Status: ' + getStatus(status)} style={{margin:'5px'}} color="primary" />
                            <Chip label={'Viewing: ' + getLabel(viewing)} style={{margin:'5px'}} color="primary" />
                            <Chip label={'Date Range: ' + formatDate(startDate) + '-' + formatDate(endDate)} style={{margin:'5px'}} color="primary" />
                            {
                              selected && ['assigned','reported','resolved'].includes(viewing) &&
                              <Chip label={'User Selected: ' + selected.name} style={{margin:'5px'}} color="primary" />
                            }
                            {
                              viewing === 'fixby' && fixByDate &&
                              <Chip label={'Fix By: ' + formatDate(fixByDate)} style={{margin:'5px'}} color="primary" />
                            }
                            {
                              viewing === 'document' && selectedDoc !== 'unselected' &&
                              <Chip label={'Document: ' + selectedDoc.name} style={{margin:'5px'}} color="primary" />
                            }
                            {
                              viewing === 'document' && selectedSub !== 'unselected' &&
                              <Chip label={'Submission: ' + formatDate(new Date(selectedSub.date))} style={{margin:'5px'}} color="primary" />
                            }
                          </div>
                          <Tooltip title="Current filters applied are present in this bar, click to expand and edit filter criteria." style={{marginLeft:'5px'}}>
                            <HelpIcon color="primary" />
                          </Tooltip>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{marginTop:'5px',marginBottom:'5px',padding:'10px'}} spacing={3}>
                          <Grid item xs={12} sm={4} style={{display:'flex',flexDirection:'row'}}>
                            <div style={{marginRight:'10px'}}>
                              <Typography classes={{root : classes.header}} variant="body2">Status</Typography>
                              <FormControl component="fieldset">
                                <RadioGroup aria-label="status" name="status" value={status} onChange={e => setStatus(e.target.value)}>
                                  <FormControlLabel classes={{label:classes.statusTag}} value="all" control={<Radio />} label="All" />
                                  <FormControlLabel classes={{label:classes.statusTag}} value="open" control={<Radio />} label={
                                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <AssignmentLateIcon color="error" />
                                        <Typography style={{fontSize:'12px'}}>Open</Typography>
                                      </div>
                                  } />
                                  <FormControlLabel classes={{label:classes.statusTag}} value="closed" control={<Radio />} label={
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                      <AssignmentTurnedInIcon color="primary" />
                                      <Typography style={{fontSize:'12px'}}>Closed</Typography>
                                    </div>
                                  } />
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div>
                              <Typography classes={{root : classes.header}} variant="body2">Created Date</Typography>
                              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <Typography variant="body2" classes={{root:classes.dateRangeTag}} component="div">From:</Typography>
                                <DatePicker
                                  value={startDate}
                                  format="EEEE MMMM d yyyy"
                                  onChange={d => setStartDate(d)}
                                  TextFieldComponent={props => (<Input
                                                                  type="text"
                                                                  onClick={props.onClick}
                                                                  value={props.value}
                                                                  onChange={props.onChange}
                                                                  style={{ textAlign : 'center', color : theme.palette.primary.main, cursor : 'pointer' }}
                                                                />)}
                                />
                              </div>
                              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <Typography variant="body2" classes={{root:classes.dateRangeTag}} component="div">To:</Typography>
                                <DatePicker
                                  value={endDate}
                                  format="EEEE MMMM d yyyy"
                                  onChange={d => setEndDate(d)}
                                  TextFieldComponent={props => (<Input
                                                                  type="text"
                                                                  onClick={props.onClick}
                                                                  value={props.value}
                                                                  onChange={props.onChange}
                                                                  style={{ textAlign : 'center', color : theme.palette.primary.main, cursor : 'pointer' }}
                                                                />)}
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography classes={{root : classes.header}} variant="body2">Viewing:</Typography>
                            <FormControl>
                              <Select
                                labelId="viewing-label"
                                id="viewing-select"
                                value={viewing}
                                onChange={(e) => setViewing(e.target.value)}
                              >
                                <MenuItem value='project'>Project Wide</MenuItem>
                                <MenuItem value='assigned'>Assigned To</MenuItem>
                                <MenuItem value='reported'>Reported By</MenuItem>
                                <MenuItem value='resolved'>Resolved By</MenuItem>
                                <MenuItem value='fixby'>Fix By Date</MenuItem>
                                <MenuItem value='document'>By Document Submission</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <Typography classes={{root : classes.header}} variant="body2">Additional Criteria:</Typography>
                            {
                              viewing === 'project' &&
                              <Typography variant="body2">None</Typography>
                            }
                            {
                              ['assigned','reported','resolved'].includes(viewing) &&
                              <>
                                <div style={{display:'flex',flexDirection:'column',position:'relative'}}>
                                  {
                                    !selected &&
                                    <div>
                                      <Typography variant="body2">{getLabel(viewing)}:</Typography>
                                      <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
                                        <SearchIcon />
                                        <Input
                                          id="search-by-name"
                                          type={'text'}
                                          value={search}
                                          placeholder="Search By Name"
                                          onChange={(e) => setSearch(e.target.value)}
                                          endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
                                        />
                                      </FormControl>
                                    </div>
                                  }
                                  {
                                    selected &&
                                    <>
                                      <List>
                                        <ListItem key={selected.id} disableRipple style={{cursor:'default'}} button>
                                          {
                                            !isMobile &&
                                            <ListItemAvatar>
                                              <ProfilePicture user={props.user} employee={selected} />
                                            </ListItemAvatar>
                                          }
                                          <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={selected.name} secondary={<Role user={selected}/>} />
                                          <ListItemSecondaryAction style={{cursor:'pointer'}} onClick={() => setSelected(null)}>
                                            <BackspaceIcon color="secondary" />
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      </List>
                                    </>
                                  }
                                  {
                                    searchResults.length > 0 &&
                                    <div style={{position:'absolute',overflowY:'auto',height:'170px', top:'60px', border: '1px solid #efefef', margin:'4px', backgroundColor:'#FFFFFF',zIndex:'1001'}}>
                                      <List>
                                        {
                                          searchResults.map((user) => {
                                            return (
                                              <ListItem key={user.id} disableRipple style={{cursor:'default'}} button onClick={() => setSelected(user)}>
                                                {
                                                  !isMobile &&
                                                  <ListItemAvatar>
                                                    <ProfilePicture user={props.user} employee={user} />
                                                  </ListItemAvatar>
                                                }
                                                <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} />
                                              </ListItem>
                                            )
                                          })
                                        }
                                      </List>
                                    </div>
                                  }
                                </div>
                              </>
                            }
                            {
                              viewing === 'fixby' &&
                              <div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center'}}>
                                <Typography variant="body2" style={{marginRight:'10px'}}>Fix By:</Typography>
                                <DatePicker
                                  value={fixByDate}
                                  format="EEEE MMMM d yyyy"
                                  onChange={d => setFixByDate(d)}
                                  TextFieldComponent={props => (<Input
                                                                  type="text"
                                                                  onClick={props.onClick}
                                                                  value={props.value}
                                                                  onChange={props.onChange}
                                                                  style={{ textAlign : 'center', color : theme.palette.primary.main, cursor : 'pointer' }}
                                                                />)}
                                />
                              </div>
                            }
                            {
                              viewing === 'document' &&
                              <div>
                                <div>
                                  <FormControl style={{width:'100%'}}>
                                    <InputLabel id="demo-simple-select-label">Document:</InputLabel>
                                    <Select
                                      labelId="viewing-label"
                                      id="viewing-select"
                                      value={selectedDoc}
                                      fullWidth
                                      onChange={(e) => setSelectedDoc(e.target.value)}
                                    >
                                      <MenuItem value='unselected'>Pleast Select</MenuItem>
                                      {
                                        documents.map((doc,i) => {
                                          return (
                                            <MenuItem key={'doc-' + i} value={doc}>{doc.name}</MenuItem>
                                          )
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                </div>
                                {
                                  selectedDoc !== 'unselected' &&
                                  <div>
                                    <FormControl style={{width:'100%'}}>
                                      <InputLabel id="demo-simple-select-label">Submission:</InputLabel>
                                      <Select
                                        labelId="viewing-label"
                                        id="viewing-select"
                                        fullWidth
                                        value={selectedSub}
                                        onChange={(e) => setSelectedSub(e.target.value)}
                                      >
                                        <MenuItem value='unselected'>Please Select</MenuItem>
                                        {
                                          submissions.map((sub, i) => {
                                            if (sub.documentId === selectedDoc.id) {
                                              return (
                                                <MenuItem key={'doc-' + i} value={sub}>{formatDateTime(new Date(sub.date))} - ({sub.observations ? sub.observations : 0}) {props.employees[sub.userId].name}</MenuItem>
                                              )
                                            } else {
                                              return (<></>);
                                            }
                                          })
                                        }
                                      </Select>
                                    </FormControl>
                                  </div>
                                }
                              </div>
                            }
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <div style={{height:'500px'}}>
                      <DataGrid
                        rows={rows}
                        density="compact"
                        autoPageSize={true}
                        checkboxSelection
                        onSelectionChange={(newSelection) => setSelectedObservations(newSelection.rowIds)}
                        columns={columns}
                        sortModel={[{field: 'date', sort: 'desc'}]}
                        onRowClick={(e) => handleClickOpen(e)}
                        className={classes.alertRow}
                        components={{
                          NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Observations Match Selection Criteria.</div>)
                        }}
                      />
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <Button style={{margin:'10px'}}  color="primary" variant="contained" onClick={() => addObservation()}>Add</Button>
                      <Button style={{margin:'10px'}} disabled={selectedObservations.length === 0} color="primary" variant="contained" onClick={() => closeObservations(selectedObservations)}>Close</Button>
                      <Button style={{margin:'10px'}} disabled={selectedObservations.length === 0} color="primary" variant="contained" onClick={() => deleteObservations(selectedObservations)}>Delete</Button>
                    </div>
                  </>
                }
                {
                  observations && observations.length === 0 &&
                  <>
                    <div style={{width:'100%',textAlign:'center'}}>
                      <Typography variant="body2">Currently No Observations Recorded.</Typography>
                      <Button style={{margin:'10px'}}  color="primary" variant="contained" onClick={() => addObservation()}>Add an Observation</Button>
                    </div>
                    <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/jKdNmjavmtU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                      </div>
                      <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                        <Typography variant="h6">Learn About Safety Observations</Typography>
                        <Typography variant="body2">Watch this video to learn more about how safety observations work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                        <ul>
                          <li>Mobile App Observation Forms</li>
                          <li>Add Observations to Field in Report</li>
                          <li>Observation Triggered from Field in Report</li>
                          <li>Viewing Observations on Web Dashboard</li>
                          <li>Viewing Observations on Web with Filters</li>
                          <li>Resolving Observations</li>
                        </ul>
                        <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/observations?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                      </div>
                    </div>
                  </>
                }
              </>
            }
            <Dialog
              fullScreen={fullScreen}
              maxWidth="xl"
              fullWidth={true}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title"></DialogTitle>
              <DialogContent>
                <Observation observation={observation} employees={props.employees} user={props.user} project={props.project} onComplete={(obv) => {
                  if (obv) {
                    let copy = [...observations];
                    for (let i = 0; i < copy.length; i++) {
                      if (copy[i].id === obv.id) {
                        if (obv.deleted) {
                          copy.splice(i, 1);
                        } else {
                          copy[i] = obv;
                        }
                        setObservations(copy);
                        break;
                      }
                    }
                  }
                  setOpen(false);
                }}  />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
