import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import queryString from 'query-string';
import { API } from 'aws-amplify';

import ProfilePicture from "../../../User/ProfilePicture";
import Certification from "./Profiles/Certification";
import Observation from "./Profiles/Observation";
import Training from "./Profiles/Training";
import Signatures from "./Profiles/Signatures";
import Role from "../../../User/Role";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BuildIcon from '@material-ui/icons/Build';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

const { toDate } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  }
}));

export default function Profiles(props) {
  const classes = useStyles();
  const theme = useTheme();
  var parsed = queryString.parse(props.location.search);
  const [employees, setEmployees] = useState([]);
  const [user, setUser] = useState(props.employees && props.employees.hasOwnProperty(parsed.userId ? parsed.userId : props.user.id) ? props.employees[parsed.userId ? parsed.userId : props.user.id] : null);
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const [badgeCounts, setBadgeCounts] = useState({
    observations : 0,
    documents : 0,
    certifications : 0,
    signatures : 0
  });
  const [showAll, setShowAll] = useState({
    documents : false,
    certifications : false
  })

  useEffect(() => {
    if (props.location && props.employees) {
      var parsed = queryString.parse(props.location.search);
      setUser(props.employees && props.employees.hasOwnProperty(parsed.userId ? parsed.userId : props.user.id) ? props.employees[parsed.userId ? parsed.userId : props.user.id] : null);
    }
  }, [props.location, props.employees, props.user]);

  useEffect(() => {
    let emps = Object.values(props.employees).filter(e => !e.inactive);
    emps.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setEmployees(emps);
  }, [props.employees]);

  useEffect(() => {
    if (props.project && user) {
      let mounted = true;
      setLoading(true);
      loadNotifications(props.project.details.id, user.id).then(res => {
        if (mounted) {
          setNotifications(res);
          setLoading(false);
        }
      }).catch(err => {
        if (mounted) {
          setNotifications(null);
          setLoading(false);
        }
      });
      return () => mounted = false;
    }
  }, [props.project, user]);

  const loadNotifications = async (projectId, userId) => {
    return API.get("budeeBackend", "checkin/notifications", {
      queryStringParameters : {
        projectId : projectId,
        userId : userId
      }
    })
  }

  const gradeCertificate = (certification) => {
    const expiration = certification.expires ? toDate(certification.expires) : null;
    const today = new Date();
    var acquired = certification.acquired ? toDate(certification.acquired) : null;
    var cutoff = certification.expires ? toDate(certification.expires) : null;
    if (certification.warning && cutoff) {
      cutoff.setDate(cutoff.getDate() + (-1 * certification.warning));
    }
    var status = 0;
    if (!acquired || acquired > today) {
      status = 2;
    } else {
      if (certification.certificationExpires) {
        if (!expiration) {
          status = 2;
        } else if (today > expiration) {
          status = 2;
        } else if (cutoff && today > cutoff) {
          status = 1;
        }
      }
    }
    return status;
  }

  useEffect(() => {
    if (notifications) {
      let certCount = 0;
      for (const cert of notifications.certifications) {
        let grade = gradeCertificate(cert);
        if (grade > 0) {
          certCount++;
        }
      }
      setBadgeCounts({
        observations : notifications.observations.length,
        documents : notifications.documents.filter(doc => !doc.read).length,
        certifications : certCount,
        signatures : notifications.signatures.length
      });
    }
  }, [notifications]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
        <Typography variant="h6">Profile Inspection</Typography>
        <div style={{flexGrow:1}}></div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <ProfilePicture user={props.user} employee={user} />
          <Role user={user} />
          <Autocomplete
            id="employee-lookup"
            options={employees}
            value={user}
            onChange={(e, v) => {
              setUser(v ? v : props.user);
              props.history.push({
                pathname : props.history.location.pathname,
                search : queryString.stringify(Object.assign(parsed, v ? {userId:v.id} : {}))
              });
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => {
              return (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <ProfilePicture user={props.user} employee={option} />
                  <Typography style={{marginLeft:'10px'}} variant="body2">{option.name}</Typography>
                  <Role user={option} />
                </div>
              )
            }}
            style={{ width: 300, marginLeft: '10px' }}
            renderInput={(params) => {
              return (
                <>
                  {
                    params.value &&
                    <TextField {...params} label="Employee Name" variant="outlined" />
                  }
                  {
                    !params.value && props.user &&
                    <TextField {...params} value={props.user.name} label="Employee Name" variant="outlined" />
                  }
                </>
              );
            }}
          />
        </div>
      </div>
      <Divider style={{margin:'10px'}} />
      {
        loading &&
        <div style={{margin:'0 auto', width:'100px'}}>
          <CircularProgress size={80} />
        </div>
      }
      {
        !loading && user &&
        <>
          <Grid container>
            <Grid xs={12} sm={3} item>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'15px'}}>
                <Badge color="error" badgeContent={badgeCounts.observations}>
                  <VisibilityIcon color={badgeCounts.observations > 0 ? 'primary' : 'disabled'} />
                </Badge>
                <Typography style={{marginLeft:'15px'}} variant="body1">Assigned Observations</Typography>
              </div>
              {
                notifications && notifications.observations.map((obv, i) => {
                  return (
                    <Observation key={'obv-' + i} observation={obv} user={props.user} employees={props.employees} reload={() => loadNotifications(props.project.details.id, user.id).then(res => setNotifications(res))} />
                  )
                })
              }
              {
                notifications && notifications.observations.length === 0 &&
                <Typography variant="body2" color="textSecondary">Currently no assigned observations</Typography>
              }
            </Grid>
            <Grid xs={12} sm={3} item>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'15px'}}>
                <Badge color="error" badgeContent={badgeCounts.documents}>
                  <BuildIcon color={badgeCounts.documents > 0 ? 'primary' : 'disabled'} />
                </Badge>
                <Typography style={{marginLeft:'15px'}} variant="body1">Training</Typography>
              </div>
              {
                notifications && notifications.documents.filter(doc => !doc.read || showAll.documents).map((doc, i) => {
                  return (
                    <Training key={'doc-' + i} document={doc} project={props.project} user={props.user} employees={props.employees} profile={user} reload={() => loadNotifications(props.project.details.id, user.id).then(res => setNotifications(res))} />
                  )
                })
              }
              <div style={{cursor:'pointer',display:'flex',flexDirection:'row',marginTop:'5px'}} onClick={() => setShowAll({...showAll,documents:!showAll.documents})}>
                <PlaylistAddIcon color="primary" />
                <Typography variant="body2">{showAll.documents ? 'Hide All' : 'Show All (' + (notifications ? notifications.documents.filter(doc => doc.read).length : '0') + ')'}</Typography>
              </div>
            </Grid>
            <Grid xs={12} sm={3} item>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'15px'}}>
                <Badge color="error" badgeContent={badgeCounts.certifications}>
                  <VerifiedUserIcon color={badgeCounts.certifications > 0 ? 'primary' : 'disabled'} />
                </Badge>
                <Typography style={{marginLeft:'15px'}} variant="body1">Certifications</Typography>
              </div>
              {
                notifications && notifications.certifications.map((cert, i) => {
                  if (gradeCertificate(cert) === 0 && !showAll.certifications) {
                    return (<React.Fragment key={'cert-' + i}></React.Fragment>)
                  } else {
                    return (
                      <Certification key={'cert-' + i} {...props} profile={user} grade={gradeCertificate(cert)} certification={cert} company={props.company} user={props.user} employees={props.employees} reload={() => loadNotifications(props.project.details.id, user.id).then(res => setNotifications(res))} />
                    );
                  }
                })
              }
              <div style={{cursor:'pointer',display:'flex',flexDirection:'row',marginTop:'5px'}} onClick={() => setShowAll({...showAll,certifications:!showAll.certifications})}>
                <PlaylistAddIcon color="primary" />
                <Typography variant="body2">{showAll.certifications && notifications ? 'Hide All' : 'Show All (' + (notifications ? notifications.certifications.filter(cert => gradeCertificate(cert) === 0).length : '0') + ')'}</Typography>
              </div>
            </Grid>
            <Grid xs={12} sm={3} item>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'15px'}}>
                <Badge color="error" badgeContent={badgeCounts.signatures}>
                  <BorderColorIcon color={badgeCounts.signatures > 0 ? 'primary' : 'disabled'} />
                </Badge>
                <Typography style={{marginLeft:'15px'}} variant="body1">Required Signatures</Typography>
              </div>
              {
                notifications && notifications.signatures.map((sig, i) => {

                  return (
                    <Signatures key={'sig-' + i} {...props} signature={sig} user={props.user} employees={props.employees} documents={props.documents} />
                  )
                })
              }
              {
                notifications && notifications.signatures.length === 0 &&
                <Typography variant="body2" color="textSecondary">Currently no signatures required</Typography>
              }
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
}
