import React, { useState } from "react";

import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import { useFormFields } from "../../../../../libs/hooks";

import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px',
    marginTop : '50px'
  }
}));

export default function DocumentForm(props) {
  const classes = useStyles();

  const [required, setRequired] = useState(props.document && props.document.roles ? 'roles' : 'all');
  const [documentRoles, setDocumentRoles] = useState(props.document && props.document.roles ? props.document.roles : []);
  const [documentContractors, setDocumentContractors] = useState(props.document && props.document.contractors ? props.document.contractors : []);
  const [documentErrors, setDocumentErrors] = useState({
    name : false,
    description : false
  });
  const [document, setDocument] = useFormFields({
    name : props.document ? props.document.name : "",
    description : props.document ? props.document.description : ""
  });
  const [selectedRole, setSelectedRole] = useState('unselected');
  const [selectedContractor, setSelectedContractor] = useState('unselected');
  const [updating, setUpdating] = useState(false);
  const [privateDoc, setPrivateDoc] = useState(props.document && props.document.private ? props.document.private : false);
  const [locked, setLocked] = useState(props.document && props.document.locked ? props.document.locked : false);
  const [scope, setScope] = useState(props.document && props.document.scope ? props.document.scope : 'company');

  const handleChange = (event) => {
    if (event.target.value === 'all') {
      setDocumentRoles([]);
      setDocumentContractors([]);
    }
    setRequired(event.target.value);
  };

  const handleRoleChange = (event) => {
    for (const role of props.roles) {
      if (role.id === event.target.value) {
        setSelectedRole(role);
      }
    }
  };

  const handleContractorChange = (event) => {
    for (const contractor of props.contractors) {
      if (contractor.id === event.target.value) {
        setSelectedContractor(contractor);
      }
    }
  };

  const addRole = async () => {
    if (selectedRole !== 'unselected') {
      for (const role of documentRoles) {
        if (role.id === selectedRole.id) {
          return;
        }
      }
      var copy = [...documentRoles];
      copy.push(selectedRole);
      setDocumentRoles(copy);
    }
  }

  const addContractor = async () => {
    if (selectedContractor !== 'unselected') {
      for (const contractor of documentContractors) {
        if (contractor.id === selectedContractor.id) {
          return;
        }
      }
      var copy = [...documentContractors];
      copy.push(selectedContractor);
      setDocumentContractors(copy);
    }
  }

  const handleDeleteRole = async (id) => {
    const copy = documentRoles.filter(role => role.id !== id);
    setDocumentRoles(copy);
  }

  const handleDeleteContractor = async (id) => {
    const copy = documentContractors.filter(contractor => contractor.id !== id);
    setDocumentContractors(copy);
  }

  const addDocument = () => {
    const check = {
      name : document.name === "" ? true : false,
      description : document.description === "" ? true : false
    };
    setDocumentErrors(check);
    if (!Object.values(check).includes(true)) {
      setUpdating(true);
      var doc = {
        name : document.name,
        description : document.description,
        roles : documentRoles.length > 0 ? documentRoles : null,
        contractors : documentContractors.length > 0 ? documentContractors : null,
        createdBy : props.user.id,
        private : privateDoc,
        locked : locked
      };
      if (props.document && props.document.hasOwnProperty('version')) {
        doc = Object.assign(doc, {
          version : props.document.version,
          id : props.document.id,
          draft : props.document.draft
        });
      }
      API.post("budeeBackend", "documents/", { body : {
        projectId : props.project.details.id,
        document : doc,
        scope : scope
      }}).then((res) => {
        if (!props.edit) {
          setDocumentRoles([]);
          setDocumentContractors([]);
          setRequired('all');
          setDocument({target:[{id:'name', value:''},{id:'description', value:''}]});
        }
        setUpdating(false);
        props.onComplete(doc);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  return (
    <>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
            Scope:
            <Tooltip style={{marginLeft:'15px'}} title={
              props.document ? "Unable to change scope of document, must create a new one." : "A document will be available to everyone in the company or just personnel of " + props.project.details.project + "."
            }>
              <HelpIcon color="primary" />
            </Tooltip>
          </FormLabel>
          <RadioGroup aria-label="scope" name="scope" value={scope} style={{flexDirection : 'row'}} onChange={(e) => setScope(e.target.value)}>
            <FormControlLabel value="project" labelPlacement="end" control={<Radio disabled={props.document != null} />} label="Project-Wide" />
            <FormControlLabel value="company" labelPlacement="end" control={<Radio disabled={props.document != null} />} label="Company-Wide" />
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{marginBottom:'10px'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
            Private:
            <Tooltip style={{marginLeft:'15px'}} title="Only privileged users on mobile will be able to view submissions.">
              <HelpIcon color="primary" />
            </Tooltip>
          </FormLabel>
          <div>
            <Checkbox
              checked={privateDoc}
              onChange={() => setPrivateDoc(!privateDoc)}
              name="private"
              color="primary"
              style={{padding:'0px'}}
            />
          </div>
        </FormControl>
      </div>
      <div style={{marginBottom:'10px'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
            Edit Locked:
            <Tooltip style={{marginLeft:'15px'}} title="Once a submission is finished, only administrators can edit it.">
              <HelpIcon color="primary" />
            </Tooltip>
          </FormLabel>
          <div>
            <Checkbox
              checked={locked}
              onChange={() => setLocked(!locked)}
              name="locked"
              color="primary"
              style={{padding:'0px'}}
            />
          </div>
        </FormControl>
      </div>
      <div style={{display : 'flex', flexDirection : 'row'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Required By:</FormLabel>
          <RadioGroup aria-label="required" name="required" value={required} onChange={handleChange}>
            <FormControlLabel value="all" control={<Radio />} label="Project Wide" />
            <FormControlLabel value="roles" control={<Radio />} label="Specific Role(s)" />
            <FormControlLabel value="contractors" control={<Radio />} label="Specific Contractor(s)" />
          </RadioGroup>
        </FormControl>
        {
          required === 'roles' && props.roles && props.roles.length > 0 &&
          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                style={{}}
                id="role-select"
                value={selectedRole !== 'unselected' && selectedRole ? selectedRole.id : 'unselected'}
                onChange={handleRoleChange}
              >
                <MenuItem key={'role-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.roles.map((role, i) => {
                  return (
                    <MenuItem key={'role-' + i} value={role.id}>{role.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addRole()} variant="contained" color="secondary" style={{}}>Add Role</Button>
            </div>
          </div>
        }
        {
          required === 'roles' && props.roles && props.roles.length === 0 &&
          <Typography>There currently are no roles to choose from, <Link href="/organization?type=roles">click here to add some in the Roles section</Link>.</Typography>
        }
        {
          required === 'contractors' && props.contractors && props.contractors.length > 0 &&
          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end'}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="contractor-label">Contractor</InputLabel>
              <Select
                labelId="contractor-label"
                style={{}}
                id="contractor-select"
                value={selectedContractor !== 'unselected' && selectedContractor ? selectedContractor.id : 'unselected'}
                onChange={handleContractorChange}
              >
                <MenuItem key={'contractor-unselected'} value={'unselected'}>Please Select</MenuItem>
              {
                props.contractors.map((c, i) => {
                  return (
                    <MenuItem key={'contractor-' + i} value={c.id}>{c.name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            <div style={{marginLeft:'10px'}}>
              <Button onClick={() => addContractor()} variant="contained" color="secondary" style={{}}>Add Contractor</Button>
            </div>
          </div>
        }
        {
          required === 'contractors' && props.contractors && props.contractors.length === 0 &&
          <Typography>There currently are no contractors to choose from, <Link href="/organization?type=contractors">click here to add some in the Contractors section</Link>.</Typography>
        }
      </div>
      {
        documentRoles && documentRoles.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          documentRoles.map((role,i) => {
            return (
              <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} onDelete={() => handleDeleteRole(role.id)} key={'role-' + i} label={role.name} size="small"/>
            )
          })
        }
        </div>
      }
      {
        documentContractors && documentContractors.length > 0 &&
        <div style={{width:'100%',marginTop:'5px'}}>
        {
          documentContractors.map((c,i) => {
            return (
              <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} onDelete={() => handleDeleteContractor(c.id)} label={c.name} size="small" />
            )
          })
        }
        </div>
      }
      <TextField
        variant="outlined"
        error={documentErrors.name}
        fullWidth
        required
        size="small"
        id="name"
        label="Name"
        name="name"
        value={document.name}
        inputProps={{maxLength:60}}
        onChange={setDocument}
        margin="normal"
      />
      <TextField
        variant="outlined"
        error={documentErrors.description}
        fullWidth
        required
        multiline
        rows={3}
        size="small"
        id="description"
        label="Description"
        name="description"
        value={document.description}
        onChange={setDocument}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => addDocument()}>
        {
          !updating && !props.edit &&
          "Add Required Document"
        }
        {
          !updating && props.edit &&
          "Update Document"
        }
        {
          updating &&
          <div>
            <CircularProgress size={10} />
          </div>
        }
      </Button>
    </>
  );
}
