import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import { API } from "aws-amplify";
import { isMobile } from "react-device-detect";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import View from '../Documents/Dynamic/View';
import ViewDetails from '../Documents/Upload/ViewDetails';
import Role from "../../../User/Role";
import { DOCUMENT_PRINT_JOB } from "../../../../config/localStorage";

import { CSVDownload } from "react-csv";

const { formatDate, formatDateTime } = require('@budeesolutions/budee-util');
const { RANGES } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  submission : {
    border:'1px solid ' + theme.palette.primary.main,
    borderRadius:'10px',
    margin:'2px',
    cursor:'pointer',
    '&:hover': {
      border:'1px solid ' + theme.palette.accentColor.main,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

var documentColumns = [
  { field: 'id', hide : true },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'document', headerName: 'Document', flex: 1, renderCell : (val) => {
      return (val.value.name);
  } },
  { field: 'user', headerName: 'Employee', flex: 1, renderCell : (val) => {
      return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {
          val.value &&
          <>
            <span>{val.value.name}</span>
            <Role user={val.value} />
          </>
        }
        </div>);
  }  }
];
if (!isMobile) {
  documentColumns.push({ field: 'score', headerName: 'Score', flex: 1 });
  documentColumns.push({ field: 'index', headerName: 'Index', flex: 1 });
}

var uploadColumns = [
  { field: 'id', hide : true },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'user', headerName: 'Employee', flex: 1, renderCell : (val) => {
      return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {
          val.value &&
          <>
            <span>{val.value.name}</span>
            <Role user={val.value} />
          </>
        }
        </div>);
  }  },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'contractor', headerName: 'Contractor', flex: 1 },
  { field: 'files', headerName: 'Files' },
  { field: 'images', headerName: 'Images' }
];

export default function Documents(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [series, setSeries] = useState(props.series);
  const [submission, setSubmission] = useState(null);
  const [documents, setDocuments] = useState({});
  const [fields, setFields] = useState([]);
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [noSubmissions, setNoSubmissions] = useState(true);
  const [employees, setEmployees] = useState(props.employees);
  const [documentRows, setDocumentRows] = useState([]);
  const [uploadRows, setUploadRows] = useState([]);
  const [type, setType] = useState('documents');
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    setSeries(props.series);
  }, [props.series]);

  useEffect(() => {
    if (props.documents) {
      setDocuments(
        props.documents.reduce(function(map, obj) {
          map[obj.id] = obj;
          return map;
        }, {})
      );
    }
  }, [props.documents]);

  useEffect(() => {
    var mounted = true;
    var uploadsGrid = [];
    var documentsGrid = [];
    var noSubs = true;
    for (const name of Object.keys(series)) {
      for (const d of series[name]) {
        noSubs = false;
        if (name === 'Contractor Uploads') {
          uploadsGrid.push({
            id : d.id,
            date : d.date ? formatDateTime(new Date(d.date)) : '',
            user : employees[d.userId],
            name : d.name,
            contractor : d.contractor,
            files : d.files ? d.files.length : 0,
            images : d.images ? d.images.length : 0,
            archive : d.id,
            doc : d
          });
        } else {
          documentsGrid.push({
            id : d.id,
            date : d.date ? formatDateTime(new Date(d.date)) : '',
            document : documents.hasOwnProperty(d.documentId) ? documents[d.documentId] : '',
            user : employees[d.userId],
            index : d.index != null ? d.index : '--',
            score : d.score != null ? formatScore(d) : 'N/A',
            doc : d
          });
        }
      }
    }

    if (mounted) {
      setNoSubmissions(noSubs);
      setDocumentRows(documentsGrid);
      setUploadRows(uploadsGrid);
      setType(documentsGrid.length > 0 ? 'documents' : (uploadsGrid.length > 0 ? 'uploads' : 'documents'));
    }
    return () => mounted = false;
  }, [series, employees, documents]);

  const formatScore = (sub) => {
    let percent = Math.trunc((sub.score / sub.highest) * 100);
    return sub.score + '/' + sub.highest + ' ' + percent + '%';
  }

  useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  const handleClose = async () => {
    setFields([]);
    setDetailsSelected(null);
    setSubmission(null);
    setOpen(false);
  }

  const handleClickOpen = async (event) => {
    if (type === 'documents') {
      setLoadingView(true);
      setOpen(true);
      var doc = {
        ...event.row.document
      };
      setDetailsSelected(doc);
      setFields(await API.get("budeeBackend", "documents/field?projectId=" + props.project.details.id + '&documentId=' + doc.id + '&version=' + doc.version, {}));
      setLoadingView(false);
    }
    setSubmission(event.row.doc);
    setOpen(true);
  };

  const handlePrint = () => {
    localStorage.setItem(DOCUMENT_PRINT_JOB, JSON.stringify({
      submissions : [submission],
      document : detailsSelected,
      employees : props.employees,
      user : props.user,
      project : props.project,
      fields : fields,
      company : props.company.details
    }));
    window.open('/print', '_blank');
  };

  const getSelectedDate = () => {
    switch (props.rollUp) {
      case RANGES.DAY:
        return 'on ' + formatDate(props.selectedDate);
      case RANGES.WEEK:
        let weekLater = new Date(props.selectedDate);
        weekLater.setDate(weekLater.getDate() + 7);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(weekLater);
      case RANGES.MONTH:
        let monthLater = new Date(props.selectedDate);
        monthLater.setDate(monthLater.getDate() + 31);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(monthLater);
      default:
        return formatDate(props.selectedDate);
    }
  }

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    for (const name of Object.keys(series)) {
      for (const d of series[name]) {
        if (name === 'Contractor Uploads') {
          data.push({
            type : 'Upload',
            date : d.date ? formatDateTime(new Date(d.date)) : '',
            user : employees[d.userId].name,
            name : d.name,
            contractor : d.contractor,
            files : d.files ? d.files.length : 0,
            images : d.images ? d.images.length : 0
          });
        } else {
          data.push({
            type : 'Report',
            date : d.date ? formatDateTime(new Date(d.date)) : '',
            document : documents.hasOwnProperty(d.documentId) ? documents[d.documentId].name : '',
            submittedBy : employees[d.userId].name,
            index : d.index != null ? d.index : '--',
            score : d.score != null ? d.score : 'N/A',
            highest: d.highest != null ? d.highest : 'N/A',
          });
        }
      }
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <IconButton onClick={() => downloadCsv()}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        <Typography variant="h6">{props.certification && props.certification.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <Typography variant="h6">CSV</Typography>
      </div>
      <Grid container>
        <Grid xs={12} item>
          <div>
            <FormControl component="fieldset" style={{textAlign : 'center'}}>
              <RadioGroup aria-label="scope" name="scope" value={type} style={{flexDirection : 'row'}} onChange={(e) => setType(e.target.value)}>
                <FormControlLabel className={classes.controlLabel} value="documents" labelPlacement="end" control={<Radio disabled={noSubmissions} />} label="Documents" />
                <FormControlLabel className={classes.controlLabel} value="uploads" labelPlacement="end" control={<Radio disabled={noSubmissions} />} label="Uploads" />
              </RadioGroup>
            </FormControl>
            <div style={{height:'400px'}}>
              <DataGrid
                rows={type === 'uploads' ? uploadRows : documentRows}
                columns={type === 'uploads' ? uploadColumns : documentColumns}
                density="compact"
                autoPageSize={true}
                onCellClick={(e) => {
                  const arr = Array.from(e.element.classList);
                  if (!arr.includes('no-select')) {
                    handleClickOpen(e);
                  }
                }}
                className={classes.documentRow}
                components={{
                  NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Documents {getSelectedDate()}</div>)
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="view-document"
      >
        <DialogTitle id="view-document"></DialogTitle>
        <DialogContent>
          {
            loadingView &&
            <div style={{margin:'0 auto', width:'100px'}}>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loadingView && submission && type === 'uploads' &&
            <ViewDetails user={props.user} details={submission} employees={employees} setOpen={setOpen} project={props.project} company={props.company} />
          }
          {
            !loadingView && submission && type === 'documents' &&
            <View submission={submission} document={detailsSelected} user={props.user} employees={employees} projectId={props.project.details.id} fields={fields} print={false} {...props} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} variant="contained" color="secondary">
            Print
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
