import React, { useState, useEffect } from "react";

import { API, Storage } from "aws-amplify";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { format } from "date-fns";
import { toDate, datediff } from '../../../../libs/util';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ProfilePicture from '../../../User/ProfilePicture';
import Role from '../../../User/Role';
import PropTypes from 'prop-types';

import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  formControl : {
    width : '200px'
  },
  image : {
    width:'400px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function RewardDetail(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [participants, setParticipants] = useState([]);
  const [results, setResults] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.project && props.reward) {
      loadParticipants(props.reward, props.project.details.id);
      loadResults(props.reward, props.project.details.id);
      loadPicture(props.reward);
    }
  }, [props.reward, props.project]);

  const loadParticipants = async (r, projectId) => {
    const res = await API.get('budeeBackend','project/reward/participants?projectId=' + projectId + '&rewardId=' + r.id,{});
    setParticipants(res);
  }

  const loadResults = async (r, projectId) => {
    const res = await API.get('budeeBackend','project/reward/results?projectId=' + projectId + '&rewardId=' + r.id,{});
    setResults(res);
  }

  const loadPicture = async (r) => {
    Storage.get(r.key).then(value => {
      setPictureUrl(value);
    });
  }

  const calculateTotal = (user) => {
    var total = 0;
    var rewarded = 0;
    total += user.profile ? user.profile : 0;
    rewarded += props.reward.profile ? props.reward.profile : 0;
    total += user.personal ? user.personal : 0;
    rewarded += props.reward.personal ? props.reward.personal : 0;
    total += user.checkins ? user.checkins : 0;
    rewarded += props.reward.checkins ? props.reward.checkins : 0;
    total += user.notifications ? user.notifications : 0;
    rewarded += props.reward.notifications ? props.reward.notifications : 0;
    total += user.certificationSubmissions ? user.certificationSubmissions : 0;
    rewarded += props.reward.certificationSubmissions ? props.reward.certificationSubmissions : 0;
    total += user.documentsRead ? user.documentsRead : 0;
    rewarded += props.reward.documentsRead ? props.reward.documentsRead : 0;
    total += user.documentSubmissions ? user.documentSubmissions : 0;
    rewarded += props.reward.documentSubmissions ? props.reward.documentSubmissions : 0;
    total += user.documentsUpload ? user.documentsUpload : 0;
    rewarded += props.reward.documentsUpload ? props.reward.documentsUpload : 0;
    total += user.safetyObservations ? user.safetyObservations : 0;
    rewarded += props.reward.safetyObservations ? props.reward.safetyObservations : 0;

    if (rewarded === 0 || total === 0) {
      return '--';
    } else {
      return round((total/rewarded * 100), 2);
    }
  }

  function round(num, places) {
      var multiplier = Math.pow(10, places);
      return Math.round(num * multiplier) / multiplier;
  }

  const formatDate = (dateStr) => {
    var parts = dateStr.split("-");
    var date = new Date(parts[0], parts[1] - 1, parts[2]);
    return format(date, "EEEE MMMM d yyyy");
  }

  const getCountdown = (start, end) => {
    var startDate = toDate(start);
    var endDate = toDate(end);
    endDate.setHours(23);
    var today = new Date();
    if (startDate.getTime() > today.getTime()) {
      return datediff(startDate, today) + ' day(s) until beginning';
    } else if (startDate.getTime() < today.getTime() && today.getTime() < endDate.getTime()) {
      return datediff(today, endDate) + ' day(s) remaining.';
    } else {
      return 'Reward Completed';
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <Typography variant="h6" style={{color:theme.palette.primary.main}}>{props.reward.title}</Typography>
          <Typography variant="body2">{formatDate(props.reward.start)} - {formatDate(props.reward.end)}</Typography>
          <Typography variant="body2" style={{color:theme.palette.primary.main}}>{getCountdown(props.reward.start,props.reward.end)}</Typography>
          <Typography variant="body2" style={{margin:'5px'}}>{props.reward.description}</Typography>
          <div style={{width:'100%',marginTop:'5px',marginBottom:'5px'}}>
          {
            props.reward.roles.length === 0 && props.reward.users.length === 0 &&
            <Chip key={'all-members'} label={'All project members'} style={{margin:'5px'}} color="primary" />
          }
          {
            (props.reward.roles.length > 0 || props.reward.users.length > 0) &&
            <>
            {
              props.reward.roles.map((role,i) => {
                return (
                  <Chip key={'role-add-' + i} label={role} style={{margin:'5px'}} color="primary" />
                )
              })
            }
            {
              props.reward.users.map((userId, i) => {
                let employee = props.employees[userId];
                if (!employee) {
                  return (
                    <React.Fragment key={'user-add-' + i}></React.Fragment>
                  )
                }
                return (
                  <Chip key={'user-add-' + i} label={employee.name + ''} style={{margin:'5px'}} color="primary" />
                )
              })
            }
            </>
          }
          </div>
          <div>
            <Typography variant="body2">Scoring:</Typography>
            <Chip label="Certifications Submitted" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.certificationSubmissions ? "default" : "primary" } />
            <Chip label="Check-Ins" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.checkins ? "default" : "primary" } />
            <Chip label="Documents Read" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.documentsRead ? "default" : "primary" } />
            <Chip label="Documents Submitted" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.documentSubmissions ? "default" : "primary" } />
            <Chip label="Documents Uploaded" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.documentsUpload ? "default" : "primary" } />
            <Chip label="Notifications" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.notifications ? "default" : "primary" } />
            <Chip label="Personal Pictures" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.personal ? "default" : "primary" } />
            <Chip label="Profile Pictures" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.profile ? "default" : "primary" } />
            <Chip label="Safety Observations" style={{margin:'5px'}} color={props.reward.config && !props.reward.config.safetyObservations ? "default" : "primary" } />
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          {
            pictureUrl &&
            <img className={classes.image} src={pictureUrl} alt={props.reward.title} />
          }
        </Grid>
        <Grid xs={12} sm={12} item>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Leaderboard" {...a11yProps(0)} />
              <Tab label="Results" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
          {
            participants.length > 0 &&
            <List>
            {
              props.employees && participants.map((participant, i) => {
                let user = props.employees[participant.userId];
                if (!user) {
                  return (
                    <React.Fragment key={"participant-"+i}></React.Fragment>
                  );
                }
                return (
                  <ListItem key={"participant-"+i}>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={user} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={user.name} secondary={<Role user={user} />} />
                    <div style={{display:'flex', alignItems:'center'}}>
                      {calculateTotal(participant)}%
                    </div>
                  </ListItem>
                );
              })
            }
            </List>
          }
          {
            participants.length === 0 &&
            <Typography>There is currently no data on participants.</Typography>
          }
          </TabPanel>
          <TabPanel value={value} index={1}>
          {
            results.length > 0 &&
            <List>
            {
              props.employees && results.map((participant, i) => {
                let user = props.employees[participant.userId];
                let suffix = 'th';
                switch (i) {
                  case 0:
                    suffix = 'st';
                    break;
                  case 1:
                    suffix = 'nd';
                    break;
                  case 2:
                    suffix = 'rd';
                    break;
                  default:
                }
                let place = i+1;
                place += suffix;
                if (!user) {
                  return (
                    <React.Fragment key={"participant-"+i}></React.Fragment>
                  );
                }
                return (
                  <ListItem key={"participant-"+i}>
                    {
                      !isMobile &&
                      <ListItemAvatar>
                        <ProfilePicture user={props.user} employee={user} />
                      </ListItemAvatar>
                    }
                    <ListItemText primary={user.name} secondary={<Role user={user} />} />
                    <div style={{display:'flex', alignItems:'center'}}>
                      ({calculateTotal(participant)}% to win)&nbsp;{place}
                    </div>
                  </ListItem>
                );
              })
            }
            </List>
          }
          {
            results.length === 0 &&
            <Typography>There is currently no data on results.</Typography>
          }
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}
