import React, { useState, useEffect, useRef } from "react";

import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import HelpIcon from '@material-ui/icons/Help';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { LANGUAGES } from "../../../../config/languages";
import { useEffectWhen } from '../../../../libs/hooks';
import { API, Storage } from "aws-amplify";

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const { formatDateTime, formatBytes } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  documentRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  }
}));

export default function BasicTraining(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [language, setLanguage] = useState('en');

  const vidRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [lastTime, setLastTime] = useState(0);
  const [translationFiles, setTranslationFiles] = useState(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffectWhen(() => {
    if (vidRef && vidRef.current) {
      if (Math.abs(currentTime - lastTime) < .5) {
        setLastTime(currentTime);
      } else {
        vidRef.current.currentTime = lastTime;
      }
    }
  }, [currentTime,lastTime], [currentTime]);

  useEffect(() => {
    var mounted = true;
    if (props.notifications && props.notifications.documents) {
      var grid = [];
      for (const doc of props.notifications.documents) {
        grid.push({
          id : doc.id,
          name : doc.name,
          description : doc.description,
          type : doc.type,
          status : doc.read,
          doc : doc
        });
      }
      if (mounted) {
        setRows(grid);
      }
    }
    return () => mounted = false;
  }, [props.notifications]);

  useEffect(() => {
    if (detailsSelected && language && translationFiles) {
      if (translationFiles.hasOwnProperty(language)) {
        setUrl(translationFiles[language].url);
      } else {
        setUrl(null);
      }
    }
  }, [detailsSelected, language, translationFiles]);

  var columns = [
    { field: 'id', hide : true },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { field: 'type', headerName: 'Type', renderCell : (val) => {
        return (
          <>
          {
            val.value === 'application/pdf' &&
            <PictureAsPdfIcon color="error" />
          }
          {
            val.value === 'video/mp4' &&
            <VideoLibraryIcon color="primary" />
          }
          </>
        );
      }
    },
    { field: 'status', headerName: 'Status', renderCell : (val) => {
        return (
          <>
            {
              val.value ? <VerifiedUserIcon color="primary" /> : <HelpIcon color="error" />
            }
          </>
        );
      }
    }
  ];

  const handleClickOpen = async (event) => {
    setDetailsSelected(event.row.doc);
    setOpen(true);
  };

  const handleClickClosed = () => {
    setOpen(false);
    setDetailsSelected(null);
  }

  useEffect(() => {
    let mounted = true;
    if (detailsSelected) {
      getTranslationFiles({
        key : detailsSelected.key,
        identityId : detailsSelected.identityId
      }, detailsSelected.type).then(res => {
        if (mounted) {
          setTranslationFiles(res);
        }
      });
    }
    return () => mounted = false;
  }, [detailsSelected]);

  const getTranslationFiles = async (f, t) => {
    var pieces;
    if (f.key.indexOf('_') >= 0) {
      pieces = f.key.split('_');
    } else {
      pieces = f.key.split('.');
    }
    const translationKey = pieces[0];
    const objects = await Storage.list(translationKey, {
      level: "protected",
      identityId : f.identityId
    });
    var files = {};
    for (const obj of objects) {
      if (obj.key.indexOf('_') >= 0) {
        const url = await Storage.get(obj.key, {
          level : 'protected',
          identityId : f.identityId
        })
        files[obj.key.substring(obj.key.indexOf('_') + 1,(obj.key.indexOf('_') + 3))] = {
          ...obj,
          url : url
        };
      }
    }
    return files;
  };

  const recordReceipt = async () => {
    return API.post('budeeBackend', 'documents/readonly/user', {
      body : {
        doc : {
          id : detailsSelected.id,
          version : detailsSelected.version,
          scope : detailsSelected.scope
        },
        projectId : props.project.details.id
      }
    }).then(res => {
      if (!detailsSelected.read) {
        let copy = {...detailsSelected};
        copy.read = true;
        setDetailsSelected(copy);
      }
      props.loadNotifications(props.project.details.id).then(() => {
        setCurrentTime(0);
        setLastTime(0);
      });
    });
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <div style={{height:'400px'}}>
            <DataGrid
              rows={rows}
              disableColumnMenu
              columns={columns}
              density="compact"
              autoPageSize={true}
              pageSize={5}
              sortModel={[{field: 'name', sort: 'asc'}]}
              onCellClick={(e) => {
                const arr = Array.from(e.element.classList);
                if (!arr.includes('no-select')) {
                  handleClickOpen(e);
                }
              }}
              className={classes.documentRow}
            />
          </div>
          <Dialog
            fullScreen={fullScreen}
            maxWidth={detailsSelected && detailsSelected.type === 'video/mp4' ? "xl" : 'md'}
            fullWidth={true}
            open={open}
            onClose={handleClickClosed}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title"></DialogTitle>
            <DialogContent>
              <div style={{display:'flex',flexDirection:'row',marginBottom:'10px'}}>
                <div>
                  <Typography variant="h6">{detailsSelected && detailsSelected.name}</Typography>
                  <Typography variant="body2">{detailsSelected && detailsSelected.description}</Typography>
                </div>
                <div style={{flexGrow:1}}></div>
                <Alert severity={detailsSelected && detailsSelected.read ? "success" : "error"}>{detailsSelected && detailsSelected.read ? 'Training Completed' : 'Incomplete Training'}</Alert>
              </div>

              <>
              {
                detailsSelected && detailsSelected.type === 'video/mp4' &&
                <>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={8} item>
                    {
                      url &&
                      <video controls
                        width="100%%"
                        ref={vidRef}
                        height="400px"
                        onEnded={() => recordReceipt()}
                        onTimeUpdate={(update) => setCurrentTime(update.target.currentTime)}
                        muted={false}
                      >
                        <source src={url} type="video/mp4" />
                      </video>
                    }
                    {
                      !url &&
                      <div style={{width:'100%',height:'400px',backgroundColor:'#cdcdcd'}}></div>
                    }
                  </Grid>
                  <Grid xs={12} sm={4} item>
                    {
                      translationFiles && detailsSelected &&
                      <div style={{border:'1px solid #cdcdcd',borderRadius:10,padding:'10px',marginBottom:'10px'}}>
                        <Typography style={{fontWeight:'bold'}} variant="body1">Select Video Language:</Typography>
                        <RadioGroup aria-label="type" name="type" value={language} onChange={(e) => setLanguage(e.target.value)}>
                        {
                          LANGUAGES.map((lang, i) => {
                            let file = null
                            if (translationFiles && translationFiles.hasOwnProperty(lang.code)) {
                              file = translationFiles[lang.code];
                            }

                            return (
                              <div key={"lang-" + i} style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
                                <FormControlLabel value={lang.code} control={<Radio />} size="small" style={{width:'130px'}} label={lang.name} />
                                {
                                  file &&
                                  <Typography variant="body2" style={{width:'80px'}}>{formatBytes(file.size, 2)}</Typography>
                                }
                                {
                                  file &&
                                  <Typography variant="body2" style={{marginLeft:'5px',marginRight:'5px',width:'125px'}}>{formatDateTime(new Date(file.lastModified))}</Typography>
                                }
                                {
                                  file  &&
                                  <CheckCircleOutlineIcon style={{marginLeft:'5px',marginRight:'5px'}} color="primary" />
                                }
                                {
                                  !file &&
                                  <HighlightOffIcon style={{marginLeft:'5px',marginRight:'5px'}} color="error" />
                                }
                              </div>
                            )
                          })
                        }
                        </RadioGroup>
                      </div>
                    }
                  </Grid>
                </Grid>
                </>
              }
              {
                detailsSelected && detailsSelected.type === 'application/pdf' &&
                <div>
                  {
                    translationFiles && detailsSelected &&
                    <div style={{border:'1px solid #cdcdcd',borderRadius:10,padding:'10px',margin:'0 auto',width:'400px'}}>
                      <Typography style={{fontWeight:'bold'}} variant="body1">Select By Language:</Typography>
                      {
                        LANGUAGES.map((lang, i) => {
                          let file = null
                          if (translationFiles && translationFiles.hasOwnProperty(lang.code)) {
                            file = translationFiles[lang.code];
                          }

                          return (
                            <div key={"lang-" + i} style={{display:'flex',flexDirection:'row'}}>
                              <Link style={{width:'100px'}} onClick={() => recordReceipt()} href={file ? file.url : ''} disabled={!file} target="_blank" rel="noreferrer">
                                { lang.name }
                              </Link>
                              {
                                file &&
                                <Typography variant="body2" style={{width:'80px'}}>{formatBytes(file.size, 2)}</Typography>
                              }
                              {
                                file &&
                                <Typography variant="body2" style={{marginLeft:'5px',marginRight:'5px',width:'125px'}}>{formatDateTime(new Date(file.lastModified))}</Typography>
                              }
                              {
                                file  &&
                                <CheckCircleOutlineIcon style={{marginLeft:'5px',marginRight:'5px'}} color="primary" />
                              }
                              {
                                !file &&
                                <HighlightOffIcon style={{marginLeft:'5px',marginRight:'5px'}} color="error" />
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </div>
              }
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickClosed} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}
