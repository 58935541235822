import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { API } from 'aws-amplify';
import { isMobile } from "react-device-detect";
import { makeStyles } from '@material-ui/core/styles';
import ProfilePicture from "../../../User/ProfilePicture";
import Role from "../../../User/Role";


import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { DataGrid } from '@material-ui/data-grid';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { format } from 'date-fns';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Tooltip from '@material-ui/core/Tooltip';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import BusinessIcon from '@material-ui/icons/Business';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import { CSVDownload } from "react-csv";

const { formatDateTime, formatDate } = require('@budeesolutions/budee-util');
const { RANGES } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  container : {
    border : '3px solid #000000',
    borderRadius : 10,
    margin : '5px',
    padding : '15px',
    backgroundColor : '#FFFFFF'
  },
  listContainer : {
    overflowY:'scroll',
    height : '500px'
  },
  checkinButton : {
    [theme.breakpoints.down('sm')]: {
      padding : '0px'
    }
  }
}));

export default function Alerts(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [employees, setEmployees] = useState(props.employees);
  const [series, setSeries] = useState(props.series);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertDetails, setAlertDetails] = useState(null);

  const [searchAcknowledged, setSearchAcknowledged] = useState('');
  const [filter, setFilter] = useState('');
  const [csvData, setCsvData] = useState(null);

  const columns = [
    { field: 'id', hide : true },
    { field: 'date', headerName: 'Date', flex: 1, renderCell : (val) => {
      return (
        <Typography variant="body2" color="textSecondary">{format(val.value, 'yyyy/MM/dd  hh:mmaaa')}</Typography>
      )
    } },
    { field: 'distribution', headerName: 'Distribution', flex: 1, renderCell : (val) => {
      return (
        <>
          <Tooltip title={
            <React.Fragment>
            {
              val.value &&
              <>
                {
                  val.value.all &&
                  <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="Project-Wide - All Employees" />
                }
                {
                  !val.value.all && val.value.supervisors &&
                  <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="All Supervisors" />
                }
                {
                  !val.value.all && val.value.administrators &&
                  <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="All Administrators" />
                }

                {
                  !val.value.all && Array.isArray(val.value.roles) && val.value.roles.map((r,i) => {
                    return (
                      <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r} size="small"/>
                    )
                  })
                }
                {
                  !val.value.all && Array.isArray(val.value.contractors) && val.value.contractors.map((r,i) => {
                    return (
                      <Chip style={{margin:'2px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white'}} />} key={'contractor-' + i} label={r} size="small"/>
                    )
                  })
                }
                {
                  !val.value.all && Array.isArray(val.value.departments) && val.value.departments.map((r,i) => {
                    return (
                      <Chip style={{margin:'2px',backgroundColor:'blue',color:'white'}} icon={<GroupWorkIcon style={{color:'white'}} />} key={'department-' + i} label={r} size="small"/>
                    )
                  })
                }
                {
                  !val.value.all && Array.isArray(val.value.singles) && val.value.singles.map((id,i) => {
                    return (
                      <Chip style={{margin:'2px',backgroundColor:'gold',color:'white'}} icon={<ProfilePicture size={'small'} user={props.user} employee={props.employees.hasOwnProperty(id) ? props.employees[id] : null} />} key={'department-' + i} label={props.employees.hasOwnProperty(id) ? props.employees[id].name : null} size="small"/>

                    )
                  })
                }
              </>
            }
            </React.Fragment>
          }>
            <div style={{marginTop:'10px'}}><AssignmentIndIcon color="primary" /></div>
          </Tooltip>
        </>
      )
    } },
    { field: 'title', headerName: 'Type', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'acknowledged', headerName: 'Acknowledged', flex: 1, renderCell : (val) => {
      return (
        <Box display="flex" width="100%" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgressWithLabel style={{height:'20px', width:'100%'}} variant="determinate" value={val.value} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {`${Math.round(val.value)}%`}
            </Typography>
          </Box>
        </Box>
      )
    } },
    { field: 'sentBy', headerName: 'Sent By', flex: 1, renderCell : (val) => {
        return (
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          {
            val.value &&
            <>
              <span>{val.value.name}</span>
              <Role user={val.value} />
            </>
          }
          </div>
        );
    } }
  ];

  useEffect(() => {
    setSeries(props.series);
  }, [props.series]);

  useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  useEffect(() => {
    if (series) {
      var mounted = true;
      var rows = [];
      for (const name of Object.keys(series)) {
        for (const notif of series[name]) {
          const success = notif.success ? notif.success : null;
          const acknowledged = notif.acknowledged ? Math.min((notif.acknowledged / success) * 100, 100) : 0;
          rows.push({
            id : notif.id,
            date : new Date(notif.date),
            title : notif.title,
            description : notif.description,
            sentBy : employees[notif.sentBy],
            acknowledged : acknowledged,
            distribution : notif.distribution
          });
        }
      }
      if (mounted) {
        setRows(rows);
      }
    }
    return () => mounted = false;
  }, [series, employees]);

  const handleClickOpen = async (event) => {
    setOpen(true);
    setLoading(true);
    setAlertDetails(await API.get("budeeBackend", "notifications?projectId=" + props.project.details.id + '&notificationId=' + event.row.id, {}));
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSelectedDate = () => {
    switch (props.rollUp) {
      case RANGES.DAY:
        return 'on ' + formatDate(props.selectedDate);
      case RANGES.WEEK:
        let weekLater = new Date(props.selectedDate);
        weekLater.setDate(weekLater.getDate() + 7);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(weekLater);
      case RANGES.MONTH:
        let monthLater = new Date(props.selectedDate);
        monthLater.setDate(monthLater.getDate() + 31);
        return 'between ' + formatDate(props.selectedDate) + ' and ' + formatDate(monthLater);
      default:
        return formatDate(props.selectedDate);
    }
  }

  const downloadCsv = async () => {
    setCsvData(null);
    var data = [];
    for (const name of Object.keys(series)) {
      for (const notif of series[name]) {
        const success = notif.success ? notif.success : null;
        const acknowledged = notif.acknowledged ? Math.min((notif.acknowledged / success) * 100, 100) : 0;
        data.push({
          date : new Date(notif.date),
          title : notif.title,
          description : notif.description,
          sentBy : employees[notif.sentBy].name,
          acknowledged : acknowledged + '%',
          ...notif.distribution,
          singles : notif.distribution.singles ? notif.distribution.singles.map(id => employees[id].name).join(',') : ''
        });
      }
    }
    setCsvData(data);
  }

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <div className={classes.container} style={{borderColor : theme.palette.secondary.main}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <IconButton onClick={() => downloadCsv()}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        <Typography variant="h6">{props.certification && props.certification.name}</Typography>
        {
          csvData &&
          <CSVDownload data={csvData} target="_blank" />
        }
        <Typography variant="h6">CSV</Typography>
      </div>
      <Grid container>
        <Grid xs={12} item>
          <div style={{height:'400px'}}>
            <DataGrid
              rows={rows}
              columns={columns}
              density="compact"
              autoPageSize={true}
              sortModel={[{field: 'date', sort: 'desc'}]}
              onRowClick={(e) => handleClickOpen(e)}
              className={classes.alertRow}
              components={{
                NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Alerts {getSelectedDate()}</div>)
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
        {
          loading &&
          <div>
            <CircularProgress size={40} color="secondary" />
          </div>
        }
        {
          !loading && alertDetails &&
          <div>
            <Typography variant="h6" gutterBottom style={{textAlign:'center',color:'grey'}}>Please Allow Up to 1 Minute After First Sent for Alerts to Propagate</Typography>
            <Typography variant="h6" gutterBottom>{alertDetails.title}</Typography>
            <Typography variant="body2" gutterBottom>{alertDetails.description}</Typography>

            {
              alertDetails.distribution.all &&
              <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="Project-Wide - All Employees" />
            }
            {
              !alertDetails.distribution.all && alertDetails.distribution.supervisors &&
              <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="All Supervisors" />
            }
            {
              !alertDetails.distribution.all && alertDetails.distribution.administrators &&
              <Chip size="small" style={{margin:'4px',color:'white'}} color="primary" label="All Administrators" />
            }

            {
              !alertDetails.distribution.all && Array.isArray(alertDetails.distribution.roles) && alertDetails.distribution.roles.map((r,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r} size="small"/>
                )
              })
            }
            {
              !alertDetails.distribution.all && Array.isArray(alertDetails.distribution.contractors) && alertDetails.distribution.contractors.map((r,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'purple',color:'white'}} icon={<BusinessIcon style={{color:'white'}} />} key={'contractor-' + i} label={r} size="small"/>
                )
              })
            }
            {
              !alertDetails.distribution.all && Array.isArray(alertDetails.distribution.departments) && alertDetails.distribution.departments.map((r,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'blue',color:'white'}} icon={<GroupWorkIcon style={{color:'white'}} />} key={'department-' + i} label={r} size="small"/>
                )
              })
            }
            {
              !alertDetails.distribution.all && Array.isArray(alertDetails.distribution.singles) && alertDetails.distribution.singles.map((id,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'gold',color:'white'}} icon={<ProfilePicture size={'small'} user={props.user} employee={props.employees.hasOwnProperty(id) ? props.employees[id] : null} />} key={'department-' + i} label={props.employees.hasOwnProperty(id) ? props.employees[id].name : null} size="small"/>

                )
              })
            }
            <div style={{display:'flex',flexDirection: isMobile ? 'column' : 'row',justifyContent: isMobile ? 'center' : 'space-between'}}>
              <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'center'}} component="fieldset">
                {
                  !isMobile &&
                  <FormLabel component="legend">Filter</FormLabel>
                }
                <RadioGroup row aria-label="position" name="position" onChange={(e) => setFilter(e.target.value)} defaultValue="all">
                  <FormControlLabel
                    value="all"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="All"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="unacknowledged"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="Unacknowledged"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="acknowledged"
                    classes={{label : isMobile ? classes.filterLabel : null}}
                    control={<Radio color="primary" size={isMobile ? 'small' : 'medium'} />}
                    label="Acknowledged"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl style={{display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
                <SearchIcon />
                <Input
                  id="search-by-name"
                  type={'text'}
                  value={searchAcknowledged}
                  placeholder="Search By Name"
                  onChange={(e) => {
                    setSearchAcknowledged(e.target.value);
                    setFilter('all');
                  }}
                  endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearchAcknowledged('')} position="end">{searchAcknowledged.length > 0 ? 'X' : ' '}</InputAdornment>}
                />
              </FormControl>
            </div>
            <List>
              {
                alertDetails && alertDetails.users.map((u, i) => {
                  let user = employees[u.userId];
                  if (!user) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  } else if ((filter === 'acknowledged' && !u.acknowledged) || (filter === 'unacknowledged' && u.acknowledged)) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  } else if (searchAcknowledged && !user.name.toLowerCase().startsWith(searchAcknowledged.toLowerCase())) {
                    return (<React.Fragment key={'no-user-' + i}></React.Fragment>);
                  }
                  return (
                    <ListItem disableRipple key={user.id} button>
                      {
                        !isMobile &&
                        <ListItemAvatar>
                          <ProfilePicture user={props.user} employee={user} />
                        </ListItemAvatar>
                      }
                      <ListItemText classes={{primary : classes.listItemText, secondary : classes.listItemText}} primary={user.name} secondary={<Role user={user}/>} />
                      {
                        u.acknowledged &&
                        <div style={{display:'flex', alignItems:'center'}}>
                          <CheckCircleIcon color="primary" />
                          Acknowledged - {u && u.modified ? formatDateTime(new Date(u.modified)) : ''}
                        </div>
                      }
                      {
                        !u.acknowledged &&
                        <div style={{display:'flex', alignItems:'center'}}>
                          <ErrorIcon color="error" />
                          Unacknowledged
                        </div>
                      }
                    </ListItem>
                  )
                })
              }
            </List>
          </div>
        }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
