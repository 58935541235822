import React, { useState } from "react";

import { API } from "aws-amplify";
import { useFormFields } from "../../libs/hooks";
import { PROVINCES, STATES, AUSTRALIAN_PROVINCES, IRELAND_PROVINCES } from '../../config/constants';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  country : {
    textAlign : 'left',
    marginTop:'16px',
    marginBottom:'8px'
  },
  selectInput : {
    paddingLeft : '14px',
    paddingTop:'10.5px',
    paddingBottom:'10.5px'
  }
}));

const setupCountryOptions = () => {
  var options = [];
  options.push(<MenuItem key={'country-unselected'}  value={'unselected'}>Select Country *</MenuItem>);
  options.push(<MenuItem key={'country-au'}  value={'Australia'}>Australia</MenuItem>);
  options.push(<MenuItem key={'country-ca'}  value={'Canada'}>Canada</MenuItem>);
  options.push(<MenuItem key={'country-uk'}  value={'Ireland'}>Ireland</MenuItem>);
  options.push(<MenuItem key={'country-uk'}  value={'United Kingdom'}>United Kingdom</MenuItem>);
  options.push(<MenuItem key={'country-us'}  value={'United States'}>United States</MenuItem>);
  return options;
}

const getProvinceState = (country) => {
  const items = [<MenuItem key={'country-unselected'}  value={'unselected'}>Select Province/State</MenuItem>];
  switch (country) {
    case 'Australia':
      for (const state of Object.values(AUSTRALIAN_PROVINCES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    case 'Canada':
      for (const province of Object.values(PROVINCES)) {
        items.push(<MenuItem key={'province-' + province}  value={province}>{province}</MenuItem>);
      }
      break;
    case 'United States':
      for (const state of Object.values(STATES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    case 'Ireland':
      for (const state of Object.values(IRELAND_PROVINCES)) {
        items.push(<MenuItem key={'state-' + state}  value={state}>{state}</MenuItem>);
      }
      break;
    default:
  }
  return items;
}

export default function CompanyProfile(props) {
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const countries = setupCountryOptions();
  const [companyDetails, setCompanyDetails] = useFormFields({
    name : "",
    website : "",
    industry : ""
  });
  const [companyAddress, setCompanyAddress] = useFormFields({
    line1 : "",
    line2 : "",
    city : "",
    state : "unselected",
    postal : "",
    country : "unselected"
  });
  const [detailErrors, setDetailErrors] = useState({
    name : false,
    website : false,
    industry : false
  });
  const [addressErrors, setAddressErrors] = useState({
    line1 : false,
    line2 : false,
    city : false,
    state : false,
    postal : false,
    country : false
  });

  const submitForm = () => {
    const checkAddress = {
      line1 : companyAddress.line1 === "" ? true : false,
      line2 : false,
      city : companyAddress.city === "" ? true : false,
      state : companyAddress.country !== "United Kingdom" && (companyAddress.state === "unselected" || companyAddress.state === "--") ? true : false,
      postal : companyAddress.postal === "" ? true : false,
      country : companyAddress.country === "unselected" ? true : false
    };
    setAddressErrors(checkAddress);
    const checkDetails = {
      name : companyDetails.name === "" ? true : false,
      website : companyDetails.website === "" ? true : false,
      industry : companyDetails.industry === "" ? true : false,
    };

    setDetailErrors(checkDetails);
    if (!Object.values(checkAddress).includes(true) && !Object.values(checkDetails).includes(true)) {
      setUpdating(true);
      API.post("budeeBackend", "company/", { body : Object.assign(companyDetails, { address : companyAddress }) }).then((res) => {
        setUpdating(false);
        props.onComplete(res);
      }).catch(err => {
        setUpdating(false);
      });
    }
  }

  return (
    <div>
      {
        props.title &&
        <h1>New Company Profile</h1>
      }
      <Grid container spacing={6}>
        <Grid xs={12} sm={6} item>
          <TextField
            variant="outlined"
            error={detailErrors.name}
            required
            fullWidth
            size="small"
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={setCompanyDetails}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={addressErrors.line1}
            required
            id="line1"
            name="line1"
            label="Address Line 1"
            size="small"
            fullWidth
            autoComplete="address-line1"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <TextField
            variant="outlined"
            id="line2"
            name="line2"
            label="Address Line 2"
            size="small"
            fullWidth
            autoComplete="address-line2"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={addressErrors.city}
            required
            id="city"
            name="city"
            label="City"
            size="small"
            fullWidth
            autoComplete="address-level2 city"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <Select
            required
            variant="outlined"
            error={addressErrors.country}
            id="country"
            name="country"
            label="Country"
            size="small"
            className={classes.country}
            classes={{root : classes.selectInput}}
            value={companyAddress.country}
            fullWidth
            defaultValue="CA"
            autoComplete="country country-name"
            onChange={setCompanyAddress}
          >
            {countries}
          </Select>
          {
            companyAddress.country !== 'United Kingdom' &&
            <Select
              required
              variant="outlined"
              error={addressErrors.state}
              id="state"
              name="state"
              label="State/Province"
              size="small"
              className={classes.country}
              classes={{root : classes.selectInput}}
              value={companyAddress.state}
              fullWidth
              defaultValue="unselected"
              autoComplete="address-level1 state province"
              onChange={setCompanyAddress}
            >
              {getProvinceState(companyAddress.country)}
            </Select>
          }
        </Grid>
        <Grid xs={12} sm={6} item>
          <TextField
            variant="outlined"
            error={addressErrors.postal}
            required
            id="postal"
            name="postal"
            label="Zip / Postal code"
            size="small"
            fullWidth
            autoComplete="postal-code"
            onChange={setCompanyAddress}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={detailErrors.industry}
            required
            id="industry"
            name="industry"
            label="Industry"
            size="small"
            fullWidth
            onChange={setCompanyDetails}
            margin="normal"
          />
          <TextField
            variant="outlined"
            error={detailErrors.website}
            required
            id="website"
            name="website"
            label="Website"
            size="small"
            fullWidth
            onChange={setCompanyDetails}
            margin="normal"
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => submitForm()}
      >
        Register New Company
      </Button>
      {
        updating &&
        <div>
          <CircularProgress size={80} />
        </div>
      }
    </div>
  );
}
