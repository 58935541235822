import React, { useState } from "react";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//
// }));

import SubmissionForm from "../../Certifications/SubmissionForm";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export default (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const renderStatus = () => {
    switch (props.grade) {
      case 0:
        return (
          <>
            <CheckIcon color="primary" style={{marginRight:'10px'}} />
            <Typography color="primary" variant="body2">Compliant</Typography>
          </>
        );
      case 1:
        return (
          <>
            <RemoveCircleOutlineIcon color="inherit" style={{color:'gold',marginRight:'10px'}} />
            <Typography color="inherit" style={{color:'gold'}} variant="body2">Expires Soon</Typography>
          </>

        );
      case 2:
        return (
          <>
            <RemoveCircleOutlineIcon color="error" style={{marginRight:'10px'}} />
            <Typography color="error" variant="body2">Missing/Expired</Typography>
          </>
        );
      default:
      return (<></>);
    }
  }

  return (
    <>
      <Card style={{margin:'4px',border:'2px solid ' + theme.palette.primary.main}} raised>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardContent>
            <div>
              <Typography gutterBottom variant="body2">{ props.certification.name }</Typography>
            </div>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.certification.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            { renderStatus() }
          </div>
        </CardActions>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography variant="h6">{props.certification.name}</Typography>
          <Typography variant="body2">{props.certification.description}</Typography>
          {
            !props.certification.roles &&
            <Chip key='role-req-projectWide' label="Project Wide" style={{margin:'5px'}} color="primary" />
          }
          {
            props.certification.roles && props.certification.roles.map((role, i) => {
              return (
                <Chip key={'role-req-' + i} label={role.name} style={{margin:'5px'}} color="primary" />
              )
            })
          }
          <Divider />
          <SubmissionForm user={props.user} submitFor={props.profile} submission={props.certification} certification={props.certification} project={props.project} company={props.company} onComplete={() => {
            setOpen(false);
            props.reload();
          }} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
