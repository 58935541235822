import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { useTheme } from '@material-ui/core/styles';

import ProgramForm from './EAP/ProgramForm';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import { isMobile } from "react-device-detect";

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  list : {
    width : '500px'
  },
  row : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  },
  certDates : {
    textAlign:'right'
  }
}));

function doScrolling(elementY, duration) {
  var startingY = window.pageYOffset;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

export default function Programs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [programs, setPrograms] = useState(null);
  const [showAdd, setShowAdd] = useState(false);

  const programFormRef = useRef(null);

  useEffect(() => {
    loadPrograms(props.company.details.id);
  }, [props.company.details.id]);

  useEffect(() => {
    var mounted = true;
    var grid = [];
    if (programs && programs.length > 0) {
      for (const p of programs) {
        var item = {
          id : p.id,
          title : p.title,
          description : p.description,
          link : p.link,
        };
        grid.push(item);
      }
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [programs, props.employees]);

  const loadPrograms = async (companyId) => {
    setLoading(true);
    return API.get("budeeBackend", "company/program?companyId=" + companyId, {}).then(res => {
      setPrograms(res);
      setLoading(false);
    });
  };

  const handleDelete = async (programId) => {
    await API.del("budeeBackend", "company/program", {
      body : {
        companyId : props.company.details.id,
        programId : programId
      }
    }).then(res => {
      setLoading(true);
      loadPrograms(props.company.details.id);
    });
  };

  useEffect(() => {
    if (showAdd) {
      scrollToRef(programFormRef);
    }
  }, [showAdd]);

  const showAddForm = async (show) => {
    setShowAdd(!show);
  }

  var columns = [
    { field: 'title', headerName: 'Title', flex:1 },
    { field: 'description', headerName: 'Description', flex: 2 }
  ];
  if (props.project.status === USER_STATUS.SUPERADMIN) {
    columns = columns.concat([
      { field: 'id', headerName : ' ', renderCell : ({value}) => {
        return (
          <Button onClick={() => handleDelete(value)} size="small" style={{margin:'10px'}} color="primary" variant="contained">Delete</Button>
        );
      }}
    ]);
  }

  if (!isMobile) {
    columns.push({ field: 'link', headerName: 'Link', renderCell : ({value}) => {
      return (
        <Link href={'http://' + value} target="_blank" variant="body2">
          Test Link
        </Link>
      )
    }});
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6" gutterBottom>Employee Assistance Programs</Typography>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            !loading && rows && rows.length === 0 &&
            <p>Currently you don't have any programs.</p>
          }
          {
            !loading && rows && rows.length > 0 &&
            <>
              <p>Programs History:</p>
              <div style={{height:'400px',marginBottom:'5px'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  density="compact"
                  autoPageSize={true}
                  sortModel={[{field: 'title', sort: 'desc'}]}
                  className={classes.row}
                />
              </div>
            </>
          }
          <div ref={programFormRef} style={{display:'flex',alignItems:'center'}}>
          {
            props.project.status === USER_STATUS.SUPERADMIN &&
            <>
              <p>Add a new program at <strong>{props.company.details.name}</strong>:</p>
              <Button onClick={() => showAddForm(showAdd)} style={{margin:'10px'}} color="primary" variant="outlined">
              {
                !showAdd &&
                "Add New Program"
              }
              {
                showAdd &&
                "Cancel New Program"
              }
              </Button>
            </>
          }
          </div>
          {
            showAdd &&
            <ProgramForm company={props.company} loadPrograms={loadPrograms} />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}
