import React from "react";

import { API } from "aws-amplify";

import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ProfilePicture from '../../../../User/ProfilePicture';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
const { formatDateTime } = require('@budeesolutions/budee-util');

const useStyles = makeStyles((theme) => ({
  submission : {
    border:'1px solid ' + theme.palette.primary.main,
    borderRadius:'10px',
    margin:'2px'
  }
}));

export default function Submission(props) {
  const classes = useStyles();

  const history = useHistory();

  const getScore = (sub) => {
    if (sub.score && sub.highest) {
      const percent = Math.round((sub.score / sub.highest) * 100);
      return sub.score + '/' + sub.highest + ' ' + percent + '%';
    } else {
      return sub.score;
    }
  }

  const getSubmission = () => {
    return (
      <IconButton onClick={(e) => props.onClick(e)}>
        <DescriptionIcon color="primary" />
      </IconButton>
    );
  };

  const getObservations = () => {
    return (
      <>
        {
          props.submission.observations > 0 &&
          <IconButton onClick={(e) => {
            history.push('observations?' + queryString.stringify({
              submissionId : props.submission.id,
              documentId : props.submission.documentId,
              documentVersion : props.submission.documentVersion
            }));
            history.go(0);
          }}>
            <Badge color="primary" badgeContent={props.submission.observations}>
              <VisibilityIcon color="primary" />
            </Badge>

          </IconButton>
        }
      </>
    );
  };

  const deleteSubmission = (projId, sub) => {
    return API.del('budeeBackend', 'documents/submission', {
      body : {
        projectId : projId,
        documentId : sub.documentId,
        documentVersion : sub.documentVersion,
        submissionId : sub.id
      }
    }).then(res => {
      if (props.reload) {
        props.reload();
      }
    });
  }

  return (
    <>
    {
      props.submission && props.employees && props.employees.hasOwnProperty(props.submission.userId) &&
      <ListItem key={props.submission.id} className={classes.submission}>
        {
          props.employees.hasOwnProperty(props.submission.userId) &&
          <ListItemAvatar>
            <ProfilePicture user={props.user} employee={props.employees[props.submission.userId]} />
          </ListItemAvatar>
        }
        <ListItemText primary={props.employees[props.submission.userId].name} secondary={formatDateTime(new Date(props.submission.date))} />
        <ListItemText primary={getSubmission()} secondary="View" />
        <ListItemText primary={getObservations()} secondary={(props.submission.score > 0 ? getScore(props.submission) : 'N/A')} />
        <ListItemText style={{flexGrow:1,flexBasis:0}} primary={props.indexed ? props.indexed.name : '-'} secondary={props.submission.index ? props.submission.index : '-'} />
        {
          props.submission.status === 'DRAFT' &&
          <IconButton onClick={(e) => deleteSubmission(props.project.details.id, props.submission)}>
            <DeleteForeverIcon color="primary" />
          </IconButton>
        }
      </ListItem>
    }
    </>
  );
}
