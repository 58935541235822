import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";
import { useTheme } from '@material-ui/core/styles';
import CertificationForm from './Certifications/CertificationForm';
import SubmissionDetails from './Certifications/SubmissionDetails';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const { USER_STATUS } = require('@budeesolutions/budee-constants');

const useStyles = makeStyles((theme) => ({
  dateLabel : {
    display : 'inline-block',
    flexGrow : 1
  },
  datePicker : {
    width : '100%',
    display : 'flex',
    margin : '16px 0px 8px 0px'
  },
  certificationList : {
    width : '500px'
  },
  certificationRow : {
    "& .MuiDataGrid-row": {
      cursor : 'pointer'
    }
  },
  certDates : {
    textAlign:'right'
  }
}));

function doScrolling(elementY, duration) {
  var startingY = window.pageYOffset;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

const scrollToRef = (ref) => doScrolling(ref.current.offsetTop - 60, 700);

export default function Certifications(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [required, setRequired] = useState([]);
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [detailsSelected, setDetailsSelected] = useState(null);
  const [employees, setEmployees] = useState(props.employees);
  const [showAdd, setShowAdd] = useState(false);
  const certificationFormRef = useRef(null);
  const [search, setSearch] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    var mounted = true;
    var grid = [];
    for (const cert of certifications) {
      if (!cert.name.toLowerCase().startsWith(search.toLowerCase())) {
        continue;
      }
      grid.push({
        id : cert.id,
        name : cert.name,
        description : cert.description,
        requiredBy: {
          roles : cert.roles,
          contractors : cert.contractors
        },
        warning : cert.warning,
        required : required.includes(cert.id),
        certification : cert
      });
    }
    if (mounted) {
      setRows(grid);
    }
    return () => mounted = false;
  }, [certifications, required, search]);

  useEffect(() => {
    if (props.project) {
      setLoading(true);
      loadCertifications(props.project.details.id);
    }
  }, [props.project]);

  useEffect(() => {
    if (props.employees) {
      var copy = {...props.employees};
      for (const [key, value] of Object.entries(copy)) {
        if (value.inactive) {
          delete copy[key];
        }
      }
      setEmployees(copy);
    }
  }, [props.employees]);

  useEffect(() => {
    if (showAdd) {
      scrollToRef(certificationFormRef);
    }
  }, [showAdd]);

  const loadCertifications = async (projectId) => {
    return API.get("budeeBackend", "certifications/project?projectId=" + projectId, {}).then(res => {
      if (res) {
        setCertifications(res.certifications);
        setRequired(res.required);
      }
      setLoading(false);
    });
  };

  const handleClickOpen = async (event) => {
    setDetailsSelected(event.row.certification);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequied = async (req, projectId, certificationId) => {
    await API.post("budeeBackend", "certifications/project/required", { body : {
        projectId : projectId,
        certificationId : certificationId,
        required : !req
      }
    }).then(res => loadCertifications(projectId));
  }

  const columns = [
    { field: 'id', hide : true },
    { field: 'required', width:120, headerName: 'Required', cellClassName : 'no-select', renderCell : (val) => {
      return (
        <Checkbox
          checked={val.value}
          className={'selectCheckbox'}
          onClick={(e) => handleRequied(e.target.checked, props.project.details.id, val.row.id)}
        />
      )
    }},
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'warning', headerName: 'Warning (Days)', flex: 1 },
    { field: 'requiredBy', headerName: 'Employees', flex: 1, renderCell : (val) => {
      if (val.value && (val.value.roles || val.value.contractors)) {
        return (
          <Tooltip title={
            <React.Fragment>
            {
              val.value.roles && val.value.roles.map((r,i) => {
                return (
                  <Chip style={{margin:'2px',backgroundColor:'red',color:'white'}} icon={<WorkOutlineIcon style={{color:'white'}} />} key={'role-' + i} label={r.name} size="small"/>
                );
              })
            }
            {
              val.value.contractors && val.value.contractors.map((c,i) => {
                return (
                  <Chip style={{margin:'2px', backgroundColor:'purple',color:'white'}} key={'contractor-' + i} icon={<BusinessIcon style={{color:'white'}} />} label={c.name} size="small" />
                );
              })
            }
            </React.Fragment>}>
            <div><AssignmentIndIcon color="primary" /></div>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="All employees of the project.">
            <div style={{fontWeight:'bold'}}>Project Wide</div>
          </Tooltip>
        );
      }
    } }
  ];

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6" gutterBottom>Certifications</Typography>
          {
            loading &&
            <div>
              <CircularProgress size={80} />
            </div>
          }
          {
            certifications && certifications.length > 0 &&
            <p>All employees will be prompted on check-in if they haven't uploaded their certification that they are missing a required certification.  They will then be able to upload a certification to match the requirement.  <strong>If the required column with checkbox is not checked, that certification will not be actively required on this jobsite</strong>.</p>
          }
          {
            !loading && certifications && certifications.length === 0 &&
            <>
              <div style={{width:'100%',textAlign:'center'}}>
                <Typography variant="body2">Currently No Certifications Required</Typography>
              </div>
              <Divider style={{marginTop:'10px',marginBottom:'10px'}} />
              <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/EeIaYB68CtQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
                </div>
                <div style={{flexGrow:1,flexBasis:0,padding:'10px'}}>
                  <Typography variant="h6">Learn About Certifications</Typography>
                  <Typography variant="body2">Watch this video to learn more about how certifications work, to learn more about all aspects of Budee visit our Training Website by clicking the button below.</Typography>
                  <ul>
                    <li>Certification Form for Adding</li>
                    <li>Adding Example and Activating Required</li>
                    <li>Mobile App Home for Required Certification</li>
                    <li>Dashboard View for Compliance</li>
                    <li>Administrative Management and Adding Certifications for Users</li>
                  </ul>
                  <Button variant="contained" color="primary" onClick={() => window.open('https://training.budeesolutions.com/certifications?platform=web&user=admin', '_blank')}>Visit Training Website!</Button>
                </div>
              </div>
            </>
          }
          {
            !loading && certifications && certifications.length > 0 &&
            <>
              <FormControl style={{marginBottom:'10px',display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent:'flex-end'}}>
                <SearchIcon />
                <Input
                  id="search-by-name"
                  type={'text'}
                  value={search}
                  placeholder="Search By Name"
                  onChange={(e) => setSearch(e.target.value)}
                  endAdornment={<InputAdornment style={{cursor:'pointer'}} onClick={() => setSearch('')} position="end">{search.length > 0 ? 'X' : ' '}</InputAdornment>}
                />
              </FormControl>
              <div style={{height:'400px'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  density="compact"
                  autoPageSize={true}
                  sortModel={[{field: 'name', sort: 'desc'}]}
                  onCellClick={(e) => {
                    const arr = Array.from(e.element.classList);
                    if (!arr.includes('no-select')) {
                      handleClickOpen(e);
                    }
                  }}
                  className={classes.certificationRow}
                />
                <Dialog
                  fullScreen={fullScreen}
                  maxWidth="md"
                  fullWidth={true}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title"></DialogTitle>
                  <DialogContent>
                    {
                      edit &&
                      <CertificationForm certification={detailsSelected} setDetailsSelected={setDetailsSelected} roles={props.roles} contractors={props.contractors} setEdit={setEdit} project={props.project} loadCertifications={loadCertifications} />
                    }
                    {
                      !edit &&
                      <SubmissionDetails user={props.user} details={detailsSelected} employees={employees} setOpen={setOpen} setEdit={setEdit} loadCertifications={loadCertifications} project={props.project} company={props.company} />
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          }
          <div ref={certificationFormRef}>
          {
            props.project.status === USER_STATUS.SUPERADMIN &&
            <>
              <p>Add a certification to be on site at <strong>{props.project.details.project}</strong>:</p>
              {
                !showAdd &&
                <Button onClick={() => setShowAdd(true)} style={{margin:'10px'}} color="primary" variant="outlined">
                  Add New Certification
                </Button>
              }
              {
                showAdd &&
                <>
                  <Button onClick={() => setShowAdd(false)} style={{margin:'10px'}} color="secondary" variant="outlined">
                    Cancel Certification Add
                  </Button>
                  <CertificationForm roles={props.roles} contractors={props.contractors} project={props.project} loadCertifications={loadCertifications} />
                </>
              }
            </>
          }
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
