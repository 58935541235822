import React, { useState, useEffect } from "react";

import { Storage } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  extraLarge : {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}));

export default function ProfilePicture(props) {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = useState('');

  useEffect(() => {
    var mounted = true;
    if (props.employee && props.employee.picture && props.user) {
      load(props.employee, props.user).then(val => {
        if (mounted && val) {
          setProfilePicture(val);
        }
      });
    }
    return () => mounted = false;
  }, [props.employee, props.user]);

  const load = async (employee, user) => {
    var pictureUrl = null;
    if (employee.id === user.id) {
      pictureUrl = await Storage.get(employee.picture.key, {
        level: "protected"
      });
    } else {
      pictureUrl = await Storage.get(employee.picture.key, {
        level: "protected",
        identityId : employee.picture.identityId
      });
    }
    return pictureUrl;
  }

  const getClass = (size) => {
    switch(size) {
      case 'small':
        return classes.small;
      case 'large':
        return classes.large;
      case 'extra-large':
        return classes.extraLarge;
      default:
        return null;
    }
  }

  return (
    <Avatar alt={props.name} src={profilePicture} className={props.size ? getClass(props.size) : null} />
  );
}
